import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CONSTANTS, IS_FOR_EXPORT_PDF, IS_FOR_EXPORT_WORD, IS_PREVIEW_MODE } from 'Constants';
import { getWordCall } from 'Services';
import { debounce } from 'Utils';
import style from './PreviewWordControl.module.scss';

/**
 * PreviewWordControl.jsx
 *
 * @summary Preview only, annual report generate/download PDF control. Only render this component in preview app.
 *
 * @param {Object} props - Component props.
 * @prop {String} annualReportCodename - Annual report codename to generate/get PDF.
 * @prop {String} [pdfFileName] - If provided use this as downloaded PDF file name, else use default file name.
 */
function PreviewWordControl({ annualReportCodename, pdfFileName }) {
  if (!IS_PREVIEW_MODE || IS_FOR_EXPORT_PDF || IS_FOR_EXPORT_WORD) {
    return null;
  }

  const [pdfExists, setPdfExists] = useState(null);
  const [waitingPDF, setWaitingPDF] = useState(false);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false);

  const isProd = process.env?.REACT_APP_ENVIRONMENT?.toLowerCase() === CONSTANTS.ENV.PROD?.toLowerCase();

  const baseURL = isProd ? 'https://apps.transparency.gov.au/' : `https://${process.env?.REACT_APP_ENVIRONMENT?.toLowerCase()}-dart-be.dxau.digital`;

  const getPdfEndpoint = `${baseURL}/wordgenerate/DownloadDocument?annualreportcodename=${annualReportCodename}`;
  const generatePdfEndpoint = `${baseURL}/wordgenerate/generate?annualreportcodename=${annualReportCodename}`;
  const checkPdfStatusEndpoint = `${baseURL}/wordgenerate/CheckDocumentExists?annualreportcodename=${annualReportCodename}`;

  useEffect(() => {
    // on initial render, check if PDF exists or not
    getWordCall(checkPdfStatusEndpoint, undefined, true, true, false)
      .then((res) => {
        console.log(res);
        if (res) {
          if (res?.status === 202) {
            // if PDF generation is in progress
            setGeneratingPDF(true);
            checkPdfStatus();
            setWaitingPDF(true);
            console.log("Word Generating");
          } else {
            setGeneratingPDF(false);
            setPdfExists(true);
            setWaitingPDF(false);
            console.log("Word Exists");
          }
        }
      })
      .catch((err) => {
        if ([400, 500].includes(err?.response?.status)) {
          alert('Something went wrong. We cannot check if PDF already exists or not. You may try generate PDF again.');
        }

        // if error status is 404, meaning pdf doesn't exists
        setPdfExists(false);
        setWaitingPDF(false);
        setGeneratingPDF(false);
      });
  }, []);

  const checkPdfStatus = debounce(() => {
    getWordCall(checkPdfStatusEndpoint, undefined, true, true)
      .then((res) => {
        console.log(res);
        if (res) {
          if (res?.status === 202) {
            // if PDF generation is in progress
            setGeneratingPDF(true);
            checkPdfStatus();
            setWaitingPDF(true);
          } else {
            // file exists
            setGeneratingPDF(false);
            setWaitingPDF(false);
            setPdfExists(true);
          }
        } else {
          // Consider PDF is not ready - response is undefined
          checkPdfStatus();
        }
      })
      .catch((err) => {
        if ([400, 500].includes(err?.response?.status)) {
          alert('Something went wrong. We cannot check if PDF already exists or not. You may try generate PDF.');
          setWaitingPDF(false);
          setPdfExists(false);
          setGeneratingPDF(false);
        } else {
          // PDF is not ready yet (if error status is 404)
          setWaitingPDF(true);

          if (err?.response?.status === 404) {
            setPdfExists(false);
            setWaitingPDF(false);
            setGeneratingPDF(false);
          }

          checkPdfStatus();
        }
      });
  }, 10 * 1000);

  const generatePdf = () => {
    setWaitingPDF(true);
    setGeneratingPDF(true);
    // trigger generate pdf api always returns 200.
    getWordCall(generatePdfEndpoint, undefined, true, true);
    checkPdfStatus();
  };

  const downloadPdf = () => {
    setDownloadingPDF(true);
    getWordCall(getPdfEndpoint, { ...undefined, responseType: 'blob' }, true, true)
      .then((res) => {
        console.log(res);
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = fileURL;
        link.target = '_blank';
        if (pdfFileName) link.download = pdfFileName;
        link.click();
        window.URL.revokeObjectURL(fileURL);
        link.remove();
      })
      .finally(() => {
        setDownloadingPDF(false);
      });
  };

  return (
    <div className={style.accordionExportButtonWrapper}>
      <div id={style.previewARcontrolPDF}>
        <button disabled={waitingPDF || downloadingPDF || generatingPDF} onClick={generatePdf}>
          {generatingPDF ? 'Generating Word' : ''}
          {waitingPDF ? (
            <span className="spinner">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
          ) : pdfExists ? (
            'Regenerate Word'
          ) : (
            'Generate Word'
          )}
        </button>
        {!generatingPDF && (
          <button disabled={!pdfExists || waitingPDF || downloadingPDF || generatingPDF} title={pdfExists ? 'Download Word' : 'Please generate Word first.'} onClick={downloadPdf}>
            {downloadingPDF ? (
              <span className="spinner">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </span>
            ) : (
              'Download Word'
            )}
          </button>
        )}
      </div>
      <p>
      Depending on your network&apos;s security policy and default settings for Microsoft Office applications, you may need to follow these additional steps to populate all content correctly in Word once you have generated the document.
      <ol>
      <ol>
      <li>Enable Editing in Word, and ensure you are in &quot;Allow Editing&quot; mode (not &quot;Viewing&quot; mode).
      </li>

      <li>Highlight all content in document (eg, CTRL+A) and press F9 to generate &#8208; this will generate the Table of Contents, the List of Requirements page references, and the Alphabetical Index references.
      </li>
      <li>Select the &quot;Header&quot; of the document, right click, and select &quot;Update Field&quot; to generate Annual Report Title as well as Chapter/Section Title.
      </li>
      <li>To remove extra hidden text, go to File &gt; Options &gt; Display &gt; then uncheck the &quot;Hidden text&quot; and &quot;Show all formatting marks&quot; options &#8208;  this will ensure there is no hidden code, text or formatting issues displaying that will interfere with the presentation of your generated report.
      </li>
      </ol>

      </ol>




        
      </p>
    </div>
  );
}

PreviewWordControl.propTypes = {
  annualReportCodename: PropTypes.string.isRequired,
  pdfFileName: PropTypes.string,
};

export default PreviewWordControl;
