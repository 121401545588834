import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useSearchParams } from 'react-router-dom';
import { CONSTANTS, NO_RESULTS_FOUND, URL_QUERY_KEYS as QUERY } from 'Constants';
import { debounce, detectViewMode } from 'Utils';
import style from './Pagination.module.scss';
import icons from 'Assets/svgSprite.svg';

/**
 * Pagination.jsx
 *
 * @summary Render as Pagination. if current url does not have `page` query, it will append it.
 *
 * @param {Object} props - Component props.
 * @prop {Number} props.totalPages - Total number of pages. It must be a positive whole number larger than 0.
 * @prop {Function} [props.onPageChange] - If given, function will be triggered upon page number changes. Will always pass target page number to passed function.
 *
 * @description If page number is changed within this component, `page` query value will be updated accordingly. If `page` query value is invalid on initial load, it will be ignored and always consider current viewing page number is 1.
 */
function Pagination({ totalPages, onPageChange }) {
  const [searchParams] = useSearchParams();
  const [viewMode, setViewMode] = useState(detectViewMode());
  const [currentPage, setPageNumber] = useState(Number(searchParams.get(QUERY.PAGE_NUMBER)) || 1); // default current page as 1 if page query is not set
  const pageNumbs = {
    prev: currentPage - 1,
    next: currentPage + 1,
    firstPage: 1,
    lastPageNumberCanDisplay: 5,
  };
  const isDesktop = viewMode === CONSTANTS.VIEW_MODE.DESKTOP;
  const isTablet = viewMode === CONSTANTS.VIEW_MODE.TABLET;
  const isMobile = viewMode === CONSTANTS.VIEW_MODE.MOBILE;

  useEffect(() => {
    // NOTE: if `page` query is not a whole number || < 0 || larger than last page number, consider current page is 1
    const pageQuery = Number(searchParams.get(QUERY.PAGE_NUMBER));
    const invalidPageNumberQuery = !pageQuery || pageQuery % 1 !== 0 || pageQuery < 0 || pageQuery > totalPages;

    if (invalidPageNumberQuery) {
      updatePage(1);
    } else {
      setPageNumber(pageQuery);
    }
  }, [searchParams.get(QUERY.PAGE_NUMBER)]);

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  const targetQuery = (targetPageNum) => {
    const queries = [];

    if (!searchParams.has(QUERY.SEARCH_TERM)) {
      queries.push(`${QUERY.SEARCH_TERM}=`);
    }

    if (!searchParams.has(QUERY.PAGE_NUMBER)) {
      queries.push(`${QUERY.PAGE_NUMBER}=${targetPageNum}`);
    }

    for (const key of searchParams.keys()) {
      if (key === QUERY.PAGE_NUMBER) {
        queries.push(`${key}=${targetPageNum}`);
      } else {
        queries.push(`${key}=${searchParams.get(key)}`);
      }
    }

    return `?${queries.join('&')}`;
  };

  const updatePage = (newPageNumb) => {
    setPageNumber(newPageNumb);

    if (onPageChange) {
      onPageChange(newPageNumb);
    }
  };

  const renderLink = (targetPageNum, text = '', linkClass = [], iconId, disabled = false, overwriteAriaLabel = false) => {
    const linkContent = (
      <>
        {text}
        {iconId && (
          <svg>
            <use href={`${icons}#${iconId}`} />
          </svg>
        )}
      </>
    );

    if (disabled) {
      return (
        <button className={linkClass.join(' ')} disabled>
          {linkContent}
        </button>
      );
    }

    return (
      <Link
        className={['btn', ...linkClass].join(' ')}
        to={targetQuery(targetPageNum)}
        onClick={() => updatePage(targetPageNum)}
        aria-label={overwriteAriaLabel ? text : `to page ${targetPageNum}`}>
        {linkContent}
      </Link>
    );
  };

  const renderPageLinks = () => {
    const MAX_LINK_NUMBERS_TO_DISPLAY = isDesktop || isTablet ? 5 : 3;
    const list = [];
    let pageNumber;
    pageNumbs.lastPageNumberCanDisplay = isDesktop || isTablet ? 5 : 3;

    if (currentPage <= MAX_LINK_NUMBERS_TO_DISPLAY) {
      pageNumber = 2;
      const buttonType = currentPage === 1 ? 'activeState' : 'noFill';
      list.push(renderLink(1, 1, [buttonType, 'alwaysFresh', style.target]));
    }

    // if current page number > 5, add `...` link to previous page at the start of list except for mobile view
    if (currentPage > MAX_LINK_NUMBERS_TO_DISPLAY) {
      let modifier = (currentPage - (isMobile ? 3 : 6)) % (isMobile ? 3 : 4);
      pageNumber = currentPage - modifier;

      pageNumbs.lastPageNumberCanDisplay = pageNumber + 3;

      if (!isMobile) {
        if (pageNumbs.lastPageNumberCanDisplay > totalPages) {
          modifier = pageNumbs.lastPageNumberCanDisplay - totalPages;
          pageNumber -= modifier + 1;
        }

        list.push(renderLink(pageNumber - 1, '...', ['noFill', 'alwaysFresh', style.target]));
      }
    }

    // add list of page links
    while (list.length <= MAX_LINK_NUMBERS_TO_DISPLAY - 1 && pageNumber <= totalPages) {
      const buttonType = pageNumber === currentPage ? 'activeState' : 'noFill';
      list.push(renderLink(pageNumber, pageNumber, [buttonType, 'alwaysFresh', style.target]));
      pageNumber++;
    }

    // add `...` at the end if not on the last group that contains last page
    if (pageNumber <= totalPages && !isMobile) {
      const isLastPage = !(pageNumbs.lastPageNumberCanDisplay < totalPages);
      const buttonClasses = [pageNumber === currentPage ? 'activeState' : 'noFill', 'alwaysFresh', style.target];
      let lastItemText = isLastPage || pageNumber === totalPages ? totalPages : '...';

      list.push(renderLink(pageNumber, lastItemText, buttonClasses));
    }

    return list.map((link, i) => <li key={`${i}-to-${pageNumber}`}>{link}</li>);
  };

  const disablePrevs = currentPage === pageNumbs.firstPage;
  const disableNexts = currentPage === totalPages;

  return (
    <>
      {totalPages > 0 ? (
        <div id={style.pagination}>
          <div className={style.jumpers}>
            {renderLink(pageNumbs.prev, 'Previous', [style.prev], 'arrow', disablePrevs, true)}
            {isDesktop && renderLink(pageNumbs.firstPage, 'First page', [style.first, 'lightFill'], 'chevron', disablePrevs, true)}
          </div>

          <menu>{renderPageLinks()}</menu>

          <div className={style.jumpers}>
            {isDesktop && renderLink(totalPages, 'Last page', [style.last, 'lightFill'], 'chevron', disableNexts, true)}
            {renderLink(pageNumbs.next, 'Next', [style.next], 'arrow', disableNexts, true)}
          </div>
        </div>
      ) : (
        <span className={style.noResults}>{NO_RESULTS_FOUND}</span>
      )}
    </>
  );
}

Pagination.propTypes = {
  totalPages: function (props, propName, componentName) {
    /**
     * custom prop type check
     *
     * `totalPages` value must be a positive whole number larger or equal to 0.
     */
    const value = Number(props[propName]);
    if (typeof props[propName] !== 'number' || (!value && value !== 0) || value % 1 !== 0 || value < 0) {
      return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Prop value must be a positive whole number larger or equal to 0.`);
    }
  },
  onPageChange: PropTypes.func,
};

export default Pagination;
