import React from 'react';
import { Link } from 'react-router-dom';
import { Loading, PageHead } from 'Components';
import { PATHS } from 'Constants';
import loadingStyle from './StandardPageLoading.module.scss';
import style from '../StandardPage/StandardPage.module.scss';

/**
 * StandardPageLoading.jsx
 *
 * @summary This component shows the loading state page view for StandardPage page.
 */
function StandardPageLoading() {
  return (
    <>
      <PageHead pageTitle="&nbsp;" darkTree keepBackgroundImage />

      <section id={style.standardPageContent}>
        <div id={style.firstCol}>
          <Link to={PATHS.HOME.BASE} className="alwaysFresh" id={style.backToHome}>
            Back to Home
          </Link>
        </div>

        <div id={style.lastCol}>
          <Loading className={loadingStyle.loadingBody} />
          <Loading className={loadingStyle.loadingBody} />
        </div>
      </section>
    </>
  );
}

export default StandardPageLoading;
