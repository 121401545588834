import React, { Suspense, useEffect, useRef, useState, } from 'react';
import {
  Chart,
  DataExplorerTable,
  DataSetsFilterAccordion,
  DropdownSelect,
  FormField,
  Loading,
  PageHead,
  Tag,
} from 'Components';

import style from './DataExplorer.module.scss';
import { BODY_TYPE, CHART_TYPE, CONSTANTS, PATHS, URL_QUERY_KEYS } from 'Constants';
import { Await, useRouteLoaderData, useSearchParams, } from 'react-router-dom';
import { debounce, detectViewMode } from 'Utils';
import { DataExplorerLoading } from 'Pages';
import sprite from 'Assets/svgSprite.svg';
import { BASE_HEIGHT, BASE_WIDTH, DATA_EXPLORER_CHART_WIDTH, DATA_EXPLORER_URL_FILTER_GROUPS, HEIGHT_MODIFIER_BAR, HEIGHT_MODIFIER_LINE, SUBTEXT_MAX_CHARACTER_LIMIT, WIDTH_MODIFIER_LINE } from 'Constants/DataExplorer';
import icons from 'Assets/svgSprite.svg';
import GreyTree from 'Assets/Tree-Half-Grey.svg';
import { getDataExplorerData } from 'Services/Delivery';
import { CSVLink } from 'react-csv';


/**
 * DataExplorer.jsx
 *
 * @summary This component is page view for Data Explorer page.
 *
 */
function DataExplorer() {

  const [isAppliedButtonActive, setIsAppliedButtonActive] = useState(false);
  const [filterData, setFilterData] = useState({
    Location:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    Gender:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    EmploymentType:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    Engagement:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    APSBand:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    NameofContractedOrganisation:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    Portfolio:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    BodyType:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    Entity:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    ReportingYear:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    ContentType:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
  });
  const [emptyFilters, setEmptyFilters] = useState(structuredClone(filterData));
  const [appliedFilterData, setAppliedFilterData] = useState(structuredClone(filterData));
  const dataExplorerLoaderData = useRouteLoaderData(PATHS.DATA_EXPLORER.ID);
  const [viewMode, setViewMode] = useState(detectViewMode());
  const [urlParams, setUrlParams] = useSearchParams();
  const [datasetTableData, setDatasetTableData] = useState();
  // const [datasetTableDataNew, setDatasetTableDataNew] = useState(); // NEw  datasetTableDataNew
  const [datasetBarData, setDatasetBarData] = useState();
  const [datasetLineData, setDatasetLineData] = useState();

  const [disableCharts, setDisableCharts] = useState(true);
  const [fetchDataSetsLoading, setFetchDataSetsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [tableCategories, setTableCategories] = useState({});

  const [chartTitle, SetChartTitle] = useState("");

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  },[]);

  // Apply filters
  const applyFilters = (e) => {
    async function fetchData(searchParams) {
      let responceData = null;
      try {
        setDisableCharts(false);
        setFetchDataSetsLoading(false);

        setFetchDataSetsLoading(true);
        responceData = await getDataExplorerData(searchParams);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {

        if (responceData && !(Object.values(responceData).length == 0) && responceData.isError != true) {
          processData(responceData); //old code
          setErrorMessage('');
        } else {
          setErrorMessage("No data returned matching the filters selected, please try a different filter selection");
        }

        setFetchDataSetsLoading(false);
      }
    }

    // function filterDataFields(data, contractedOrganisationFilters) {
    //   return data.map(item => {
    //     return {
    //       ...item,
    //       datafields: item.datafields.flatMap(field => {
    //         if (typeof field.value === 'object') {
    //           return field.value
    //           .filter(org => org.excludeFromExplorer !== "false")
    //           .filter(org => contractedOrganisationFilters.some(filter => filter.returnValue === org['name of organisation']))
    //           .map(org => ({
    //             Entity: item.entity,
    //             ReportingPeriod: item.reportingPeriod,
    //             "Organisation": org['name of organisation'],
    //             "Expenditure": org["expenditure $'000(gst inc)"],
    //             "ABN": org["organisation abn"],
    //           }));
    //         } else {
    //           return [field];
    //         }
    //       })
    //     };
    //   });
    // }

    /*
    function processData(responceData) {
      console.log('processData - responceData');
        console.log(responceData);
      if (responceData) {
        // Data Explorer Table Processing
        const filteredData = filterDataFields(responceData, filterData.NameofContractedOrganisation.filterData?.filter((option) => option.selected === true));
        console.log('processData - filteredData');
        console.log(filteredData);
        

        setDatasetTableData(transformToTableData(filteredData,tableCategories));
        // Data Explorer Bar Graph Processing
        setDatasetBarData(transformBarGraphData(filteredData));
        // Data Explorer Linegraph Processing
        setDatasetLineData(transformToLineChartData(filteredData));

      } 
    }
    */

    function processData(responceData) {
      if (responceData) {

        console.log(tableCategories); //dummy       
        const transformedTableData = transformToTableData(responceData);
        setDatasetTableData(transformedTableData);
        setDatasetBarData(transformBarGraphData(transformedTableData));
        setDatasetLineData(transformToLineChartDataNew(transformedTableData));

      }

    }

    e.preventDefault();
    setAppliedFilterData(structuredClone(filterData));

    //sends out query for api responce
    const result = {
      tags: {},
      reportSelector: {}
    };

    for (const key in filterData) {
      if (filterData?.[key]) {
        const filterDataObject = filterData[key].filterData;
        const selectedValues = filterDataObject?.filter(item => item.selected)?.map(item => item.returnValue);

        if (selectedValues?.length > 0) {
          if (key == "Location" || key == "Gender" || key == "EmploymentType" || key ==  "Engagement" || key == "APSBand" || key == "NameofContractedOrganisation") {
            result["tags"][key] = selectedValues;
          } else  if (key == "Portfolio" || key ==  "BodyType" || key == "Entity" || key == "ReportingYear") {
            result["reportSelector"][key] = selectedValues;
          }
        }
      }
    }

    SetChartTitle(Object.keys(result["tags"]).map(key => {return filterData[key]?.displayName || key;}).join(', ') + " for selected Portfolios/Body Types/Entities by Reporting Period");

    console.log('filterData');
    console.log(filterData);
    setUrlParams("");
    setUrlParams((prevParams) => {
      Object.keys(DATA_EXPLORER_URL_FILTER_GROUPS).forEach((key) => {
        const filterGroup = DATA_EXPLORER_URL_FILTER_GROUPS[key].VALUE;
        const filterDataObj = filterData[filterGroup]?.filterData || [];
        const selectAll = filterData[filterGroup]?.selectAll || false;
        const selectedValues = selectAll
          ? "ALL"
          : filterDataObj
              .filter((item) => item.selected)
              .map((item) => item.returnValue)
              .join(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
        if (selectedValues){
          prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${filterGroup}`, selectedValues || '');
        }

      });
      return prevParams;
    });

    console.log('urlParams');
    console.log(urlParams);


    fetchData(result);

    setIsAppliedButtonActive(false);
  };

  // Clears filters, all applied filter and data set tables
  const clearFilters = () => {
    setFilterData(structuredClone(emptyFilters));
    setAppliedFilterData(structuredClone(emptyFilters));
    checkApplied();

    setUrlParams('');
  };

  // Used for displaying of dropdown options selection subtext info
  const numberOfDropdownOptionsSelected = (filterList) => {
    if (filterList?.filterData?.every((option) => option.selected === true)) {
      return 'All';
    }
    return `${filterList?.filterData?.filter((option) => option.selected === true).length}` || '0';
  };

  const isFilterDataSelected = (propName) => {
    if (filterData.propName && Array.isArray(filterData[propName].filterData)) {
      return filterData[propName].filterData.every(filterItem => filterItem.selected === true);
    } else {
      return false;
    }
  };

  const setAllFilterDataSelection = (propName, selectedValue) => {
    if (filterData[propName]) {

      const newFilterData = { ...filterData };
      newFilterData[propName].filterData = newFilterData[propName].filterData.map(filterItem => ({
        ...filterItem,
        selected: selectedValue
      }));
  
      newFilterData[propName].selectAll = selectedValue;
      setFilterData(newFilterData);
      checkApplied();
    }
  };


  //checked if the applied content is the same as the current filter content, if they are the same it disables the button otherwise it is enabled
  function checkApplied() {
    function deepEqual(obj1, obj2) {
      if (obj1 === obj2) {
        return true;
      }
    
      if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
      }
    
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
    
      if (keys1.length !== keys2.length) {
        return false;
      }
    
      for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }
    
      return true;
    }
    if (deepEqual(filterData, appliedFilterData)) {
      setIsAppliedButtonActive(false);
    } else {
      setIsAppliedButtonActive(true);
    }
  }

  const atLeastOneReportingYearSelected = () => {
    return filterData.ReportingYear.filterData.some((filterOption) => filterOption.selected === true);
  };

  const atLeastOneEntitySelected = () => {
    return filterData.Entity.filterData.some((filterOption) => filterOption.selected === true);
  };

  const atLeastOneBodyTypeSelected = () => {
    return filterData.BodyType.filterData.some((filterOption) => filterOption.selected === true);
  };

  const atLeastOnePortfolioSelected = () => {
    return filterData.Portfolio.filterData.some((filterOption) => filterOption.selected === true);
  };

  const atLeastOneSelectedInAll = () => {
    const filterCategories = [
      'Location',
      'Gender',
      'EmploymentType',
      'Engagement',
      'APSBand',
      'NameofContractedOrganisation'
    ];
  
    return filterCategories.some(category => 
      filterData[category].filterData.some(filterOption => filterOption.selected === true)
    );
  };

  const selectLocationFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.Location, selectedOption);
    setFilterData({ 
      ...filterData, 
      Location: {
        ...filterData.Location,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectGenderFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.Gender, selectedOption);
    setFilterData({ 
      ...filterData, 
      Gender: {
        ...filterData.Gender,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectEmploymentTypeFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.EmploymentType, selectedOption);
    setFilterData({ 
      ...filterData, 
      EmploymentType: {
        ...filterData.EmploymentType,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectEngagementFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.Engagement, selectedOption);
    setFilterData({ 
      ...filterData, 
      Engagement: {
        ...filterData.Engagement,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectAPSBandFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.APSBand, selectedOption);
    setFilterData({ 
      ...filterData, 
      APSBand: {
        ...filterData.APSBand,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectNameofContractedOrganisationFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.NameofContractedOrganisation, selectedOption);
    setFilterData({ 
      ...filterData, 
      NameofContractedOrganisation: {
        ...filterData.NameofContractedOrganisation,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectPortfolioFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.Portfolio, selectedOption);
    setFilterData({ 
      ...filterData, 
      Portfolio: {
        ...filterData.Portfolio,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectBodyTypeFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.BodyType, selectedOption);
    setFilterData({ 
      ...filterData, 
      BodyType: {
        ...filterData.BodyType,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectEntityFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.Entity, selectedOption);
    setFilterData({ 
      ...filterData, 
      Entity: {
        ...filterData.Entity,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };

  const selectReportingYearFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.ReportingYear, selectedOption);
    setFilterData({ 
      ...filterData, 
      ReportingYear: {
        ...filterData.ReportingYear,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  // Updates a dropdown selection option list with updated value from selected option. (Converts 'selected' property to true/false or with a specific override value)
  const updateDropdownSelection = (filterCategory, selectedOption, overrideValue) => {
    const updatedSelection = [...filterCategory.filterData];
    const foundSelectedOption = updatedSelection.find((option) => option.returnValue === selectedOption.returnValue);

    if (foundSelectedOption) {
      foundSelectedOption.selected = overrideValue !== undefined && overrideValue !== null ? overrideValue : !foundSelectedOption.selected;
    }

    const selectAll = updatedSelection.every(option => option.selected);

    return {
      selectAll:selectAll,
      filterData:updatedSelection
    };
  };

  function formatDisplayNames(data) {
    // Filter the array to include only items where selected is true
    const selectedItems = data.filter(item => item.selected);
  
    // Extract the display names from the filtered array
    const displayNames = selectedItems.map(item => item.display);
  
    // Join the display names with commas and an "and" at the end
    if (displayNames.length === 0) {
      return '';
    } else if (displayNames.length === 1) {
      return displayNames[0];
    } else if (displayNames.length === 2) {
      return displayNames.join(' and ');
    } else {
      let result = '';
      for (let i = 0; i < displayNames.length; i++) {
        const name = displayNames[i];
        if (result.length + name.length + 2 > SUBTEXT_MAX_CHARACTER_LIMIT) { // +2 for ', ' or ' and '
          return `${result} and others`;
        }
        if (i === displayNames.length - 1) {
          result += `and ${name}`;
        } else if (i === displayNames.length - 2) {
          result += `${name} `;
        } else {
          result += `${name}, `;
        }
      }
      return result;
    }
  }

  function createObjectFromArray(arr) {
    const obj = {};
    arr.forEach((key) => {
      obj[key] = ''+key+'';
    });
    console.log('createObjectFromArray');
    console.log(obj);
    return obj;
  }
 
  // function transformToTableData(dataArray, tableCategoryData) { // Old code
    
  //   console.log('transformToTableData - dataArray');
  //   console.log(dataArray);
  //   console.log('transformToTableData - tableCategoryData');
  //   console.log(tableCategoryData);
  //   const filteredTableData = dataArray.flatMap(item => {
  //     const { entity, reportingPeriod, datafields } = item;
  
  //     return datafields.map(dataField => {
  //       const { dataFieldName, value, unit } = dataField;
  //       const additionalData = {};
    
  //       if (dataFieldName) {
  //         const splitValues = dataFieldName.split(/ \| | - /);
  
  //         // Initialize all keys with null values
  //         for (const category of Object.keys(tableCategoryData)) {
  //           additionalData[category] = '-';
  //         }
    
  //         splitValues.forEach(splitValue => {
  //           for (const [category, values] of Object.entries(tableCategoryData)) {
  //             if (values.includes(splitValue.toLowerCase())) {
  //               additionalData[category] = splitValue;
  //             }
  //           }
  //         });
  //       } else {
  //         additionalData["Organisation"]= dataField["Organisation"];
  //         additionalData["Expenditure"]= dataField["Expenditure"];
  //         additionalData["ABN"]= dataField["ABN"];
  //       }

  
  //       return {
  //         Entity: entity,
  //         ReportingPeriod: reportingPeriod,
  //         DataFieldName: dataFieldName,
  //         Value: value,
  //         Units: unit,
  //         ...additionalData
  //       };
  //     });
  //   });
  
  //   return filteredTableData;
  // }

  function transformToTableData(dataArray) { // New Code   

    // let additionalData = {};
    let filteredTableData =null;

      filteredTableData = null;
      filteredTableData = dataArray.flatMap(item => {
        const { Classification, Statistic } = item;

        console.log('item filter Table');
        console.log(item);

        console.log('classification');
        console.log(Classification);
        var keyNames = Object.keys(Classification);
        console.log('keyNames');
        console.log(keyNames);

        var valueNames = Object.values(Classification);
        console.log('valueNames');
        console.log(valueNames);
        // console.log('classification-'+ classification.engagement);
        console.log('Reporting Period-'+ item['Reporting Period']);
        console.log('Statistic-'+ Statistic.Count.Value);

        console.log('KEyValue-'+ Classification[keyNames[0]]);

        let tableData = {};
        for (const prop of keyNames) {
          
          const propTitle = prop.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
          tableData[propTitle]= Classification[prop];
        }
        console.log('aaaa');
        tableData['Reporting Period'] = item['Reporting Period'];
        tableData['Value'] = Statistic.Count.Value;
        tableData['Units'] = Statistic.Count.Units;
        console.log(tableData);
        
        return tableData;      

      });
      console.log('Just out filteredTableData');
      console.log(filteredTableData);
    

        //////////////////////////////////////
  console.log('filteredTableData');
  console.log(filteredTableData);
    return filteredTableData;
  }

  function transformToLineChartDataNew(dataArray) {
    // Initialize the result object
    const dataByYear = {};

    ///////////  from Bar start

    const groupedByPeriod = {};

    dataArray.forEach((data) => {
        const period = data['Reporting Period'];
        groupedByPeriod[period] = groupedByPeriod[period] || [];
        groupedByPeriod[period].push(data);
    });

    console.log('Object.values(groupedByPeriod)');
    console.log(Object.values(groupedByPeriod));

 
  Object.values(groupedByPeriod).forEach( periodEntry => {
    const reportingPeriod = periodEntry[0]['Reporting Period'];

    if (!dataByYear[reportingPeriod]) {
      dataByYear[reportingPeriod] = [];
    }

    // const groupedByEntity = {};

    // periodEntry.forEach((perioddata) => {
    //     const entity = perioddata[Object.keys(perioddata)[0]];
    //     console.log('entity');
    //     console.log(entity);
    //     groupedByEntity[entity] = groupedByEntity[entity] || [];
    //     groupedByEntity[entity].push(perioddata);
    // });

 //   Object.values(groupedByEntity).forEach( entityGroup => { 

    
      periodEntry.forEach(rawData => {

           const rawLength = Object.keys(rawData).length ; 

            let key = "";
            for (let i = 0; i < rawLength-3; i++) {

              if(i==0){
                key += rawData[Object.keys(rawData)[i]] + ' - ';
              }
              else if(i==1) {
                key +=  rawData[Object.keys(rawData)[i]] ;
              }
              else {
                key += ' | '+ rawData[Object.keys(rawData)[i]] ;
              }            
             
            }

            if (!dataByYear[reportingPeriod][key]) {
              dataByYear[reportingPeriod][key] = 0;
            }

            dataByYear[reportingPeriod][key] = rawData['Value'];      


            });         
           
           

   // });///

  

  });
  console.log('dataByYear Line') ;
  console.log(dataByYear) ;

    /////// Bar end //////
  
    // Process each entry in the dataArray
    // dataArray.forEach(report => {
    //   // const reportingPeriod = report.reportingPeriod;
    //   const reportingPeriod = report['Reporting Period'];
  
    //   if (!dataByYear[reportingPeriod]) {
    //     dataByYear[reportingPeriod] = {};
    //   }
  
    //   report.datafields.forEach(field => {
    //     let key = "";
    //     if (field.dataFieldName) {
    //       key = `${report.entity} - ${field.dataFieldName}`;
    //     } else if (field.Expenditure) {
    //       key = `${report.entity} - ${field.Organisation}`;
    //     }
        
    //     if (!dataByYear[reportingPeriod][key]) {
    //       dataByYear[reportingPeriod][key] = 0;
    //     }


    //     if (field.dataFieldName) {
    //       dataByYear[reportingPeriod][key] += field.value;
    //     } else if (field.Expenditure) {
    //       dataByYear[reportingPeriod][key] += field.Expenditure;
    //     }
        
    //   });
    // });
  
    // Transform the aggregated data into the desired format
    const chartData = Object.entries(dataByYear).map(([reportingPeriod, data]) => {
      return {
        name: reportingPeriod,
        ...data
      };
    });
  
    // Custom sorting function for financial year periods
    const sortFinancialYears = (a, b) => {
      const parseYear = (year) => {
        const parts = year.split('-');
        return parts.length === 1 ? [parseInt(parts[0]), parseInt(parts[0])] : parts.map(part => parseInt(part));
      };
  
      const [startA, endA] = parseYear(a.name);
      const [startB, endB] = parseYear(b.name);
  
      if (startA !== startB) {
        return startA - startB;
      }
      return endA - endB;
    };
  
    // Sort the chartData by financial year periods
    chartData.sort(sortFinancialYears);

    const totalDatafieldsCount = dataArray.length;
    // const totalDatafieldsCount = dataArray.reduce((totalCount, report) => totalCount + (Array.isArray(report.datafields) ? report.datafields.length : 0), 0);

    const chartGroup = {
      chartGroupHeading: "",
      chartData: [
        {
          heading: "",
          data: chartData,
          width: BASE_WIDTH + (chartData.length * WIDTH_MODIFIER_LINE),
          height: BASE_HEIGHT + (totalDatafieldsCount * HEIGHT_MODIFIER_LINE),
        }
      ]
    };
    const result = [chartGroup];

    console.log('result line');
    console.log(result);
  
    return result;
  }

  //
//   function transformToLineChartData(dataArray) {
//     // Initialize the result object
//     const dataByYear = {};
  
//     // Process each entry in the dataArray
//     dataArray.forEach(report => {
//       const reportingPeriod = report.reportingPeriod;
  
//       if (!dataByYear[reportingPeriod]) {
//         dataByYear[reportingPeriod] = {};
//       }
//       console.log('transformToLineChartData Old');
//       console.log('dataArray');
//       console.log(dataArray);
//       report.datafields.forEach(field => {
//         let key = "";
//         if (field.dataFieldName) {
//           key = `${report.entity} - ${field.dataFieldName}`;
//         } else if (field.Expenditure) {
//           key = `${report.entity} - ${field.Organisation}`;
//         }

//         console.log();
//         console.log(key);
        
//         if (!dataByYear[reportingPeriod][key]) {
//           dataByYear[reportingPeriod][key] = 0;
//         }
//  console.log(dataByYear);
//  console.log('dataByYear');

//         if (field.dataFieldName) {
//           dataByYear[reportingPeriod][key] += field.value;
//         } else if (field.Expenditure) {
//           dataByYear[reportingPeriod][key] += field.Expenditure;
//         }
        
//       });
//     });
  
//     // Transform the aggregated data into the desired format
//     const chartData = Object.entries(dataByYear).map(([reportingPeriod, data]) => {
//       return {
//         name: reportingPeriod,
//         ...data
//       };
//     });
  
//     // Custom sorting function for financial year periods
//     const sortFinancialYears = (a, b) => {
//       const parseYear = (year) => {
//         const parts = year.split('-');
//         return parts.length === 1 ? [parseInt(parts[0]), parseInt(parts[0])] : parts.map(part => parseInt(part));
//       };
  
//       const [startA, endA] = parseYear(a.name);
//       const [startB, endB] = parseYear(b.name);
  
//       if (startA !== startB) {
//         return startA - startB;
//       }
//       return endA - endB;
//     };
  
//     // Sort the chartData by financial year periods
//     chartData.sort(sortFinancialYears);

//     const totalDatafieldsCount = dataArray.reduce((totalCount, report) => totalCount + (Array.isArray(report.datafields) ? report.datafields.length : 0), 0);

//     const chartGroup = {
//       chartGroupHeading: "",
//       chartData: [
//         {
//           heading: "",
//           data: chartData,
//           width: BASE_WIDTH + (chartData.length * WIDTH_MODIFIER_LINE),
//           height: BASE_HEIGHT + (totalDatafieldsCount * HEIGHT_MODIFIER_LINE),
//         }
//       ]
//     };
//     const result = [chartGroup];
  
//     return result;
//   }

  // const transformBarGraphData = (dataArray) => {
  //   // Process each entry in the dataArray
  //   const dataByYear = {};
  
  //   dataArray.forEach(entry => {
  //     const reportingPeriod = entry.reportingPeriod;
  
  //     if (!dataByYear[reportingPeriod]) {
  //       dataByYear[reportingPeriod] = [];
  //     }
  
  //     const entityData = {
  //       entity: entry.entity,
  //       data: {}
  //     };

  
  //     entry.datafields.forEach(field => {
  //       if (field.dataFieldName){
  //         entityData.data[field.dataFieldName] = field.value;
  //       } else if (field.Expenditure) {
  //         entityData.data[field.Organisation] = field.Expenditure;
  //       }
  //     });
  
  //     dataByYear[reportingPeriod].push(entityData);
  //   });
  
  //   const result = Object.entries(dataByYear).map(([reportingPeriod, entities]) => {
  //     const chartData = entities.map(entity => ({
  //       name: entity.entity,
  //       ...entity.data
  //     }));

  //     const totalDatafieldsCount = dataArray.reduce((totalCount, report) => totalCount + (Array.isArray(report.datafields) ? report.datafields.length : 0), 0);
  
  //     return {
  //       chartGroupHeading: reportingPeriod,
  //       chartData: [{
  //         heading: '',
  //         data: chartData,
  //         height: BASE_HEIGHT + (totalDatafieldsCount * HEIGHT_MODIFIER_BAR),
  //       }]
  //     };
  //   });
  
  //   // Sort the result by reporting year
  //   result.sort((a, b) => {
  //     const [startYearA, endYearA] = a.chartGroupHeading.split('-').map(Number);
  //     const [startYearB, endYearB] = b.chartGroupHeading.split('-').map(Number);
  
  //     if (startYearA !== startYearB) {
  //       return startYearA - startYearB;
  //     }
  //     return endYearA - endYearB;
  //   });
  
  //   return result;
  // };

  const transformBarGraphData = (dataArray) => {
    // Process each entry in the dataArray
    // const dataByYear = {};
    const dataByYear = {};
//////////////////// NEw //////////
    const groupedByPeriod = {};

    dataArray.forEach((data) => {
        const period = data['Reporting Period'];
        groupedByPeriod[period] = groupedByPeriod[period] || [];
        groupedByPeriod[period].push(data);
    });

    console.log('Object.values(groupedByPeriod)');
    console.log(Object.values(groupedByPeriod));

 
  Object.values(groupedByPeriod).forEach( periodEntry => {
    const reportingPeriodNew = periodEntry[0]['Reporting Period'];

    if (!dataByYear[reportingPeriodNew]) {
      dataByYear[reportingPeriodNew] = [];
    }

    const groupedByEntity = {};

    periodEntry.forEach((perioddata) => {
        const entity = perioddata[Object.keys(perioddata)[0]];
        console.log('entity');
        console.log(entity);
        groupedByEntity[entity] = groupedByEntity[entity] || [];
        groupedByEntity[entity].push(perioddata);
    });

    Object.values(groupedByEntity).forEach( entityGroup => { 

      const ent = entityGroup[0][Object.keys(entityGroup[0])[0]];
      const entityData = {
        entity: ent,
        data: {}
      };
            entityGroup.forEach(rawData => {

           const rawLength = Object.keys(rawData).length ; 

            let txt = "";
            for (let i = 1; i < rawLength-3; i++) {

              if(i==1){
                txt += rawData[Object.keys(rawData)[i]] ;
              }
              else {
                txt +=  ' | '+ rawData[Object.keys(rawData)[i]] ;
              }
             
            }
            entityData.data[txt] = rawData['Value'];
            });
             
           
            dataByYear[reportingPeriodNew].push(entityData);
           

    });

  

  });

  const result = Object.entries(dataByYear).map(([reportingPeriod, entities]) => {
    const chartData = entities.map(entity => ({
      name: entity.entity,
      ...entity.data
    }));

    const totalDatafieldsCount = dataArray.length;

    return {
      chartGroupHeading: reportingPeriod,
      chartData: [{
        heading: '',
        data: chartData,
        height: BASE_HEIGHT + (totalDatafieldsCount * HEIGHT_MODIFIER_BAR),
      }]
    };
  });

  result.sort((a, b) => {
      const [startYearA, endYearA] = a.chartGroupHeading.split('-').map(Number);
      const [startYearB, endYearB] = b.chartGroupHeading.split('-').map(Number);
  
      if (startYearA !== startYearB) {
        return startYearA - startYearB;
      }
      return endYearA - endYearB;
    }); 
  
    return result;
  };
  
  const [chartType, setChartType] = useState(CHART_TYPE.TABLE);
  const [isChartVertical, setIsChartVertical] = useState(false);
  const [showLabels, setShowLabels] = useState(false);

  const [CSVTableData, setCSVTableData] = useState([]);

  const csvLinkRef = useRef(null);

  const downloadCSV = (csvHeadings, csvData) => {
    new Promise((resolve) => {
      constructCSVData(csvHeadings, csvData);
      resolve();
    }).then(() => {
      csvLinkRef.current.link.click();
    });
  };

  const constructCSVData = (csvHeadings, csvData) => {
    if (csvData.length === 0) {
      setCSVTableData([]);
      return;
    }
  
    // Determine which headings have corresponding data in csvData
    const validHeadings = Object.keys(csvHeadings).filter(heading => 
      csvData.some(item => item[heading])
    );
  
    console.log('csvHeadings');
    console.log(csvHeadings);
    // Construct the header row based on valid headings
    const headerRow = validHeadings.map(heading => csvHeadings[heading]);
  
    const result = [headerRow];
  
    // Construct each row based on valid headings
    csvData.forEach(item => {
      const row = validHeadings.map(heading => item[heading] || '');
      result.push(row);
    });
  
    setCSVTableData(result);
  };
  


  return (
    <>
      <>
        <PageHead
          pageTitle={PATHS.DATA_EXPLORER.TITLE}
          keepBackgroundImage={viewMode !== CONSTANTS.VIEW_MODE.MOBILE}>
          <span>
            Combine data points across all entities and reporting periods into a singular view.<br/><br/>

            The information and data displayed in these data sets does not supersede the published annual reports produced by Commonwealth entities and companies.
          </span>
        </PageHead>
        <div className={style.dataSetsContainer}>
          <h2>Select a data set to view</h2>
          <p className={style.bold}>Learn more about entity and company body types</p>
          <div className={style.entityTypes}>
            <span>
              <Tag displayText={BODY_TYPE.NCE.TAG} fullText={BODY_TYPE.NCE.TEXT} />
              <a className="alwaysFresh" href="https://www.finance.gov.au/about-us/glossary/pgpa/term-non-corporate-commonwealth-entity-nce" rel="noreferrer" target="_blank">
                {BODY_TYPE.NCE.TEXT}
                <svg>
                  <use href={`${sprite}#open-in-new-tab`} />
                </svg>
              </a>
            </span>
            <span>
              <Tag displayText={BODY_TYPE.CCE.TAG} fullText={BODY_TYPE.CCE.TEXT} />
              <a className="alwaysFresh" href="https://www.finance.gov.au/about-us/glossary/pgpa/term-corporate-commonwealth-entity-cce" rel="noreferrer" target="_blank">
                {BODY_TYPE.CCE.TEXT}
                <svg>
                  <use href={`${sprite}#open-in-new-tab`} />
                </svg>
              </a>
            </span>
            <span>
              <Tag displayText={BODY_TYPE.CC.TAG} fullText={BODY_TYPE.CC.TEXT} />
              <a className="alwaysFresh" href="https://www.finance.gov.au/about-us/glossary/pgpa/term-commonwealth-company" rel="noreferrer" target="_blank">
                {BODY_TYPE.CC.TEXT}
                <svg>
                  <use href={`${sprite}#open-in-new-tab`} />
                </svg>
              </a>
            </span>
          </div>
        </div>
      </>
      <Suspense fallback={<DataExplorerLoading/>}>
        <Await resolve={dataExplorerLoaderData}>
          {(resolvedData) => {
            //
            useEffect(() => {
              setTableCategories(resolvedData.tableCategories);
              const updatedFilterData = { ...resolvedData.filters };



              setEmptyFilters(structuredClone(updatedFilterData));
              setAppliedFilterData({});

              Object.keys(DATA_EXPLORER_URL_FILTER_GROUPS).forEach((key) => {
                const filterGroup = DATA_EXPLORER_URL_FILTER_GROUPS[key].VALUE;
                const urlParam = urlParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${filterGroup}`);
                const filterOptions = resolvedData.filters[filterGroup]?.filterData || [];

                if (urlParam) {
                  if (urlParam == "ALL") {
                    updatedFilterData[filterGroup] = {
                      ...updatedFilterData[filterGroup],
                      displayName: resolvedData.filters[filterGroup].displayName,
                      filterData: filterOptions.map((option) => ({
                        ...option,
                        selected: true,
                      })),
                      selectAll: true,
                    };
                  } else {
                    const selectedValues = urlParam.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
                    updatedFilterData[filterGroup] = {
                      ...updatedFilterData[filterGroup],
                      displayName: resolvedData.filters[filterGroup].displayName,
                      filterData: filterOptions.map((option) => ({
                        ...option,
                        selected: selectedValues.includes(option.returnValue),
                      })),
                      selectAll: filterOptions.every((option) => selectedValues.includes(option.returnValue)),
                    };
                  }
                }
              });
            
              setFilterData(structuredClone(updatedFilterData));
              setIsAppliedButtonActive(true);  
            }, []);

            return (
              <>
                {/* Dataset Filters */}
                <form className={style.sectionContainer} onSubmit={(e) => e.preventDefault()}>
                  {
                    filterData && (
                      <>
                        <DataSetsFilterAccordion title="Select Attributes" isRequired={true}  startingOpenState={true}>
                          <div className={style.titleArea}>
                            <span className={[style.filterTitle, style.isRequired].join(' ')}></span>
                            <br/>
                            <p>Select Attributes below to generate a query. Please note that we are limited slightly with what data has been collected, and you will not be able to click &quot;Apply&quot; for any set of Attributes we do not have data for.</p>

                            <p>We do not collect APS Band by location, for instance, so the &quot;APS Band&quot; and &quot;Location&quot; Attributes will currently not produce any data.</p>

                            <p>Similarly for &quot;Name of Contracted Organisation&quot; and any other Attribute.</p>

                            <p>&quot;Reporting Period&quot; and at least one selection from either &quot;Portfolio&quot;, &quot;Body Type&quot; or &quot;Entities and Companies&quot; is mandatory.</p>

                            <p>Source: This data has been provided by Commonwealth entities and companies to represent a subset of their published annual reports. To see the data they provided for a given reporting period, please visit the <a href="/data-sets">Data sets</a> page.</p>

                            <p>We welcome any <a href="/feedback">Feedback</a> or questions on the data captured on this page.</p>
                          </div>
      
                          <div className={style.filterList}>
                            <div className={style.upperBlockContainer}>
                              {/* Location Filter */}
                              <div className={style.filterCardContainer}>
                                {filterData.Location.filterData && (
                                  <FormField
                                    title={filterData.Location.displayName}
                                    isRequired={false}
                                    showSubText
                                    subText={formatDisplayNames(filterData.Location.filterData)}
                                  >
                                    <div className={style.filterContainer}>
                                      <input
                                        className={!isFilterDataSelected(filterData.Location) && filterData.Location.selectAll ? 'checkbox' : ''}
                                        type="checkbox"
                                        checked={filterData.Location.selectAll}
                                        onChange={(e) => setAllFilterDataSelection("Location", e.target.checked)}
                                      />
                                      <DropdownSelect
                                        dropdownOptions={filterData.Location.filterData}
                                        selectOptionFunction={selectLocationFilter}
                                        placeholderText={numberOfDropdownOptionsSelected(filterData.Location) + " locations selected"}
                                        isMultiSelect
                                        renderAsFixed
                                      >
                                      </DropdownSelect>
                                    </div>
                                  </FormField>
                                )}
                              </div>

                              {/* Gender Filter */}
                              <div className={style.filterCardContainer}>
                                {filterData.Gender.filterData && (
                                  <FormField
                                    title={filterData.Gender.displayName}
                                    isRequired={false}
                                    showSubText
                                    subText={formatDisplayNames(filterData.Gender.filterData)}
                                  >
                                    <div className={style.filterContainer}>
                                      <input
                                        className={!isFilterDataSelected(filterData.Gender) && filterData.Gender.selectAll ? 'checkbox' : ''}
                                        type="checkbox"
                                        checked={filterData.Gender.selectAll}
                                        onChange={(e) => setAllFilterDataSelection("Gender", e.target.checked)}
                                      />
                                      <DropdownSelect
                                        dropdownOptions={filterData.Gender.filterData}
                                        selectOptionFunction={selectGenderFilter}
                                        placeholderText={numberOfDropdownOptionsSelected(filterData.Gender) + " genders selected"}
                                        isMultiSelect
                                        renderAsFixed
                                      >
                                      </DropdownSelect>
                                    </div>
                                  </FormField>
                                )}
                              </div>

                              {/* Employment Type Filter */}
                              <div className={style.filterCardContainer}>
                                {filterData.EmploymentType.filterData && (
                                  <FormField
                                    title={filterData.EmploymentType.displayName}
                                    isRequired={false}
                                    showSubText
                                    subText={formatDisplayNames(filterData.EmploymentType.filterData)}
                                  >
                                    <div className={style.filterContainer}>
                                      <input
                                        className={!isFilterDataSelected(filterData.EmploymentType) && filterData.EmploymentType.selectAll ? 'checkbox' : ''}
                                        type="checkbox"
                                        checked={filterData.EmploymentType.selectAll}
                                        onChange={(e) => setAllFilterDataSelection("EmploymentType", e.target.checked)}
                                      />
                                      <DropdownSelect
                                        dropdownOptions={filterData.EmploymentType.filterData}
                                        selectOptionFunction={selectEmploymentTypeFilter}
                                        placeholderText={numberOfDropdownOptionsSelected(filterData.EmploymentType) + " employment types selected"}
                                        isMultiSelect
                                        renderAsFixed
                                      >
                                      </DropdownSelect>
                                    </div>
                                  </FormField>
                                )}
                              </div>

                              {/* Engagement Type Filter */}
                              <div className={style.filterCardContainer}>
                                {filterData.Engagement.filterData && (
                                  <FormField
                                    title={filterData.Engagement.displayName}
                                    isRequired={false}
                                    showSubText
                                    subText={formatDisplayNames(filterData.Engagement.filterData)}
                                  >
                                    <div className={style.filterContainer}>
                                      <input
                                        className={!isFilterDataSelected(filterData.Engagement) && filterData.Engagement.selectAll ? 'checkbox' : ''}
                                        type="checkbox"
                                        checked={filterData.Engagement.selectAll}
                                        onChange={(e) => setAllFilterDataSelection("Engagement", e.target.checked)}
                                      />
                                      <DropdownSelect
                                        dropdownOptions={filterData.Engagement.filterData}
                                        selectOptionFunction={selectEngagementFilter}
                                        placeholderText={numberOfDropdownOptionsSelected(filterData.Engagement) + " engagement types selected"}
                                        isMultiSelect
                                        renderAsFixed
                                      >
                                      </DropdownSelect>
                                    </div>
                                  </FormField>
                                )}
                              </div>

                              {/* APS Filter */}
                              <div className={style.filterCardContainer}>
                                {filterData.APSBand.filterData && (
                                  <FormField
                                    title={filterData.APSBand.displayName}
                                    isRequired={false}
                                    showSubText
                                    subText={formatDisplayNames(filterData.APSBand.filterData)}
                                  >
                                    <div className={style.filterContainer}>
                                      <input
                                        className={!isFilterDataSelected(filterData.APSBand) && filterData.APSBand.selectAll ? 'checkbox' : ''}
                                        type="checkbox"
                                        checked={filterData.APSBand.selectAll}
                                        onChange={(e) => setAllFilterDataSelection("APSBand", e.target.checked)}
                                      />
                                      <DropdownSelect
                                        dropdownOptions={filterData.APSBand.filterData}
                                        selectOptionFunction={selectAPSBandFilter}
                                        placeholderText={numberOfDropdownOptionsSelected(filterData.APSBand) + " APS bands selected"}
                                        isMultiSelect
                                        renderAsFixed
                                      >
                                      </DropdownSelect>
                                    </div>
                                  </FormField>
                                )}
                              </div>

                              {/* Name of Contracted Organisation Filter */}
                              <div className={style.filterCardContainer}>
                                {filterData.NameofContractedOrganisation.filterData && (
                                  <FormField
                                    title={filterData.NameofContractedOrganisation.displayName}
                                    isRequired={false}
                                    showSubText
                                    subText={formatDisplayNames(filterData.NameofContractedOrganisation.filterData)}
                                  >
                                    <div className={style.filterContainer}>
                                      <input
                                        className={!isFilterDataSelected(filterData.NameofContractedOrganisation) && filterData.NameofContractedOrganisation.selectAll ? 'checkbox' : ''}
                                        type="checkbox"
                                        checked={filterData.NameofContractedOrganisation.selectAll}
                                        onChange={(e) => setAllFilterDataSelection("NameofContractedOrganisation", e.target.checked)}
                                      />
                                      <DropdownSelect
                                        dropdownOptions={filterData.NameofContractedOrganisation.filterData}
                                        selectOptionFunction={selectNameofContractedOrganisationFilter}
                                        placeholderText={numberOfDropdownOptionsSelected(filterData.NameofContractedOrganisation) + " contractors selected"}
                                        inputEnabled={true}
                                        isMultiSelect
                                        renderAsFixed
                                      >
                                      </DropdownSelect>
                                    </div>
                                  </FormField>
                                )}
                              </div>
                            </div>

                            <div className={style.lowerBlockContainer}>
                              {/* Portfolio Filter */}
                              <div className={style.filterCardContainer}>
                                {filterData.Portfolio.filterData && (
                                  <FormField
                                    title={filterData.Portfolio.displayName}
                                    isRequired={false}
                                    showSubText
                                    subText={formatDisplayNames(filterData.Portfolio.filterData)}
                                  >
                                    <div className={style.filterContainer}>
                                      <input
                                        className={!isFilterDataSelected(filterData.Portfolio) && filterData.Portfolio.selectAll ? 'checkbox' : ''}
                                        type="checkbox"
                                        checked={filterData.Portfolio.selectAll}
                                        onChange={(e) => setAllFilterDataSelection("Portfolio", e.target.checked)}
                                      />
                                      <DropdownSelect
                                        dropdownOptions={filterData.Portfolio.filterData}
                                        selectOptionFunction={selectPortfolioFilter}
                                        placeholderText={numberOfDropdownOptionsSelected(filterData.Portfolio) + " portfolios selected"}
                                        isMultiSelect
                                        renderAsFixed
                                      >
                                      </DropdownSelect>
                                    </div>
                                  </FormField>
                                )}
                              </div>

                              {/* Body Type Filter */}
                              <div className={style.filterCardContainer}>
                                {filterData.BodyType.filterData && (
                                  <FormField
                                    title={filterData.BodyType.displayName}
                                    isRequired={false}
                                    showSubText
                                    subText={formatDisplayNames(filterData.BodyType.filterData)}
                                  >
                                    <div className={style.filterContainer}>
                                      <input
                                        className={!isFilterDataSelected(filterData.BodyType) && filterData.BodyType.selectAll ? 'checkbox' : ''}
                                        type="checkbox"
                                        checked={filterData.BodyType.selectAll}
                                        onChange={(e) => setAllFilterDataSelection("BodyType", e.target.checked)}
                                      />
                                      <DropdownSelect
                                        dropdownOptions={filterData.BodyType.filterData}
                                        selectOptionFunction={selectBodyTypeFilter}
                                        placeholderText={numberOfDropdownOptionsSelected(filterData.BodyType) + " body types selected"}
                                        isMultiSelect
                                        renderAsFixed
                                      >
                                      </DropdownSelect>
                                    </div>
                                  </FormField>
                                )}
                              </div>

                              {/* Entity Filter */}
                              <div className={style.filterCardContainer}>
                                {filterData.Entity.filterData && (
                                  <FormField
                                    title={filterData.Entity.displayName}
                                    isRequired={false}
                                    showSubText
                                    subText={formatDisplayNames(filterData.Entity.filterData)}
                                  >
                                    <div className={style.filterContainer}>
                                      <input
                                        className={!isFilterDataSelected(filterData.Entity) && filterData.Entity.selectAll ? 'checkbox' : ''}
                                        type="checkbox"
                                        checked={filterData.Entity.selectAll}
                                        onChange={(e) => setAllFilterDataSelection("Entity", e.target.checked)}
                                      />

                                      <DropdownSelect
                                        dropdownOptions={filterData.Entity.filterData}
                                        selectOptionFunction={selectEntityFilter}
                                        placeholderText={numberOfDropdownOptionsSelected(filterData.Entity) + " entities/companies selected"}
                                        inputEnabled={true}
                                        isMultiSelect
                                        renderAsFixed
                                      >
                                      </DropdownSelect>
                                    </div>
                                  </FormField>
                                )}
                              </div>

                              {/* Reporting Year Filter */}
                              <div className={style.filterCardContainer}>
                                {filterData.ReportingYear.filterData && (
                                  <FormField
                                    title={"Reporting Period"}
                                    isRequired={false}
                                    showSubText
                                    subText={formatDisplayNames(filterData.ReportingYear.filterData)}
                                  >
                                    <div className={style.filterContainer}>
                                      <input
                                        className={!isFilterDataSelected(filterData.ReportingYear) && filterData.ReportingYear.selectAll ? 'checkbox' : ''}
                                        type="checkbox"
                                        checked={filterData.ReportingYear.selectAll}
                                        onChange={(e) => setAllFilterDataSelection("ReportingYear", e.target.checked)}
                                      />
                                      <DropdownSelect
                                        dropdownOptions={filterData.ReportingYear.filterData}
                                        selectOptionFunction={selectReportingYearFilter}
                                        placeholderText={numberOfDropdownOptionsSelected(filterData.ReportingYear) + " reporting periods selected"}
                                        isMultiSelect
                                        renderAsFixed
                                      >
                                      </DropdownSelect>
                                    </div>
                                  </FormField>
                                )}
                              </div>
                            </div>
                          </div>

                        </DataSetsFilterAccordion>
      
                        <div className={style.applyFilters}>
                          <div className={style.groupedButtons}>
                            <div className={style.filterButtons}>
                              <button onClick={clearFilters} className="noFill">
                                Clear
                              </button>
                              <button 
                                onClick={applyFilters} 
                                disabled={
                                  !isAppliedButtonActive || 
                                  !((atLeastOneEntitySelected()|| atLeastOnePortfolioSelected() || atLeastOneBodyTypeSelected()) 
                                  && atLeastOneReportingYearSelected()
                                  && atLeastOneSelectedInAll()
                                )}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                </form>

                {!disableCharts && (
                  errorMessage !== '' ? (
                    <p>{errorMessage}</p>
                  ) : (
                    <>
                    <div className={style.controlWrapper}>
                      <span className={style.label}>View data as:</span>
                      <div className={style.buttonWrapper}>
                        <div>
                          <button disabled={disableCharts} className={chartType === CHART_TYPE.TABLE ? 'activeState' : null} onClick={() => setChartType(CHART_TYPE.TABLE)}>
                            Table
                            <svg>
                              <use href={`${icons}#stacked-bar-graph`} />
                            </svg>
                          </button>
                          <button disabled={disableCharts} className={chartType === CHART_TYPE.BAR ? 'activeState' : null} onClick={() => setChartType(CHART_TYPE.BAR)}>
                            Bar chart
                            <svg>
                              <use href={`${icons}#bar-graph`} />
                            </svg>
                          </button>
                          <button disabled={disableCharts} className={chartType === CHART_TYPE.TIME_PLOT ? 'activeState' : null} onClick={() => setChartType(CHART_TYPE.TIME_PLOT)}>
                            Time Series Plot Chart
                            <svg>
                              <use href={`${icons}#stacked-bar-graph`} />
                            </svg>
                          </button>

                        </div>
              
                        {!disableCharts && (
                          <div>
                            {datasetTableData && chartType === CHART_TYPE.TABLE && (
                              <>
                                {/* <button
                                  onClick={() => downloadCSV(
                                    {
                                      Entity: DATA_EXPLORER_URL_FILTER_GROUPS.ENTITY.DISPLAY_NAME,
                                      ReportingPeriod: DATA_EXPLORER_URL_FILTER_GROUPS.REPORTING_YEAR.DISPLAY_NAME,
                                      "Employment Type":"Employment Type",
                                      Engagement:"Engagement",
                                      Gender:"Gender",
                                      "APS Band":"APS Band",
                                      Location:"Location",
                                      "Organisation": "Organisation",
                                      "ABN":"ABN",
                                      "Expenditure": "Expenditure",
                                      Value: 'Value',
                                      Units: "Units",
                                    },
                                    datasetTableData
                                    )}
                                  className="lightFill"
                                    >
                                  Export as CSV
                                </button> */}
                                   <button
                                  onClick={() => downloadCSV( 
                                    createObjectFromArray(Object.keys(datasetTableData[0])),
                                    datasetTableData
                                    )}
                                  className="lightFill"
                                    >
                                  Export as CSV
                                </button>
                                <CSVLink
                                  ref={csvLinkRef} 
                                  data={CSVTableData}
                                  uFEFF={false}
                                  filename={`Data Explorer CSV`}

                                  >
                                </CSVLink>
                              </>

                            )}
                            <label className={['buttonStyle lightFill', style.abolishedButton].join(' ')} disabled={chartType === CHART_TYPE.TABLE ? true : false }>
                              <input
                                id="abolishedButton"
                                type="checkbox"
                                checked={showLabels}
                                onChange={() => {
                                  setShowLabels(!showLabels);
                                }}
                                disabled={chartType === CHART_TYPE.TABLE ? true : false }
                              />
                              Show data labels
                            </label>
                            <button className="lightFill" onClick={() => setIsChartVertical(!isChartVertical)} disabled={chartType === CHART_TYPE.TABLE || chartType === CHART_TYPE.TIME_PLOT ? true : false }>
                              Rotate
                              <svg className={isChartVertical ? style.mirrored : null}>
                                <use href={`${icons}#rotate`} />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                      <h3>{chartTitle}</h3>
                    </div>

                    {fetchDataSetsLoading ? (
                      <Loading className={style.loadingDataTables}></Loading>
                    ):(
                      <>
                      {
                          datasetTableData && chartType === CHART_TYPE.TABLE ? (
                          <>
                            {/* <DataExplorerTable
                              datasetHeadings={{
                                Entity: DATA_EXPLORER_URL_FILTER_GROUPS.ENTITY.DISPLAY_NAME,
                                ReportingPeriod: DATA_EXPLORER_URL_FILTER_GROUPS.REPORTING_YEAR.DISPLAY_NAME,
                                "Employment Type":"Employment Type",
                                Engagement:"Engagement",
                                Gender:"Gender",
                                "APS Band":"APS Band",
                                Location:"Location",
                                "Organisation": "Organisation",
                                "ABN":"ABN",
                                "Expenditure": "Expenditure",
                                Value: 'Value',
                                Units: "Units",
                              }}
                              dataSet={datasetTableData}
      
                            />                           
                          </> */}

                          <DataExplorerTable
                               datasetHeadings = {createObjectFromArray(Object.keys(datasetTableData[0]))}
                               dataSet={datasetTableData}
      
                            />               
                          </>

                        ) : datasetLineData && chartType === CHART_TYPE.TIME_PLOT ? (

             
                          <section className={style.graphView}>
                          <div className={style.mobileSeperater}>
                            {disableCharts ? (
                              <div className={[style.chartWrapper, style.noData].join(' ')} style={{ '--BACKGROUND-IMAGE': `url(${GreyTree})` }}></div>
                            ) : (
                              <div className={style.chartWrapper}>
                                {datasetLineData?.map((item, i) => {
                                  const key = `data-item-${i}`;
                                  return (
                                    <div key={key} className={style.chartGroup}>
                                      <div className={style.charts}>
                                        {item?.chartData?.map((chart, i) => {

                                          const chartKey = `chartKey-${i}`;

                                          return (
                                            <div key={chartKey} className={style.borders}>
                                              <Chart xUnit={"Reporting Period"} yUnit={datasetTableData[0].Units} data={chart?.data} type={chartType} isVertical={false} chartLabel={chart?.heading} showLabels={showLabels} width={DATA_EXPLORER_CHART_WIDTH} height={chart.height} legend={true}/>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      {item?.chartGroupHeading && <span className={['h6', style.gropuHeading].join(' ')}>{item.chartGroupHeading}</span>}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </section>
      
                        ) : (chartType === CHART_TYPE.BAR || chartType === CHART_TYPE.STACKED_BAR ) ? (
                          
                          <section className={style.graphView}>
                          <div className={style.mobileSeperater}>
                            {disableCharts ? (
                              <div className={[style.chartWrapper, style.noData].join(' ')} style={{ '--BACKGROUND-IMAGE': `url(${GreyTree})` }}></div>
                            ) : (
                              <div className={style.chartWrapper}>
                                {datasetBarData?.map((item, i) => {

                                  const key = `data-item-${i}`;
                                  return (
                                    <div key={key} className={style.chartGroup}>
  
                                      <div className={style.charts}>
                                        {item?.chartData?.map((chart, i) => {
                                          const chartKey = `chartKey-${i}`;

                                          return (
                                            <div key={chartKey} className={style.borders}>
                                              <Chart yUnit={datasetTableData[0].Units} data={chart?.data} type={chartType} isVertical={isChartVertical} chartLabel={chart?.heading} showLabels={showLabels} width={DATA_EXPLORER_CHART_WIDTH} height={chart.height} legend={true}/>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      {item?.chartGroupHeading && <span className={['h6', style.gropuHeading].join(' ')}>{item.chartGroupHeading}</span>}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </section>
      
                        ) : (null)
                      }
                      </>
                    )}
                  </>
                  )
                )
                }
              </>
            );
          }}
        </Await>
      </Suspense>
    </>
  );
}

export default DataExplorer;