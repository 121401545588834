import axios from 'axios';
import { getPublicationType, updateResponseData } from 'Utils';
import { CONTENT_ITEM_TYPES, IS_FOR_EXPORT_PDF, IS_FOR_EXPORT_WORD, IS_PREVIEW_MODE, PUBLICATION_TYPE, TAG_STYLE_ENUMS } from 'Constants';
import { SEARCH_API_URL } from 'Constants/Search';
import { ApiError, STATUS_TYPES } from 'Utils/Error';
import { TAG_POSITION_ENUMS } from 'Constants/Constants';
import { DATA_SETS_API, DATA_SETS_FILTER_OPTIONS_API } from 'Constants/DataSets';
import { DATA_EXPLORER_FILTER_OPTIONS_API, DATA_EXPLORER_RESULTS_API } from 'Constants/DataExplorer';

// DELIVERY SERVICE LAYER EXCLUSIVE CONSTANTS
const URL = `/${process.env.REACT_APP_DELIVER_API_PROJECT_ID}/items`;
const MODULAR_CONTENT = 'modular_content';
const LINKED_ITEMS = 'linkedItems';

/**
 * Set axios defaults
 */
const setAxiosDefaults = () => {
  // Axios default set up
  if (IS_PREVIEW_MODE && !IS_FOR_EXPORT_PDF && !IS_FOR_EXPORT_WORD) {
    /**
     * tokens are stored in session storage by b2c
     */
    const idToken = JSON.parse(sessionStorage.getItem(`msal.token.keys.${process.env.REACT_APP_B2C_CLIENT_ID}`))?.idToken?.[0];
    const token = JSON.parse(sessionStorage.getItem(idToken))?.secret; // decision made to use secret token as access token

    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    axios.defaults.baseURL = process.env.REACT_APP_API_END_POINT_BASE;
  } else if (IS_FOR_EXPORT_PDF) {
    axios.defaults.baseURL = process.env.REACT_APP_API_END_POINT_BASE;
  } else if (IS_FOR_EXPORT_WORD){
    axios.defaults.baseURL = process.env.REACT_APP_API_END_POINT_BASE;
  } else {
    // on non preview mode, api endpoint is always public and is always kontent delivery api endpoint
    axios.defaults.baseURL =
      process.env.REACT_APP_ENVIRONMENT?.toLowerCase() !== 'prod' ? process.env.REACT_APP_API_END_POINT_BASE : 'https://previewapi.transparency.gov.au/delivery';
  }
};

/**
 * GET call for general purpose other than fetch from Kontent.ai
 *
 * @param {String} url Target API end point.
 * @param {Object} [options={}] Any axios option to pass for get call.
 * @param {Boolean} [withCredentials] If true, set `withCredentials` to `true`. Only to be used for preview mode for secure assets. By default, it is true if `IS_PREVIEW_MODE` is true and `IS_FOR_EXPORT_PDF` is false from constant.
 * @param {Boolean} [noResponseData=false] If true, API does not expect response data on success call.
 * @param {Boolean} [logError=true] If true, when API errors out, show error log on console. By default, true.
 * @returns {Any} Return `data` of response.
 */
const getCall = async (url, options = {}, withCredentials = IS_PREVIEW_MODE && !IS_FOR_EXPORT_PDF && !IS_FOR_EXPORT_WORD, noResponseData = false, logError = true) => {
  try {
    const response = await axios.get(url, { ...options, withCredentials });

    if (noResponseData) {
      return response;
    }

    if (response?.data) {
      return response.data;
    }

    throw new Error('Something went wrong. Could not fetch data');
  } catch (error) {
    if (logError) {
      console.error(error);
    }
    throw error;
  }
};

/**
 * Use Kontent delivery SDK to fetch content item list of given content type.
 * @param {String[]} contentTypes List of content type of content items from Kontent.ai.
 * @param {{ [collection]: String, [page]: Number, [limit]: Number, [skip]: Number, [depth]: Number, [order]: String }} filter Additonal filter option.
 * @returns {Object} Response data of list of given content type content items in descending order (latest item comes first).
 */
const getContentItemLists = async (contentTypes, filter = {}) => {
  const MAX_PUBLICATION_PER_PAGE = 16;

  try {
    let skipItems = 0;
    if (filter?.page > 0) {
      skipItems = (filter.page - 1) * (filter?.limit || MAX_PUBLICATION_PER_PAGE); // skip number of items
    }

    const params = {
      'system.type[in]': contentTypes.join(','),
      limit: filter?.limit || MAX_PUBLICATION_PER_PAGE,
      skip: skipItems,
      depth: filter?.depth || 0,
      order: filter?.order || 'system.last_modified[desc]',
    };

    if (filter.collection) {
      params['system.collection'] = filter.collection;
    }

    const response = await axios.get(URL, { params });

    if (response.data) {
      const res = response.data;

      res[LINKED_ITEMS] = res[MODULAR_CONTENT];
      delete res[MODULAR_CONTENT];

      // restructure response
      res?.items?.forEach((item) => {
        try {
          // if there is circular reference on Kontent, this will go into endless loop and fail
          // `updateResponseData` will still updateresponse data until it face circular reference
          item.elements = updateResponseData(item.elements, res[LINKED_ITEMS]);
        } catch (error) {
          console.error(`[ERROR]:: getContentItemLists() with contentType=\`${contentTypes}\` and filter=\`${JSON.stringify(filter)}\``, error);
        }
      });

      return res;
    }
  } catch (error) {
    console.error(error);
    return new ApiError(`[ERROR]:: getContentItemLists() call failed`, error);
  }
};

/**
 * Use Kontent delivery SDK to fetch a specific content item.
 * @param {String} urlSlug URL slug of content item.
 * @param {String} contentType Content type of item.
 * @param {Boolean|Undefined} [useCodename] Use codename instead of url slug
 * @param {Object} [queryParams={}] Extra query params to add to fetch for specfic content
 * @param {Boolean} [acceptNoResData=false] If true, consider API isn't failed even response data is empty. Only work for custom BE response
 * @returns {Object} Response data.
 */
const getAContentItem = async (urlSlug, contentType, useCodename, queryParams = {}, acceptNoResData = false, removeCodenameFromPayload) => {
  const params = {
    'system.type': contentType,
    depth: 100, // set default depth level
    ...queryParams,
  };
  try {
    if (!useCodename) {
      params['elements.web_url[eq]'] = urlSlug;
    } else if (useCodename && !removeCodenameFromPayload) {
      // only add system.codename if there is no other query params (in order to search by other terms, codename must not be given)
      params['system.codename'] = urlSlug;
    }
    const response = await axios.get(`${URL}`, {
      params,
      ...(IS_PREVIEW_MODE &&
        !IS_FOR_EXPORT_PDF &&
        !IS_FOR_EXPORT_WORD && {
          withCredentials: true,
        }),
    });

    if (response?.data?.System) {
      // if response is custom BE response
      // if BE would return different response structure. use `System` as flag if it is correct response structure or not
      return response.data;
    }

    if (response.data) {
      // FIXME: for now always check if items array has index 0 and if it does, consider this as correct target item.
      // when overwrapping portfolio active year issue is resolved, it should be following: if (response.data?.items?.length === 1) {
      if (response.data?.items?.[0]) {
        const res = response.data;
        res.item = res.items[0];
        // delete 'items' as it's data is copied to 'item'
        delete res.items;

        // rename 'modular_content' to 'linkedItems'
        res[LINKED_ITEMS] = res[MODULAR_CONTENT];
        delete res[MODULAR_CONTENT];

        // restructure response
        if (res?.item?.elements) {
          try {
            // if there is circular reference on Kontent, this will go into endless loop and fail.
            // `updateResponseData` will still updateresponse data until it face circular reference
            res.item.elements = updateResponseData(res.item.elements, res[LINKED_ITEMS]);
          } catch (error) {
            console.error(
              `[ERROR]:: getAContentItem() with ${useCodename ? 'codename' : 'urlSlug'}=\`${urlSlug}\`, contentType=\`${contentType}\` and queryParams=\`${JSON.stringify(
                queryParams,
              )}\``,
              error,
            );
          }
        }

        return res;
      } else if (response.data?.items?.length > 1) {
        throw new ApiError(`[ERROR]:: Content item with \`${contentType}\` type and \`${urlSlug}\` ${useCodename ? 'codename' : 'web url'} contains multiple content items.`, {
          response: { status: STATUS_TYPES.MULTIPLE_CONTENT_ITEM },
        });
      } else if (response.data?.[0]?.Table) {
        return response.data;
      }

      throw new ApiError(`[NOT FOUND]:: Content item with \`${contentType}\` type and \`${urlSlug}\` ${useCodename ? 'codename' : 'web url'} not be found.`, {
        response: { status: STATUS_TYPES.NOT_FOUND },
      });
    }

    if (!acceptNoResData) {
      throw new ApiError(`[ERROR]:: Content item with \`${contentType}\` type and \`${urlSlug}\` ${useCodename ? 'codename' : 'web url'} returned no data`, {
        response: { status: STATUS_TYPES.OTHER },
      });
    }
  } catch (error) {
    console.error(params, error);
    if (error instanceof RangeError) {
      return new ApiError(error.message, { response: { status: STATUS_TYPES.RANGE_ERROR } });
    }
    return new ApiError(`[ERROR]:: getAContentItem() call failed.`, error);
  }
};

/**
 * Get government body content based on government body content item's url slug
 * @param {String} governmentBodyUrlSlug Government body url slug
 * @param {Boolean} [isCodename=false] If `true`, treat `governmentBodyUrlSlug` as codename of annual report and find goverment body that contains passed annual report
 * @param {Number} [depth=2] "Depth" level for kontent delivery api to limit number of content items fetched. By default, 2
 * @returns {Object} Response data for government body
 */
const getGovernmentBody = async (governmentBodyUrlSlug, isCodename = false, depth = 2) => {
  try {
    let response;

    if (isCodename) {
      response = await getAContentItem(
        governmentBodyUrlSlug,
        CONTENT_ITEM_TYPES.GOVERNMENT_BODY.ID,
        true,
        {
          'elements.annual_reports[contains]': governmentBodyUrlSlug,
          'system.collection': CONTENT_ITEM_TYPES.GOVERNMENT_BODY.COLLECTION,
          depth,
        },
        false,
        true,
      );
    } else {
      response = await getAContentItem(
        governmentBodyUrlSlug,
        CONTENT_ITEM_TYPES.GOVERNMENT_BODY.ID,
        undefined,
        {
          'system.collection': CONTENT_ITEM_TYPES.GOVERNMENT_BODY.COLLECTION,
          depth,
        },
        false,
        true,
      );
    }

    if (response && !response.isError) {
      return response;
    }

    if (response?.isError && response?.statusCode === STATUS_TYPES.MULTIPLE_CONTENT_ITEM) {
      throw new Error(
        isCodename
          ? `[ERROR]:: Selected annual report with codename: \`${governmentBodyUrlSlug}\` is a part of multiple government bodies/Entities.`
          : `[ERROR]:: Multiple government body items found with url slug: \`${governmentBodyUrlSlug}\`. Each government body items must have unique url slug. Please resolve this in Kontent.ai CMS.`,
      );
    }
    throw new Error(
      isCodename
        ? `[NOT FOUND]:: Government body/Entity could not be found for selected annual report with codename: \`${governmentBodyUrlSlug}\`.`
        : `[NOT FOUND]:: Government body item with url slug: \`${governmentBodyUrlSlug}\` could not be found.`,
    );
  } catch (error) {
    console.error(error);
  }
};

/**
 * Get taxonomy items
 * @param {String} taxonomyCodename - Codename of taxonomy
 * @returns response data
 */
const getTaxonomies = async (taxonomyCodename) => {
  try {
    const response = await axios.get(`/${process.env.REACT_APP_DELIVER_API_PROJECT_ID}/taxonomies/${taxonomyCodename}`);

    if (response?.data) {
      return response.data;
    }

    throw new ApiError(`[ERROR]:: getTaxonomies() call unexpected data.`);
  } catch (error) {
    console.error(error);
    return new ApiError(`[ERROR]:: getTaxonomies() call failed.`, error);
  }
};

/**
 * Get portfolio content based on portfolio metadata content item's url slug
 * @param {String} portfolioUrlSlug Portfolio url slug
 * @param {String|null} reportingYearCodename Reporting year codename to filter portfolio by (portfolio should have active years in its content and if reportingYearCodename matches any of those years, then it returns portfolio). If not given, will not filter by it
 * @param {Boolean} [isCodename=false] If `true`, treat `portfolioUrlSlug` as codename of government body and find goverment body that contains passed government body
 * @param {Number} [depth=2] "Depth" level for kontent delivery api to limit number of content items fetched. By default, 2
 * @returns {Object} Response data for portfolio
 */
const getPortfolio = async (portfolioUrlSlug, reportingYearCodename, isCodename = false, depth = 2) => {
  try {
    let response;
    if (isCodename) {
      const filters = {
        'system.collection': CONTENT_ITEM_TYPES.PORTFOLIO.COLLECTION,
        depth,
      };
      if (reportingYearCodename) {
        filters['elements.active_during[contains]'] = reportingYearCodename;
      }
      response = await getAContentItem(portfolioUrlSlug, CONTENT_ITEM_TYPES.PORTFOLIO.ID, true, { ...filters, 'elements.entities[contains]': portfolioUrlSlug }, false, true);

      if (response?.isError) {
        // If entity not found in portfolio, also search leading entity
        response = await getAContentItem(
          portfolioUrlSlug,
          CONTENT_ITEM_TYPES.PORTFOLIO.ID,
          true,
          { ...filters, 'elements.leading_entity[contains]': portfolioUrlSlug },
          false,
          true,
        );
      }
    } else {
      response = await getAContentItem(portfolioUrlSlug, CONTENT_ITEM_TYPES.PORTFOLIO.ID, undefined, { 'system.collection': CONTENT_ITEM_TYPES.PORTFOLIO.COLLECTION, depth });
    }

    if (response && !response.isError) {
      return response;
    }

    if (response?.isError && response?.statusCode === STATUS_TYPES.MULTIPLE_CONTENT_ITEM) {
      throw new Error(
        isCodename
          ? `[ERROR]:: Selected entity with codename: \`${portfolioUrlSlug}\` is a part of multiple portfolios.`
          : `[ERROR]:: Multiple portfolio metadata items found with url slug: \`${portfolioUrlSlug}\`. Each portfolio metadata items must have unique url slug. Please resolve this in Kontent.ai CMS.`,
      );
    }
    throw new Error(
      isCodename
        ? `[NOT FOUND]:: Portfolio could not be found for selected entity with codename: \`${portfolioUrlSlug}\`.`
        : `[NOT FOUND]:: Portfolio metadata item with url slug: \`${portfolioUrlSlug}\` could not be found.`,
    );
  } catch (error) {
    console.error(error);
  }
};

/**
 * Uses Microsoft Azure Cognitive search api to search for content items.
 * https://learn.microsoft.com/en-us/rest/api/searchservice/search-documents
 *
 * @param {Number} numberOfItems - number of items that should be fetched
 * @param {Number} page - page number
 * @param {String} searchTerms - search terms to search by https://learn.microsoft.com/en-us/azure/search/query-simple-syntax
 * @param {String} filter - filter string using OData filter syntax https://learn.microsoft.com/en-us/azure/search/search-query-odata-filter
 * @param {String} orderBy - order string that abides by OData orderby syntax https://learn.microsoft.com/en-us/azure/search/search-query-odata-orderby
 * @param {Boolean} fullQuery - allow for searching by full query using Lucene query syntax. Useful for searching for suffix/prefix https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax
 * @param {Boolean} useGET - Boolean to use GET request instead. Will default to POST if not given.
 * @returns {Array} Response data of search, should contain an array containing fetched values and the count (of all possible values)
 */
const searchItems = async (numberOfItems, page, searchTerms, filter, orderBy, fullQuery, useGET) => {
  const skipItems = (page - 1) * numberOfItems; // skip number of items
  const requestBody = {
    search: searchTerms || '',
    highlight: 'Content',
    searchMode: 'all',
  };

  if (useGET) {
    requestBody['$filter'] = filter || '';
    requestBody['$orderby'] = orderBy || '';
    requestBody['$top'] = numberOfItems >= 0 ? numberOfItems : 0;
    requestBody['$skip'] = skipItems >= 0 ? skipItems : 0;
    requestBody['$count'] = true;
  } else {
    requestBody.filter = filter || '';
    requestBody.orderby = orderBy || '';
    requestBody.top = numberOfItems >= 0 ? numberOfItems : 0;
    requestBody.skip = skipItems >= 0 ? skipItems : 0;
    requestBody.count = true;
  }

  if (fullQuery) {
    requestBody.queryType = 'full'; // 'full' is required value from search api in order to use Lucene query syntax queries
  } else {
    requestBody.queryType = 'simple';
  }

  const headers = {
    'Content-Type': 'application/json',
    'api-key': process.env.REACT_APP_SEARCH_API_KEY,
    'x-ms-azs-return-searchid': 'true',
    'Access-Control-Expose-Headers': 'x-ms-azs-searchid',
  };

  const serviceNameDev = SEARCH_API_URL.SERVICE_NAME_DEV;
  const indexName = SEARCH_API_URL.INDEX_NAME();
  const apiVersionDefault = SEARCH_API_URL.API_VERSION_DEFAULT;

  try {
    let response;
    if (useGET) {
      response = await axios.get(SEARCH_API_URL.GET_URL(serviceNameDev, indexName, apiVersionDefault), { headers, params: requestBody });
    } else {
      response = await axios.post(SEARCH_API_URL.POST_URL(serviceNameDev, indexName, apiVersionDefault), requestBody, { headers });
    }

    if (response.data && response.data.value) {
      return [response.data.value, response.data['@odata.count'], response.headers?.['x-ms-azs-searchid']];
    }

    throw new Error('Incorrect data format');
  } catch (error) {
    console.error(error);
    return new ApiError(`[ERROR]:: searchItems() call failed`, error);
  }
};

/**
 * Uses search facet api to get filter option values
 * @returns An array with filterData and filterGroup Values
 * @example filterData - Object that contains the filter value as key and its filter options in an array
 *                       e.g.  {Entity: [{"display": "Department of Demos", "returnValue": "Department of Demos", "selected": false,"filterGroupTitle": "Entity"}]}
 * @example filterGroupValues - Array containing all group values return value and display name
 *                              e.g. [{"returnValue": "Entity", "display": "Entities and companies"}, {"returnValue": "ReportingYear", "display": "Reporting Year"}]
 */
const getSearchFilterOptions = async () => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common = {};
    const response = await instance.post(SEARCH_API_URL.FILTER_FACET_URL());

    if (response.data && Array.isArray(response.data)) {
      const filterData = {};
      const filterGroupValues = [];
      response.data.forEach((facet) => {
        const filterGroupName = facet.indexField;
        const filterValues = facet?.facetValues?.map((option) => {
          let tagInfo;

          // If contains additionalField (which is the abolished tag), add the tag
          if (option.additionalField) {
            tagInfo = { tags: [{ display: option.additionalField, type: TAG_STYLE_ENUMS.GREY }] };
          }
          // If is a annual_report, corp_plan or pbs codename, will display appropriate tags
          if ([PUBLICATION_TYPE.ANNUAL_REPORT.VALUE, PUBLICATION_TYPE.ANNUAL_REPORT_GROUP.VALUE, PUBLICATION_TYPE.CORPORATE_PLAN.VALUE, PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.VALUE].includes(option.key)) {
            tagInfo = { tagPosition: TAG_POSITION_ENUMS.LEFT, tags: [{ display: getPublicationType(option.key), type: TAG_STYLE_ENUMS.HIGHLIGHT }] };
          }

          const newObj = {
            display: option.value,
            returnValue: option.key,
            selected: false,
            filterGroupTitle: filterGroupName,
            tagInfo: tagInfo || null,
          };

          if (option?.urlSlug) newObj.urlSlug = option.urlSlug;
          if (option?.codename) newObj.codename = option.codename;

          return newObj;
        });
        filterData[filterGroupName] = filterValues;

        filterGroupValues.push({ returnValue: filterGroupName, display: facet.displayName });
      });

      return [filterData, filterGroupValues];
    }

    throw new Error('Incorrect data format');
  } catch (error) {
    console.error(error);
    return { isError: true, statusCode: error.message };
  }
};

const getDataSetsFilterOptions = async () => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common = {
      'Content-Type': 'application/json',
    };

    const response = await instance.get(DATA_SETS_FILTER_OPTIONS_API());

    if (response?.data) {
      return response.data;
    }

    throw new ApiError('Incorrect data format');
  } catch (error) {
    console.error(error);
    return new ApiError(`[ERROR]:: getDataSets() call failed`, error);
  }
};

/**
 * Fetches data table template to construct table and graphs for data sets
 * @param {String} tableCodename - Data set codename
 * @returns Data template data
 */
const getDataTableTemplate = async (tableCodename) => {
  const params = {
    'system.type': 'data_template_structure',
    depth: 0,
    'system.collection': 'admin',
    'elements.data_table_codename[eq]': tableCodename,
  };

  try {
    const response = await axios.get(URL, { params });

    if (response?.data?.items?.[0]) {
      const dataTemplateResponse = response?.data?.items?.[0];
      if (dataTemplateResponse?.elements?.json_structure?.value) {
        return { structure: JSON.parse(dataTemplateResponse?.elements?.json_structure?.value), defaultValue: dataTemplateResponse?.elements?.default_value?.value, description: dataTemplateResponse?.elements?.dataset_filters_description?.value
        }; // always assume this is correct JSON data
      }
    }

    throw new ApiError(`[ERROR]:: getDataTableTemplate() with table codename: ${tableCodename} is not the correct format`);
  } catch (err) {
    console.error(`[ERROR]:: getDataTableTemplate() with table codename: ${tableCodename} not found`, err);
    return { isError: true, statusCode: err.message };
  }
};

/**
 * Fetches data set information with given data set codename, reporting years and entities
 * @param {String|[String]} dataSetCodename - Codename string of data set
 * @param {[String]} reportingYears - Array of string codenames of the reporting years
 * @param {[String]} entities - Array of string codenames of the entities
 * @returns Data set information
 */
const getDataSets = async (dataSetCodename, reportingYears, entities) => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common = {
      'Content-Type': 'application/json',
    };
    const params = {
      contentType: Array.isArray(dataSetCodename) ? dataSetCodename : dataSetCodename.split(',').map((value) => value.trim()),
      entity: [],
      bodyType: [],
      entityCodename: entities || [],
      reportingPeriod: reportingYears || [],
      tableCodename: [],
      portfolio: [],
      dataGroups: [],
    };
    const response = await instance.post(DATA_SETS_API(), params);

    if (response?.data) {
      return response.data;
    }

    throw new ApiError('Incorrect data format');
  } catch (error) {
    console.error(error);
    return new ApiError(`[ERROR]:: getDataSets() call failed`, error);
  }
};

const getDataExplorerFilterOptions = async () => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common = {
      'Content-Type': 'application/json',
    };

    const response = await instance.get(DATA_EXPLORER_FILTER_OPTIONS_API());

    if (response?.data) {
      return response.data;
    }

    throw new ApiError('Incorrect data format');
  } catch (error) {
    console.error(error);
    return new ApiError(`[ERROR]:: getDataExplorerFilterOptions() call failed`, error);
  }
};

/**
 * Fetches search result for internal annual report search
 * @param {[String]} annualReportGuid - Codename string of data set
 * @param {[String]} queryTerm - Array of string codenames of the reporting years
 * @param {Boolean} exactMatch - Array of string codenames of the entities
 * @returns Data set information
 */
const getAnnualReportSearch = async (annualReportGuid, queryTerm, exactMatch) => {
  const baseURL =
      process.env.REACT_APP_ENVIRONMENT?.toLowerCase() !== 'prod' ? 
      `https://${process.env.REACT_APP_ENVIRONMENT}-dart-be-previewapi.dxau.digital` : 
      'https://previewapi.transparency.gov.au/delivery';
      
  const endpoint = `${baseURL}/search/annualreport?guid=${annualReportGuid}&query=${queryTerm}&exactMatch=${exactMatch}`;
  
  console.log("Annual Report Search");
  try {
    const instance = axios.create();
    instance.defaults.headers.common = {
      'Content-Type': 'application/json',
    };

    const response = await instance.get(endpoint);

    if (response?.data) {
      return response.data;
    }

    throw new ApiError('Incorrect data format');
  } catch (error) {
    console.error(error);
    return new ApiError(`[ERROR]:: getAnnualReportSearch() call failed`, error);
  }
};

const getDataExplorerData = async (filters) => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common = {
      'Content-Type': 'application/json',
    };

    const params = {
      tags:filters.tags,
      reportSelector: filters.reportSelector,
    };

    const response = await instance.post(DATA_EXPLORER_RESULTS_API(), params);

    if (response?.data) {
      return response?.data;
    }

    throw new ApiError('Incorrect data format');
  } catch (error) {
    console.error(error);
    return new ApiError(`[ERROR]:: getDataExplorerFilterOptions() call failed`, error);
  }
};


/**
 * Uses Microsoft Azure Cognitive search api to search for content items.
 * https://learn.microsoft.com/en-us/rest/api/searchservice/search-documents
 *
 * @param {Number} numberOfItems - number of items that should be fetched
 * @param {Number} page - page number
 * @param {String} searchTerms - search terms to search by https://learn.microsoft.com/en-us/azure/search/query-simple-syntax
 * @param {String} filter - filter string using OData filter syntax https://learn.microsoft.com/en-us/azure/search/search-query-odata-filter
 * @param {String} orderBy - order string that abides by OData orderby syntax https://learn.microsoft.com/en-us/azure/search/search-query-odata-orderby
 * @param {Boolean} fullQuery - allow for searching by full query using Lucene query syntax. Useful for searching for suffix/prefix https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax
 * @returns {Array} Response data of search, should contain an array containing fetched values and the count (of all possible values)
 */
const exportSearchResults = async (numberOfItems, page, searchTerms, filter, orderBy, fullQuery) => {
  const skipItems = (page - 1) * numberOfItems; // skip number of items
  const requestBody = {
    search: searchTerms || '',
    highlight: 'Content',
    searchMode: 'all',
  };

  requestBody.filter = filter || '';
  requestBody.orderby = orderBy || '';
  requestBody.top = numberOfItems >= 0 ? numberOfItems : 0;
  requestBody.skip = skipItems >= 0 ? skipItems : 0;
  requestBody.count = true;

  if (fullQuery) {
    requestBody.queryType = 'full'; // 'full' is required value from search api in order to use Lucene query syntax queries
  } else {
    requestBody.queryType = 'simple';
  }

  const headers = {
    'Content-Type': 'application/json',
    'api-key': process.env.REACT_APP_SEARCH_API_KEY,
    'x-ms-azs-return-searchid': 'true',
    //'Access-Control-Expose-Headers': 'x-ms-azs-searchid',
  };

  console.log("FETCHING");
  console.log(SEARCH_API_URL.EXPORT_URL());

  try {
    const response = await axios.post(SEARCH_API_URL.EXPORT_URL(), requestBody, { headers });
    if (response.data) {
      return response.data;
    }

    throw new Error('Incorrect data format');
  } catch (error) {
    console.error(error);
    return new ApiError(`[ERROR]:: searchItems() call failed`, error);
  }
};

/**
 * GET call for general purpose other than fetch from Kontent.ai
 *
 * @param {String} url Target API end point.
 * @param {Object} [options={}] Any axios option to pass for get call.
 * @param {Boolean} [withCredentials] If true, set `withCredentials` to `true`. Only to be used for preview mode for secure assets. By default, it is true if `IS_PREVIEW_MODE` is true and `IS_FOR_EXPORT_PDF` is false from constant.
 * @param {Boolean} [noResponseData=false] If true, API does not expect response data on success call.
 * @param {Boolean} [logError=true] If true, when API errors out, show error log on console. By default, true.
 * @returns {Any} Return `data` of response.
 */
const getWordCall = async (url, options = {}, withCredentials = IS_PREVIEW_MODE && !IS_FOR_EXPORT_PDF && !IS_FOR_EXPORT_WORD, noResponseData = false, logError = true) => {
  try {
    const response = await axios.get(url, {
      ...options,
      withCredentials,
    });
    if (noResponseData) {
      return response;
    }

    if (response?.data) {
      return response.data;
    }

    throw new Error('Something went wrong. Could not fetch data');
  } catch (error) {
    if (logError) {
      console.error(error);
    }
    throw error;
  }
};



export {
  getAContentItem,
  getCall,
  getContentItemLists,
  getDataSets,
  getDataSetsFilterOptions,
  getDataTableTemplate,
  getGovernmentBody,
  getPortfolio,
  getSearchFilterOptions,
  getTaxonomies,
  searchItems,
  setAxiosDefaults,
  getDataExplorerFilterOptions,
  getDataExplorerData,
  getAnnualReportSearch,
  exportSearchResults,
  getWordCall,
};
