import React from 'react';
import { Loading, LoadingMenu } from 'Components';
import AnnualReportTopPartLoading from './AnnualReportTopPartLoading';
import style from './AnnualReportCoverLoading.module.scss';

/**
 * AnnualReportCoverLoading.jsx
 *
 * @summary This component shows the loading state page view for both annual report cover pages.
 */
function AnnualReportCoverLoading() {
  return (
    <>
      <AnnualReportTopPartLoading />

      <h1 id={style.reportTitle}>
        <Loading className={style.loadingFullHeight}>
          <span className={style.invisibleTitle}>Loading</span>
        </Loading>

        <Loading className={style.loadingFullHeight}>
          <p className={style.invisibleTitle}>Loading</p>
        </Loading>
      </h1>

      <div id={style.cover}>
        <div>
          <LoadingMenu className={style.loadingSideNavigation} />
        </div>

        <div className={style.coverImage}>
          <Loading className={style.loadingCoverImg} />
        </div>
      </div>
    </>
  );
}

export default AnnualReportCoverLoading;
