import React, { useEffect, useState, useRef, Children, cloneElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import style from './HorizontalScroll.module.scss';

/**
 * HorizontalScroll.jsx
 *
 * @summary Component for allowing for horizontal scroll to overflow parent
 *
 * @param {Object} props - Component props.
 * @prop {Node} [props.children] - Children nodes add that will allow for scrolling within.
 *
 */
function HorizontalScroll({ children, reRenderChild }) {
  const childRef = useRef();
  const containerRef = useRef();
  const scrollBarRef = useRef();
  const [childElementDimensions, setChildElementDimensions] = useState({ height: 0, width: 0, top: 0, right: 0, bottom: 0, left: 0 });
  const [scrollAmount, setScrollAmount] = useState(0);
  const [allowScrolling, setAllowScrolling] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  // recalculate window and child element dimensions
  const detectViewPortChange = () => {
    if (childRef.current) {
      const { top, right, bottom, left } = childRef.current.getBoundingClientRect();
      setChildElementDimensions({ width: childRef.current.scrollWidth, height: childRef.current.clientHeight, top, right, bottom, left });
    }
  };

  useEffect(() => {
    if (childRef.current) {
      const { top, right, bottom, left } = childRef.current.getBoundingClientRect();
      // use scrollwidth to get the total width (including overflow), clientWidth only gets actual width (so percentage widths wont register correctly)
      setChildElementDimensions({ width: childRef.current.scrollWidth, height: childRef.current.clientHeight, top, right, bottom, left });
    }
  }, []);

  useEffect(() => {
    if (childRef.current) {
      const { top, right, bottom, left } = childRef.current.getBoundingClientRect();
      // use scrollwidth to get the total width (including overflow), clientWidth only gets actual width (so percentage widths wont register correctly)
      setChildElementDimensions({ width: childRef.current.scrollWidth, height: childRef.current.clientHeight, top, right, bottom, left });
    }
  }, [reRenderChild]);

  const reCalculateChildDimensions = useCallback(() => {
    if (childRef.current) {
      const { top, right, bottom, left } = childRef.current.getBoundingClientRect();
      // use scrollwidth to get the total width (including overflow), clientWidth only gets actual width (so percentage widths wont register correctly)
      setChildElementDimensions({ width: childRef.current.scrollWidth, height: childRef.current.clientHeight, top, right, bottom, left });
    }
  }, []);

  useEffect(() => {
    // rerun dimensions each time a resize in window
    window.addEventListener('resize', detectViewPortChange);
    window.addEventListener('wheel', mouseScrollHorizontal);
    // rerun to detect horizontal scroll when using mouse wheel

    return () => {
      window.removeEventListener('resize', detectViewPortChange);
      window.removeEventListener('wheel', mouseScrollHorizontal);
    };
  });

  const scrollContent = () => {
    setScrollAmount(scrollBarRef?.current?.scrollLeft || 0);
  };

  const mouseScrollHorizontal = (e) => {
    if (allowScrolling || isFocused) {
      if (e.deltaX !== 0) {
        if (scrollBarRef?.current) {
          scrollBarRef.current.scrollLeft = scrollAmount + e.deltaX;
        }
      }
    }
  };

  return (
    // Add extra 16 pixel to height since that is the scrollbar is extra 16px in width
    <div className={style.horizontalContainerWrapper} style={{ height: `${childElementDimensions.height + 16}px` }}>
      <div className={style.horizontalContainer} ref={containerRef}>
        <div className={style.horizontalScroll}>
          <div
            className={style.scrollContainer}
            ref={childRef}
            onMouseEnter={() => setAllowScrolling(true)}
            onMouseLeave={() => setAllowScrolling(false)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            style={{ '--SCROLL-AMOUNT': `${scrollAmount}px` }}>
            {Children.map(children, (child) => {
              return cloneElement(child, { ...child.props, updateParentData: reCalculateChildDimensions });
            })}
          </div>
        </div>
        <div
          className={style.scrollbar}
          ref={scrollBarRef}
          tabIndex={0}
          style={{ overflowX: 'auto' }}
          onScroll={scrollContent}
          onMouseEnter={() => setAllowScrolling(true)}
          onMouseLeave={() => setAllowScrolling(false)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}>
          <div>
            <div className={style.childMirror} style={{ height: `1px`, width: `${childElementDimensions.width}px` }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

HorizontalScroll.propTypes = {
  children: PropTypes.node.isRequired,
  reRenderChild: PropTypes.any,
};

export default HorizontalScroll;
