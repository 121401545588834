import { annualReportUrl, getNeighbouringArrayElements } from 'Utils';

/**
 * Utility function to obtain the title and paths of Publications on AnnualReportCover.jsx for PageNavigation.jsx component links (to go previous and next)
 * @param {Array} publicationsList - Array of publications to find next/previous publications (each publication is an object and should contain a codename - unique identifier)
 * @param {String} currentPublicationWebUrl - Web url value for the current publication page that the user is on
 * @returns {Object} navigationData object with title and path for prev and next publications. Can be null if either/neither sibling publications are found
 */
export const findPrevNextPublicationNavigationItems = (publicationsList, currentPublicationWebUrl) => {
  const currentPublicationIndex = publicationsList.findIndex((element) => {
    return element.webUrl === currentPublicationWebUrl;
  });

  const navigationData = {
    prevTitle: null,
    prevPath: null,
    nextTitle: null,
    nextPath: null,
  };
  // Set navigation page data for PageNavigation component
  if (currentPublicationIndex !== -1) {
    const neighbouringElements = getNeighbouringArrayElements(publicationsList, currentPublicationIndex);

    if (neighbouringElements.prev) {
      navigationData.prevTitle = neighbouringElements.prev.name;
      navigationData.prevPath = `/${annualReportUrl(neighbouringElements.prev.portfolioWebUrl, neighbouringElements.prev.entityWebUrl, neighbouringElements.prev.webUrl)}`;
    }
    if (neighbouringElements.next) {
      navigationData.nextTitle = neighbouringElements.next.name;
      navigationData.nextPath = `/${annualReportUrl(neighbouringElements.next.portfolioWebUrl, neighbouringElements.next.entityWebUrl, neighbouringElements.next.webUrl)}`;
    }
  }

  return navigationData;
};

/**
 * Utility function to obtain the title and paths of article sections for PageNavigation.jsx component links (to go previous and next) to be used in AnnualReportArticle.jsx
 * @param {{path: String, routeTitle: String, children: Array}[]} routesMenu - Array of objects which have a 'path', 'routeTitle' and a 'children' array.
 * children array can be empty, or hold an array of objects with the same properties as above.
 * @param {Number} currentSectionIndex - Current index of section (should be the correct index of the current section in routesMenu)
 * @param {Boolean} isSubSection - Boolean value to suggest whether current section is a sub-section. If false, it is a main section
 * @param {Number} currentSubsectionIndex - Current index of sub-section. isSubSection should be true in order for this value to be used
 * @returns {Object} navigationData object with title and path for prev and next publications. Can be null if either/neither sibling publications are found
 */
export const findPrevNextArticleNavigationItems = (routesMenu, currentSectionIndex, isSubSection, currentSubsectionIndex) => {
  const pageNavigation = {
    prevTitle: null,
    prevPath: null,
    nextTitle: null,
    nextPath: null,
  };

  const neighbouringSections = getNeighbouringArrayElements(routesMenu, currentSectionIndex);

  // if not a sub-section (is a chapter)
  if (!isSubSection) {
    const previousSection = neighbouringSections.prev;
    const nextSection = neighbouringSections.next;

    // Get previous article
    if (previousSection) {
      if (previousSection.children && previousSection.children.length > 0) {
        // if there are sub-sections of the previous chapter, use the last sub-section
        const finalSubSectiion = previousSection.children.slice(-1)[0];
        pageNavigation.prevTitle = finalSubSectiion.routeTitle;
        pageNavigation.prevPath = finalSubSectiion.path;
      } else {
        // if there are no sub-sections of previous section, go straight to the previous chapter
        pageNavigation.prevTitle = previousSection.routeTitle;
        pageNavigation.prevPath = previousSection.path;
      }
    }

    // Get next article
    if (routesMenu[currentSectionIndex]?.children && routesMenu[currentSectionIndex].children?.length > 0) {
      // if the current chapter has sub-sections, the next page from current section is the first sub-section of the chapter
      const firstSubSection = routesMenu[currentSectionIndex].children[0];
      pageNavigation.nextTitle = firstSubSection.routeTitle;
      pageNavigation.nextPath = firstSubSection.path;
    } else if (nextSection) {
      // when there is no sub-sections of the current chapter, the next page will be the next chapter (if any)

      if (nextSection?.chapterHasContent) {
        pageNavigation.nextTitle = nextSection.routeTitle;
        pageNavigation.nextPath = nextSection.path;
      } else {
        // if next section (chapter) has no own contents, go to first sub section.
        // Note: if chapter has no contents on its own, always expect there are some sub sections
        const targetSubSection = nextSection?.children?.[0];
        pageNavigation.nextTitle = targetSubSection?.routeTitle || '';
        pageNavigation.nextPath = targetSubSection?.path || '';
      }
    }
  } else {
    const neighbouringSubSections = getNeighbouringArrayElements(routesMenu[currentSectionIndex].children, currentSubsectionIndex);

    // Get previous article
    const previousSubSection = neighbouringSubSections.prev;
    if (previousSubSection) {
      // if there are previous sub-sections, take it
      pageNavigation.prevTitle = previousSubSection.routeTitle;
      pageNavigation.prevPath = previousSubSection.path;
    } else {
      // when there is no previous sub-section, previous page will be the chapter page (there will always be a chapter page if there are sub-sections under it)
      let targetChapter = routesMenu[currentSectionIndex];

      if (targetChapter?.chapterHasContent) {
        pageNavigation.prevTitle = targetChapter.routeTitle;
        pageNavigation.prevPath = targetChapter.path;
      } else {
        if (currentSectionIndex === 0) {
          pageNavigation.prevTitle = null;
          pageNavigation.prevPath = null;
        } else {
          // if target chapter does not have any content, previous page should be previous chapter of target chapter or its sub section
          targetChapter = routesMenu[currentSectionIndex - 1];
          const targetChapterChildren = targetChapter.children;

          if (targetChapterChildren.length > 0) {
            // if target chapter has children
            pageNavigation.prevTitle = targetChapterChildren[targetChapterChildren.length - 1].routeTitle;
            pageNavigation.prevPath = targetChapterChildren[targetChapterChildren.length - 1].path;
          } else {
            // if target chapter does not have childrun.
            // Note: for live mode, if target chapter has no children, always expect chapter has some contents itself
            // Note: for preview mode, target chapter may contain no content (set empty string to show disabled page nav state)
            pageNavigation.prevTitle = targetChapter.routeTitle || '';
            pageNavigation.prevPath = targetChapter.path || '';
          }
        }
      }
    }

    // Get next article
    const nextSubSection = neighbouringSubSections.next;
    if (nextSubSection) {
      // if there is another sub-section, the next page is that sub-section
      pageNavigation.nextTitle = nextSubSection.routeTitle;
      pageNavigation.nextPath = nextSubSection.path;
    } else if (neighbouringSections.next) {
      // if there is no other sub-section (currently on the last sub-section), go to next available chapter page (if any)
      if (neighbouringSections.next.chapterHasContent) {
        pageNavigation.nextTitle = neighbouringSections.next.routeTitle;
        pageNavigation.nextPath = neighbouringSections.next.path;
      } else {
        // if next chapter does not have content body, go to first child section of it
        // Note: For live mode, always assume there is at least one child section if chapter does not have body content of its own
        // Note: For preview mode, there can be potentially be chapter sections that have no content
        pageNavigation.nextTitle = neighbouringSections.next.children[0]?.routeTitle || '';
        pageNavigation.nextPath = neighbouringSections.next.children[0]?.path || '';
      }
    }
  }

  return pageNavigation;
};
