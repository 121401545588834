/**
 * Save to local storage (stringifies data given)
 * @param {String} key - Key value to save to local storage
 * @param {Any} itemToStore - Item to store in local storage
 */
export const saveToLocalStorage = (key, itemToStore) => {
  if (itemToStore) {
    window.localStorage.setItem(key, JSON.stringify(itemToStore));
  }
};

/**
 * Get item from local storage (parses the data)
 * @param {String} key - Key value to save to local storage
 * @returns {Any} Returns the parsed stored data in the key (returns null if not found)
 */
export const getFromLocalStorage = (key) => {
  if (key) {
    const item = window.localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
  }
  return null;
};

/**
 * Remove item from local storage (if found)
 * @param {String} key - Key to remove from local
 */
export const removeFromLocalStorage = (key) => {
  const storageItemFound = window.localStorage.getItem(key);

  if (storageItemFound) {
    window.localStorage.removeItem(key);
  }
};

/**
 * Save to session storage (stringifies data given)
 * @param {String} key - Key value to save to session storage
 * @param {Any} itemToStore - Item to store in session storage
 */
export const saveToSessionStorage = (key, itemToStore) => {
  if (itemToStore) {
    window.sessionStorage.setItem(key, JSON.stringify(itemToStore));
  }
};

/**
 * Get item from session storage (parses the data)
 * @param {String} key - Key value to save to session storage
 * @returns {Any} Returns the parsed stored data in the key (returns null if not found)
 */
export const getFromSessionStorage = (key) => {
  if (key) {
    const item = window.sessionStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
  }
  return null;
};
