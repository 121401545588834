import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, LineChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from 'recharts';
import { CHART_TYPE } from 'Constants';
import style from './Chart.module.scss';
import { formatStringNumberWithCommas } from 'Utils';

/**
 * Chart.jsx
 *
 * @summary Render as Chart.
 *
 * @param {Object} props - Component props.
 * @prop {[{ name: String }]} props.data - Array of object data to display in chart. Object must contain `name`, which would be used as each category label. Along with `name` key, there also should be other key data pairs to plot data and each key would be label of each data.
 * @prop {String} [props.type = 'bar'] - Type of charts. enum from CHART_TYPE constants. by default, CHART_TYPE.BAR value.
 * @prop {Boolean} [props.isVertical = false] - Define if chart is horizontal or vertical. By default value is false.
 * @prop {String} [props.chartLabel] - Chart label.
 * @prop {boolean} [props.showLabels] - Show labels on bar.
 */
function Chart({ data, type = CHART_TYPE.BAR, isVertical = false, chartLabel, showLabels = true, width }) {
  const DEFAULT_CHART_VALUES = {
    MIN_WIDTH: 576,
    MIN_HEIGHT: 385,
  };

  const BAR_COLORS = {
    PRIMARY: '#005562',
    GREEN: '#00AA55',
    PINK: '#F092E7',
    BLUE: '#4C79ED',
    RED: '#FF4B4B',
    TURQUOISE: '#52C9D0',
    YELLOW: '#FADB00',
    PURPLE: '#A561DA',
    ORANGE: '#FF9900',
  };

  const commonSettings = () => {
    const GREY = '#868686';
    const BLACK = '#000000';
    const categoryTick = { fill: BLACK, fontWeight: 700 };
    const numberTick = { fill: GREY, fontSize: '14px' };

    return (
      <>

        <CartesianGrid vertical={isVertical} horizontal={!isVertical} />
        <XAxis
          type={isVertical ? 'number' : 'category'}
          dataKey={isVertical ? null : 'name'}
          tickLine={false}
          tickSize={20}
          axisLine={isVertical}
          angle={isVertical ? 0 : -45}
          tickCount={isVertical ? 11 : null}
          tick={isVertical ? numberTick : categoryTick}
          padding={10}
          dx={-20}
          tickFormatter={(value) => {
            if (typeof value === 'string') {
              if (value?.length < 6) return value;
              return `${value?.substring(0, 6)}...`;
            }
            return value;
          }}
          includeHidden={!isVertical}>
          {chartLabel && <Label value={chartLabel} offset={30} position="bottom" fill={BLACK} fontWeight={400} />}
        </XAxis>
        <YAxis
          type={isVertical ? 'category' : 'number'}
          dataKey={isVertical ? 'name' : null}
          axisLine={false}
          tickLine={false}
          tickCount={isVertical ? null : 11}
          tick={isVertical ? categoryTick : numberTick}
          tickFormatter={(value) => {
            if (typeof value === 'string') {
              if (value?.length < 6) return value;
              return `${value?.substring(0, 6)}...`;
            } else if (typeof value === 'number') {
              const numberString = String(value);
              if (numberString.length > 6) {
                return `${numberString.substring(0, 5)}..`;
              }
            }
            return value;
          }}
          includeHidden={isVertical}></YAxis>
        <Tooltip content={<CustomTooltip />} />
      </>
    );
  };

  const renderBars = () => {
    const bars = [];
    let labelPosition = 'top';

    if (isVertical) {
      labelPosition = 'right';
    }

    data
      ?.map((obj) => {
        const copiedObj = Object.assign({}, obj); // to make sure no side effect on original data

        delete copiedObj.name; // delete `name` as it's not part of data

        return copiedObj;
      })
      ?.forEach((item) => {
        if (typeof item === 'object' && !Array.isArray(item)) {
          Object.keys(item).forEach((key) => {
            if (!bars.includes(key)) {
              bars.push(key);
            }
          });
        }
      });

    return (
      <>
        {bars.map((dataKey, i) => (
          <Bar
            key={`bar-${dataKey}`}
            dataKey={dataKey}
            fill={Object.values(BAR_COLORS)[i]}
            label={
              showLabels ? { fontSize: 14, fontWeight: 400, fill: BAR_COLORS.BLACK, position: labelPosition, formatter: (value) => formatStringNumberWithCommas(value) } : null
            }
            stackId={type === CHART_TYPE.STACKED_BAR ? 'stacked' : null}
            maxBarSize={44}
          />
        ))}
      </>
    );
  };

  const renderLines = () => {
    const lines = [];

    data
      ?.map((obj) => {
        const copiedObj = Object.assign({}, obj); // to make sure no side effect on original data

        delete copiedObj.name; // delete `name` as it's not part of data

        return copiedObj;
      })
      ?.forEach((item) => {
        if (typeof item === 'object' && !Array.isArray(item)) {
          Object.keys(item).forEach((key) => {
            if (!lines.includes(key)) {
              lines.push(key);
            }
          });
        }
      });

    return (
      <>
        {lines.map((dataKey, i) => (
          <Line
            key={`line-${dataKey}`}
            dataKey={dataKey}
            stroke={Object.values(BAR_COLORS)[i]}
            label={
              showLabels ? { fontSize: 14, fontWeight: 400, fill: BAR_COLORS.BLACK, position: 'top', formatter: (value) => formatStringNumberWithCommas(value) } : null
            }
          />
        ))}
      </>
    );
  };

  const renderChartType = () => {
    switch (type) {
      case CHART_TYPE.STACKED_BAR:
      case CHART_TYPE.BAR:
        return (
          <BarChart
            accessibilityLayer
            width={width || DEFAULT_CHART_VALUES.MIN_WIDTH}
            height={DEFAULT_CHART_VALUES.MIN_HEIGHT}
            data={data}
            layout={isVertical ? 'vertical' : 'horizontal'}
            margin={{
              top: 16,
              bottom: 50,
              left: isVertical ? 32 : undefined,
              right: isVertical ? 32 : undefined,
            }}
            barGap={8}>
            {commonSettings()}
            {renderBars()}
          </BarChart>
        );
      case CHART_TYPE.TIME_PLOT:
        return (
          <LineChart
            accessibilityLayer
            width={width || DEFAULT_CHART_VALUES.MIN_WIDTH}
            height={DEFAULT_CHART_VALUES.MIN_HEIGHT}
            data={data}
            margin={{
              top: 16,
              bottom: 50,
              left: 32,
              right: 32,
            }}>
            {commonSettings()}
            {renderLines()}
          </LineChart>
        );
      default:
        return null;
    }
  };

  return (
    <div className={style.chart}>
      <ResponsiveContainer minHeight={DEFAULT_CHART_VALUES.MIN_HEIGHT} minWidth={width || DEFAULT_CHART_VALUES.MIN_WIDTH}>
        {renderChartType()}
      </ResponsiveContainer>
    </div>
  );
}

const dataItemShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
});

Chart.propTypes = {
  data: PropTypes.arrayOf(dataItemShape).isRequired,
  type: PropTypes.oneOf(Object.values(CHART_TYPE)),
  isVertical: PropTypes.bool,
  chartLabel: PropTypes.string,
  showLabels: PropTypes.bool,
  width: PropTypes.number,
};

/**
 * @summary Render customised axis tick
 * @param {Object} prop - Component props.
 * @prop {Any} prop.x - `x` value passed by Recharst.
 * @prop {Any} prop.y - `y` value passed by Recharst.
 * @prop {Any} prop.payload - Data passed by Recharst.
 */
function CustomizedXAxisTick({ x, y, payload }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" className={style.xAxisTickLabel}>
        {payload.value}
      </text>
    </g>
  );
}

CustomizedXAxisTick.propTypes = {
  x: PropTypes.any,
  y: PropTypes.any,
  payload: PropTypes.any,
};

/**
 * @summary Render custom tooltip content.
 * @param {Object} data - Object data passed via recharts.
 */
function CustomTooltip(data) {
  if (data.active && data?.payload?.length > 0) {
    return (
      <div className={style.tooltipWrapper}>
        <p className={style.xAxisTickLabel}>{data.label}</p>
        <ol>
          {data?.payload?.map((item) => {
            const key = item.dataKey;
            return (
              <li key={`dataKey-${key}`} style={{ '--ITEM_COLOR': item?.color }}>
                <span className={style.keyIndicator}>{key}:</span>
                <span>{formatStringNumberWithCommas(item.value)}</span>
              </li>
            );
          })}
        </ol>
      </div>
    );
  }

  return <></>;
}

CustomTooltip.propTypes = {
  data: PropTypes.any,
};

export default Chart;
