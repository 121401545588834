import React from 'react';
import style from './LoadingMenu.module.scss';
import PropTypes from 'prop-types';

/**
 * LoadingMenu.jsx
 *
 * @summary Component for showing side navigation menu loading animation. Currently shows exactly 8 menu items.
 *
 * @param {Object} props - Component props.
 * @prop {Node} [props.children] - Children nodes to add to loading content.
 * @prop {String} [props.id] - Element id of loader.
 * @prop {String} [props.className] - Class name for styling.
 * @prop {Number} [numberOfItems=8] - Number of items to show in loading menu, will default to 8.
 */
function LoadingMenu({ children, id, className, numberOfItems = 8 }) {
  return (
    <ul id={id} className={[style.loadingSideNav, className].join(' ')}>
      {[...Array(numberOfItems)].map((_, i) => {
        return (
          <li key={i}>
            <p className={style.invisibleTitle}>Loading</p>
            {children}
          </li>
        );
      })}
    </ul>
  );
}

LoadingMenu.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  className: PropTypes.string,
  numberOfItems: PropTypes.number,
};

export default LoadingMenu;
