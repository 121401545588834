import React, { Suspense } from 'react';
import { Await, Navigate, useLoaderData } from 'react-router';
import style from './LoadingAnnualReportPreview.module.scss';
import PropTypes from 'prop-types';

/**
 * LoadingAnnualReportPreview.jsx
 *
 * @summary This component is page view for loading annual report preview page.
 *
 * This page only exists in preview app.
 */
function LoadingAnnualReportPreview({ overrideMessage }) {
  const { redirectTo } = useLoaderData();
  const message = overrideMessage || 'Retrieving annual report preview...';
  document.title = message;

  const loading = (
    <div id={style.findingAR}>
      <div id={style.spinnerHolder}>
        <span className="spinner">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>

      <p id={style.message}>{message}</p>
    </div>
  );

  return (
    <Suspense fallback={loading}>
      <Await resolve={redirectTo}>
        {(navigateTo) => {
          return <Navigate to={navigateTo} replace={true} />;
        }}
      </Await>
    </Suspense>
  );
}

LoadingAnnualReportPreview.propTypes = {
  overrideMessage: PropTypes.string,
};

export default LoadingAnnualReportPreview;
