import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './History.module.scss';

/**
 * History.jsx
 *
 * @summary Render as History.
 *
 * @param {Object} props - Component props.
 * @param {String} props.title - Title of history.
 * @param {{ info: String, date: String}[]} props.data - List of events.
 */
function History({ data, title }) {
  const [historyPage, updateHistoryPage] = useState(1);

  let numberOfHistoryToShow = 0;
  const renderHistoryItems = () => {
    const numberofShowAtATime = 5;
    return data?.map((history, i) => {
      if (i < historyPage * numberofShowAtATime) {
        numberOfHistoryToShow = i + 1;

        return (
          <li key={`history-item-${i}`}>
            <span className={style.historyItem}>
              <strong>{history?.info}</strong>
              <span>{history?.date}</span>
            </span>
          </li>
        );
      }
    });
  };

  return (
    <div id={style.historyHolder}>
      <h2 className="h5">{title}</h2>
      <ol id={style.historyLine}>
        {renderHistoryItems()}
        {/* {numberOfHistoryToShow < data.length && <li></li>} */}
      </ol>
      {numberOfHistoryToShow < data.length && (
        <>
          <span className={style.tailConnector}></span>
          <span className={style.tail}></span>
          <button className="noFill" onClick={() => updateHistoryPage(historyPage + 1)}>
            Show more
          </button>
        </>
      )}
    </div>
  );
}

History.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      info: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default History;
