import React, { useEffect, useId, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Outline, Page, pdfjs } from 'react-pdf';
import { Loading, MessageBox } from 'Components';
import { getCall } from 'Services';
import { CONSTANTS, IS_PREVIEW_MODE, MESSAGE_BOX_TYPE } from 'Constants';
import { debounce, detectViewMode } from 'Utils';
import style from './PdfEmbed.module.scss';
import sprite from 'Assets/svgSprite.svg';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js`;

/**
 * PdfRender
 *
 * @summary This is actual pdf component.
 * @param {Object} props - Component props.
 * @prop {Object} props.pdfSrc - PDF file data fetched from API in Unit8Array format.
 * @prop {String} [props.fileName] - PDF file name. If provided, download button will download pdf directly, else, open in new tab.
 */
function PdfRender({ data, pdfSrc, fileName }) {
  const [numPages, setNumPages] = useState(null);
  const [viewMode, setViewMode] = useState(detectViewMode());
  const [pageLoading, setPageLoading] = useState([]);
  const [pageWidth, setPageWidth] = useState(null);
  const [pageRendered, setPageRendered] = useState(false);
  const [failedPdfRender, setFailedPdfRender] = useState(false);
  const pdfEmbedId = useId();
  const pdfData = useMemo(() => {
    return { data: data.slice(0) };
  }, [data]);
  const commonDocumentOptions = useMemo(() => ({ withCredentials: true }), []);
  const documentRef = useRef();
  const pageRefs = useRef([]);
  const [isOutlineOpen, setOutlineOpen] = useState(false);
  const [outlineContents, setOutlineContents] = useState([]);
  const [loadingDocumentOutline, setLoadingDocumentOutline] = useState(true);

  useLayoutEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  useLayoutEffect(() => {
    const documentArea = documentRef.current;
    setPageWidth(documentArea.offsetWidth);
  }, [viewMode]);

  useLayoutEffect(() => {
    setPageRendered(false);
  }, [pageWidth]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageRendered(true);
    setPageLoading(new Array(numPages).fill(true));
  };

  const updatePageLoading = (index) => {
    setPageLoading((previousValue) => {
      const newState = [...previousValue];
      newState[index] = false;
      return newState;
    });
  };

  const jumpToPage = ({ pageNumber }) => {
    // auto scroll to target page
    const targetPage = documentRef?.current?.querySelector(`[data-page-number="${pageNumber}"]`);

    if (documentRef?.current && targetPage) {
      window.scrollTo({ top: targetPage.getBoundingClientRect().top + window.pageYOffset - 100 });
    }

    setOutlineOpen(false);
  };

  //Outline load tracker, prevents the error message from appearing while the load is occuring
  const onOutlineLoadSuccess = (outline) => {
    setOutlineContents(outline);
    setLoadingDocumentOutline(false); 
  };

  const onOutlineLoadError = () => {
    setOutlineContents([]); 
    setLoadingDocumentOutline(false); 
  };

  const outlineClasses = [style.outlineWrapper];

  if (isOutlineOpen) outlineClasses.push(style.showOutline);

  const updatePageRendered = (index) => {
    updatePageLoading(index);
    setPageRendered(true);
  };

  const outlineId = useId();
  const hamburgerButtonId = useId();

  return (
    <div id={pdfEmbedId} className={`${style.pdfEmbed} linkCopyWrapper`} ref={documentRef}>
      <h4>{fileName}</h4>
      {pdfData && (
        <Document className={style.document} file={pdfData} onLoadSuccess={onDocumentLoadSuccess} onLoadError={() => setFailedPdfRender(true)} options={commonDocumentOptions}>
          <div className={[style.menu].join(' ')}>
            {numPages > 1 && outlineContents && outlineContents?.length > 0 && (
              <button
                className={[style.menuButton].join(' ')}
                id={hamburgerButtonId}
                onClick={() => setOutlineOpen(!isOutlineOpen)}
                aria-controls={outlineId}
                aria-expanded={isOutlineOpen}
                aria-label={`${isOutlineOpen ? 'Close' : 'Open'} table of contents`}
                title={`${isOutlineOpen ? 'Close' : 'Open'} table of contents`}>
                <svg>
                  <use href={`${sprite}#${isOutlineOpen ? 'close' : 'hamburger'}`} />
                </svg>
              </button>
            )}

            <div id={outlineId} className={outlineClasses.join(' ')}>
              <Document file={pdfSrc} loading="Loading table of contents..." options={commonDocumentOptions}>
                <Outline className={style.outline} onItemClick={jumpToPage} onLoadSuccess={onOutlineLoadSuccess} onLoadError={onOutlineLoadError} />
              </Document>
            </div>
            {(!outlineContents || outlineContents?.length <= 0) && IS_PREVIEW_MODE && !loadingDocumentOutline && (
              <MessageBox type={MESSAGE_BOX_TYPE.WARNING} iconOverride="information">
                The embedded PDF that you have added does not meet the requirements for upload. Please ensure that you add a file that contains a table of contents so that the
                contents are consistently presented on Transparency Portal. For any questions, please contact <a href="mailto:dar@finance.gov.au">dar@finance.gov.au</a>.
              </MessageBox>
            )}
          </div>
          {outlineContents &&
            outlineContents?.length > 0 &&
            Array.from(new Array(numPages), (el, index) => {
              return (
                <div className={style.pageParent} key={`page_${index + 1}`}>
                  <Page
                    canvasRef={(el) => (pageRefs.current[index] = el)}
                    pageNumber={index + 1}
                    className={style.page}
                    scale={viewMode !== CONSTANTS.VIEW_MODE.MOBILE ? 1 : null}
                    width={pageWidth}
                    onRenderSuccess={() => updatePageRendered(index)}
                    loading={() => setPageRendered(false)}
                    _className={pageLoading[index] && style.hidden}
                  />
                  {pageLoading[index] && <div className={style.loadingIndicator}></div>}
                </div>
              );
            })}
        </Document>
      )}
      {!pageRendered && outlineContents && !failedPdfRender && <div className={style.loadingIndicator}></div>}
    </div>
  );
}

PdfRender.propTypes = {
  data: PropTypes.object.isRequired,
  pdfSrc: PropTypes.string.isRequired,
  fileName: PropTypes.string,
};

/**
 * PdfEmbed.jsx
 *
 * @summary Render as PDF viewer.
 *
 * @param {Object} props - Component props.
 * @prop {String} props.pdfSrc - PDF file source.
 * @prop {String} [props.fileName] - PDF file name. If provided, download button will download pdf directly, else, open in new tab.
 */
function PdfEmbed({ pdfSrc, fileName }) {
  const [pdfData, setPdfData] = useState(null);
  const [pdfApiFailed, setPdfApiFailed] = useState(false);

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        const res = await getCall(pdfSrc, {
          responseType: 'arraybuffer',
        });
        setPdfData(new Uint8Array(res));
      } catch (err) {
        console.error(err);
        setPdfApiFailed(true);
      }
    };

    fetchPDF();
  }, [pdfSrc]);

  /**
   * pass unit8array pdf file to component instead handle pdf component logic in `PdfEmbed` for performance reason as we want
   * to use `useMemo` to cache downloaded pdf file within component while it gets rerendered and `useMemo` can not be used with
   * api call
   */
  if (pdfData) {
    return <PdfRender pdfSrc={pdfSrc} data={pdfData} fileName={fileName} />;
  }

  if (pdfApiFailed) {
    return <p className={style.notFoundText}>PDF file not found</p>;
  }

  return <Loading className={style.loading} />;
}

PdfEmbed.propTypes = {
  pdfSrc: PropTypes.string.isRequired,
  fileName: PropTypes.string,
};

export default PdfEmbed;
