import React from 'react';
import style from './Loading.module.scss';
import PropTypes from 'prop-types';

/**
 * Loading.jsx
 *
 * @summary Component for showing flashing loading animation.
 *
 * @param {Object} props - Component props.
 * @prop {Node} [props.children] - Children nodes to add to loading content.
 * @prop {String} [props.id] - Element id of loader.
 * @prop {String} [props.className] - Class name for styling.
 */
function Loading({ children, id, className }) {
  return (
    <div id={id} className={[style.loading, className].join(' ')}>
      {children}
    </div>
  );
}

Loading.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  className: PropTypes.string,
};

export default Loading;
