import React, { Children, useState, useEffect, useRef, useId } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import style from './MobileFilter.module.scss';
import icons from 'Assets/svgSprite.svg';
import { Tag } from 'Components';
import { CONSTANTS, TAG_STYLE_ENUMS } from 'Constants';
import { FILTER_API_FAILED } from 'Constants/StaticContents';

/**
 * MobileFilter.jsx
 *
 * @summary Component for mobile view of filter items functionality.
 *
 * @param {Object} props - Component props.
 * @prop {String} props.filterTitle - Title for filter.
 * @prop {Function} props.clearFilter - Function to clear filter.
 * @prop {Function} props.applyFilter - Function to apply filter.
 * @prop {Node} props.children - FilterGroupDropdown.jsx component nodes add which will appear in filter dropdowns section (only these should be added as children).
 * @prop {{filterTitle: String, returnValue: String, display: String}[]} [props.selectedFilters] - list of currently selected filters.
 * @prop {String} [props.filterLabel] - Label for filter (shows in bottom left of filter).
 *
 * @description props.children requires the usage of FilterGroupDropdown.jsx to function as expected. FilterGroupDropdown should contain props 'filterGroupTitle' and 'children'
 * TODO:
 * - Implement removeFilter function
 * - Add 'abolish entity' switch filter
 * - Implement 'Clear' and 'Apply' button functions
 */
function MobileFilter({ children, filterTitle, filterLabel, selectedFilters = [], clearFilter, applyFilter, isDisabled, disableApplyButton, notShowFilterCount }) {
  const [filterPopupOpen, setFilterPopupOpen] = useState(false);
  const [openedFilterIndex, setOpenedFilterIndex] = useState(null);
  const backdropRef = useRef();
  const popupMainPageRef = useRef();
  const popupSecondPageRef = useRef();
  const closeButtonRef = useRef();

  useEffect(() => {
    const close = (e) => {
      // listen for escape key pressed
      if (e.key === CONSTANTS.ESCAPE_KEY) {
        closeFilterPopup();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  });

  const openFilterPopup = () => {
    setFilterPopupOpen(true);
  };

  const closeFilterPopup = () => {
    setFilterPopupOpen(false);
    setOpenedFilterIndex(null);
  };

  // closes popup on backdrop clicked
  const closePopup = (event) => {
    if (event.target.id === style.mobileFilterPopup) {
      closeFilterPopup();
    }
  };

  const updateOpenedFilter = (index) => {
    setOpenedFilterIndex(index);
    if (index === null) {
      // refocus to first page of modal when second page is exited (second page doesn't render when index === null)
      closeButtonRef.current.focus();
    }
  };

  const clearCurrentFilter = () => {
    closeFilterPopup();
    if (clearFilter) {
      clearFilter();
    }
  };

  const applyCurrentFilter = () => {
    closeFilterPopup();
    if (applyFilter) {
      applyFilter();
    }
  };

  const countFilters = (filterName) => {
    return selectedFilters.filter((selectedFilter) => {
      if (filterName) {
        // Note: Each selected filter dropdown should have the `filterGroupTitle` attribute for filter count to behave as expected
        return selectedFilter.filterGroupTitle?.toLowerCase().replace(' ', '') === filterName.toLowerCase().replace(' ', '');
      } else {
        return true;
      }
    }).length;
  };

  const popupHeader = (id, ref) => {
    return (
      <div className={style.filterPopupHeader}>
        <span>{filterTitle}</span>
        <button id={id} ref={ref} autoFocus className="noFill" aria-label="Close filter popup" title="Close filter popup" onClick={closeFilterPopup}>
          <svg>
            <use href={icons + '#close'} />
          </svg>
        </button>
      </div>
    );
  };

  return (
    <>
      <div className={style.mobileFilterContainer}>
        <button disabled={isDisabled} onClick={openFilterPopup} aria-description="Open filter" aria-label={filterTitle} title="Open filter">
          <span>
            {filterTitle}
            {countFilters() !== 0 && <Tag type={TAG_STYLE_ENUMS.DARK} displayText={countFilters().toString()}></Tag>}
          </span>

          <span className={style.filterIcon}>
            <svg className={isDisabled ? style.disabledIcon : ''}>
              <use href={icons + '#filter'} />
            </svg>
          </span>
        </button>
        {isDisabled ? <span className={style.apiFailed}>{FILTER_API_FAILED}</span> : <span className={style.filterLabel}>{filterLabel}</span>}
      </div>
      {/* Backdrop transition */}
      <CSSTransition
        in={filterPopupOpen}
        nodeRef={backdropRef}
        classNames={{
          enter: style.fadeIn,
          exit: style.fadeOut,
        }}
        appear={true}
        timeout={{ enter: 300, exit: 300 }}
        unmountOnExit>
        <div ref={backdropRef} id={style.mobileFilterPopup} onClick={closePopup}></div>
      </CSSTransition>
      {/* First page popup transition */}
      <CSSTransition
        in={filterPopupOpen}
        nodeRef={popupMainPageRef}
        classNames={{
          enter: style.slideUp,
          exit: style.slideDown,
        }}
        appear={true}
        timeout={{ enter: 300, exit: 300 }}
        unmountOnExit>
        <>
          <div ref={popupMainPageRef} className={style.mobileFilterMainPage}>
            {popupHeader(useId(), closeButtonRef)}
            <div className={style.filterPopupContent}>
              <ol className={style.filterMenuContainer}>
                {Children.map(children, (child, index) => {
                  if (child) {
                    // Assumes that 'filterGroupTitle' is available as a prop (since children should only be FilterGroupDropdown components)
                    const filterGroupTitle = child.props.filterGroupTitle;
                    const filterGroupTitleDisplay = child.props.filterGroupDisplayName ?? filterGroupTitle; // uses filterGroupDisplayName if found, otherwise uses filterGroupTitle
                    const selectedFiltersCount = countFilters(filterGroupTitle);

                    return (
                      <li className={child.props.children ? null : style.disabledListItem}>
                        <button
                          className="noFill"
                          disabled={child.props.children ? false : true}
                          onClick={() => updateOpenedFilter(index)}
                          aria-label={`Open ${filterGroupTitleDisplay} filter`}>
                          <span className={['h6', style.filterGroupTitle].join(' ')}>{filterGroupTitleDisplay}</span>
                          {selectedFiltersCount !== 0 && <Tag type={TAG_STYLE_ENUMS.HIGHLIGHT} displayText={selectedFiltersCount.toString()}></Tag>}
                          <span className={style.alignIconRight}>
                            <svg className={child.props.children ? '' : style.disabledIcon}>
                              <use href={icons + '#arrow'} />
                            </svg>
                          </span>
                        </button>
                      </li>
                    );
                  }
                })}
              </ol>
              <div className={style.filterButtons}>
                <button aria-label="Clear filter values" title="Clear filter values" className="noFill" onClick={clearCurrentFilter}>
                  Clear
                </button>
                <button aria-label="Apply filter" title="Apply filter" disabled={disableApplyButton} onClick={applyCurrentFilter}>
                  Apply
                </button>
              </div>
            </div>
          </div>
          {/* Second page transition */}
          <CSSTransition
            in={openedFilterIndex !== null}
            nodeRef={popupSecondPageRef}
            classNames={{
              enter: style.slideLeft,
              exit: filterPopupOpen ? style.slideRight : style.slideDown,
            }}
            appear={true}
            timeout={{ enter: 300, exit: 300 }}
            unmountOnExit>
            <div ref={popupSecondPageRef} className={style.openedFilterPopup}>
              {popupHeader(useId())}
              <div className={style.openedFilterContent}>
                {Children.map(children, (child, index) => {
                  if (index === openedFilterIndex) {
                    // Assumes that 'filterGroupTitle' is available as a prop (since children should only be FilterGroupDropdown components)
                    const filterGroupTitle = child.props.filterGroupTitle;
                    const filterGroupTitleDisplay = child.props.filterGroupDisplayName ?? filterGroupTitle; // uses filterGroupDisplayName if found, otherwise uses filterGroupTitle
                    const selectedFiltersCount = countFilters(filterGroupTitle);

                    const showFilterCount = (filterGroupTitle) => {
                      for (let i = 0; i < notShowFilterCount?.length; ++i) {
                        if (notShowFilterCount[i] === filterGroupTitle) {
                          return false;
                        }
                      }
                      return true;
                    };

                    return (
                      <>
                        <div className={style.openedFilterHeading}>
                          <button className="noFill" onClick={() => updateOpenedFilter(null)} aria-label="Go back" title="Go back">
                            <svg>
                              <use href={icons + '#arrow'} />
                            </svg>
                          </button>
                          <span className="h6">{filterGroupTitleDisplay}</span>
                          <span className={style.alignIconRight}>
                            {showFilterCount(filterGroupTitleDisplay) && (
                              <Tag type={selectedFiltersCount > 0 ? TAG_STYLE_ENUMS.HIGHLIGHT : TAG_STYLE_ENUMS.GREY} displayText={selectedFiltersCount.toString()}></Tag>
                            )}
                          </span>
                        </div>
                        <div className={style.openedFilterChildren}>{Array.isArray(child.props.children) ? child.props.children[0] : child.props.children}</div>
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </CSSTransition>
        </>
      </CSSTransition>
    </>
  );
}

MobileFilter.propTypes = {
  children: PropTypes.node.isRequired,
  filterTitle: PropTypes.string.isRequired,
  filterLabel: PropTypes.string,
  selectedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string.isRequired,
      returnValue: PropTypes.string.isRequired,
      filterGroupTitle: PropTypes.string.isRequired,
      selected: PropTypes.bool,
    }),
  ),
  clearFilter: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  disableApplyButton: PropTypes.bool,
  notShowFilterCount: PropTypes.array,
};

export default MobileFilter;
