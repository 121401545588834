import React from 'react';
import {
  exportSearchResults,
  getAContentItem,
  getContentItemLists,
  getDataExplorerFilterOptions,
  getDataSetsFilterOptions,
  getGovernmentBody,
  getPortfolio,
  getSearchFilterOptions,
  getTaxonomies,
  searchItems,
} from 'Services/Delivery.js';
import { defer, redirect } from 'react-router-dom';
import {
  BODY_TYPE_OPTIONS,
  CONTENT_ITEM_TYPES,
  DOMAIN_LIST,
  IS_FOR_EXPORT_PDF,
  IS_FOR_EXPORT_WORD,
  IS_PREVIEW_MODE,
  PATHS,
  PUBLICATION_TYPE,
  SEARCH_FACETS_SESSION_STORAGE_KEY,
  SEARCH_PAGE_METADATA_LOCAL_STORAGE_KEY,
  TAG_POSITION_ENUMS,
  TAG_STYLE_ENUMS,
  URL_PARAMS,
} from 'Constants';
import { FILTERABLE_GROUPS, SEARCH_API_CONSTANTS, SEARCH_API_FUNCTION } from 'Constants/Search.js';
import { MAX_PUBLICATION_PER_PAGE } from 'Constants/Publications.js';
import { MAX_SEARCH_RESULTS_PER_PAGE } from 'Constants/SearchPage.js';
import {
  annualReportUrl,
  entityProfileUrl,
  getPublicationType,
  getUrlObject,
  parseRichTextHtml,
  portfolioProfileUrl,
  processWebTableContents,
  removeHtmlTagsInString,
  sortByObjectProperty,
  standardPageUrl,
  titleToUrl,
  innerPageScroll,
} from 'Utils';
import { STATUS_TYPES } from 'Utils/Error';
import { processArticleContent, processContentItem } from './ProcessContents.js';
import { getPublicationsFromLocal, savePublicationsToLocal } from './Publications/Publications.js';
import { getFromSessionStorage, saveToSessionStorage } from './StorageUtils.js';
import { getSearchResultsFromLocal, saveSearchResultsToLocal } from './Search/SearchPage.js';
import { setAxiosDefaults } from 'Services';
import { processCompareFunctionMandatoryRequirements } from './AnnualReport/AnnualReport.js';
import { HashLink } from 'react-router-hash-link';
import { getBodyTypeTag, getBodyTypeTextFromTag } from 'Constants/DataSets.js';

/**
 * Function to fetch web data table content item and return react node.
 *
 * NOTE: This loader does not need to defer as it's only used for BE's export pdf feature.
 *
 * @param {String} tableCodename Web data content or data table template content item codename.
 * @returns {Node} Return react nodes of web data table.
 */
export const webDataTableLoader = async (tableCodename) => {
  const data = await getAContentItem(tableCodename, undefined, true, { middlewareContentType: 'table' });

  if (data.isError || data?.items?.length === 0) {
    return null;
  }

  let tableData = data;
  let showTitle = true; // if codename provided is data table itself, always display title
  let title;
  let htmlString;

  // if returned data is table element content item
  if (data?.[0]?.Table) {
    const dataTable = data?.[0];

    return { node: parseRichTextHtml(dataTable?.Table), title: null, emptyTable: dataTable?.[0]?.Table === '' };
  }

  // if returned data is data table template content item
  if (data?.item?.system?.type === CONTENT_ITEM_TYPES?.DATA_TABLE?.ID) {
    tableData = { item: data?.item?.elements?.data_table?.linkedItems?.[0] };

    if (!tableData?.item) {
      return null;
    }

    // if codename provided is data table template, which can hide table title, decide hide table title or not based on passed value.
    showTitle = data?.item?.elements?.display_title?.value?.[0]?.codename?.toLowerCase() === 'display_table_title';
  }

  title = tableData?.item?.elements?.title?.value;
  const renderingOptions = tableData?.item?.elements?.web_table_rendering_options?.linkedItems?.[0]?.elements;

  // if returned data is data table content item (if web table rendering options object does not exists) return null
  if (!renderingOptions) {
    return null;
  }

  htmlString = processWebTableContents(
    renderingOptions?.web_table__web_table_base?.value,
    tableData?.item?.elements,
    renderingOptions?.web_table__table_type?.value?.[0]?.codename?.toLowerCase() !== 'insert',
  );

  return { node: parseRichTextHtml(htmlString), title: showTitle ? title : null };
};

/**
 * Function to fetch standard page data but defer so that react router loader will still run component even if data has not been fetched
 * @param {String} standardPageWebUrl Standard page web url to fetch content
 * @returns defer object passed to loader, which allows for accessing resolved data through <Await>
 */
export const standardPageLoader = (standardPageWebUrl) => {
  return defer({
    standardPage: fetchStandardPage(standardPageWebUrl),
  });
};

/**
 * Function to fetch standard page content and process it
 * @param {String} standardPageUrlSlug Standard page web url to fetch content
 * @returns {Object} Standard page content
 */
const fetchStandardPage = async (standardPageUrlSlug) => {
  const data = await getAContentItem(standardPageUrlSlug, CONTENT_ITEM_TYPES.STANDARD_PAGE.ID, false, { 'system.collection': CONTENT_ITEM_TYPES.STANDARD_PAGE.COLLECTION });
  if (data.isError) {
    return data;
  }

  const processedData = processContentItem([data?.item], data.linkedItems);
  const standardPageData = processedData?.processedSectionNodes?.[0]; // always expect only one item
  const res = {
    routeTitle: standardPageData?.title?.value || '',
    contentNode: standardPageData?.node || null,
    routeBaseUrl: `/${standardPageUrl(standardPageUrlSlug)}`,
    relevantLinks: standardPageData?.related_links?.linkedItems?.map((obj) => {
      const element = obj?.elements;
      const urlObject = getUrlObject(element?.url?.value); // get url to replace origin

      return {
        url: DOMAIN_LIST.includes(urlObject?.origin) ? `${window.location.origin}${urlObject.pathname}${urlObject.hash}` : element?.url?.value || '', // replace url origin if one of origin values found
        title: element?.title?.value || '',
        summary: element?.summary?.value || '',
        newTab: element?.open_in_new_tab?.value?.[0]?.codename?.toLowerCase() === 'yes',
      };
    }),
  };

  return res;
};

/**
 * Function to fetch home page data but defer so that react router loader will still run component even if data has not been fetched
 * @param {Boolean} [isDART=false] If true, loader is used for
 * @returns defer object passed to loader, which allows for accessing resolved data through <Await>
 */
export const homepageLoader = (isDART = false) => {
  if (isDART) {
    setAxiosDefaults();
  }
  return defer({
    homepage: !isDART ? fetchHomepageData() : undefined,
    headerFooterData: fetchHeaderFooterData(),
  });
};

/**
 * Function to fetch home page data (currently fetches filter facet options)
 * @returns {Object} Contains filter options (object) and filter group values (array)
 */
export const fetchHomepageData = async () => {
  // fetch homepage metadata
  const homepageMetadataData = await getAContentItem(CONTENT_ITEM_TYPES.PORTAL_HOMEPAGE.CODENAME, undefined, true);
  if (homepageMetadataData.isError) {
    return homepageMetadataData;
  }
  const data = homepageMetadataData?.item?.elements;

  const processedNode = (targetData) => {
    const node = processContentItem([{ system: homepageMetadataData?.item?.system, elements: { body: targetData } }], homepageMetadataData.linkedItems, {}, false)
      ?.processedSectionNodes?.[0]?.node;

    return node;
  };

  const res = {
    homepageMetadata: {
      heroBanner: {
        body: processedNode(data?.banner_body),
        links: data?.banner_card_links?.linkedItems?.map((obj) => {
          const element = obj?.elements;
          return { title: element?.title?.value, url: element?.url?.value, summary: element?.summary?.value };
        }),
      },
      publicationsSection: {
        title: data?.publications_section_title?.value,
        columns: ['annual_reports', 'corporate_plans', 'portfolio_budget_statements']
          .map((key) => {
            return {
              title: key
                ?.split('_')
                ?.map((str) => `${str[0]?.toUpperCase()}${str?.slice(1)}`)
                ?.join(' '),
              node: processedNode(data?.[key]),
            };
          })
          .filter(Boolean),
      },
      relatedResources: data?.links?.linkedItems?.map((link) => {
        const data = link?.elements;
        return {
          title: data?.title?.value,
          url: data?.url?.value,
          summary: data?.summary?.value,
          openInNewTab: data?.open_in_new_tab?.value?.[0]?.codename?.toLowerCase() === 'yes',
        };
      }),
    },
  };

  // Get search facets from session storage if found
  const searchFacets = getFromSessionStorage(SEARCH_FACETS_SESSION_STORAGE_KEY);
  if (searchFacets) {
    return { ...res, ...searchFacets };
  }

  // Fetch filter facets
  const searchFilterOptionsData = await getSearchFilterOptions();
  if (searchFilterOptionsData.isError) {
    // show enough data to display the homepage
    return { ...res };
  }

  const [filterData, filterGroupValues] = searchFilterOptionsData;
  const filterObj = { filterOptions: filterData, filterGroupValues };

  if (filterData && filterGroupValues) {
    saveToSessionStorage(SEARCH_FACETS_SESSION_STORAGE_KEY, filterObj);
  }

  return { ...res, ...filterObj };
};

/**
 * Function to fetch footer data
 * @returns {[Object]} Return list of standard page content item object
 */
export const fetchHeaderFooterData = async () => {
  const data = await getAContentItem(CONTENT_ITEM_TYPES.PORTAL_MAIN_FOOTER.CODENAME, undefined, true);

  if (data.isError) {
    return data;
  }

  const formPageLinkList = (list) => {
    return (
      list
        ?.map((obj) => {
          const title = obj?.elements?.title?.value;
          const urlSlug = obj?.elements?.web_url?.value;

          if (title && urlSlug) {
            return {
              title,
              url: standardPageUrl(urlSlug),
              isMenuItem: obj?.elements?.show_in_navigation?.value?.[0]?.codename?.toLowerCase() === 'yes',
            };
          }

          return undefined;
        })
        ?.filter(Boolean) || []
    );
  };

  const processedData = processContentItem([data?.item], data.linkedItems);
  const res = {
    contentNode: processedData?.processedSectionNodes?.[0]?.node,
    tpMainMenuLinks: [],
    tpPageLinks: formPageLinkList(data?.item?.elements?.tp_page_link?.linkedItems || []),
    dartPageLinks: formPageLinkList(data?.item?.elements?.dart_page_link?.linkedItems || []),
  };

  return res;
};

/**
 * Loader function called by preview annual report path on preview app to redirect user to correct annual report preview page
 * @param {{ codename: String}} params Params object that contains `codename`
 * @returns defer object passed to loader, which allows for accessing resolved data through <Await>
 */
export const previewAnnualReportRedirector = (params) => {
  return defer({
    redirectTo: retrievePreviewAnnualReport(params),
  });
};

/**
 * Function to construct correct annual report path for preview annual report
 * @param {{ codename: String}} params Params object that contains `codename`
 * @returns Redirect to correct annual report page
 */
const retrievePreviewAnnualReport = async (params) => {
  const codename = params[URL_PARAMS.CODENAME];

  let fetchedData;
  let govBody;
  let portfolioData;

  [fetchedData, govBody] = await Promise.all([
    getAContentItem(codename, CONTENT_ITEM_TYPES.ANNUAL_REPORT.ID, true), // get AR data
    getGovernmentBody(codename, true, 1), // get government body from AR codename
  ]);

  const fetchedARReportingPeriodCodename = fetchedData?.ReportingPeriod?.[0]?.codename; // always assume one reporting period selected

  if (fetchedARReportingPeriodCodename && govBody?.item?.system?.codename) {
    // get portfolio data from govenrment body codename
    portfolioData = await getPortfolio(govBody.item?.system?.codename, fetchedARReportingPeriodCodename, true, 1);
  }

  const urlBase = `/${annualReportUrl(
    portfolioData?.item?.elements?.web_url?.value || `:${URL_PARAMS.PORTFOLIO}`,
    govBody?.item?.elements?.web_url?.value || `:${URL_PARAMS.ENTITY}`,
    fetchedData?.item?.elements?.web_url?.value || fetchedData?.WebUrl || `:${URL_PARAMS.ANNUAL_REPORT}`,
  )}`;

  return urlBase;
};

/**
 * Loader function to redirect old annual report link structure to the new structure
 * @param {{reportingYearUrlSlug: String, entityUrlSlug: String}} params - Parameters from the url (which includes the reporting year and entity url slug)
 * @returns deferred Object of redirect link
 */
export const oldAnnualReportLinkRedirect = (params) => {
  return defer({
    redirectTo: retrieveAnnualReportFromOldLink(params),
  });
};

/**
 * Retrieve annual report from old link
 * @param {{reportingYearUrlSlug: String, entityUrlSlug: String}} params - Parameters from the url (which includes the reporting year and entity url slug)
 * @returns new Annual report url path to redirect to (if not found, will return /page-not-found)
 */
const retrieveAnnualReportFromOldLink = async (params) => {
  const publicationAcceptedValues = [PUBLICATION_TYPE.ANNUAL_REPORT.VALUE];
  const arFilters = SEARCH_API_FUNCTION.FILTER_IN(FILTERABLE_GROUPS.PUBLICATION_TYPE.VALUE, publicationAcceptedValues);
  // Replace all 2018-2019 values with 2018-19
  const reportingYearFilters = SEARCH_API_FUNCTION.FILTER_IN(FILTERABLE_GROUPS.REPORTING_YEAR.VALUE, [
    params.reportingYearUrlSlug === '2018-2019' ? '2018-19' : params.reportingYearUrlSlug,
  ]);
  const searchTerms = SEARCH_API_FUNCTION.SEARCH_TERM(params.entityUrlSlug);

  const entityContentItemList = await searchItems(MAX_PUBLICATION_PER_PAGE, 1, searchTerms, `${arFilters} ${SEARCH_API_CONSTANTS.AND} ${reportingYearFilters}`, '', false, false);

  if (!entityContentItemList.isError) {
    // find entity and get the annual report, entity and portfolio with web url to redirect to
    if (entityContentItemList && Array.isArray(entityContentItemList) && entityContentItemList.length > 0) {
      const entityARList = entityContentItemList[0];
      const entityAR = entityARList?.[0]; // always take first item that comes back from search

      if (entityAR?.UrlSlug) {
        const arCodename = entityAR.CodeName;
        const arUrlSlug = entityAR.UrlSlug;
        let fetchedData;
        let govBody;
        let portfolioData;
        [fetchedData, govBody] = await Promise.all([
          getAContentItem(arUrlSlug, CONTENT_ITEM_TYPES.ANNUAL_REPORT.ID, false, {
            ...(!IS_PREVIEW_MODE && !IS_FOR_EXPORT_PDF && !IS_FOR_EXPORT_WORD && { lite: true }),
          }), // get AR data
          getGovernmentBody(arCodename, true, 1), // get government body from AR codename
        ]);

        if (fetchedData?.ReportingPeriod?.[0]?.codename && govBody?.item?.system?.codename) {
          // get portfolio data from govenrment body codename
          portfolioData = await getPortfolio(govBody.item?.system?.codename, fetchedData?.ReportingPeriod?.[0]?.codename, true, 1);
        }

        const urlBase = `/${annualReportUrl(
          portfolioData?.item?.elements?.web_url?.value || `:${URL_PARAMS.PORTFOLIO}`,
          govBody?.item?.elements?.web_url?.value || `:${URL_PARAMS.ENTITY}`,
          fetchedData?.item?.elements?.web_url?.value || fetchedData?.WebUrl || `:${URL_PARAMS.ANNUAL_REPORT}`,
        )}`;
        return urlBase;
      }
    }
  }
  return `/${PATHS.PAGE_NOT_FOUND}`;
};

/**
 * Function to fetch annual report but defer so that react router loader will still run component even if data has not been fetched
 * @param {{ arUrlSlug: String, subSection: String }} params Params object that contains `arUrlSlug` and `subSection`
 * @returns defer object passed to loader, which allows for accessing resolved data through <Await>
 */
export const annualReportLoader = async (params) => {
  const isPublishedApp = !IS_PREVIEW_MODE && !IS_FOR_EXPORT_PDF && !IS_FOR_EXPORT_WORD;
  const annualReport = fetchAnnualReport(params, isPublishedApp);

  return defer({
    base: annualReport,
    annualReport,
  });
};

/**
 * Function to fetch annual report content item from Kontent.ai via Delivery API SDK
 * @param {{ arUrlSlug: String, subSection: String }} params - Params object that contains `arUrlSlug` and `subSection`
 * @param {Boolean} [liteVersion = false] - If true, fetched AR data would be lite version which would contain minimum to just render AR cover page not enough to render AR content page.
 * @returns {Object} Return object of processed data and other to render annual report cover and article page
 */
const fetchAnnualReport = async (params, liteVersion = false) => {
  const arUrlSlug = params[URL_PARAMS.ANNUAL_REPORT];
  const portfolioUrlSlug = params[URL_PARAMS.PORTFOLIO];
  const govBodyUrlSlug = params[URL_PARAMS.ENTITY];
  const urlBase = `/${annualReportUrl(portfolioUrlSlug, govBodyUrlSlug, arUrlSlug)}`;

  let annualReportData;
  let govBody;
  let portfolioData;

  portfolioData = await getPortfolio(portfolioUrlSlug, null, undefined, 2);
  govBody =
    portfolioData?.item?.elements?.entities?.linkedItems?.find((entityObj) => entityObj?.elements?.web_url?.value === govBodyUrlSlug) ||
    portfolioData?.item?.elements?.leading_entity?.linkedItems?.find((entityObj) => entityObj?.elements?.web_url?.value === govBodyUrlSlug);

  // if entity is not part of portfolio, return error
  if (!govBody) {
    return { isError: true, statusCode: STATUS_TYPES.NOT_FOUND };
  }

  const targetAR = govBody?.elements?.annual_reports?.linkedItems?.find((arObj) => arObj?.elements?.web_url?.value === arUrlSlug);
  // if targetAR is undefined
  if (!targetAR) {
    return { isError: true, statusCode: STATUS_TYPES.NOT_FOUND };
  }

  // Due to limit of "data depth" level of 6 in Kontent, even annual report data object already exists from `govBody`, fetch target annual report data itself again
  let filteredPublications;
  [annualReportData, filteredPublications] = await Promise.all([
    getAContentItem(arUrlSlug, CONTENT_ITEM_TYPES.ANNUAL_REPORT.ID, false, { 'system.collection': targetAR?.system?.collection, ...(liteVersion && { lite: true }) }),
    getContentItemLists([CONTENT_ITEM_TYPES.ANNUAL_REPORT.ID], { collection: targetAR?.system?.collection }), // filter publications to those only in the collection of the current annual report
  ]);

  if (annualReportData instanceof Error) {
    // If error for annual report, then send to 'Something went wrong page'
    return { isError: true, statusCode: STATUS_TYPES.OTHER };
  }

  if (!IS_PREVIEW_MODE && (!portfolioData || !govBody || annualReportData?.isError === true || !annualReportData)) {
    return { isError: true, statusCode: STATUS_TYPES.NOT_FOUND };
  }



  // Processes rulelist of mandatory requirements for compare function
  let ruleList;
  let mandatoryRequirementsContentItem;
  ruleList = processCompareFunctionMandatoryRequirements(annualReportData?.MandatoryRequirements, { mandatoryTableHtml: annualReportData?.ListOfRequirementsMarkup || '' });
  mandatoryRequirementsContentItem = annualReportData?.item?.elements?.mandatory_requirements?.linkedItems || [];
  const processedData = processArticleContent(annualReportData, arUrlSlug).filter(
    (chapter) => {
      const chapterHasContent = chapter?.hasBodyContent || (Array.isArray(chapter.bodyNode) ? chapter.bodyNode.length > 0 : chapter.bodyNode && chapter.bodyNode !== 'undefined' ? chapter.bodyNode : false) ||
        IS_PREVIEW_MODE ||
        IS_FOR_EXPORT_PDF ||
        IS_FOR_EXPORT_WORD ||
        chapter?.pdfItem?.length > 0 ||
        false;
      const sections = chapter.sections || [];
      const hasChildren = sections.map((section) => {
        if (!IS_PREVIEW_MODE && section?.pdfItem?.length === 0 && section?.bodyNode?.length === 0 && !section?.containsLoR) {
          // if is non preview mode and section has no pdf item nor body contents nor LoR, do not render this section
          return false;
        }
        return true;
      })?.filter(Boolean) || [];

      if (!IS_PREVIEW_MODE) {
        // filter out route that has no children and no chapter content if not preview mode
        return !(!chapterHasContent && hasChildren.length === 0);
      }
      return chapter;
    }
  );

  const extraObjToParser = {
    chapterList: annualReportData?.ReportSections,
    articleWebUrl: annualReportData?.WebUrl,
  };

  const routes = processedData
    ?.map((chapter) => {
      const chapterTitle = chapter.title;
      const chapterPath = `${urlBase}/${chapter?.webUrl || titleToUrl(chapterTitle)}`; // take chapter web url if not empty, otherwise take the title and convert to url
      const sections = chapter.sections || [];
      const chapterCodename = chapter?.system?.codename;
      const chapterId = chapter?.system?.id;

      return {
        chapterHasContent:
          chapter?.hasBodyContent ||
          (Array.isArray(chapter.bodyNode) ? chapter.bodyNode.length > 0 : chapter.bodyNode && chapter.bodyNode !== 'undefined' ? chapter.bodyNode : false) ||
          IS_PREVIEW_MODE ||
          IS_FOR_EXPORT_PDF ||
          IS_FOR_EXPORT_WORD ||
          chapter?.pdfItem?.length > 0 ||
          false,
        path: chapterPath,
        preLoadedContent: chapter?.system?.type === CONTENT_ITEM_TYPES.MANDATORY_REQUIREMENT_REPORT_SECTION.ID ? chapter.bodyNode : false,
        fetched: chapterCodename ? (IS_PREVIEW_MODE ? true : chapter?.system?.type === CONTENT_ITEM_TYPES.MANDATORY_REQUIREMENT_REPORT_SECTION.ID) : true,
        fetching: false,
        contentCodename: chapterCodename,
        chapterId: chapterId,
        routeTitle: chapterTitle,
        bodyDataProcessed: chapter.bodyDataProcessed,
        children:
          sections
            .map((section) => {
              const sectionTitle = section.title;
              const sectionCodename = section.system.codename;
              const sectionId = section?.system?.id;

              if (!IS_PREVIEW_MODE && section?.pdfItem?.length === 0 && section?.bodyNode?.length === 0 && !section?.containsLoR) {
                // if is non preview mode and section has no pdf item nor body contents nor LoR, do not render this section
                return null;
              }

              return {
                path: `${chapterPath}/${section?.webUrl || titleToUrl(sectionTitle)}`, // take section web url if not empty, otherwise take the section title and convert to url
                fetched: sectionCodename ? (IS_PREVIEW_MODE ? true : section?.system?.type === CONTENT_ITEM_TYPES.MANDATORY_REQUIREMENT_REPORT_SECTION.ID) : true,
                preLoadedContent: chapter?.system?.type === CONTENT_ITEM_TYPES.MANDATORY_REQUIREMENT_REPORT_SECTION.ID ? section.bodyNode : false,
                fetching: false,
                contentCodename: sectionCodename,
                sectionId: sectionId,
                routeTitle: sectionTitle,
                bodyDataProcessed: section.bodyDataProcessed,
              };
            })
            ?.filter(Boolean) || [],
      };
    })
    .filter((route) => {
      if (!IS_PREVIEW_MODE) {
        // filter out route that has no children and no chapter content if not preview mode
        return !(!route.chapterHasContent && route.children.length === 0);
      }

      return route;
    });

  const governmentBody = govBody?.elements;
  const portfolioElement = portfolioData?.item?.elements;
  const logoItem = annualReportData?.Logo?.[0]; // always expect there is a single item if logo is given from AR content item

  // FE will take logo item from metadata and if not given, fallback to logo from government body element
  let logoUrl;
  if (!logoItem && governmentBody?.logo?.value?.[0]?.url) {
    logoUrl = `${governmentBody?.logo?.value?.[0]?.url}?${governmentBody?.logo?.value?.[0]?.renditions?.default?.query}`;
  }

  if (logoItem?.Image?.[0]?.url) {
    logoUrl = `${logoItem?.Image?.[0]?.url}?${logoItem?.Image?.[0]?.renditions?.default?.query || ''}`;
  }

  const logo = {
    url: logoUrl,
    altText: logoItem?.AltText || governmentBody?.logo?.value?.[0]?.description || '',
  };

  let department = {};
  let portfolio = {};

  // If entity is not found in portfolio or annual report is not found in entity, don't return the portrfolio/entity data
  if (governmentBody) {
    // As requirement, government body will always required with url
    department = {
      name: governmentBody?.body_name?.value,
      url: governmentBody?.url?.value,
      webUrl: governmentBody?.web_url?.value && portfolioElement?.web_url?.value ? entityProfileUrl(portfolioElement.web_url.value, governmentBody?.web_url?.value) : '',
      bodyType: governmentBody?.body_type?.value?.[0]?.name,
      statusText: governmentBody?.status_text?.value,
    };

    /**
     * As requirement, assume portfolio will always available.
     * according to client, annual report can only be generated AFTER portfolio and corporate plan is released
     * (eg: annual report for 2022-23 can only be release AFTER portfolio and corporate plan of 2023-24 is released)
     */
    portfolio = {
      name: portfolioData?.item?.elements?.portfolio_name?.value,
      webUrl: portfolioData?.item?.elements?.web_url?.value ? portfolioProfileUrl(portfolioData?.item?.elements?.web_url?.value) : '#',
    };
  }

  // always expect single reporting period item is returned if it is given
  const reportingPeriod = annualReportData?.ReportingPeriod?.[0]?.name;

  // always expect single cover image is returned if it is given
  const coverImage = annualReportData?.CoverImage?.[0]?.Image?.[0];
  let coverImageSrc = coverImage?.url;
  if (coverImage?.renditions?.default?.query) {
    coverImageSrc += `?${coverImage?.renditions?.default?.query}`;
  }

  // always expect single banner image is returned if it is given
  const bannerImage = annualReportData?.Banner?.[0]?.Image?.[0];
  let banner = bannerImage?.url;
  if (bannerImage?.renditions?.default?.query) {
    banner += `?${bannerImage?.renditions?.default?.query}`;
  }

  const cpObj = governmentBody?.corporate_plans?.linkedItems?.find((obj) => obj?.elements?.reporting_period?.value?.[0]?.name === reportingPeriod)?.elements;
  const pbsObj = portfolioElement?.portfolio_budget_statments?.linkedItems?.find((obj) => obj?.elements?.reporting_period?.value?.[0]?.name === reportingPeriod)?.elements;

  const relevantLinks = {
    entity: { name: department?.name || '', url: `/${department?.webUrl}` || '', entityType: department?.bodyType || '', newTab: false },
    corporatePlan: { name: cpObj?.title?.value || '', url: cpObj?.pdf_file?.value?.[0]?.url || '', period: reportingPeriod, newTab: true },
    portfolio: { name: pbsObj?.title?.value || '', url: pbsObj?.pdf_file?.value?.[0]?.url || '', period: reportingPeriod, newTab: true },
  };

  filteredPublications =
    filteredPublications?.items?.map((publication) => {
      return {
        webUrl: publication.elements?.web_url?.value,
        name: publication.elements?.title?.value,
        portfolioWebUrl: portfolioData?.item?.elements?.web_url?.value,
        entityWebUrl: governmentBody?.web_url?.value,
      };
    }) || [];

  const loaderRes = {
    extraObjToParser,
    banner,
    coverImageSrc,
    department,
    filteredPublications,
    logo,
    portfolio,
    relevantLinks,
    reportingPeriod,
    routes,
    routeTitle: annualReportData?.Title || '',
    routeBaseUrl: urlBase,
    system: annualReportData?.System,
    ruleList, // used for compare function
    mandatoryRequirementsContentItem, // used for compare function
    performanceData: annualReportData?.PerformanceData || [], // used for compare function
    generatePdfBatchImageLoadNumber: Number(Number(annualReportData?.ImagesToLoadConcurrently).toFixed(0)) || 10, // this is only used in generate pdf env
    processedData,
  };

  // if current route does have child sections and no content itself, redirect to its first child route
  const currentRoute = routes.find((route) => route.path === params[PATHS.ANNUAL_REPORT.CHILDREN.REPORT_SECTION]);
  if (currentRoute && !currentRoute?.chapterHasContent && !params[PATHS.ANNUAL_REPORT.CHILDREN.REPORT_SUB_SECTION]) {
    const redirectTarget = `${urlBase}/${currentRoute?.children?.[0]?.path}`;

    return redirect(redirectTarget);
  }

  return loaderRes;
};

/**
 * Function to fetch publications but defer so that react router loader will still run component even if data has not been fetched
 * @returns defer object passed to loader, which allows for accessing resolved data through <Await>
 */
export const publicationsLoader = (page, filters, searchTerm) => {
  return defer({
    publications: fetchPublications(page, false, filters, searchTerm),
  });
};

/**
 * Fetches publications (AR, CP and PBS) data to display. Allows for fetching a certain page of publications with specified extra filters and search terms.
 *
 * @param {Number} page - Page number, if not given, will default to 1
 * @param {Boolean} fetchNewContentOverride - Override fetching new content (get from api instead of from local storage)
 * @param {String} extraFilters - String of extra filters to apply to fetch publications
 * @param {String} searchTerm - String of search term to search with
 * @returns {{publications: Array, filterOptions: Object, filterGroupValues: Array, totalItems: Number}} Returns an array of publications, filter group and values, and the total count (of all possible values, to aid with pagination)
 */
export const fetchPublications = async (page, fetchNewContentOverride, extraFilters, searchTerm = '') => {
  const pageNumber = page ? page : 1;
  // Get publications from local storage if that particular page is found (and timestamp is within 5 mins)
  const publicationsInLocal = getPublicationsFromLocal(pageNumber);
  if (publicationsInLocal && !fetchNewContentOverride) {
    if (publicationsInLocal.filter === extraFilters && publicationsInLocal.searchTerm === searchTerm) {
      const newPublications = publicationsInLocal.publications || [];
      const itemCount = publicationsInLocal.totalItems || 0;
      return { publications: newPublications, totalItems: itemCount };
    }
  }

  // Convert all values into expressions to be used for search api
  const publicationAcceptedValues = [PUBLICATION_TYPE.ANNUAL_REPORT.VALUE, PUBLICATION_TYPE.CORPORATE_PLAN.VALUE, PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.VALUE];
  const filters = SEARCH_API_FUNCTION.FILTER_IN(FILTERABLE_GROUPS.PUBLICATION_TYPE.VALUE, publicationAcceptedValues);
  const orderBy = SEARCH_API_FUNCTION.ORDER_BY([
    { orderGroup: FILTERABLE_GROUPS.REPORTING_YEAR.VALUE, desc: true },
    { orderGroup: FILTERABLE_GROUPS.PUBLICATION_TYPE.VALUE },
    { orderGroup: FILTERABLE_GROUPS.ENTITY.VALUE },
  ]);
  const searchTerms = SEARCH_API_FUNCTION.SEARCH_TERM(searchTerm);

  const fetchedData = await searchItems(
    MAX_PUBLICATION_PER_PAGE,
    pageNumber,
    searchTerms,
    extraFilters ? `${filters} ${SEARCH_API_CONSTANTS.AND} ${extraFilters}` : filters,
    searchTerm ? '' : orderBy,
    false,
    false,
  );

  // If error, return isError structure
  if (fetchedData.isError) {
    return fetchedData;
  }

  const [fetchedPublications, totalCount] = fetchedData;

  const returnData = fetchedPublications.map((publication) => {
    const publicationType = getPublicationType(publication.ContentType);

    const tags = [
      { display: publicationType, type: TAG_STYLE_ENUMS.HIGHLIGHT },
      { display: publication.ReportingYear, type: TAG_STYLE_ENUMS.GREY },
    ];

    if (publication.abolished) {
      tags.push({ display: 'Abolished', type: TAG_STYLE_ENUMS.DARK });
    }

    let headerLink = '';
    if (publication.PortfolioUrlSlug) {

      if ([PUBLICATION_TYPE.ANNUAL_REPORT.TAG, PUBLICATION_TYPE.ANNUAL_REPORT_GROUP.TAG, PUBLICATION_TYPE.CORPORATE_PLAN.TAG].includes(publicationType)) {
        if (publication.EntityUrlSlug) {
          headerLink = entityProfileUrl(publication.PortfolioUrlSlug, publication.EntityUrlSlug);
        }
      } else if (publicationType === PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.TAG) {
        headerLink = portfolioProfileUrl(publication.PortfolioUrlSlug);
      }
    }

    let publicationLink;
    switch (publicationType?.toUpperCase()) {
      case PUBLICATION_TYPE.ANNUAL_REPORT.TAG?.toUpperCase():
        publicationLink = annualReportUrl(publication.PortfolioUrlSlug, publication.EntityUrlSlug, publication.UrlSlug);
        break;

      case PUBLICATION_TYPE.CORPORATE_PLAN.TAG?.toUpperCase():
      case PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.TAG?.toUpperCase():
      default:
        publicationLink = publication.UrlSlug;
        break;
    }

    return {
      publicationTitle: publication.Title,
      publicationType: publicationType,
      publicationHeader: publicationType === PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.TAG ? publication.Portfolio : publication.Entity,
      publicationHeaderLink: headerLink,
      reportingYear: publication.ReportingYear,
      coverImageSrc: publication.CoverImageUrl || null,
      abolished: publication.Abolished || false,
      publicationLink,
      tags,
    };
  });

  // Save publication data to local storage if there is data
  if (returnData.length > 0) {
    savePublicationsToLocal(pageNumber, returnData, totalCount, extraFilters, searchTerm);
  }

  return { publications: returnData, totalItems: totalCount };
};

/**
 * Function to fetch annual report but defer so that react router loader will still run component even if data has not been fetched
 * @param {String} routeId - Route id of where this loader is called.
 * @param {String} entityOrPortfolio - Profile identifier to fetch data.
 * @param {String|Undefined} [portfolioParam] - If given, consider data fetch is for entity profile, else, portfolio profile.
 * @returns defer object passed to loader, which allows for accessing resolved data through <Await>
 */
export const profileLoader = (routeId, entityOrPortfolio, portfolioParam) => {
  return defer({
    [routeId]: fetchProfileLoader(entityOrPortfolio, portfolioParam),
  });
};
/**
 * @param {String} entityOrPortfolio - Profile identifier to fetch data
 * @param {String|Undefined} [portfolioParam] - If given, consider loader is for entity
 * @returns {Object} Return object of processed data to render entity/portfolio profile page
 *
 */
const fetchProfileLoader = async (entityOrPortfolio, portfolioParam) => {
  const routeBaseUrl = `/${PATHS.PORTFOLIOS_ENTITIES_COMPANIES.BASE}${portfolioParam ? `/${portfolioParam}` : ''}/${entityOrPortfolio}`;
  let previousItems = [];
  let sections;
  let entityOrPortfolioObj;
  let websiteLink = '';
  let previousItemsParentURLs = [];
  let entityImageURL = null;

  if (portfolioParam) {
    const fetchedEntity = await getAContentItem(entityOrPortfolio, CONTENT_ITEM_TYPES.GOVERNMENT_BODY.ID, false, {
      'system.collection': CONTENT_ITEM_TYPES.PORTFOLIO.COLLECTION,
      depth: 2,
    });
    if (fetchedEntity.isError) {
      return fetchedEntity;
    }
    const previousEntities = fetchedEntity.item?.elements?.previous_bodies?.linkedItems;
    entityOrPortfolioObj = fetchedEntity.item;
    websiteLink = entityOrPortfolioObj.elements?.url?.value;
    // check if using cropped image
    const croppedImgURL = entityOrPortfolioObj?.elements?.logo?.value?.[0]?.renditions?.default?.query;
    entityImageURL = entityOrPortfolioObj?.elements?.logo?.value?.[0]?.url
      ? `${entityOrPortfolioObj?.elements?.logo?.value?.[0]?.url}${croppedImgURL ? '?' + croppedImgURL : ''}`
      : null;

    if (previousEntities && Array.isArray(previousEntities) && previousEntities.length > 0) {
      previousItems = previousEntities;

      // previous entities might have a different parent - use getPortfolio, use codename
      for (let i = 0; i < previousItems.length; i++) {
        let parentPortfolioData = await getPortfolio(previousItems[i].system?.codename, null, true, 1);
        if (parentPortfolioData) previousItemsParentURLs[previousItems[i].system?.codename] = { parentURLSlug: parentPortfolioData.item?.elements?.web_url?.value };
        else previousItemsParentURLs[previousItems[i].system?.codename] = { parentURLSlug: null };
      }
    }

    // annual report data
    let annualReportData = [];
    const annualReports = fetchedEntity.item?.elements?.annual_reports?.linkedItems;
    if (annualReports && Array.isArray(annualReports) && annualReports.length > 0) {
      annualReportData = annualReports.map((ar) => {
        return {
          isDocument: true,
          name: ar.elements?.title?.value,
          url: { entityUrl: entityOrPortfolioObj.elements?.web_url?.value, arUrl: ar.elements?.web_url?.value },
          tags: [
            { displayText: PUBLICATION_TYPE.ANNUAL_REPORT.TAG, type: TAG_STYLE_ENUMS.HIGHLIGHT },
            { displayText: ar.elements?.reporting_period?.value?.[0]?.name, type: TAG_STYLE_ENUMS.GREY },
          ],
          imageUrl: ar.elements?.cover_image?.linkedItems?.[0]?.elements?.image?.value?.[0]?.url || null,
          year: ar.elements?.reporting_period?.value?.[0]?.name,
          isAR: true,
          contentType: PUBLICATION_TYPE.ANNUAL_REPORT.VALUE,
          codename: ar.system?.codename,
        };
      });
    }
    // order reports based off year
    annualReportData = sortByObjectProperty(annualReportData, 'year').reverse();

    // corporate plan data
    let corporatePlansData = [];
    const corporatePlans = fetchedEntity.item?.elements?.corporate_plans?.linkedItems;
    if (corporatePlans && Array.isArray(corporatePlans) && corporatePlans.length > 0) {
      corporatePlansData = corporatePlans.map((cp) => {
        return {
          isDocument: true,
          name: cp.elements?.title?.value,
          url: cp.elements?.pdf_file?.value?.[0]?.url || cp.elements?.corp_plan_url?.value,
          tags: [
            { displayText: PUBLICATION_TYPE.CORPORATE_PLAN.TAG, type: TAG_STYLE_ENUMS.HIGHLIGHT },
            { displayText: cp.elements?.reporting_period?.value?.[0]?.name, type: TAG_STYLE_ENUMS.GREY },
          ],
          imageUrl: cp.elements?.cover_image?.value?.[0]?.url,
          year: cp.elements?.reporting_period?.value?.[0]?.name,
          contentType: PUBLICATION_TYPE.CORPORATE_PLAN.VALUE,
          codename: cp.system?.codename,
        };
      });
    }
    corporatePlansData = sortByObjectProperty(corporatePlansData, 'year').reverse();

    sections = [
      {
        title: 'Annual Reports',
        items: annualReportData,
      },
      {
        title: 'Corporate Plans',
        items: corporatePlansData,
      },
      {
        title: 'Portfolio Budget Statements',
      },
    ];
  } else {
    const fetchedPortfolioOrEntity = await getAContentItem(entityOrPortfolio, CONTENT_ITEM_TYPES.PORTFOLIO.ID, false, {
      'system.collection': CONTENT_ITEM_TYPES.GOVERNMENT_BODY.COLLECTION,
      depth: 2,
    });
    if (fetchedPortfolioOrEntity.isError) {
      return fetchedPortfolioOrEntity;
    }
    entityOrPortfolioObj = fetchedPortfolioOrEntity.item;
    const leadEntityName = fetchedPortfolioOrEntity.item.elements?.leading_entity?.linkedItems?.[0]?.elements?.body_name?.value;
    const leadEntityObj = fetchedPortfolioOrEntity.item.elements?.leading_entity?.linkedItems?.[0];
    const previousPortfolios = fetchedPortfolioOrEntity.item.elements?.previous_portfolios?.linkedItems;
    const PBSItems = fetchedPortfolioOrEntity.item.elements?.portfolio_budget_statments?.linkedItems;

    if (previousPortfolios && Array.isArray(previousPortfolios) && previousPortfolios.length > 0) {
      previousItems = previousPortfolios;
    }

    let leadEntity = null;
    let leadEntityIndex = -1;
    let leadTags = [];
    if (leadEntityObj) {
      if (leadEntityObj?.elements?.body_type?.value?.[0]?.name) {
        leadTags = [{ displayText: leadEntityObj.elements?.body_type?.value?.[0]?.name, type: null }];
      }

      if (leadEntityObj?.elements?.status_text?.value) {
        leadTags.push({ displayText: leadEntityObj.elements.status_text.value, type: TAG_STYLE_ENUMS.DARK });
      }

      let leadEntityUrl = entityProfileUrl(entityOrPortfolioObj.elements?.web_url?.value, leadEntityObj?.elements?.web_url?.value);
      const leadCroppedImgURL = leadEntityObj?.elements?.logo?.value?.[0]?.renditions?.default?.query;

      leadEntity = {
        isDocument: false,
        name: leadEntityName || null,
        url: leadEntityUrl === 'unknown-entity' ? null : '/' + leadEntityUrl,
        tags: leadTags,
        imageUrl: leadEntityObj?.elements?.logo?.value?.[0]?.url ? `${leadEntityObj?.elements?.logo?.value?.[0]?.url}${leadCroppedImgURL ? '?' + leadCroppedImgURL : ''}` : null,
        contentType: leadEntityObj?.system?.type,
      };
    }
    let entityData = [];
    const linkedItems = fetchedPortfolioOrEntity.item?.elements?.entities?.linkedItems;
    if (linkedItems && Array.isArray(linkedItems) && linkedItems.length > 0) {
      entityData = linkedItems.map((entity, index) => {
        if (leadEntityName === entity?.elements?.body_name?.value) {
          // add lead entity index to remove repeated entity
          leadEntityIndex = index;
        }
        const bodyTypeValueArr = entity.elements?.body_type?.value?.[0]?.name;
        let tags = [];
        if (bodyTypeValueArr) {
          tags = [{ displayText: bodyTypeValueArr, type: null }];
        }

        if (entity.elements?.status_text?.value) {
          tags.push({ displayText: entity.elements.status_text.value, type: TAG_STYLE_ENUMS.DARK });
        }

        let entityUrl = entityProfileUrl(entityOrPortfolioObj.elements?.web_url?.value, entity.elements?.web_url?.value);
        const croppedImgURL = entity.elements?.logo?.value?.[0]?.renditions?.default?.query;
        const newData = {
          isDocument: false,
          name: entity.elements?.body_name?.value || null,
          url: entityUrl === 'unknown-entity' ? null : '/' + entityUrl,
          tags: tags,
          imageUrl: entity.elements?.logo?.value?.[0]?.url ? `${entity.elements?.logo?.value?.[0]?.url}${croppedImgURL ? '?' + croppedImgURL : ''}` : null,
          contentType: entity.system?.type,
          codename: entity.system?.codename,
        };

        return newData;
      });
    }

    if (entityData.length > 0 && leadEntity) {
      // removes leading entity repeat if also in entity list
      if (leadEntityIndex !== -1) {
        entityData.splice(leadEntityIndex, 1);
      }
      // sorts entities alphabetically
      entityData = sortByObjectProperty(entityData, 'name');
      // adds leading entity to front
      entityData.unshift(leadEntity);
      // updates leading entity property
      entityData[0].leadEntity = true;
    } else if (entityData.length > 0) {
      entityData = sortByObjectProperty(entityData, 'name');
      entityData[0].leadEntity = false;
    } else if (leadEntity) {
      // if only lead entity is found
      entityData = [leadEntity];
      entityData[0].leadEntity = true;
    }

    let pbsData = [];
    if (PBSItems && Array.isArray(PBSItems) && PBSItems.length > 0) {
      pbsData = PBSItems.map((pbs) => {
        return {
          isDocument: true,
          name: pbs.elements?.title?.value,
          url: pbs.elements?.pdf_file?.value?.[0]?.url,
          tags: [
            { displayText: PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.TAG, type: TAG_STYLE_ENUMS.HIGHLIGHT },
            { displayText: pbs.elements.reporting_period.value[0].name, type: TAG_STYLE_ENUMS.GREY },
          ],
          imageUrl: pbs.elements?.cover_image?.value?.[0]?.url,
          year: pbs.elements?.reporting_period?.value?.[0]?.name,
          contentType: pbs.system?.type,
          codename: pbs.system?.codename,
        };
      });

      pbsData = sortByObjectProperty(pbsData, 'year').reverse();
    }

    sections = [
      {
        title: 'Entities and companies in this Portfolio',
        items: entityData,
      },
      {
        title: 'Portfolio Budget Statements',
        items: pbsData,
      },
    ];
  }

  const historyData = [];
  // Add current history to the history data too
  if (entityOrPortfolioObj?.elements?.status_long_text?.value && entityOrPortfolioObj?.elements?.status_date?.value) {
    historyData.push({ info: entityOrPortfolioObj?.elements?.status_long_text?.value, date: entityOrPortfolioObj?.elements?.status_date?.value });
  }
  const addHistoryData = (previousData) => {
    previousData?.forEach((data) => {
      const elements = data?.elements;
      const eventText = elements?.status_long_text?.value;
      const eventDate = elements?.status_date?.value;

      if (eventText && eventDate) {
        historyData.push({ info: eventText, date: eventDate });
      }
    });

    previousData?.forEach((data) => {
      const previous = data?.elements?.previous_portfolios?.linkedItems || data?.elements?.previous_bodies?.linkedItems;
      if (previous) {
        addHistoryData(previous);
      }
    });

    // if previous item has previous item.
  };

  addHistoryData(previousItems);

  const res = {
    routeTitle: portfolioParam ? entityOrPortfolioObj?.elements?.body_name?.value : entityOrPortfolioObj?.elements.portfolio_name?.value,
    routeBaseUrl,
    pageCategory: portfolioParam ? entityOrPortfolioObj.elements?.body_type?.value?.[0]?.name : 'Portfolio',
    pageTag: entityOrPortfolioObj.elements?.status_text?.value ? { displayText: entityOrPortfolioObj.elements.status_text.value } : null,
    previousItems,
    sections,
    historyData,
    websiteLink,
    portfolioUrl: entityOrPortfolioObj?.elements?.web_url?.value,
    previousItemsParentURLs,
    entityImageURL,
  };

  return res;
};

/* Function to fetch portfolios but defer so that react router loader will still run component even if data has not been fetched
 * @returns defer object passed to loader, which allows for accessing resolved data through <Await>
 */
export function portfoliosLoader() {
  return defer({
    portfolios: fetchPortfolios(),
  });
}

/**
 * Function to fetch portfolios
 */
export const fetchPortfolios = async (overrideDepth) => {
  const limit = 10;
  let page = 1;
  let hasNextPage = false;
  const allPortfolios = [];
  let failedResponse = null;

  const mapEntities = (entityItems, portfolioWebUrl, isLeadEntity) => {
    const formattedEntities =
      entityItems?.map((entity) => {
        const logoUrl = entity?.elements?.logo?.value?.[0]?.url;
        const logoRenditions = entity?.elements?.logo?.value?.[0]?.renditions?.default?.query;
        return {
          codename: entity?.system?.codename,
          name: entity?.elements?.body_name?.value || '',
          bodyType: entity?.elements?.body_type?.value?.[0]?.name || '',
          statusText: entity?.elements?.status_text?.value || '',
          logo: logoUrl ? (logoRenditions ? `${logoUrl}?${logoRenditions}` : logoUrl) : '',
          leadEntity: isLeadEntity || false,
          url: entityProfileUrl(portfolioWebUrl, entity?.elements?.web_url?.value),
        };
      }) || [];
    return sortByObjectProperty(formattedEntities, 'name');
  };

  while ((page === 1) | hasNextPage) {
    const fetchedPortfolios = await getContentItemLists([CONTENT_ITEM_TYPES.PORTFOLIO.ID], {
      limit,
      page,
      depth: overrideDepth || 2,
      collection: CONTENT_ITEM_TYPES.PORTFOLIO.COLLECTION,
    });

    if (fetchedPortfolios.isError) {
      failedResponse = fetchedPortfolios;
    }
    if (fetchedPortfolios.items && fetchedPortfolios.items.length > 0 && fetchedPortfolios.pagination) {
      if (fetchedPortfolios.pagination.next_page) {
        hasNextPage = true;
      } else {
        hasNextPage = false;
      }

      fetchedPortfolios.items.forEach((portfolio) => {
        const portfolioWebUrl = portfolio?.elements?.web_url?.value;

        const entities = mapEntities(portfolio?.elements?.entities?.linkedItems, portfolioWebUrl);
        const leadEntity = mapEntities(portfolio?.elements?.leading_entity?.linkedItems, portfolioWebUrl, true);
        const allEntities = [...leadEntity, ...entities.filter((entity) => entity.codename !== leadEntity?.[0]?.codename)];
        const activeDuring =
          portfolio?.elements?.active_during?.value?.map((reportingYearObj) => {
            return reportingYearObj.codename;
          }) || [];

        const previousPortfolios = portfolio?.elements?.previous_portfolios?.linkedItems?.map((previousPortfolio) => {
          return {
            name: previousPortfolio?.elements?.portfolio_name?.value || '',
            url: portfolioProfileUrl(previousPortfolio?.elements?.web_url?.value),
          };
        });

        // count ARs and CPs
        let ARNum = 0;
        let CPNum = 0;
        for (let i = 0; i < portfolio?.elements?.entities?.linkedItems?.length; ++i) {
          ARNum += portfolio?.elements?.entities.linkedItems?.[i]?.elements?.annual_reports?.linkedItems?.length;
          CPNum += portfolio?.elements?.entities.linkedItems?.[i]?.elements?.corporate_plans?.linkedItems?.length;
        }

        allPortfolios.push({
          title: portfolio?.elements?.portfolio_name?.value || '',
          codename: portfolio?.system?.codename || '',
          activeDuring,
          subText: [],
          entities: allEntities,
          previouslyKnownAs: previousPortfolios,
          statusText: portfolio?.elements?.status_text?.value || '',
          url: portfolioProfileUrl(portfolioWebUrl),
          PBSNum: portfolio?.elements?.portfolio_budget_statments?.linkedItems?.length || 0,
          ARNum,
          CPNum,
        });
      });
    }
    page += 1;
  }

  if (failedResponse !== null) {
    return failedResponse;
  }

  return { portfolioList: sortByObjectProperty(allPortfolios, 'title'), routes: [], routeTitle: PATHS.PORTFOLIOS_ENTITIES_COMPANIES.TITLE };
};

export const searchLoader = async (page, searchTerm, filters) => {
  return defer({
    metadata: fetchSearchPageMetadata(),
    search: fetchSearchResults(page, false, searchTerm, filters),
  });
};

export const fetchSearchPageMetadata = async () => {
  const metadata = getFromSessionStorage(SEARCH_PAGE_METADATA_LOCAL_STORAGE_KEY);
  if (metadata) {
    return metadata;
  }

  const data = await getAContentItem(CONTENT_ITEM_TYPES.SEARCH_PAGE_METADATA.CODENAME, undefined, true);

  if (data.isError) {
    return data;
  }

  const res = {
    noResultsMessage: data?.item?.elements?.no_result_content?.value,
    noSearchKeyMessage: data?.item?.elements?.no_search_key_content?.value,
  };

  saveToSessionStorage(SEARCH_PAGE_METADATA_LOCAL_STORAGE_KEY, res);

  return res;
};

export const fetchSearchResults = async (page, rerunApi, searchTerm, filters, exactMatch = false, orderByEntity = false) => {
  const noResultsRes = { results: [], totalResults: 0 };
  if (!searchTerm) {
    return noResultsRes;
  }

  const pageNumber = page ? page : 1;



  const searchResultsInLocal = getSearchResultsFromLocal(pageNumber);
  if (searchResultsInLocal && !rerunApi) {
    // Only take from local storage if current filters and search term is equal to filtered values in storage
    if (searchResultsInLocal.filters === filters && searchResultsInLocal.searchTerm === searchTerm) {
      const previousResults = searchResultsInLocal.searchResults;
      const itemCount = searchResultsInLocal.totalItems;
      return {
        totalResults: itemCount,
        currentPage: pageNumber,
        results: previousResults,
      };
    }
  }


  const orderBy = SEARCH_API_FUNCTION.ORDER_BY(orderByEntity ? [
    { orderGroup: FILTERABLE_GROUPS.ENTITY.VALUE },
  ] : [
    { orderGroup: FILTERABLE_GROUPS.ENTITY.VALUE },
    { orderGroup: FILTERABLE_GROUPS.REPORTING_YEAR.VALUE, desc: true },
    { orderGroup: FILTERABLE_GROUPS.PUBLICATION_TYPE.VALUE },
  ]);
  const orderByValue = orderByEntity ? orderBy : searchTerm ? '' : orderBy;

  const searchTerms = SEARCH_API_FUNCTION.SEARCH_TERM(searchTerm, exactMatch);

  const data = await searchItems(MAX_SEARCH_RESULTS_PER_PAGE, pageNumber, searchTerms, filters || '', orderByValue, false, false);

  if (data.isError) {
    return noResultsRes;
  }

  const [fetchedResults, totalCount, searchId] = data;

  const returnData = fetchedResults.map((searchResult) => {
    const publicationType = getPublicationType(searchResult.ContentType);
    const tags = [];
    const entity = { name: '', urlSlug: '', logo: '' };
    const portfolio = { name: '', urlSlug: '' };

    if (searchResult.Portfolio && searchResult.PortfolioUrlSlug) {
      portfolio.name = searchResult.Portfolio;
      portfolio.urlSlug = searchResult.PortfolioUrlSlug;
    }

    if (searchResult.Entity && searchResult.EntityUrlSlug) {
      entity.name = searchResult.Entity;
      entity.urlSlug = searchResult.EntityUrlSlug;
    }
    // If is a portfolio search result
    if (searchResult.ContentType === CONTENT_ITEM_TYPES.PORTFOLIO.ID) {
      tags.push({ displayText: 'Portfolio' });
      portfolio.name = searchResult.Title;
      portfolio.urlSlug = searchResult.UrlSlug;
    }

    let annualReportTitle = null;
    let chapterTitle = null;
    if ([CONTENT_ITEM_TYPES.REPORT_CHAPTER.ID, CONTENT_ITEM_TYPES.REPORT_SECTION.ID].includes(searchResult.ContentType)) {
      tags.push({ displayText: PUBLICATION_TYPE.ANNUAL_REPORT.TAG, type: TAG_STYLE_ENUMS.HIGHLIGHT });

      if (searchResult.ReportingYear) {
        tags.push({ displayText: searchResult.ReportingYear, type: TAG_STYLE_ENUMS.GREY });
      }

      // collect chapter data
      chapterTitle = searchResult.ChapterTitle || 'chapter title';
      annualReportTitle = searchResult.Title;
    }

    if (searchResult.ContentType === CONTENT_ITEM_TYPES.REPORT_CHAPTER.ID) {
      tags.push({ displayText: 'Chapter', type: TAG_STYLE_ENUMS.GREY });
    }

    let sectionTitle = null;
    if (searchResult.ContentType === CONTENT_ITEM_TYPES.REPORT_SECTION.ID) {
      tags.push({ displayText: 'Section', type: TAG_STYLE_ENUMS.GREY });
      // collect section data
      sectionTitle = searchResult.SectionTitle || 'section title';
    }

    if (searchResult.ContentType === CONTENT_ITEM_TYPES.GOVERNMENT_BODY.ID) {
      entity.name = searchResult.Title;
      entity.urlSlug = searchResult.UrlSlug;
      entity.logo = searchResult.CoverImageUrl;
    }

    if (![CONTENT_ITEM_TYPES.PORTFOLIO.ID, PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.VALUE].includes(searchResult.ContentType) && searchResult.BodyType) {
      tags.push({ displayText: searchResult.BodyType });
    }

    // If is a PBS publication
    if (publicationType === PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.TAG && searchResult.Portfolio) {
      portfolio.name = searchResult.Portfolio;
      portfolio.urlSlug = searchResult.PortfolioUrlSlug;
    }

    if (publicationType) {
      tags.push({ displayText: publicationType, type: TAG_STYLE_ENUMS.HIGHLIGHT });

      if (searchResult.ReportingYear) {
        tags.push({ displayText: searchResult.ReportingYear, type: TAG_STYLE_ENUMS.GREY });
      }
    }

    if (searchResult.StatusText) {
      tags.push({ displayText: `${searchResult.StatusText}`, type: TAG_STYLE_ENUMS.DARK, isStatusText: true });
    }

    const searchSummary = searchResult?.['@search.highlights']?.Content?.[0] ? removeHtmlTagsInString(searchResult?.['@search.highlights']?.Content?.[0]) : '';

    return {
      tags,
      entity,
      portfolio,
      coverImage: searchResult.CoverImageUrl || null,
      targetLink: searchResult.UrlSlug || '',
      title: sectionTitle || chapterTitle || searchResult.Title,
      summary: searchSummary,
      type: searchResult.ContentType,
      isError: false,
      annualReportTitle: annualReportTitle,
      chapterTitle: chapterTitle,
      searchId,
      docId: searchResult.ID,
    };
  });

  if (returnData.length > 0) {
    saveSearchResultsToLocal(pageNumber, returnData, totalCount, filters, searchTerm);
  }

  const res = {
    totalResults: totalCount,
    currentPage: pageNumber,
    results: returnData,
    searchId,
  };

  return res;
};

export const fetchCSVFile = async (page, searchTerm, filters, exactMatch = false, orderByEntity = false) => {
  const noResultsRes = { results: [], totalResults: 0 };
  if (!searchTerm) {
    return noResultsRes;
  }

  const orderBy = SEARCH_API_FUNCTION.ORDER_BY(orderByEntity ? [
    { orderGroup: FILTERABLE_GROUPS.ENTITY.VALUE },
  ] : [
    { orderGroup: FILTERABLE_GROUPS.ENTITY.VALUE },
    { orderGroup: FILTERABLE_GROUPS.REPORTING_YEAR.VALUE, desc: true },
    { orderGroup: FILTERABLE_GROUPS.PUBLICATION_TYPE.VALUE },
  ]);
  const orderByValue = orderByEntity ? orderBy : searchTerm ? '' : orderBy;

  const searchTerms = SEARCH_API_FUNCTION.SEARCH_TERM(searchTerm, exactMatch);

  const data = await exportSearchResults(10, page, searchTerms, filters || '', orderByValue, false, true);

  if (data.isError) {
    return noResultsRes;
  }

  return data;
};

export const dataSetsPageLoader = () => {
  return defer({
    dataSets: fetchDataSetsPage(),
  });
};

export const fetchDataSetsPage = async () => {
  const dataSetFiltersOptions = await getDataSetsFilterOptions();
  if (dataSetFiltersOptions.isError) {
    return dataSetFiltersOptions;
  }

  const additiionalFilters =
    dataSetFiltersOptions.additionalFilters?.map((filterGroup) => {
      return { ...filterGroup, dataSets: filterGroup.datasets };
    }) || [];

  let dataSetFilters = dataSetFiltersOptions?.datasetFilters;

  const allLinkedDataSets = [];
  let groupedDataSets = {};

  if (Array.isArray(dataSetFilters) && dataSetFilters !== null) {
    dataSetFilters.forEach((filterGroup) => {
      const displayName = filterGroup.displayName;
      const filters = filterGroup.filters.map((dataFilter) => {
        let allBodyTypesFromLinkedDataSets = [];
        const linkedDataSet = [];
        if (dataFilter?.linkedDataSets) {
          let foundLinkedDataSet = filterGroup.filters.find((dataSet) => dataFilter?.linkedDataSets.includes(dataSet.codename));
          if (foundLinkedDataSet) {
            linkedDataSet.push({
              ...foundLinkedDataSet,
              bodyTypes: foundLinkedDataSet.bodyTypes.map((bodyType) => {
                return getBodyTypeTextFromTag(bodyType);
              }),
            });
            allBodyTypesFromLinkedDataSets = allBodyTypesFromLinkedDataSets.concat(foundLinkedDataSet.bodyTypes);
            allLinkedDataSets.push(foundLinkedDataSet.codename);
          }
        }
        return {
          codename: dataFilter.codename,
          display: dataFilter.display,
          returnValue: dataFilter.returnValue,
          selected: false,
          dataSetGroup: dataFilter.datasetGroups,
          tagInfo: {
            tagPosition: TAG_POSITION_ENUMS.RIGHT,
            tags: [...new Set([...dataFilter.bodyTypes, ...allBodyTypesFromLinkedDataSets])].map((bodyType) => {
              return { display: bodyType };
            }),
          },
          linkedDataSet: linkedDataSet,
          reportingPeriods: dataFilter.reportingPeriods,
          canBeVisualised: dataFilter.canBeVisualised,
        };
      });

      groupedDataSets[displayName] = sortByObjectProperty(
        filters.filter((dataSetFilter) => !allLinkedDataSets.includes(dataSetFilter.codename)),
        'display');
    });
  } else {
    groupedDataSets = {};
  }

  const dataSetGroups = dataSetFiltersOptions.datasetGroups?.map((group) => {
    return {
      display: group.display,
      returnValue: group.returnValue,
      selected: false,
      tagInfo: {
        tagPosition: TAG_POSITION_ENUMS.RIGHT,
        tags: group.bodyTypes.map((bodyType) => {
          return { display: bodyType, type: TAG_STYLE_ENUMS.DARK };
        }),
      },
    };
  });

  // fetch reporting year
  const reportingYears = await getTaxonomies('reporting_period');
  if (reportingYears.isError) {
    return reportingYears;
  }

  let reportingYearOptionList = [];
  if (reportingYears && reportingYears.terms) {
    reportingYears.terms?.forEach((year) => {
      reportingYearOptionList.push({
        display: year.name,
        returnValue: year.codename,
        selected: false,
      });
    });
  }

  // fetch entity and portfolio filter options
  const depth = 1;
  const portfolios = await fetchPortfolios(depth);
  if (portfolios.isError) {
    return portfolios;
  }
  let portfoliosList = [];
  let entityList = [];
  if (portfolios && portfolios.portfolioList) {
    const entitiesSeen = {};
    portfolios.portfolioList.forEach((portfolio) => {
      const portfolioObject = {
        activeDuring: portfolio?.activeDuring || [],
        display: portfolio.title,
        returnValue: portfolio.codename,
        selected: false,
      };

      if (portfolio.statusText) {
        portfolioObject.tagInfo = { tags: [{ display: portfolio.statusText, type: TAG_STYLE_ENUMS.GREY }] };
      }
      portfoliosList.push(portfolioObject);
      portfolio?.entities.forEach((entity) => {
        if (!entitiesSeen[entity.codename]) {
          entitiesSeen[entity.codename] = {
            activeDuring: portfolio?.activeDuring || [],
            display: entity.name,
            returnValue: entity.codename,
            selected: false,
            tagInfo: { tagPosition: TAG_POSITION_ENUMS.RIGHT, tags: [{ display: getBodyTypeTag(entity.bodyType) }] },
            bodyType: entity.bodyType,
            portfolio: [portfolio.codename],
          };
        } else if (entitiesSeen[entity.codename]?.portfolio) {
          if (!entitiesSeen[entity.codename].portfolio.includes(portfolio.codename)) {
            entitiesSeen[entity.codename].portfolio.push(portfolio.codename);
            entitiesSeen[entity.codename].activeDuring = entitiesSeen[entity.codename].activeDuring.concat(portfolio?.activeDuring || []);
          }
        }
      });
    });
    entityList = Object.keys(entitiesSeen).map((key) => {
      return entitiesSeen[key];
    });
  }

  const fetchedContent = await getAContentItem(CONTENT_ITEM_TYPES.DATA_SETS_PAGE.CODENAME, undefined, true);

  let footnoteData;
  const elements = fetchedContent?.item?.elements;
  if (elements) {
    footnoteData = elements?.[CONTENT_ITEM_TYPES.DATA_SETS_PAGE.DATA_SET_ANNOTATION]?.value;
    if (footnoteData) {
      footnoteData = parseRichTextHtml(footnoteData);
    }
  }

  return {
    groupedDataSetsFilters: dataSetGroups,
    dataSetFilters: groupedDataSets,
    reportingYearFilters: sortByObjectProperty(reportingYearOptionList, 'display').reverse(),
    bodyTypeFilters: BODY_TYPE_OPTIONS,
    portfolioFilters: sortByObjectProperty(portfoliosList, 'display'),
    entityFilters: sortByObjectProperty(entityList, 'display'),
    additionalFilters: additiionalFilters,
    footnoteData: footnoteData || '',
  };
};

/**
 * Function to fetch data sets definitions page data but defer so that react router loader will still run component even if data has not been fetched
 * @returns defer object passed to loader, which allows for accessing resolved data through <Await>
 */
export const dataSetsPageDefinitionsLoader = async () => {
  return defer({
    standardPage: fetchDataSetsDefinitionsPage(),
  });
};

/**
 * TODO: This need to be updated in MVP 3 as it is temp content between MVP 2 release and MVP 3 release
 * Function to fetch data sets page data sets definitions page content and process it
 * @returns {Object} Data sets page page content
 */
const fetchDataSetsDefinitionsPage = async () => {
  const fetchedContent = await getAContentItem(CONTENT_ITEM_TYPES.DATA_SETS_PAGE.CODENAME, undefined, true);
  if (fetchedContent.isError) {
    return fetchedContent;
  }

  let schemaDefintionContent;
  const elements = fetchedContent?.item?.elements;
  if (elements) {
    schemaDefintionContent = elements?.[CONTENT_ITEM_TYPES.DATA_SETS_PAGE.DEFINITIONS_SCHEMA]?.value;
    if (schemaDefintionContent) {
      schemaDefintionContent = processContentItem(
        [{ ...fetchedContent?.item, elements: { ...fetchedContent?.item?.elements, body: fetchedContent?.item?.elements?.definitions_and_schema } }],
        fetchedContent.linkedItems,
        {},
        false,
      );
    }
  }

  const res = {
    routeTitle: PATHS.DATA_SETS.CHILDREN.DATA_DEFINITIONS.TITLE,
    contentNode: schemaDefintionContent?.processedSectionNodes?.[0]?.node || null,
    routeBaseUrl: `/${PATHS.DATA_SETS.CHILDREN.DATA_DEFINITIONS.BASE}`,
    routes: [
      {
        chapterHasContent: false,
        routeTitle: PATHS.DATA_SETS.TITLE,
        path: `/${PATHS.DATA_SETS.BASE}`,
        children: [
          {
            routeTitle: PATHS.DATA_SETS.CHILDREN.DATA_SETS_VISUALISED.TITLE,
            path: `/${PATHS.DATA_SETS.BASE}/${PATHS.DATA_SETS.CHILDREN.DATA_SETS_VISUALISED.BASE}`,
          },
          {
            routeTitle: PATHS.DATA_SETS.CHILDREN.DATA_DEFINITIONS.TITLE,
            path: `/${PATHS.DATA_SETS.BASE}/${PATHS.DATA_SETS.CHILDREN.DATA_DEFINITIONS.BASE}`,
          },
        ],
      },
    ],
  };

  return res;
};

/**
 * Function to fetch financial ratios page data but defer so that react router loader will still run component even if data has not been fetched
 * @returns defer object passed to loader, which allows for accessing resolved data through <Await>
 */
export const financialRatiosPageLoader = async () => {
  return defer({
    financialRatios: fetchFinancialRatiosPage(),
  });
};

/**
 * TODO: This need to be updated in MVP 3 as it is temp content between MVP 2 release and MVP 3 release
 * Function to fetch financial ratios page financial ratios pageage content and process it
 * @returns {Object} financial ratios page page content
 */
const fetchFinancialRatiosPage = async () => {
  const financialRatios = [
    {
      entityName: 'Aboriginal Hostels Limited',
      entityCodename: '1',
      entityWebUrl: 'department-of-great-work',
      portfolioWebUrl: 'great-work',
      arWebUrl: 'department-of-great-work-ar-2022-23',
      reportTitle: 'Department of Great Work AR 2022-23',
      coverImage: '',
      year: '2021-22',
      ratios: [
        {
          title: 'Total liabilities to total assets',
          helpTextId: '1',
          values: {
            'Total liabilities': 13250000,
            'Total assets': 163900888,
            'Total liabilities to total assets': 13,
          },
        },
        {
          title: 'Financial asset to liabilities ratio',
          helpTextId: 2,
          values: {
            'Total liabilities': 38819000,
            'Total assets': 13205000,
            'Total liabilities to total assets': 294,
          },
        },
        {
          title: 'Capital turnover',
          helpTextId: 3,
          values: {
            'Depreciation and amortisation expense': 6709000,
            'Purchase of property, plant and equipment': 1950000,
            'Purchase of intangibles': 13392000,
            'Depreciation on right-of-use assets': 17430999,
          },
        },
        {
          title: 'Current ratio',
          helpTextId: 4,
          values: {
            'No more than 12 months assets': 'N/A',
            'No more than 12 months liabilities': 'N/A',
            'Current ratio': 'N/A',
          },
        },
      ],
    },
    {
      entityName: 'Department of Finance',
      entityCodename: '2',
      entityWebUrl: 'department-of-great-work',
      portfolioWebUrl: 'great-work',
      arWebUrl: 'department-of-great-work-ar-2022-23',
      reportTitle: 'Department of Great Work AR 2022-23',
      coverImage: '',
      year: '2021-22',
      ratios: [
        {
          title: 'Total liabilities to total assets',
          helpTextId: 1,
          values: {
            'Total liabilities': 1445564,
            'Total assets': 634992,
            'Total liabilities to total assets': 29,
          },
        },
        {
          title: 'Financial asset to liabilities ratio',
          helpTextId: 2,
          values: {
            'Total liabilities': 4532223,
            'Total assets': 324354,
            'Total liabilities to total assets': 300,
          },
        },
        {
          title: 'Capital turnover',
          helpTextId: 3,
          values: {
            'Depreciation and amortisation expense': 32234,
            'Purchase of property, plant and equipment': 324,
            'Purchase of intangibles': 4324,
            'Depreciation on right-of-use assets': 342234,
          },
        },
        {
          title: 'Current ratio',
          helpTextId: 4,
          values: {
            'No more than 12 months assets': 'N/A',
            'No more than 12 months liabilities': 'N/A',
            'Current ratio': 'N/A',
          },
        },
      ],
    },
  ];

  let content = null;
  const fetchedContent = await getAContentItem(CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CODENAME, undefined, true);
  if (fetchedContent.isError) {
    content = <></>;
  }

  const elements = fetchedContent?.item?.elements;

  let bannerContent = null;
  if (elements) {
    const aboutContent = elements?.[CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.ABOUT.CODENAME]?.value;
    const totalLiabilitiesContent = elements?.[CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.TOTAL_LIABILTIIES.CODENAME]?.value;
    const financialAssetsContent = elements?.[CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.FINANCIAL_ASSETS.CODENAME]?.value;
    const capitalTurnoverContent = elements?.[CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CAPITAL_TURNOVER.CODENAME]?.value;
    const currentRatioContent = elements?.[CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CURRENT_RATIO.CODENAME]?.value;

    bannerContent = parseRichTextHtml(elements?.[CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.BANNER_CONTENT.CODENAME]?.value);

    content = (
      <>
        <h2>{CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.ABOUT.DISPLAY}</h2>
        {aboutContent ? parseRichTextHtml(aboutContent) : <></>}

        <h2 id={CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.TOTAL_LIABILTIIES.CODENAME}>
          {CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.TOTAL_LIABILTIIES.DISPLAY}
          <HashLink
            className="noFill"
            smooth
            to={`#${CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.TOTAL_LIABILTIIES.TABLE}`}
            scroll={() => innerPageScroll(CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.TOTAL_LIABILTIIES.TABLE)}>
            ↩
          </HashLink>
        </h2>
        {totalLiabilitiesContent ? parseRichTextHtml(totalLiabilitiesContent) : <></>}

        <h2 id={CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.FINANCIAL_ASSETS.CODENAME}>
          {CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.FINANCIAL_ASSETS.DISPLAY}
          <HashLink
            className="noFill"
            smooth
            to={`#${CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.FINANCIAL_ASSETS.TABLE}`}
            scroll={() => innerPageScroll(CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.FINANCIAL_ASSETS.TABLE)}>
            ↩
          </HashLink>
        </h2>
        {financialAssetsContent ? parseRichTextHtml(financialAssetsContent) : <></>}

        <h2 id={CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CAPITAL_TURNOVER.CODENAME}>
          {CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CAPITAL_TURNOVER.DISPLAY}
          <HashLink
            className="noFill"
            smooth
            to={`#${CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CAPITAL_TURNOVER.TABLE}`}
            scroll={() => innerPageScroll(CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CAPITAL_TURNOVER.TABLE)}>
            ↩
          </HashLink>
        </h2>
        {capitalTurnoverContent ? parseRichTextHtml(capitalTurnoverContent) : <></>}

        <h2 id={CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CURRENT_RATIO.CODENAME}>
          {CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CURRENT_RATIO.DISPLAY}
          <HashLink
            className="noFill"
            smooth
            to={`#${CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CURRENT_RATIO.TABLE}`}
            scroll={() => innerPageScroll(CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CURRENT_RATIO.TABLE)}>
            ↩
          </HashLink>
        </h2>
        {currentRatioContent ? parseRichTextHtml(currentRatioContent) : <></>}
      </>
    );
  }

  return { bannerContent, content, financialRatios };
};



/**
 * Function to fetch Data Explorer filters on the page prerender
 * 
 */
export const dataExplorerLoader = async () => {

  const transformFilterData = (rawData) => {
    const newData = {};

    rawData.Value.forEach((filterCategory) => {
      const key = filterCategory.IndexField.replace(/\s+/g, '');

      const filters = filterCategory.FacetValues.map((facet) => ({
        display: facet.Value,
        returnValue: facet.Key,
        selected: false
      }));

      newData[key] = {
        displayName: filterCategory.DisplayName,
        filterData: filters,

      };
    });

    newData.ReportingYear.filterData = newData.ReportingYear.filterData.filter(item =>
      item.returnValue === "2023-24" || item.returnValue === "2022-23");

    return newData;
  };
  const transformFilterDataToValues = (rawData) => {
    const newData = {};
    const validDisplayNames = ["Employment Type", "Engagement", "Gender", "Location", "APS Band"];

    rawData.Value.forEach((filterCategory) => {
      const key = filterCategory.DisplayName;

      if (validDisplayNames.includes(key)) {
        const values = filterCategory.FacetValues.map((facet) => facet.Key);
        newData[key] = values;
      }
    });
    console.log(newData);
    return newData;
  };


  const rawFilterData = await getDataExplorerFilterOptions();

  return ({
    filters: transformFilterData(rawFilterData),
    tableCategories: transformFilterDataToValues(rawFilterData),
  });
};