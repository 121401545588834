import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'Components';
import style from './DataSetsLoading.module.scss';

/**
 * FilterLoading.jsx
 *
 * @summary This component shows the loading state page view for the Filter component loading.
 *
 */
function DataSetsLoading({ isVisualisation }) {
  return (
    <div className={style.dataSetLoadingFilters}>
      <Loading className={style.loadingFilterDropdown} />
      {!isVisualisation && (
        <>
          <Loading className={style.loadingFilterDropdown} />
          <Loading className={style.loadingFilterDropdown} />
        </>
      )}
    </div>
  );
}

DataSetsLoading.propTypes = {
  isVisualisation: PropTypes.bool,
};

export default DataSetsLoading;
