import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'Components/Tooltip/Tooltip';
import style from './LinkCopy.module.scss';
import sprite from 'Assets/svgSprite.svg';

/**
 * LinkCopy.jsx
 *
 * @summary Render as link copy button.
 *
 * @param {Object} props - Component props.
 * @prop {String} props.targetId - Target id, which will be captured as hash of full url to be copied.
 */
function LinkCopy({ targetId }) {
  const { origin, pathname } = document.location;
  const [urlCopied, updateUrlCopiedState] = useState(false);

  const copyUrlToClipboard = () => {
    // always take the full url (including origin)
    const urlToBeCopied = `${origin}${pathname}#${targetId}`;

    // copy url to clipboard
    navigator.clipboard.writeText(urlToBeCopied);
    updateUrlCopiedState(true);
  };

  return (
    <div className={style.LinkCopyWrapper} onMouseLeave={() => updateUrlCopiedState(false)} onBlur={() => updateUrlCopiedState(false)}>
      <Tooltip tooltipText={urlCopied ? 'Copied!' : 'Click to copy url'}>
        <button className={style.LinkCopy} onClick={copyUrlToClipboard}>
          Copy link
          <svg>
            <use href={`${sprite}#link`} />
          </svg>
        </button>
      </Tooltip>
    </div>
  );
}

LinkCopy.propTypes = {
  targetId: PropTypes.string.isRequired,
};

export default LinkCopy;
