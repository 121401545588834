import CONTENT_ITEM_TYPES from './ContentItemTypes';
import { URL_QUERY_KEYS } from './Constants';

export const TARGET_CODENAMES = {
  NCE: [
    'extract_of_statement_of_financial_position_____cop',
    'extract_of_statement_of_comprehensive_inco_____cop',
    'extract_of_cash_flow_statement___cce__copy_',
    'commonwealth_leases___departmental_leases______cop',
    'current_assets_and_liabilities___cce__copy_',
  ],
  CCE: [
    'extract_of_statement_of_financial_position___cce',
    'cce_finance_comp_income_statement_period',
    'extract_of_cash_flow_statement___cce',
    'commonwealth_leases___departmental_leases___cce',
    'current_assets_and_liabilities___cce',
  ],
  CC: [
    'extract_of_statement_of_financial_position___cc',
    'extract_of_statement_of_comprehensive_income___cc',
    'extract_of_cash_flow_statement___cc',
    'commonwealth_leases___departmental_leases___cc',
    'current_assets_and_liabilities___cc',
  ],
  UPDATED_NCE: [
    'extract_of_statement_of_financial_position_____cop',
    'extract_of_statement_of_comprehensive_inco_____cop',
    'extract_of_cash_flow_statement___nce_23_24_',
    'commonwealth_leases___depart__leases_nce_23_24',
    'dept__current_distinction___assets_and_liab__nce_23_24'
  ],
  UPDATED_CCE: [
    'extract_of_statement_of_financial_position___cce',
    'cce_finance_comp_income_statement_period',
    'extract_of_dept_cash_flow_statement_cce_23_24',
    'commonwealth_leases___depart__leases_cce_23_24',
    'dept__current_distinction___assets_and_liab__cce_23_24'
  ],
  UPDATED_CC: [
    'extract_of_statement_of_financial_position___cc',
    'extract_of_statement_of_comprehensive_income___cc',
    'extract_of_cash_flow_statement___cc_23_24',
    'commonwealth_leases___depart__leases_cc_23_24',
    'current_distinction___assets_and_liab__cc___23_24'
  ]
};

export const FINANCIAL_RATIOS_CONTENT_CODENAMES = [
  ...TARGET_CODENAMES.NCE, 
  ...TARGET_CODENAMES.CCE, 
  ...TARGET_CODENAMES.CC, 
  ...TARGET_CODENAMES.UPDATED_NCE, 
  ...TARGET_CODENAMES.UPDATED_CCE, 
  ...TARGET_CODENAMES.UPDATED_CC
];

export const FINANCIAL_RATIO_TABLE_ROW_HEADING = {
  TOTAL_LIABILTIIES: 'Total liabilities',
  TOTAL_ASSETS: 'Total assets',
  TOTAL_LIAB_TO_TOTAL_ASSETS: 'Total liabilities to total assets',
  DEP_AND_AMORT_EXP: 'Depreciation and amortisation expense',
  PURCHASE_PROPERTY: 'Purchase of property, plant and equipment',
  PURCHASE_INTANGIBLES: 'Purchase of intangibles',
  DEP_ON_ROU_ASSETS: 'Depreciation on right-of-use assets',
  NO_MORE_12M_ASSETS: 'No more than 12 months assets',
  NO_MORE_12M_LIAB: 'No more than 12 months liabilities',
  CURRENT_RATIO: 'Current ratio',
  CAPITAL_TURNOVER: 'Capital turnover',
  FIN_ASSETS: 'Financial assets',
  FIN_ASSETS_TO_TOTAL_LIAB: 'Financial asset to liabilities',
};

export const FINANCIAL_RATIOS_TABLE_DEFAULT = [
  {
    title: CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.TOTAL_LIABILTIIES.HEADING,
    id: CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.TOTAL_LIABILTIIES.CODENAME,
    values: {
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.TOTAL_LIABILTIIES]: '',
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.TOTAL_ASSETS]: '',
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.TOTAL_LIAB_TO_TOTAL_ASSETS]: '',
    },
  },
  {
    title: CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.FINANCIAL_ASSETS.HEADING,
    id: CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.FINANCIAL_ASSETS.CODENAME,
    values: {
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.FIN_ASSETS]: '',
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.TOTAL_LIABILTIIES]: '',
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.FIN_ASSETS_TO_TOTAL_LIAB]: '',
    },
  },
  {
    title: CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CAPITAL_TURNOVER.HEADING,
    id: CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CAPITAL_TURNOVER.CODENAME,
    values: {
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.DEP_AND_AMORT_EXP]: '',
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.PURCHASE_PROPERTY]: '',
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.PURCHASE_INTANGIBLES]: '',
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.DEP_ON_ROU_ASSETS]: '',
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.CAPITAL_TURNOVER]: '',
    },
  },
  {
    title: CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CURRENT_RATIO.HEADING,
    id: CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CURRENT_RATIO.CODENAME,
    values: {
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.NO_MORE_12M_ASSETS]: '',
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.NO_MORE_12M_LIAB]: '',
      [FINANCIAL_RATIO_TABLE_ROW_HEADING.CURRENT_RATIO]: '',
    },
  },
];

export const FINANCIAL_RATIOS_DATA_KEY = {
  TOTAL_LIAB_TO_TOTAL_ASSETS: {
    TOTAL_LIABILTIIES: {
      SUM: ['Liabilities - Total Liabilities|Current Year'.toLowerCase()],
      SUB: [],
    },
    TOTAL_ASSETS: {
      SUM: ['Assets - Total Assets|Current Year'.toLowerCase()],
      SUB: [],
    },
  },
  FIN_ASSET_TO_LIAB: {
    TOTAL_LIABILTIIES: {
      SUM: ['Liabilities - Total Liabilities|Current Year'.toLowerCase()],
      SUB: [],
    },
    TOTAL_ASSETS: {
      SUM: ['Assets - Total Financial Assets|Current Year'.toLowerCase()],
      SUB: [],
    },
  },
  CAPITAL_TURNOVER: {
    DEP_AND_AMORT_EXP: {
      SUM: ['Expenses - Depre. and Amort. Expense|Current Year'.toLowerCase()],
      SUB: [],
    },
    PURCHASE_PROPERTY: {
      SUM: ['Purchase of Property, Plant & Equip.|Current Year'.toLowerCase()],
      SUB: [],
    },
    PURCHASE_INTANGIBLES: {
      SUM: ['Purchase of Intangibles|Current Year'.toLowerCase()],
      SUB: [],
    },
    DEP_ON_ROU_ASSETS: {
      SUM: ['Depreciation on right-of-use assets|Current Year'.toLowerCase()],
      SUB: [],
    },
  },
  CURRENT_RATIO: {
    NO_MORE_12M_ASSETS: {
      SUM: ['Assets - No more than 12 months|Current Year'.toLowerCase()],
      SUB: [],
    },
    NO_MORE_12M_LIAB: {
      SUM: ['Liabilities - No more than 12 months|Current Year'.toLowerCase()],
      SUB: [],
    },
  },
};

export const EXPORT_DROPDOWN_OPTIONS = {
  ALL_ENTITIES: {
    display: 'All entities and companies',
    returnValue: 'allEntities',
  },
  CURRENT_SELECTION: {
    display: 'Current selection',
    returnValue: 'currentSelection',
  },
  ALL_YEARS: {
    display: 'All',
    returnValue: 'allReportingYear',
    selected: false,
  },
};

export const FILTER_PARAMS = {
  ENTITY: `${URL_QUERY_KEYS.FILTER_DENOTE}entity`,
  REPORTING_YEAR: `${URL_QUERY_KEYS.FILTER_DENOTE}reportingYear`,
};
