import React, { useEffect } from 'react';
import icons from 'Assets/svgSprite.svg';
import style from './Modal.module.scss';
import PropTypes from 'prop-types';
import { CONSTANTS } from 'Constants';

/**
 * Modal.jsx
 *
 * @summary Component for showing pop-up modal.
 *
 * @param {Object} props - Component props.
 * @prop {Node} props.children - Children nodes of modal content.
 * @prop {Function} props.closeModal - Function to trigger closing modal.
 * @prop {Boolean} [props.hasCloseModalButton] - Allow modal to have X close button on the modal.
 * @prop {Object} [props.backdropStyleOverride] - Key value pairs for overriding backdrop style (keys should be css variables in camelcase).
 * @prop {Object} [props.modalStyleOverride] - Key value pairs for overriding modal style (keys should be css variables in camelcase).
 */
function Modal({ children, closeModal, hasCloseModalButton, backdropStyleOverride, modalStyleOverride }) {
  const handleCloseModal = () => {
    closeModal();
  };

  useEffect(() => {
    const close = (e) => {
      // listen for escape key pressed
      if (e.key === CONSTANTS.ESCAPE_KEY) {
        handleCloseModal();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  });

  return (
    <>
      <div id={style['modal-view']}>
        <div key="background" className={style.backdrop} style={backdropStyleOverride} onClick={handleCloseModal}></div>
        <div className={style.modal} style={modalStyleOverride} aria-hidden="false" role="dialog">
          <div className={style['modal-content']} aria-label="pop up modal">
            {hasCloseModalButton && (
              <div className={style.header}>
                <button onClick={handleCloseModal} aria-label="close" tabIndex="0" value="Close">
                  <svg>
                    <use href={icons + '#close'} />
                  </svg>
                </button>
              </div>
            )}
            <div className={style.content}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
  hasCloseModalButton: PropTypes.bool,
  backdropStyleOverride: PropTypes.objectOf(PropTypes.string),
  modalStyleOverride: PropTypes.objectOf(PropTypes.string),
};

export default Modal;
