import React, { useEffect, useId, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MESSAGE_BOX_TYPE } from 'Constants';
import style from './MessageBox.module.scss';
import icons from 'Assets/svgSprite.svg';

/**
 * MessageBox.jsx
 *
 * @summary Render as error message box. If `title` prop is given, this must be placed top of the content area and AFTER <h1>.
 *
 * @param {Object} props - Component props.
 * @prop {Node} props.children - Message Content.
 * @prop {String} [props.title] - Message title. If not given, message box would not have title.
 * @prop {String} [props.type] - Message type. By default 'error'. Enums defined by MESSAGE_BOX_TYPE under `/Constants/Constants.js`
 */
function MessageBox({ title, children, type = MESSAGE_BOX_TYPE.ERROR, iconOverride }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const contentRef = useRef(null); 

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const checkContentHeight = () => {
      const contentElement = contentRef.current;
      if (contentElement) {
        setShowButton(contentElement.scrollHeight > 120);
      }
    };

    checkContentHeight();

    window.addEventListener('resize', checkContentHeight);
    return () => window.removeEventListener('resize', checkContentHeight);
  }, [children]); 


  const titleId = useId();
  const contentId = useId();
  const boxClasses = [style.messageBox];
  const contentClasses = [style.content];

  let iconId = 'error';
  switch (type) {
    case MESSAGE_BOX_TYPE.CONFIRMED:
      boxClasses.push(style.confirmed);
      iconId = 'check';
      break;

    case MESSAGE_BOX_TYPE.INFORMATION:
      boxClasses.push(style.information);
      iconId = 'information';
      break;

    case MESSAGE_BOX_TYPE.HELP:
      boxClasses.push(style.help);
      iconId = 'question';
      break;

    case MESSAGE_BOX_TYPE.WARNING:
      boxClasses.push(style.warning);
      iconId = 'warning';
      break;

    case MESSAGE_BOX_TYPE.ERROR:
    default:
      break;
  }

  if (!title) contentClasses.push(style.noTitle);

  const icon = (
    <svg className={style.icon}>
      <use href={`${icons}#${iconOverride || iconId}`} />
    </svg>
  );

  return (
    <div className={boxClasses.join(' ')} role="dialog" aria-labelledby={title ? titleId : null} aria-describedby={contentId}>
      {title && (
        <div className={style.head}>
          {title === "About this data" &&
            <>
              <h2 id={titleId} className={[style.title, 'h6'].join(' ')}>
                {title}
              </h2>
              <p>
                The following entities or companies have declared no data to report for the reporting periods selected: 
              </p>
            </>
          }
          {title === "No data available for the selected Data Set" && 
            <>
              {icon}
              <h2 id={titleId} className={[style.title, 'h6'].join(' ')}>
                {title}
              </h2>
            </>
          }

        </div>
      )}

      <div
        id={contentId}
        ref={contentRef} // Attach the ref to the content div
        className={contentClasses.join(' ')}
        style={{
          maxHeight: isExpanded ? 'none' : '100px',
          overflow: 'hidden',
        }}
      >
        {!title && icon}
        <div>{children}</div>
      </div>
      {showButton && ( // Conditionally render the button based on showButton state
        <div className={style.buttonRow} >

          <button onClick={toggleExpansion}>
            {isExpanded ? 'Minimise' : 'Expand'}

            <svg xmlns="http://www.w3.org/2000/svg" fill="none">
              <path d="M12.0016 15.4498L7.08012 10.5284C6.76513 10.2134 6.98822 9.6748 7.43367 9.6748H16.5695C17.0149 9.6748 17.238 10.2134 16.923 10.5284L12.0016 15.4498Z" fill="white"/>
            </svg>
            </button>
        </div>
      )}


    </div>
  );

/*
  return (
    <div className="messageBox">
      <div className="titleRow">
        <h2 className="titleRow-title" id="titleId">
            {icon}
            {title}
        </h2>
      </div>

      <div className="contentRow">


      }
      </div>

      <div className="expandRow">
        <button>Expand</button>      
      </div>
    </div>
  );
  */
}

MessageBox.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.values(MESSAGE_BOX_TYPE)),
  iconOverride: PropTypes.string,
};

export default MessageBox;
