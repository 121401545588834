import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'Components';
import { TAG_STYLE_ENUMS } from 'Constants';
import LightTree from 'Assets/Tree-Half-Light.svg';
import DarkTree from 'Assets/Tree-Half-Dark.svg';
import style from './PageHead.module.scss';

/**
 * PageHead.jsx
 *
 * @summary Render as Page head.
 *
 * @param {Object} props - Component props.
 * @prop {String} props.pageTitle - Page title. Used as h1 and tab title.
 * @prop {String} [props.pageCategory] - Page category appears on top of <h1>.
 * @prop {{ displayText: String, [fullText]: String }} [props.tagData] - Tag appears on top of <h1>. If pageCategory is given, tag will always appears after it with primary colour style (dark). Only one tag can be applied.
 * @prop {String} [props.children] - Any additional UI or context below <h1>. Style and structure of it would be from parent component.
 * @prop {Boolean} [props.darkTree] - If true or given, tree background image would be coloured and if false, tree background.
 * @prop {Boolean} [props.keepBackgroundImage] - If true or given, tree background image always displays, else, on mobile view, image is hidden image would be light mono coloured.
 */
function PageHead({ pageTitle, pageCategory, tagData, children, darkTree, keepBackgroundImage, givenImageURL }) {
  const backgroundImage = {
    '--BACKGROUND-IMAGE': `url(${darkTree ? DarkTree : LightTree})`,
  };

  // update title
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const extraInfo = () => {
    if (pageCategory || tagData) {
      return (
        <div id={style.pageHeadExtraInfo}>
          {pageCategory && <strong id={style.pageCategory}>{pageCategory}</strong>}
          {tagData && <Tag displayText={tagData.displayText} fullText={tagData?.fullText} type={TAG_STYLE_ENUMS.DARK} />}
        </div>
      );
    }

    return null;
  };

  const containerClass = ['fullColorBackground'];
  if (keepBackgroundImage) containerClass.push(style.mobileBackgroundImage);

  const defaultContent = (
    <div id={style.pageHead} className={containerClass.join(' ') + " " + style.defaultBackground} style={backgroundImage}>
      {extraInfo()}
      <h1>{pageTitle}</h1>
      {children && <div id={style.childrenWrapper}>{children}</div>}
    </div>);

const entityPageContent = (
  <div id={style.outerEntityDiv} className={containerClass.join(' ') + " " + style.outerEntityDiv} style={backgroundImage}>
    <div id={style.pageHead} className={containerClass.join(' ')} style={backgroundImage}>
      {extraInfo()}
      <h1>{pageTitle}</h1>
      {children && <div id={style.childrenWrapper}>{children}</div>}
    </div>
    <div className={style.entityCoverImgDiv}>
      <img className={style.entityCoverImg} src={givenImageURL} alt="" role="presentation" />
    </div>
  </div>);

  return (
    givenImageURL ?
    entityPageContent : defaultContent
  );
}

PageHead.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageCategory: PropTypes.string,
  tagData: PropTypes.shape({
    displayText: PropTypes.string.isRequired,
    fullText: PropTypes.string,
  }),
  children: PropTypes.node,
  darkTree: PropTypes.bool,
  keepBackgroundImage: PropTypes.bool,
  givenImageURL: PropTypes.string
};

export default PageHead;
