import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AusLogo from 'Assets/AusGovLogo.svg';
import AusLogoWhite from 'Assets/AusGovLogo_White.svg';
import TPLogo from 'Assets/TPLogo.svg';
import TPLogoWhite from 'Assets/TPLogo_White.svg';
import DARTLogo from 'Assets/DARTLogo.svg';
import DARTLogoWhite from 'Assets/DARTLogo_White.svg';
import { PATHS } from 'Constants';
import style from './LogosHeaderFooter.module.scss';

/**
 * LogosHeaderFooter.jsx
 *
 * @summary Australian government and transparency portal logos.
 *
 * @param {Object} props - Component props.
 * @prop {Bool} [props.isWhite] - If given or value is true, logos will be white.
 * @prop {Bool} [props.isDART] - If given or value is true, DART Logo will be displayed instead TP logo. it also make logos non link.
 */
function LogosHeaderFooter({ isWhite, isDART }) {
  const ausLogo = isWhite ? AusLogoWhite : AusLogo;
  const tpLogo = isWhite ? TPLogoWhite : TPLogo;
  const dartLogo = isWhite ? DARTLogoWhite : DARTLogo;

  const nodes = (
    <>
      <img className={[style.logo, style.ausGov].join(' ')} src={ausLogo} alt="Australian Government" />
      <img className={[style.logo, style.transparency].join(' ')} src={isDART ? dartLogo : tpLogo} alt="Transparency Portal" />
    </>
  );

  if (isDART) {
    return <div className={style.logoWrapper}>{nodes}</div>;
  }

  return (
    <Link className={style.logoWrapper} to={PATHS.HOME.BASE}>
      {nodes}
    </Link>
  );
}

LogosHeaderFooter.propTypes = {
  isWhite: PropTypes.bool,
  isDART: PropTypes.bool,
};

export default LogosHeaderFooter;
