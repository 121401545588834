import React, { Children, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './Carousel.module.scss';
import icons from 'Assets/svgSprite.svg';
import { debounce } from 'Utils';

/**
 * Carousel.jsx
 *
 * @summary Component for carousel functionality
 *
 * @param {Object} props - Component props.
 * @prop {Node} props.children - Children nodes to be added in carousel
 * @prop {Object} props.maxPages - Maximum number of pages in the carousel (used to display page indicators)
 * @prop {Number} [props.currentPage] - Current page carousel is on (will default to 1 if not given)
 * @prop {Boolean} [props.fullWidth] - Boolean value which sets the width of each carousel item (true will be full width, otherwise, will be half width)
 * @prop {Funciton} [props.onPageChange] - Run a function from parent when carousel page changes
 *
 */
function Carousel({ children, maxPages, currentPage, fullWidth, onPageChange, className }) {
  const [page, setPage] = useState(currentPage || 1);
  const carouselRef = useRef();
  const [carouselDimensions, setCarouselDimensions] = useState({ height: 0, width: 0 });
  const [carouselPageActive, setCarouselPageActive] = useState(updateCarouselActiveState());

  useEffect(() => {
    if (carouselRef.current) {
      const { width, height } = carouselRef.current.getBoundingClientRect();
      setCarouselDimensions({ width, height });
    }
  }, []);

  useEffect(() => {
    // recalculate window, element and tooltip dimensions when the screen size is changed or window scrolled
    const detectViewPortChange = debounce(() => {
      if (carouselRef.current) {
        const { width, height } = carouselRef.current.getBoundingClientRect();
        setCarouselDimensions({ width, height });
      }
    });

    // rerun dimensions each time a resize in window
    window.addEventListener('resize', detectViewPortChange);

    return () => {
      window.removeEventListener('resize', detectViewPortChange);
    };
  });

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  /**
   * This useEffect controls the displaying of carousel when it slides in and out of view
   * - Updates the new pages while showing old pages before
   */
  useEffect(() => {
    setTimeout(() => {
      // after timeout reset carousel back to only show current pages
      // this will ensure that the animations still runs for the panel that is disappearing from view
      setCarouselPageActive(updateCarouselActiveState());
    }, 400);
    setCarouselPageActive(
      carouselPageActive.map((value, index) => {
        // Set current and next page active straight away
        if (index + 1 === page || index + 1 === page + 1) {
          return true;
        }
        return value;
      }),
    );
  }, [page]);

  function updateCarouselActiveState() {
    // shows active state for next two pages (in order to cater for slide animation)
    const activeState = new Array(maxPages + 2).fill(false).map((value, index) => {
      if (showCarouselItems(index)) {
        return true;
      }
      return value;
    });
    return activeState;
  }

  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      if (onPageChange) {
        onPageChange(page - 1);
      }
    }
  };

  const nextPage = () => {
    if (page < maxPages) {
      setPage(page + 1);
      if (onPageChange) {
        onPageChange(page + 1);
      }
    }
  };

  const setNewPage = (index) => {
    const page = index ? index + 1 : 1;
    if (page >= 1 && page <= maxPages) {
      setPage(page);
      if (onPageChange) {
        onPageChange(page);
      }
    }
  };

  function showCarouselItems(index) {
    // On mobile view (which is full width), only render current page
    if (fullWidth) {
      return index + 1 === page;
    }

    // On desktop/tablet view, render current page, the page before and the page after
    if ([page, page + 1].includes(index + 1)) {
      return true;
    }
  }

  return (
    <div className={[style.carouselContainer, className].join(' ')} ref={carouselRef}>
      <div
        className={style.carousel}
        style={{
          '--CAROUSEL-PAGE': page,
          '--CAROUSEL-WIDTH': `${fullWidth ? carouselDimensions.width : carouselDimensions.width / 2}px`,
          '--CAROUSEL-HEIGHT': `${carouselDimensions.height}px`,
        }}>
        {Children.toArray(children)
          .filter(Boolean)
          .map((child, index) => {
            return (
              <div
                key={`carousel-child-${index}`}
                className={style.carouselItem}
                style={{ '--CAROUSEL-WIDTH': `${fullWidth ? carouselDimensions.width : carouselDimensions.width / 2}px` }}>
                {carouselPageActive[index] && child}
              </div>
            );
          })}
      </div>
      <div className={style.carouselControls}>
        <button disabled={page <= 1} className={['lightFill', style.previousButton].join(' ')} onClick={previousPage}>
          Previous
          <svg>
            <use href={`${icons}#arrow`} />
          </svg>
        </button>
        <div className={style.indicators}>
          {[...Array(maxPages)].map((_, index) => {
            return (
              <button
                onClick={() => setNewPage(index)}
                key={`indicator-page-${index + 1}`}
                className={['noFill', style.circle, index + 1 === page ? style.highlighted : null].join(' ')}>
                Page {index + 1}
              </button>
            );
          })}
        </div>
        <button disabled={page >= maxPages} className={['lightFill', style.nextButton].join(' ')} onClick={nextPage}>
          Next
          <svg>
            <use href={`${icons}#arrow`} />
          </svg>
        </button>
      </div>
    </div>
  );
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  maxPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  fullWidth: PropTypes.bool,
  onPageChange: PropTypes.func,
  className: PropTypes.string,
};

export default Carousel;
