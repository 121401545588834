import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'Components';
import style from './AnnualReportTopPartLoading.module.scss';

/**
 * AnnualReportTopPartLoading.jsx
 *
 * @summary This component shows the loading state page view for both annual report cover pages.
 */
function AnnualReportTopPartLoading({ isARPage = false }) {
  return (
    <>
      <Loading className={style.loadingBreadcrumb} />

      <Loading className={[style.loadingBanner, isARPage ? style.isAR : ''].join(' ')} />
    </>
  );
}

AnnualReportTopPartLoading.propTypes = {
  isARPage: PropTypes.bool,
};

export default AnnualReportTopPartLoading;
