import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './TabView.module.scss';
import { Link } from 'react-router-dom';
import icons from 'Assets/svgSprite.svg';

/**
 * TabView.jsx
 *
 * @summary Component for viewing tabs functionality.
 *
 * @param {Object} props - Component props.
 * @prop {{title: String, subText: String[], tabContents: Node}[], isExpandable: Boolean} props.tabInformation - List of information for each tab. Each object represents its own tab options.
 *
 * @description Each object in the list of 'tabInformation' has propeties 'title', 'subText' (any text for the tab), 'isExpandable' (allows for tab to expand and show contents rather than a link) and 'tabContents' (contents for tab when opened).
 *
 * TODO:
 * - Update with correct format of information for tabs
 * - Update tab behaviour once design is approved
 * - Allow for clickable links in each tab
 * - Re-assess accessibility (especiallly for tab content open)
 * - Allow for adding tab content (currently is a node passed through props but can be changed if contents will always be consistent)
 * - UI for mobile and tablet view
 */
function TabView({ tabInformation = [] }) {
  const numberOfItemsPerRow = 3;
  const [selectedTab, setSelectedTab] = useState(null);

  const expandTab = (tabIndex, isExpandable) => {
    if (isExpandable) {
      if (selectedTab === tabIndex) {
        setSelectedTab(null);
      } else {
        setSelectedTab(tabIndex);
      }
    }
  };

  // Finds which row to add expanded content (depending on which tab is expanded)
  const tabContentRow = (tabIndex) => {
    return Math.floor(tabIndex / numberOfItemsPerRow) + 2;
  };

  return (
    <div className={style.tabViewContainer}>
      {tabInformation.map((tabObject, i) => {
        const isActive = selectedTab === i ? style.isActive : '';
        const tabObjectSubText = tabObject.subText && Array.isArray(tabObject.subText) ? tabObject.subText : [];

        return (
          <React.Fragment key={`tab-${tabObject.title}-${i}`}>
            <div className={[style.tab, isActive].join(' ')}>
              <div className={style.tabInfo}>
                {/* Tab title and text */}
                <div>
                  <Link className={style.title}>{tabObject.title}</Link>
                  {tabObjectSubText.map((subText, j) => {
                    return <span key={`${tabObject.title}-${subText}-${j}`}>{subText}</span>;
                  })}
                </div>
                <div className={style.tabFooter}>
                  {/* Tab footer/expand */}
                  {tabObject.isExpandable && <Link>View entities and companies</Link>}
                  {/* TODO: Update link (depending on its behaviour - link or expand) */}
                  <button className={tabObject.isExpandable ? style.triangle : ''} aria-expanded={selectedTab === i} onClick={() => expandTab(i, tabObject.isExpandable)}>
                    {!tabObject.isExpandable && (
                      <svg>
                        <use href={icons + '#arrow'} />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>
            {/* Expanded tab contents */}
            {selectedTab === i && <div className={style.expandedContents} style={{ '--row-start': tabContentRow(selectedTab) }}></div>}
          </React.Fragment>
        );
      })}
    </div>
  );
}

TabView.propTypes = {
  tabInformation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subText: PropTypes.arrayOf(PropTypes.string),
      tabContents: PropTypes.node,
      isAccordion: PropTypes.bool,
    }),
  ).isRequired,
};

export default TabView;
