import React from 'react';
import { Loading } from 'Components';
import style from './PortfolioEntitiesCompaniesLoading.module.scss';

/**
 * PortfolioEntitiesCompaniesLoading.jsx
 *
 * @summary This component shows the loading state page view for both annual report cover and annual report article pages.
 * TODO: Add extra loading when PEC page gets updated
 */
function PortfolioEntitiesCompaniesLoading() {
  return (
    <div className={style.pecLoading}>
      {Array(5)
        .fill('pec-loading-tile')
        .map((value, index) => {
          return <Loading key={`${value}-${index}`} className={style.loadingPortfolioCards} />;
        })}
    </div>
  );
}

export default PortfolioEntitiesCompaniesLoading;
