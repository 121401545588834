/**
 * Function to get the neighbouring (previous and next) array values from current array value in a given array
 * @param {Number} currentArrayIndex Index of current array value
 * @param {Array} array Array to find previous and next array value
 * @returns {Object} Object with properties 'next' and 'prev' array values. Properties will contain an array element or null.
 */
export const getNeighbouringArrayElements = (array, currentArrayIndex) => {
  const arrayElements = {
    prev: null,
    next: null,
  };

  if (array.length > 1) {
    if (currentArrayIndex - 1 >= 0 && currentArrayIndex - 1 < array.length) {
      arrayElements.prev = array[currentArrayIndex - 1];
    }
    if (currentArrayIndex + 1 < array.length && currentArrayIndex + 1 >= 0) {
      arrayElements.next = array[currentArrayIndex + 1];
    }
  }

  return arrayElements;
};

/**
 * This util function will take in an array of objects that contain the same property name and sort in ascending order based on that property.
 * If the object does not contain the property name, these objects will not be sorted and will be appended to end of the list.
 *
 * @param {array} array - array to be sorted
 * @param {string} propertyName - object property name to sort by
 * @param {string} secondaryProperty - secondary object property name to sort by (in cases where first property is the same)
 * @param {Boolean} secondarySortDescend - sort secondary in descending order
 * @return returns one of the arrays below:
 *         - sorted array in ascending order with any unsorted objects at the end of the list
 *         - the original array if the specified property cannot be found for any object
 */
export const sortByObjectProperty = (array, propertyName, secondaryProperty, secondarySortDescend) => {
  const newArray = JSON.parse(JSON.stringify(array));
  const containsPropertyArray = [];
  const noPropertyArray = [];
  for (let i = 0; i < newArray.length; i++) {
    if (typeof newArray[i] === 'object' && !Array.isArray(newArray[i]) && newArray[i] !== null && propertyName in newArray[i]) {
      containsPropertyArray.push(newArray[i]);
    } else {
      noPropertyArray.push(newArray[i]);
    }
  }

  const sortedArray = containsPropertyArray.sort(function (a, b) {
    if (secondaryProperty) {
      if (a[propertyName] === b[propertyName]) {
        if (secondarySortDescend) {
          return a[secondaryProperty] < b[secondaryProperty] ? 1 : -1;
        }
        return a[secondaryProperty] > b[secondaryProperty] ? 1 : -1;
      }
    }
    return a[propertyName] > b[propertyName] ? 1 : -1;
  });

  return sortedArray.concat(noPropertyArray);
};

/**
 * This util function will check if two arrays of objects are equal for a specific property name given
 * @param {{propertyName: Any}[]} currentArray - Array of objects to check comparison
 * @param {{propertyName: Any}[]} arrayToCompare - Array of objects to compare with
 * @param {String} propertyName - Property name to compare with within each object in array
 * @returns {Boolean} - True if arrays are equal, false if they are not
 */
export const arraysAreEqualByProperty = (currentArray, arrayToCompare, propertyName) => {
  if (currentArray && Array.isArray(currentArray) && arrayToCompare && Array.isArray(arrayToCompare)) {
    const isEqual = currentArray.every((valueFromCurrentArray) => {
      if (typeof valueFromCurrentArray === 'object' && !Array.isArray(valueFromCurrentArray) && valueFromCurrentArray !== null) {
        return arrayToCompare.some((valueFromCompare) => {
          if (typeof valueFromCompare === 'object' && !Array.isArray(valueFromCompare) && valueFromCompare !== null) {
            return valueFromCompare[propertyName] === valueFromCurrentArray[propertyName];
          }
          return false;
        });
      }
      return false;
    });

    return isEqual && currentArray.length === arrayToCompare.length;
  }
  return false;
};

export const convertObjectToArray = (objectValue) => {
  let convertedArray = [];
  Object.values(objectValue).forEach((value) => {
    if (value && Array.isArray(value)) {
      convertedArray = convertedArray.concat(value);
    }
  });

  return convertedArray;
};
