import React from 'react';
import { LoadingMenu } from 'Components';
import style from './SearchPageLoading.module.scss';

/**
 * SearchPageLoading.jsx
 *
 * @summary This component shows the loading state page view for the SearchPage page.
 *
 */
function SearchPageLoading() {
  return (
    <>
      <div id={style.searchPageLoading}>
        <LoadingMenu numberOfItems={10} />
      </div>
    </>
  );
}

export default SearchPageLoading;
