import React, { useEffect, Suspense, useState } from 'react';
import { hotjar } from 'react-hotjar';
import PropTypes from 'prop-types';
import { Await, Link, useLocation, useRouteLoaderData } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { History, LoadingMenu, PageHead, Tag } from 'Components';
import { ERROR_PAGE, PAGE_NOT_FOUND_PAGE, PATHS, PUBLICATION_TYPE, CONSTANTS } from 'Constants';
import { annualReportUrl, innerPageScroll, portfolioProfileUrl, detectViewMode, debounce, getUrlObject } from 'Utils';
import icon from 'Assets/svgSprite.svg';
import style from './ProfilePage.module.scss';
import { STATUS_TYPES } from 'Utils/Error';
import { ERROR_CONTENT } from 'Constants/StaticContents';
import DefaultCoverImage from 'Pages/AnnualReportCover/DefaultCoverImage';
import DefaultEntityLogo from 'Assets/AusGovCrest-DefaultEntityLogo.svg';
import { ProfilePageLoading } from 'Pages';

/**
 * ProfilePage.jsx
 *
 * @summary This component is page view for Portfolio and entity profile pages.
 *
 * @param {Object} props - Component props.
 * @prop {String} props.loaderId - Loader data id from react router.
 * @prop {Boolean} [props.isEntity] - If given or true, consider profile page is for entity profile.
 *
 * TODO: when add display page not found when profile can't be found due to route doesn't exist (can only be done after PEC page is complete)
 */
function ProfilePage({ loaderId, isEntity }) {
  const { hash } = useLocation();
  const loaderData = useRouteLoaderData(loaderId);
  const profile = loaderData[loaderId];
  const [profileCoverImgLoad, setProfileCoverImgLoad] = useState({}); // used to track loading state of cover page images loading
  const [viewMode, setViewMode] = useState(detectViewMode());
  // const [historyPage, updateHistoryPage] = useState(1);
  // const [showFullTrail, updateShowFullTrail] = useState(false);

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  /**
   * if page component is for entity profile, use some of data from parent's loader data (portfolio profile) as it would
   * already contains data to render entity profile page and it always triggered first
   */
  const portfolioData = useRouteLoaderData(PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.PORTFOLIO.ID);

  /**
   * Add loading image state (only profiles with cover images will be in loading state)
   * @param {{coverImageSrc: String}[]} sections - List of section data
   * @returns {Array} An array of boolean values set to true for any profile with images (to display loading animation)
   */
  const imageLoadingState = (sections) => {
    // add all image data into an object using sections and return object
    const loadingStatesObj = {};

    // iterate through the sections and add each item with 'codename: true/false'
    sections.map((section) => {
      section.items?.map((item) => {
        if (item.imageUrl) {
          loadingStatesObj[item.codename] = true;
        }
      });
    });

    return loadingStatesObj;
  };

  /**
   * Set loading of image back to false when it finishes loading (in order to stop showing animation)
   * @param {String} codename - Codename of profile list item
   */
  const onImageLoad = (codename) => {
    setProfileCoverImgLoad((prevState) => {
      const updated = { ...prevState };
      updated[codename] = false;
      return updated;
    });
  };

  function getEndingYear(text) {
    // Use regex to find the 'YYYY-YY' pattern 
    const match = text.match(/\d{4}-(\d{2})/);
    if (match) {
      const startYear = match[0].split('-')[0];
      const endYearSuffix = match[1];
      return `${startYear.slice(0, 2)}${endYearSuffix}`;
      // Combine to form the full end year 
    }
    return null;
  }

  function getAbolishEndingYear(text) {
    if (text) {
      //checking the tag contains Abolished
      const matchAbolish = text.match(/^[aA]bolished./);
      if (matchAbolish) {
        const matchSingleYear = text.match(/\d{4}$/);
        if (matchSingleYear) {
          return matchSingleYear;
        }
        else {
          return getEndingYear(text);
        }
      }
    }

    return null;
  }

  /**
   * Set loading to false and set item URL to undefined if it fails to load
   * @param {Number} item - Item of profile list
   */
  const onImageError = (item) => {
    onImageLoad(item.codename);
    item.imageUrl = undefined;
  };

  return (
    // use Suspense to show loading page before profile page finishes loading
    // TODO: need to pass loading state on fallback
    <Suspense fallback={<ProfilePageLoading />}>
      <Await resolve={Promise.all([profile, portfolioData[PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.PORTFOLIO.ID]])} errorElement={ERROR_PAGE()}>
        {(resolvedData) => {
          if (resolvedData?.[0]?.isError || resolvedData?.[1]?.isError) {
            if (resolvedData[0].statusCode === STATUS_TYPES.NOT_FOUND) return PAGE_NOT_FOUND_PAGE(ERROR_CONTENT.NOT_FOUND);
            if (resolvedData[1].statusCode === STATUS_TYPES.NOT_FOUND) return PAGE_NOT_FOUND_PAGE(ERROR_CONTENT.NOT_FOUND);
            return ERROR_PAGE();
          }
          const { historyData, pageCategory, previousItems, sections, routeTitle, websiteLink, previousItemsParentURLs, entityImageURL, pageTag } = resolvedData[0];
          let abolishedYear;
          const portfolioData = resolvedData[1];
          const sectionIds = sections?.map((section) => {
            if (section?.title) {
              return section.title?.toLowerCase();
            }

            return null;
          });
          // Deriving abolished year
          if (pageTag) {
            abolishedYear = getAbolishEndingYear(pageTag.displayText);
          }

          useEffect(() => {
            setProfileCoverImgLoad(imageLoadingState(sections)); // set all loading states to 'true' initially
          }, []);

          let pageHeadContent;
          if (isEntity) {
            pageHeadContent = (
              <div>
                <p>
                  Portfolio:{' '}
                  <Link className={['alwaysFresh', style.textColor].join(' ')} to={portfolioData.routeBaseUrl}>
                    {portfolioData.routeTitle}
                  </Link>
                </p>
                {previousItems && previousItems.length > 0 && (
                  <p>
                    Previously known as:{' '}
                    {previousItems.map((entity, index) => {
                      // check entity and add parent url slug if it exists
                      if (previousItemsParentURLs[entity.system?.codename].parentURLSlug) {
                        return (
                          <span key={index}>
                            <a
                              key={index}
                              className={['alwaysFresh', style.textColor].join(' ')}
                              href={`../${previousItemsParentURLs[entity.system?.codename].parentURLSlug}/${entity.elements?.web_url?.value}`}>
                              {entity.elements?.body_name?.value}
                            </a>
                            {index !== previousItems.length - 1 ? ', ' : ''}
                          </span>
                        );
                      } else {
                        return (
                          <span key={index}>
                            {entity.elements?.body_name?.value}
                            {index !== previousItems.length - 1 ? ', ' : ''}
                          </span>
                        );
                      }
                    })}
                  </p>
                )}
                {websiteLink && getUrlObject(websiteLink) && (
                  <a href={websiteLink} className={['alwaysFresh', style.textColor].join(' ')}>
                    Website
                    <svg className={style.webLinkSvg}>
                      <use href={`${icon}#web-link`} />
                    </svg>
                  </a>
                )}
              </div>
            );
          } else if (previousItems && previousItems.length > 0) {
            pageHeadContent = (
              <p id="portfolioHeadContent">
                Previously known as:{' '}
                {previousItems.map((portfolio, index) => {
                  return (
                    <span key={index}>
                      <Link key={index} className={['alwaysFresh', style.textColor].join(' ')} to={`/${portfolioProfileUrl(portfolio.elements?.web_url?.value)}`}>
                        {portfolio.elements?.portfolio_name?.value}
                      </Link>
                      {index !== previousItems.length - 1 ? ', ' : ''} &nbsp;
                    </span>
                  );
                })}
              </p>
            );
          }

          useEffect(() => {
            // FIXME: HOTJAR INTEGRATION - After MVP 2 release, at the start of MVP3, hotjar need to be removed as this was only for research purpose by designer between MVP2 and MVP3
            hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
          }, []);

          useEffect(() => {
            const scrollTargetId = hash?.slice(1).replace(/%20/g, ' '); // ignore index 0 as it would always `#`
            const scrollTarget = document.getElementById(scrollTargetId);

            // if scroll target exists
            if (scrollTargetId && scrollTarget) {
              innerPageScroll(scrollTargetId);
            } else {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          });

          const renderSkipToSectionButtons = () => {
            if (isEntity && Array.isArray(sections) && sections?.every((section) => section.title)) {
              return (
                <nav id={style.jumpToSection} className={style.itemHolder}>
                  {sections.map((section, i) => {
                    const targetId = sectionIds?.[i];

                    return (
                      <li key={`skip-button-${i}`}>
                        <HashLink className="btn" smooth to={`#${targetId}`} scroll={() => innerPageScroll(targetId)}>
                          {section.title}
                          <svg>
                            <use href={`${icon}#arrow`} />
                          </svg>
                        </HashLink>
                      </li>
                    );
                  })}
                </nav>
              );
            }

            return null;
          };

          const renderSections = () => {
            if (Array.isArray(sections) && sections?.length > 0) {
              return (
                <>
                  {sections.map((section, sectionIndex) => {
                    const isPBSSection = isEntity && sectionIndex === 2;
                    if (isPBSSection) {
                      section['items'] = [];
                      if (portfolioData.sections?.[1]?.items?.length > 0) section.items = portfolioData.sections[1].items;
                    }

                    return (
                      <section key={section.title} id={sectionIds?.[sectionIndex]}>
                        <div className={style.sectionHeading}>
                          <h2>{section.title}</h2>
                          {isPBSSection && (
                            <Link to=".." className="btn lightFill">
                              View <b>{portfolioData.title}</b> portfolio
                            </Link>
                          )}
                        </div>

                        <ol className={[style.itemHolder, sectionIndex === 0 && !isEntity ? style.entity : null].join(' ')}>
                          {Array.isArray(section?.items) && section?.items?.length > 0 ? (
                            section.items.map((item, i) => {
                              // In the case of abolished portfolios, make linked entities only showing items from that reporting period or earlier  
                              let isDataSatisfied = true;
                              if (item.year) {
                                console.log('item.year ' + item.year);
                                const docEndingYear = isNaN(item.year) ? getEndingYear(item.year) : Number(item.year);
                                isDataSatisfied = isNaN(abolishedYear) ? true : Number(docEndingYear) <= Number(abolishedYear);
                              }
                              if (isDataSatisfied) {
                                const cardKey = `${section.title}-item-${i}`;
                                let cardListItemArr = ['card', 'listItem'];
                                const isLeadEntity = i === 0 && sectionIndex === 0 && !isEntity ? item.leadEntity : false;
                                const PBSOrCP = item.contentType === PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.VALUE || item.contentType === PUBLICATION_TYPE.CORPORATE_PLAN.VALUE;
                                let ARItemUrl = '';

                                if (isLeadEntity) cardListItemArr.push('leadingEntity');
                                if (sectionIndex === 0 && !isEntity) cardListItemArr.push('entityItem');

                                if (item.isAR) {
                                  const thisARReportingYear = item?.year;
                                  const targetPortfolioUrl =
                                    portfolioData?.previousItems?.find((obj) => {
                                      return obj?.elements?.active_during?.value?.map((activeDuringItem) => activeDuringItem?.name)?.includes(thisARReportingYear);
                                    })?.elements?.web_url?.value || portfolioData.portfolioUrl;

                                  // get url info
                                  ARItemUrl = '/' + annualReportUrl(targetPortfolioUrl, item.url?.entityUrl, item.url?.arUrl);
                                }

                                return (
                                  <li key={cardKey} className={cardListItemArr.join(' ')}>
                                    {/* TODO: Find out if 'open in new tab' button is required */}
                                    {isPBSSection && (
                                      <span className="portfolioInfo">
                                        {'Portfolio: '}
                                        <Link className="headerLink" to={portfolioData.routeBaseUrl}>
                                          {portfolioData.routeTitle}
                                        </Link>
                                      </span>
                                    )}
                                    {/* Need to check if AR and get URL using function - add boolean property to item object in loaders */}
                                    <Link
                                      to={item.isAR ? ARItemUrl : `${item.url}` || '#'}
                                      className={!item.isDocument ? [style.notDocumentLink, 'entityLink'].join(' ') : ''}
                                      referrerPolicy="no-referrer"
                                      target={PBSOrCP && item.url ? '_blank' : '_self'}>
                                      <span className="limitTextLines">
                                        {Array.isArray(item?.tags) && item?.tags?.length > 0 && (
                                          <span className="tags">
                                            {item.tags.map((tag, j) => (
                                              <Tag key={`${cardKey}-tag-${j}`} displayText={tag.displayText} fullText={tag.fullText} type={tag.type} />
                                            ))}
                                          </span>
                                        )}
                                        <span className={isLeadEntity ? 'cardTitleWhite' : 'cardTitle'}>
                                          {item.name}
                                          {PBSOrCP && (
                                            <svg>
                                              <use href={icon + '#open-in-new-tab'} />
                                            </svg>
                                          )}
                                        </span>
                                      </span>
                                      {/* TODO: Add cover image for entity and CP when the components are created */}
                                      <LoadingMenu
                                        numberOfItems={!item.isDocument ? (viewMode === CONSTANTS.VIEW_MODE.MOBILE ? 2 : 4) : viewMode === CONSTANTS.VIEW_MODE.MOBILE ? 5 : 10}
                                        className={[profileCoverImgLoad[item.codename] ? style.display : style.hide, !item.isDocument ? style.entityLoadingImage : 'imgLoading'].join(
                                          ' ',
                                        )}
                                      />
                                      {item.imageUrl || !item.isDocument ? (
                                        <div className={['imgContainer', profileCoverImgLoad[item.codename] ? style.hide : ''].join(' ')}>
                                          <img
                                            src={!item.isDocument ? item.imageUrl || DefaultEntityLogo : item.imageUrl || null}
                                            alt=""
                                            role="presentation"
                                            onLoad={() => onImageLoad(item.codename)}
                                            onError={() => onImageError(item)}
                                          />
                                        </div>
                                      ) : (
                                        <div className="imgLoading">
                                          <DefaultCoverImage reportTitle={item.name} entityName={routeTitle} />
                                        </div>
                                      )}
                                    </Link>
                                  </li>
                                );
                              }
                            })
                          ) : !isEntity && sectionIndex === 0 ? (
                            <p>No entities or companies found</p>
                          ) : (
                            <p>No {section.title.toLowerCase()} found</p>
                          )}
                        </ol>
                      </section>
                    );
                  })}
                </>
              );
            }

            return null;
          };

          return (
            <>
              <PageHead pageTitle={routeTitle} pageCategory={pageCategory} tagData={pageTag} givenImageURL={isEntity ? entityImageURL || DefaultEntityLogo : null}>
                {pageHeadContent}
              </PageHead>

              <div id={style.mainLayout}>
                <div>
                  {isEntity && renderSkipToSectionButtons()}
                  <div id={style.sectionsHolder}>{renderSections()}</div>
                </div>

                {Array.isArray(historyData) && historyData?.length > 0 && (
                  <div>
                    <History data={historyData} title={`History of this ${pageCategory?.toLowerCase()}`} />
                  </div>
                )}
              </div>
            </>
          );
        }}
      </Await>
    </Suspense>
  );
}

ProfilePage.propTypes = {
  loaderId: PropTypes.string.isRequired,
  isEntity: PropTypes.bool,
};

export default ProfilePage;
