import React, { useRef, useState, useEffect, useId } from 'react';
import style from './Tooltip.module.scss';
import PropTypes from 'prop-types';
import { debounce } from 'Utils';
import { CONSTANTS } from 'Constants';

const DEFAULT_DIMENSIONS = {
  height: 0,
  width: 0,
};

const TOOLTIP_POSITION = {
  top: 'top',
  bottom: 'bottom',
  left: 'left',
  right: 'right',
  topRight: 'topRight',
  bottomRight: 'bottomRight',
  topLeft: 'topLeft',
  bottomLeft: 'bottomLeft',
};

/**
 * Tooltip.jsx
 *
 * @summary This component is used for adding a tooltip message to an element.
 *
 * @param {Object} props - Component props.
 * @prop {Node} props.children - Children nodes of for tooltip to be wrapped around.
 * @prop {String} props.tooltipText - Text to display in tooltip.
 */
function Tooltip({ children, tooltipText }) {
  const childRef = useRef();
  const tooltipRef = useRef();
  const tooltipId = useId();
  const [activeTooltip, setActiveTooltip] = useState(false);

  // uses following dimensions to calculate where the tooltip should appear on screen
  const [childElementDimensions, setChildElementDimensions] = useState({ ...DEFAULT_DIMENSIONS, top: 0, right: 0, bottom: 0, left: 0 });
  const [tooltipDimensions, setTooltipDimensions] = useState({ ...DEFAULT_DIMENSIONS });
  const [windowDimensions, setWindowDimensions] = useState(DEFAULT_DIMENSIONS);

  useEffect(() => {
    if (childRef.current) {
      const { top, right, bottom, left } = childRef.current.getBoundingClientRect();
      setChildElementDimensions({ width: childRef.current.clientWidth, height: childRef.current.clientHeight, top, right, bottom, left });
    }

    if (tooltipRef.current) {
      const { width, height } = tooltipRef.current.getBoundingClientRect();
      setTooltipDimensions({ width, height });
    }

    const windowDetails = { width: document.documentElement.clientWidth, height: window.innerHeight };
    setWindowDimensions(windowDetails);
  }, [activeTooltip]);

  useEffect(() => {
    // recalculate window, element and tooltip dimensions when the screen size is changed or window scrolled
    const detectViewPortChange = debounce(() => {
      const windowDetails = { width: document.documentElement.clientWidth, height: window.innerHeight };
      setWindowDimensions(windowDetails);

      if (childRef.current) {
        const { top, right, bottom, left } = childRef.current.getBoundingClientRect();
        setChildElementDimensions({ width: childRef.current.clientWidth, height: childRef.current.clientHeight, top, right, bottom, left });
      }

      if (tooltipRef.current) {
        const { width, height } = tooltipRef.current.getBoundingClientRect();
        setTooltipDimensions({ width, height });
      }
    });

    // rerun dimensions each time a resize in window or if there has been a scroll
    window.addEventListener('resize', detectViewPortChange);
    window.addEventListener('scroll', detectViewPortChange);

    return () => {
      window.removeEventListener('resize', detectViewPortChange);
      window.removeEventListener('scroll', detectViewPortChange);
    };
  });

  useEffect(() => {
    const close = (e) => {
      // listen for escape key pressed
      if (e.key === CONSTANTS.ESCAPE_KEY) {
        setActiveTooltip(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  });

  const tooltipClass = () => {
    let tooltipStyleClass = '';

    const hasSpaceAboveElement = childElementDimensions.top > tooltipDimensions.height;
    const hasSpaceBelowElement = childElementDimensions.bottom + tooltipDimensions.height < windowDimensions.height;
    const hasSpaceRightOfElement = childElementDimensions.right + tooltipDimensions.width < windowDimensions.width;
    const hasSpaceLeftOfElement = childElementDimensions.left > tooltipDimensions.width;

    // tooltip is always positioned in the middle of the element when above or below
    // this is used to check that there is space on left/right of tooltip on the page
    const hasSpaceLeftForHalfTooltipWidth = childElementDimensions.left > tooltipDimensions.width / 2;
    const hasSpaceRightForHalfTooltipWidth = childElementDimensions.right + tooltipDimensions.width / 2 < windowDimensions.width;

    // tooltip is positioned in the center of element when right or left
    // this is used to check if there is space on bottom/top of tooltip
    const hasSpaceTopForHalfTooltipHeight = childElementDimensions.top > tooltipDimensions.height / 2;
    const hasSpaceBottomForHalfTooltipHeight = childElementDimensions.bottom + tooltipDimensions.height / 2 < windowDimensions.height;

    // tooltip will appear based on the following order: Top, Bottom, Left, Right, Top left, Top right, Bottom left, Bottom right
    if (hasSpaceAboveElement && hasSpaceLeftForHalfTooltipWidth && hasSpaceRightForHalfTooltipWidth) {
      tooltipStyleClass = TOOLTIP_POSITION.top;
    } else if (hasSpaceBelowElement && hasSpaceLeftForHalfTooltipWidth && hasSpaceRightForHalfTooltipWidth) {
      tooltipStyleClass = TOOLTIP_POSITION.bottom;
    } else if (hasSpaceLeftOfElement && hasSpaceTopForHalfTooltipHeight && hasSpaceBottomForHalfTooltipHeight) {
      tooltipStyleClass = TOOLTIP_POSITION.left;
    } else if (hasSpaceRightOfElement && hasSpaceTopForHalfTooltipHeight && hasSpaceBottomForHalfTooltipHeight) {
      tooltipStyleClass = TOOLTIP_POSITION.right;
    } else if (hasSpaceAboveElement && hasSpaceLeftOfElement) {
      tooltipStyleClass = TOOLTIP_POSITION.topLeft;
    } else if (hasSpaceAboveElement && hasSpaceRightOfElement) {
      tooltipStyleClass = TOOLTIP_POSITION.topRight;
    } else if (hasSpaceBelowElement && hasSpaceLeftOfElement) {
      tooltipStyleClass = TOOLTIP_POSITION.bottomLeft;
    } else if (hasSpaceBelowElement && hasSpaceRightOfElement) {
      tooltipStyleClass = TOOLTIP_POSITION.bottomRight;
    }

    return tooltipStyleClass;
  };

  const activateTooltip = () => {
    setActiveTooltip(true);
  };

  const deactivateTooltip = () => {
    setActiveTooltip(false);
  };

  return (
    <div
      className={style.tooltipWrapper}
      onMouseEnter={debounce(activateTooltip, 800)}
      onMouseLeave={deactivateTooltip}
      onFocus={debounce(activateTooltip, 800)}
      onBlur={deactivateTooltip}>
      {activeTooltip && (
        <div className={style.tooltip}>
          <span
            role="tooltip"
            id={tooltipId}
            ref={tooltipRef}
            className={[style.tooltipText, style[tooltipClass()]].join(' ')}
            style={{
              '--tooltip-height': `${tooltipDimensions.height}px`,
              '--tooltip-width': `${tooltipDimensions.width}px`,
              '--child-element-height': `${childElementDimensions.height}px`,
              '--child-element-width': `${childElementDimensions.width}px`,
            }}
            onMouseEnter={deactivateTooltip}>
            {tooltipText}
          </span>
        </div>
      )}
      <div className={style.childWrapper} ref={childRef} aria-describedby={tooltipId}>
        {children}
      </div>
    </div>
  );
}

export default Tooltip;

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipText: PropTypes.string.isRequired,
};
