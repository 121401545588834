import React, { useState, useEffect } from 'react';
import { Loading, LoadingMenu } from 'Components';
import { CONSTANTS } from 'Constants';
import style from './FilterLoading.module.scss';
import { debounce, detectViewMode } from 'Utils';

/**
 * FilterLoading.jsx
 *
 * @summary This component shows the loading state page view for the Filter component loading.
 *
 */
function FilterLoading() {
  const [viewMode, setViewMode] = useState(detectViewMode());

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  return (
    <div className={style.filter}>
      {viewMode !== CONSTANTS.VIEW_MODE.MOBILE ? (
        <>
          <LoadingMenu numberOfItems={5} className={style.filterDropdownButtons}></LoadingMenu>
          <div className={style.filterButtons}>
            <Loading />
            <Loading />
          </div>
          <div className={style.selectedFilters}></div>
        </>
      ) : (
        <Loading className={style.mobileFilter} />
      )}
      <Loading className={style.numberOfResults}></Loading>
    </div>
  );
}

export default FilterLoading;
