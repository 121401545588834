export const constructCSVFixedTable = (dataSetResponse, dataTemplate, defaultValue) => {
  if (!dataSetResponse || !dataTemplate) {
    return [];
  }

  function removeDashsFromData (inputDataSet){
    function replaceDashes(inputString) {
      // Use a regular expression to match en dash, em dash, and non-breaking hyphen
      return inputString.replace(/–|—|‑/g, '-');
    }
    // Process rows
    inputDataSet.rows.forEach(row => {
      row.display = replaceDashes(row.display);
    });

    // Process columns
    inputDataSet.columns.forEach(column => {
      column.display = replaceDashes(column.display);
    });

    return inputDataSet;

  }

  dataTemplate = removeDashsFromData(dataTemplate);

  let entityHeadingList = [];
  let reportingYearList = [''];

  const entityColSpanInfo = { '': 1 };
  dataSetResponse.forEach((entityData) => {
    let totalColumns = 0;
    dataTemplate?.columns?.forEach((colGroup) => {
      if (colGroup?.subColumns && colGroup?.subColumns?.length > 0) {
        totalColumns += colGroup?.subColumns?.length || 0;
      } else {
        totalColumns += 1;
      }
    });
    if (entityColSpanInfo[entityData.entity]) {
      entityColSpanInfo[entityData.entity] += 1 * (totalColumns || 1);
    } else {
      entityColSpanInfo[entityData.entity] = 1 * (totalColumns || 1);
    }
  });
  Object.keys(entityColSpanInfo).map((entity) => {
    const entityArray = Array(entityColSpanInfo[entity] || 1).fill('');
    entityArray[0] = entity;
    entityHeadingList = entityHeadingList.concat(entityArray);
  });

  // reporting year
  dataSetResponse.forEach((entityData) => {
    let totalReportingYearColumns = 0;
    dataTemplate?.columns?.forEach((colGroup) => {
      if (colGroup?.subColumns && colGroup?.subColumns?.length > 0) {
        totalReportingYearColumns += colGroup?.subColumns?.length || 0;
      } else {
        totalReportingYearColumns += 1;
      }
    });
    const individualReportingYearArray = Array(totalReportingYearColumns || 1).fill('');
    individualReportingYearArray[0] = entityData.reportingPeriod;
    reportingYearList = reportingYearList.concat(individualReportingYearArray);
  });

  // column group/column
  let columnList = [''];
  dataSetResponse?.forEach(() => {
    dataTemplate?.columns?.forEach((col) => {
      const colArray = Array(col?.subColumns?.length || 1).fill('');
      colArray[0] = col.display;
      columnList = columnList.concat(colArray);
    });
  });

  const subColList = [''];
  dataSetResponse?.forEach(() => {
    dataTemplate.columns?.forEach((col) => {
      col?.subColumns?.map((subCol) => {
        subColList.push(subCol.display);
      });
    });
  });

  const tableDataValues = [];
  dataTemplate?.rows?.forEach((row) => {
    let rowData = [row.display];
    dataSetResponse?.forEach((entityYearData) => {
      let colData = [];
      dataTemplate.columns?.forEach((col) => {
        if (col.subColumns?.length > 0) {
          colData = colData.concat(
            col.subColumns?.map((subCol) => {
              // If is row heading, don't render any values here
              return row.isRowHeading
                ? ''
                : entityYearData.datafields?.[
                    subCol.key?.toLowerCase()
                      ? `${row.key?.toLowerCase()}${row.key ? '|' : ''}${col.key?.toLowerCase()}-${subCol.key?.toLowerCase()}`
                      : `${row.key?.toLowerCase()}|${col.key?.toLowerCase()}`
                  ] || defaultValue;
            }) || [],
          );
        } else {
          colData.push(row.isRowHeading ? '' : entityYearData.datafields?.[`${row.key?.toLowerCase()}${row.key ? '|' : ''}${col.key?.toLowerCase()}`] || defaultValue);
        }
      });
      rowData = rowData.concat(colData);
    });
    tableDataValues.push(rowData);
  });

  const hasSubColumns = dataTemplate?.columns?.some((col) => col?.subColumns?.length > 0);

  return [entityHeadingList, reportingYearList, columnList, hasSubColumns ? subColList : null].filter(Boolean).concat(tableDataValues);
};

export const constructCSVAppendTable = (dataSetResponse, dataTemplate, defaultValue) => {
  if (!dataSetResponse || !dataTemplate) {
    return [];
  }

  let columnList = [];
  dataTemplate?.columns?.forEach((col) => {
    const colArray = Array(col?.subColumns?.length || 1).fill('');
    colArray[0] = col.display;
    columnList = columnList.concat(colArray);
  });

  let subColList = [];
  dataTemplate.columns?.forEach((col) => {
    col?.subColumns?.map((subCol) => {
      subColList.push(subCol.display);
    });
  });

  const tableDataValues = [];
  let rowData = [];
  dataSetResponse?.forEach((entityYearData) => {
    entityYearData.datafields?.forEach((dfRow) => {
      let colData = [];
      dataTemplate.columns?.forEach((col) => {
        if (col.subColumns?.length > 0) {
          colData = colData.concat(
            col.subColumns?.map((subCol) => {
              if (subCol.concatFields?.length) {
                let valuesToConcat = [];
                subCol.concatFields.map((concatField) => {
                  valuesToConcat.push(dfRow?.[`${concatField?.toLowerCase()}`]);
                });

                return dfRow['name'] || valuesToConcat.length > 0 ? valuesToConcat.join(' ') : defaultValue;
              } else {
                return dfRow?.[`${subCol.key?.toLowerCase()}`] || defaultValue;
              }
            }) || [],
          );
        } else {
          if (col.concatFields?.length) {
            let valuesToConcat = [];
            if (dfRow['name']) {
              colData.push(dfRow['name'] || defaultValue);
            } else {
              col.concatFields.map((concatField) => {
                valuesToConcat.push(dfRow?.[`${concatField?.toLowerCase()}`] || defaultValue);
              });
              colData.push(valuesToConcat.join(' '));
            }
          } else {
            colData.push(dfRow?.[`${col.key?.toLowerCase()}`] || defaultValue);
          }
        }
      });
      rowData = rowData.concat(colData);
      tableDataValues.push(colData);
    });
  });

  const hasSubColumns = dataTemplate?.columns?.some((col) => col?.subColumns?.length > 0);

  return [columnList, hasSubColumns ? subColList : null].filter(Boolean).concat(tableDataValues);
};
