import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLoaderData } from 'react-router-dom';
import { MessageBox } from 'Components';
import style from './TablePreview.module.scss';

/**
 * TablePreview.jsx
 *
 * @summary This component is page view for TablePreview page.
 *
 * This page is for PDF export for BE to print web data table in landscape layout if needed.
 * This page does not need to have error handling nor loading animation as it only requires for export pdf hence no user will have access to this page. Also, no need to error handling as if error happens, meaning it's error in Kontent or API fail and in this case BE wouldn't be able to generate PDF anyway.
 */
function TablePreview({ isPreview = false }) {
  const data = useLoaderData();
  const [isPrintView, setIsPrintView] = useState(false);

  return (
    <div id="TPApp" className={['printOnly', isPrintView ? 'forcePrintStyle' : ''].join(' ')}>
      {isPreview && data && (
        <header className={style.control}>
          <button onClick={() => setIsPrintView(false)} className={[!isPrintView ? 'activeState' : null, 'noFontChange'].join(' ')}>
            Web view
          </button>
          <button onClick={() => setIsPrintView(true)} className={[isPrintView ? 'activeState' : null, 'noFontChange'].join(' ')}>
            Print view
          </button>
        </header>
      )}

      <main id="main-content">
        <div id="annualReportHolder" className={[style.wrapper, !isPrintView ? style.webView : null].join(' ')}>
          {data ? (
            data.emptyTable ? (
              <div className={style.messageWrapper}>
                <MessageBox title="No Preview available">
                  <p>No Preview available - please add content to your table in order to Preview this table</p>
                </MessageBox>
              </div>
            ) : (
              <div className="tableElement">
                {data?.node}
                {data?.node && <span id="readyForPrint" hidden key={new Date().getTime()}></span>}
              </div>
            )
          ) : (
            <div className={style.messageWrapper}>
              <MessageBox title="Table not found">
                <p>Please make the table a reusable content item.</p>
              </MessageBox>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

TablePreview.propTypes = {
  isPreview: PropTypes.bool,
};

export default TablePreview;
