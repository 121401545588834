import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tag } from 'Components';
import { IS_PREVIEW_MODE, TAG_STYLE_ENUMS } from 'Constants';
import style from './RelevantLinks.module.scss';

/**
 * RelevantLinks.jsx
 *
 * @summary Render as Relevant Links list of Annual report cover and content page
 *
 * @param {Object} props - Component props.
 * @prop {{ [name]: String, [url]: String, [period]: String, [newTab]: String }} props.corporatePlan - Object that holds all the data to render CP link card. All key data are optional to support preview app.
 * @prop {{ [name]: String, [url]: String, [period]: String, [newTab]: String }} props.portfolio - Object that holds all the data to render PBS link card. All key data are optional to support preview app.
 * @prop {{ [name]: String, [url]: String, entityType: String, [newTab]: String }} [props.entity] - Object that holds all the data to render entity link card. If this is not passed, component will only have CP and PBS link cards in that order else, entity link card will always comes first.
 */
function RelevantLinks({ corporatePlan, portfolio, entity }) {
  const TAGS = {
    ENTITY: [],
    CP: [
      <Tag key="cp-tag" displayText="CP" fullText="Corporate Plan" type={TAG_STYLE_ENUMS.HIGHLIGHT} />,
      corporatePlan.period ? <Tag key="cp-period" displayText={corporatePlan.period} type={TAG_STYLE_ENUMS.GREY} /> : undefined,
    ],
    PBS: [
      <Tag key="pbs-tag" displayText="PBS" fullText="Portfolio Budget Statement" type={TAG_STYLE_ENUMS.HIGHLIGHT} />,
      portfolio.period ? <Tag key="pbs-period" displayText={portfolio.period} type={TAG_STYLE_ENUMS.GREY} /> : undefined,
    ],
  };

  if (entity?.entityType) {
    TAGS.ENTITY.push(<Tag key="entity" displayText={entity.entityType} />);
  }

  // assuming all links are open within the same tab as design does not have open in new tab icon with it
  const cardLink = (url, tags, name, extraText = null, newTab = false) => {
    const linkClass = ['card', style.relevantLink];

    if (!url || !name) {
      linkClass.push('noContentOutlineOutside'); // Red box style to notify missing content

      if (!IS_PREVIEW_MODE) {
        return <></>; // if published app, return nothing
      }
    }

    return (
      <li>
        <Link className={linkClass.join(' ')} to={url} target={newTab ? '_blank' : null}>
          <span className={style.tags}>{tags.map((tag) => tag)}</span>
          <span className={style.linkName}>{name}</span>
          <span className={style.extraText}>{extraText}</span>
        </Link>
      </li>
    );
  };

  return (
    <div id={style.RelevantLinks} className="fullColorBackground">
      <h2 className="h6">Relevant links</h2>

      <ul>
        {entity && cardLink(entity?.url, TAGS.ENTITY.filter(Boolean), entity?.name, undefined, entity?.newTab)}
        {cardLink(corporatePlan.url, TAGS.CP.filter(Boolean), corporatePlan.name, entity?.name, corporatePlan?.newTab)}
        {cardLink(portfolio.url, TAGS.PBS.filter(Boolean), portfolio.name, entity?.name, portfolio?.newTab)}
      </ul>
    </div>
  );
}

const commonPropObjectShape = {
  name: PropTypes.string, // if not given, will show red box to notify missing content
  url: PropTypes.string, // if not given, will show red box to notify missing content
  period: PropTypes.string,
  newTab: PropTypes.bool,
};

RelevantLinks.propTypes = {
  entity: PropTypes.shape({
    name: PropTypes.string, // if not given, will show red box to notify missing content
    url: PropTypes.string, // if not given, will show red box to notify missing content
    entityType: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
  }),
  corporatePlan: PropTypes.shape(commonPropObjectShape).isRequired,
  portfolio: PropTypes.shape(commonPropObjectShape).isRequired,
};

export default RelevantLinks;
