/**
 * Function to update response data to be match like delivery API SDK response
 * 
 * NOTE: currently this isn't being used but keeping it just in case in future
 * we move away from Kontent Delivery API SDK
 * @param {Object} obj `elements` data from API
 * @param {Object} modularContent `modular_content` data from API
 * @returns {Object} Response data with linked items connected
 */
export const updateResponseData = (obj, modularContent) => {
  const MODULAR_CONTENT = 'modular_content';
  const LINKED_ITEMS = 'linkedItems';
  const sortedObj = { ...obj };

  Object.keys(sortedObj)?.forEach((key) => {
    const targetObj = sortedObj[key];

    // if key is `links`
    if (targetObj?.links && !Array.isArray(targetObj?.links)) {
      // update `links` value to be array of objects to match with how SDK would process
      targetObj.links = Object.keys(targetObj.links).map(linkId => {
        return {
          ...targetObj.links[linkId],
          linkId
        };
      });
    }

    // if target element item type is modular content
    if (Array.isArray(targetObj?.value) && targetObj.type === MODULAR_CONTENT && !targetObj?.[LINKED_ITEMS]) {
      // if targetObj does not have `linkedItems`, create empty array for it
      if (!Array.isArray(targetObj?.[LINKED_ITEMS])) {
        targetObj[LINKED_ITEMS] = [];
      }

      // push linked item
      targetObj.value.forEach(linkedITemKey => {
        targetObj[LINKED_ITEMS].push(modularContent[linkedITemKey]);
      });
    }

    // if target sortedObj item has `modular_content` key exists
    if (targetObj[MODULAR_CONTENT] && !targetObj?.[LINKED_ITEMS]) {
      // add `linkedItems` if doesn't exist
      if (!Array.isArray(targetObj?.[LINKED_ITEMS])) {
        targetObj[LINKED_ITEMS] = [];
      }

      targetObj[MODULAR_CONTENT].forEach(linkedItemKey => {
        targetObj[LINKED_ITEMS].push(modularContent[linkedItemKey]);
      });

      // rename `modular_content` key to `linkedItemCodenames`
      targetObj.linkedItemCodenames = targetObj[MODULAR_CONTENT];
      delete targetObj[MODULAR_CONTENT];
    }

    // if target item is linked item list
    if (targetObj[LINKED_ITEMS]) {
      targetObj[LINKED_ITEMS] = targetObj[LINKED_ITEMS].filter(Boolean);
      targetObj[LINKED_ITEMS].forEach(data => {
        return data = {
          elements: { ...updateResponseData(data.elements, modularContent) },
          system: data.system
        };
      });
    }
  });

  return sortedObj;
};