import React from 'react';
import { IS_PREVIEW_MODE } from './Constants';

const PAGE_NOT_FOUND_PUBLIC = (
  <>
    <p>The page you are looking for could not be found. Consider the following to find the correct page:</p>

    <ul>
      <li>Double check the spelling in the URL.</li>
      <li>Use the global search to find a similar page.</li>
      <li>Retrace your steps through the menu.</li>
    </ul>
  </>
);

export const PAGE_NOT_FOUND = (content) => {
  return IS_PREVIEW_MODE ? (
    <>
      <p>Sorry, we cannot display your page due to one of following reasons:</p>
      <ul>
        {content ? (
          content
        ) : (
          <>
            <li>Due to slow or unstable network - try refreshing the page (or use CTR + F5 to refresh the page&#39;s cache).</li>
            <li>
              The URL of the Preview page has changed due to a removal of, or change to, a Chapter or Section title. Close the preview page and re-launch it from the Tool using the
              Preview button.
            </li>
            <li>The Preview page URL will break if there is no Title for a Chapter or Section. Please ensure all Chapters and Sections have Titles, then re-launch Preview.</li>
            <li>
              An invalid content item may have been inserted into the Report Chapters tab - please check you have only inserted a Report Chapter, Independent Auditor&#39;s Report
              or List of Requirements item only.
            </li>
            <li>
              An invalid content item may have been inserted into the Sections tab of a Report Chapter (where you add subsections to a chapter) - please check you have only
              inserted a Report Section, Independent Auditor&#39;s Report or List of Requirements item only.
            </li>
            <li>
              The website may have crashed due to an unknown reason. If you have troubleshooted the above points and think you have done everything right, please let us know at{' '}
              <a href="mailto:dar@finance.gov.au">dar@finance.gov.au</a>.
            </li>
          </>
        )}
      </ul>
    </>
  ) : (
    PAGE_NOT_FOUND_PUBLIC
  );
};

export const ERROR_CONTENT = {
  NOT_FOUND: IS_PREVIEW_MODE ? (
    <li>
      The URL of the Preview page has changed due to a removal of, or change to, Web URL field value. Close the preview page and re-launch it from the Tool using the Preview
      button.
    </li>
  ) : (
    PAGE_NOT_FOUND_PUBLIC
  ),
  NOT_FOUND_CHAPTER: IS_PREVIEW_MODE ? (
    <li>
      The URL of the Preview page has changed due to a removal of, or change to, Chapter title. Close the preview page and re-launch it from the Tool using the Preview button.
    </li>
  ) : (
    PAGE_NOT_FOUND_PUBLIC
  ),
  INVALID_CONTENT: IS_PREVIEW_MODE ? (
    <>
      <li>
        An invalid content item may have been inserted into the Report Chapters tab - please check you have only inserted a Report Chapter, Independent Auditor&#39;s Report or List
        of Requirements item only.
      </li>
      <li>
        An invalid content item may have been inserted into the Sections tab of a Report Chapter (where you add subsections to a chapter) - please check you have only inserted a
        Report Section, Independent Auditor&#39;s Report or List of Requirements item only.
      </li>
    </>
  ) : (
    PAGE_NOT_FOUND_PUBLIC
  ),
};

export const SOMETHING_WENT_WRONG = <p>Sorry, we currently cannot display the page because something went wrong. Please retry after some time.</p>;

export const NO_FILTER_RESULTS_FOUND = <p>No results found. Try different words or phrases.</p>;

export const NO_RESULTS_FOUND = <p>No results found. Try different words or remove filters.</p>;

export const FILTER_API_FAILED = <p>Something went wrong. Please refresh to use the filters.</p>;

export const NO_CONTENT_FOUND_FOR_RULE = (rule, className) => <p className={className || ''}>No content reference has been found for the rule {`${rule || ''}`}</p>;
