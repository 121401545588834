import React from 'react';
import { Loading } from 'Components';
import style from './HomePageLoading.module.scss';

/**
 * HomePageLoading.jsx
 *
 * @summary This component shows the loading state page view for home page
 *
 */
function HomePageLoading() {
  return (
    <div id={style.homepageLoading}>
      <Loading className={[style.loadingHero].join(' ')} />
      <div className={style.publicationsSection}>
        <Loading>
          <span className={style.invisibleTitle}>Loading</span>
        </Loading>
        <div className={style.publicationType}>
          <Loading />
          <Loading />
          <Loading />
        </div>
      </div>
      <Loading className={style.loadingSection} />
      <div className={style.relatedResources}>
        <Loading>
          <span className={style.invisibleTitle}>Loading</span>
        </Loading>
        <div className={style.relatedLinks}>
          <Loading />
          <Loading />
          <Loading />
          <Loading />
          <Loading />
          <Loading />
        </div>
      </div>
    </div>
  );
}

export default HomePageLoading;
