import React, { Suspense, useState, useEffect, useMemo, useRef } from 'react';
import { CSVLink } from 'react-csv';
import style from './DataSetsVisualised.module.scss';
import { DataSetsHeader, DataSetsFilterAccordion, FormField, DropdownSelect, AlphabetGroupFilterList, GroupedFilterList, GraphView, MessageBox, Loading } from 'Components';
import { PATHS, URL_QUERY_KEYS, DATA_SETS_URL_FILTER_GROUPS, MESSAGE_BOX_TYPE, DATA_TABLE_TYPES, SOMETHING_WENT_WRONG } from 'Constants';
import { Await, useRouteLoaderData, useSearchParams, useLocation } from 'react-router-dom';
import { getDataSets, getDataTableTemplate } from 'Services/Delivery';
import { DataSetsLoading } from 'Pages';
import { constructCSVFixedTable } from 'Utils/DataSets/DataSetsUtils';
import { sortByObjectProperty } from 'Utils';

/**
 * DataSetsVisualised.jsx
 *
 * @summary This component is page view for Data sets (graph) page.
 *
 */
function DataSetsVisualised() {
  const { dataSets } = useRouteLoaderData(PATHS.DATA_SETS.ID);
  const { pathname } = useLocation();
  const [reportingYearFilterSelection, setReportingFilterSelection] = useState({ reportingYearFilters: [], selectAllReportingYears: false });
  const [atLeastOneYearSelected, setAtLeastOneYearSelected] = useState(false);
  const [entityFilterSelection, setEntityFilterSelection] = useState({
    bodyTypeFilters: [],
    selectAllBodyTypes: false,
    portfolioFilters: [],
    selectAllPortfolios: false,
    entityFilters: [],
    selectAllEntities: false,
  });
  const [showOnlySelectedEntities, setShowOnlySelectedEntities] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({ chosenDataSet: {}, reportingYearFilters: [], entityFilters: [] });
  const [dataSetOptions, setDataSetOptions] = useState({});
  const [dataSet, setDataSet] = useState({});
  const [dataSetSelected, setDataSetSelected] = useState(false);
  const [urlParams, setUrlParams] = useSearchParams();
  const [allAdditionalFilters, setAllAdditionalFilters] = useState([]);
  const [specificAdditionalFilters, setSpecificAdditionalFilters] = useState([]);
  const [dataSetGraphData, setDataSetGraphData] = useState([]);
  const [fetchDataSetsLoading, setFetchDataSetsLoading] = useState(false);
  const [entitiesNotRequiredToReport, setEntitiesNotRequiredToReport] = useState([]);
  const [qualitativeData, setQualitativeData] = useState(false);
  const [dataSetTemplate, setdataSetTemplate] = useState({});
  const [initialDataSetResponse, setInitialDataSetResponse] = useState(null);
  const [defaultValue, setDefaultValue] = useState('');
  const [exportCSVData, setExportCSVData] = useState([]);
  const [applyChanges, setApplyChanges] = useState(false);
  const exportCSVButton = useRef();

  const selectAllReportingYears = () => {
    const updatedReportingYearOptions = [...reportingYearFilterSelection.reportingYearFilters];
    updatedReportingYearOptions.forEach((option) => {
      option.selected = !reportingYearFilterSelection.selectAllReportingYears;
    });
    setReportingFilterSelection({ reportingYearFilters: updatedReportingYearOptions, selectAllReportingYears: !reportingYearFilterSelection.selectAllReportingYears });
  };

  const selectReportingYearDropdown = (selectedOption) => {
    const updatedSelection = [...reportingYearFilterSelection.reportingYearFilters];
    const foundSelectedOption = updatedSelection.find((option) => option.returnValue === selectedOption.returnValue);
    if (foundSelectedOption) {
      foundSelectedOption.selected = !foundSelectedOption.selected;
    }
    setReportingFilterSelection({ ...reportingYearFilterSelection, reportingYearFilters: updatedSelection });

    // Reset entity selection only if it has been removed from list (since reporting year changes the list of entities to only show active ones)
    const selectedBodyTypes = entityFilterSelection.bodyTypeFilters.filter((filter) => filter.selected === true) || [];
    const selectedPortfolios = entityFilterSelection.portfolioFilters.filter((filterValue) => filterValue.selected === true) || [];
    const entitiesSelection = [];
    entityFilterSelection.entityFilters.forEach((entity) => {
      if (
        entity.activeDuring.some((activeYear) =>
          reportingYearFilterSelection.reportingYearFilters
            .filter((value) => value.selected === true)
            ?.map((value) => value.returnValue)
            ?.includes(activeYear),
        )
      ) {
        if (selectedBodyTypes.length > 0 || selectedPortfolios.length > 0) {
          const selectedBodyCodenames = selectedBodyTypes.map((bodyTypeOption) => {
            return bodyTypeOption.returnValue;
          });
          const selectedPortfolioCodenames = selectedPortfolios.map((portfolioOption) => portfolioOption.returnValue);
          if (selectedBodyCodenames.includes(entity.bodyType) || entity.portfolio?.some((portfolioCodename) => selectedPortfolioCodenames.includes(portfolioCodename))) {
            entitiesSelection.push({ ...entity, selected: true });
          } else {
            entitiesSelection.push({ ...entity, selected: false });
          }
        } else {
          entitiesSelection.push(entity);
        }
      } else {
        entitiesSelection.push({ ...entity, selected: false });
      }
    });
    // recalculate portfolio and body type selections
    selectedBodyTypes.forEach((bodyType) => {
      selectPortfolioFilterOption(bodyType);
    });
    setEntityFilterSelection({ ...entityFilterSelection, entityFilters: entitiesSelection });
  };

  const selectReportingYearsDropdown = (givenOptions, givenReportingYearFilterSelection) => {
    const dataCpy = structuredClone(givenReportingYearFilterSelection);
    givenOptions.map((givenOption) => {
      const updatedSelection = [...dataCpy.reportingYearFilters];
      const foundSelectedOption = updatedSelection.find((option) => option.returnValue === givenOption);
      if (foundSelectedOption) {
        foundSelectedOption.selected = !foundSelectedOption.selected;
      }
      setReportingFilterSelection({ ...givenReportingYearFilterSelection, reportingYearFilters: updatedSelection });
    });
  };

  const clearReportingYearSelection = () => {
    const updatedReportingYears = setSelectedValue([...reportingYearFilterSelection.reportingYearFilters], false);
    setReportingFilterSelection({ reportingYearFilters: updatedReportingYears, selectAllReportingYears: false });
  };

  const numberOfDropdownOptionsSelected = (filterList) => {
    if (filterList.every((option) => option.selected === true)) {
      return 'All';
    }
    return `${filterList.filter((option) => option.selected === true).length}` || '0';
  };

  // Updates a dropdown selection option list with updated value from selected option. (Converts 'selected' property to true/false or with a specific override value)
  const updateDropdownSelection = (optionsList, selectedOption, overrideValue) => {
    const updatedSelection = [...optionsList];
    const foundSelectedOption = updatedSelection.find((option) => option.returnValue === selectedOption.returnValue);
    if (foundSelectedOption) {
      foundSelectedOption.selected = overrideValue !== undefined && overrideValue !== null ? overrideValue : !foundSelectedOption.selected;
    }
    return updatedSelection;
  };

  const selectMultipleEntities = (updatedMultiSelectList, overrideValue, entityFilterSelectionGiven = null) => {
    if (updatedMultiSelectList && Array.isArray(updatedMultiSelectList)) {
      if (entityFilterSelectionGiven !== null) {
        const dataCpy = structuredClone(entityFilterSelectionGiven);
        let updatedList = [...dataCpy.entityFilters];
        updatedMultiSelectList.forEach((selectedOption) => {
          updatedList = updateDropdownSelection(updatedList, selectedOption, overrideValue);
        });
        // this pathway doesn't select all entities unless already selected
        setEntityFilterSelection({ ...dataCpy, entityFilters: updatedList, selectAllEntities: dataCpy.selectAllEntities });
      } else {
        let updatedList = [...entityFilterSelection.entityFilters];
        updatedMultiSelectList.forEach((selectedOption) => {
          updatedList = updateDropdownSelection(updatedList, selectedOption, overrideValue);
        });
        setEntityFilterSelection({ ...entityFilterSelection, entityFilters: updatedList, selectAllEntities: !entityFilterSelection.selectAllEntities });
      }
    }
  };

  // Filters down the entity list to show only those applicable to the selected reporting years
  const filterEntityBySelectedReportingYear = () => {
    return entityFilterSelection.entityFilters.filter((entity) => {
      return entity.activeDuring.some((activeYear) =>
        reportingYearFilterSelection.reportingYearFilters
          .filter((value) => value.selected === true)
          ?.map((value) => value.returnValue)
          ?.includes(activeYear),
      );
    });
  };

  const clearEntitySelection = () => {
    const updatedBodyTypes = setSelectedValue([...entityFilterSelection.bodyTypeFilters], false);
    const updatedPortfolios = setSelectedValue([...entityFilterSelection.portfolioFilters], false);
    const updatedEntities = setSelectedValue([...entityFilterSelection.entityFilters], false);
    setEntityFilterSelection({
      bodyTypeFilters: updatedBodyTypes,
      selectAllBodyTypes: false,
      portfolioFilters: updatedPortfolios,
      selectAllPortfolios: false,
      entityFilters: updatedEntities,
      selectAllEntities: false,
    });
    setShowOnlySelectedEntities(false);
  };

  // Updates the body type filter value. Also selects all entities that contain that body type too.
  const selectBodyTypeFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection([...entityFilterSelection.bodyTypeFilters], selectedOption);
    const updatedValue = updatedSelection.find((option) => option.returnValue === selectedOption.returnValue);
    const entitiesToSelect = filterEntityBySelectedReportingYear()?.filter((option) => option.bodyType === selectedOption.returnValue);
    selectMultipleEntities(entitiesToSelect, updatedValue?.selected);
    setEntityFilterSelection({ ...entityFilterSelection, bodyTypeFilters: updatedSelection });
  };

  const allBodyTypesSelected = () => {
    return entityFilterSelection.bodyTypeFilters.every((option) => option.selected === true);
  };

  const allPortfoliosSelected = () => {
    return entityFilterSelection.portfolioFilters.every((option) => option.selected === true);
  };

  const atLeastOneEntitySelected = () => {
    return entityFilterSelection.entityFilters.some((filterOption) => filterOption.selected === true);
  };

  const atLeastOneReportingYearSelected = () => {
    return reportingYearFilterSelection.reportingYearFilters.some((filterOption) => filterOption.selected === true);
  };

  useEffect(() => {
    if (atLeastOneReportingYearSelected()) {
      setAtLeastOneYearSelected(true);
    } else {
      setAtLeastOneYearSelected(false);
    }
  }, [reportingYearFilterSelection]);

  // Select all options in given list
  const selectAllOptionsInList = (list, previousSelectAllValue) => {
    const updatedOptions = [...list];
    updatedOptions.forEach((option) => {
      option.selected = !previousSelectAllValue;
    });
    return updatedOptions;
  };

  const selectAllEntitiesFunc = (givenEntityFilterSelection) => {
    selectMultipleEntities(givenEntityFilterSelection.entityFilters, true, { ...givenEntityFilterSelection, selectAllEntities: true });
  };

  // Selects all body types. Also selects entities relating to those body types
  const selectAllBodyTypesHandler = () => {
    const updatedFilters = selectAllOptionsInList([...entityFilterSelection.bodyTypeFilters], entityFilterSelection.selectAllBodyTypes);
    updatedFilters.forEach((bodyType) => {
      const entitiesToSelect = filterEntityBySelectedReportingYear()?.filter((option) => option.bodyType === bodyType.returnValue) || [];
      selectMultipleEntities(entitiesToSelect, !entityFilterSelection.selectAllBodyTypes);
    });
    setEntityFilterSelection({ ...entityFilterSelection, bodyTypeFilters: updatedFilters, selectAllBodyTypes: !entityFilterSelection.selectAllBodyTypes });
  };

  // Function to update portfolio option checkbox. This also updates the entities that contain this portfolio too.
  const selectPortfolioFilterOption = (selectedOption) => {
    const updatedSelection = updateDropdownSelection([...entityFilterSelection.portfolioFilters], selectedOption);
    const updatedValue = updatedSelection.find((option) => option.returnValue === selectedOption.returnValue);
    const entitiesToSelect = filterEntityBySelectedReportingYear()?.filter((option) => option.portfolio?.includes(selectedOption.returnValue)) || [];
    selectMultipleEntities(entitiesToSelect, updatedValue?.selected); // updates all entities checkbox that contain this portfolio
    setEntityFilterSelection({ ...entityFilterSelection, portfolioFilters: updatedSelection });
  };

  // Select all portfolios. Also checks all entities relating to those portfolios
  const selectAllPortfoliosHandler = () => {
    const updatedFilters = selectAllOptionsInList([...entityFilterSelection.portfolioFilters], entityFilterSelection.selectAllPortfolios) || [];
    updatedFilters.forEach((portfolio) => {
      const entitiesToSelect = filterEntityBySelectedReportingYear()?.filter((option) => option.portfolio?.includes(portfolio.returnValue)) || [];
      selectMultipleEntities(entitiesToSelect, !entityFilterSelection.selectAllPortfolios);
    });
    setEntityFilterSelection({ ...entityFilterSelection, portfolioFilters: updatedFilters, selectAllPortfolios: !entityFilterSelection.selectAllPortfolios });
  };

  // Selects an entity filter option
  const selectEntityOption = (selectedOption) => {
    const updatedSelection = updateDropdownSelection([...entityFilterSelection.entityFilters], selectedOption);
    setEntityFilterSelection({ ...entityFilterSelection, entityFilters: updatedSelection });
  };

  // Adds reporting years into string to display what reporting years have been selected
  const reportingYearsSelectedText = () => {
    const selectedReportingYears = reportingYearFilterSelection.reportingYearFilters.map((option) => {
      if (option.selected === true) {
        return option.display;
      }
    });
    return selectedReportingYears.filter(Boolean).join(', ');
  };

  // Update selected attribute of object with value to set
  const setSelectedValue = (arrayList, valueToSet) => {
    return arrayList.map((filterValue) => {
      return { ...filterValue, selected: valueToSet };
    });
  };

  const removeDataSetInfo = () => {
    setDataSetSelected(false);
    setDataSet({});

    let dataSetOptionsCpy = dataSetOptions;
    for (const heading in dataSetOptionsCpy) {
      dataSetOptionsCpy[heading].forEach((option) => {
        option.selected = false;
      });
    }
    setSpecificAdditionalFilters([]);
    setDataSetGraphData([]);
    setDataSetOptions(dataSetOptionsCpy);
  };

  const filterOnlySelectedEntities = () => {
    return filterEntityBySelectedReportingYear()?.filter((entity) => entity.selected === true) || [];
  };

  // Clears filters, all applied filter and data set tables
  const clearFilters = () => {
    setAppliedFilters({ reportingYearFilters: [], entityFilters: [] });
    const updatedBodyTypes = setSelectedValue([...entityFilterSelection.bodyTypeFilters], false);
    const updatedPortfolios = setSelectedValue([...entityFilterSelection.portfolioFilters], false);
    const updatedEntities = setSelectedValue([...entityFilterSelection.entityFilters], false);
    setEntityFilterSelection({
      bodyTypeFilters: updatedBodyTypes,
      selectAllBodyTypes: false,
      portfolioFilters: updatedPortfolios,
      selectAllPortfolios: false,
      entityFilters: updatedEntities,
      selectAllEntities: false,
    });
    setShowOnlySelectedEntities(false);
    setUrlParams((prevParams) => {
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.REPORTING_YEAR}`, '');
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.ENTITY}`, '');
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.DATA_SETS}`, '');

      return prevParams;
    });

    const updatedReportingYears = setSelectedValue([...reportingYearFilterSelection.reportingYearFilters], false);
    setReportingFilterSelection({ reportingYearFilters: updatedReportingYears, selectAllReportingYears: false });
    removeDataSetInfo();
  };

  /**
   * Updates data template based on additional filters added (allows for filtering out data rows/cols from table)
   * @param {{filterTitle: String, filterSubtext: String, filterOptions: [{display: String, returnValue: string}], dataSets: [String]}[]} additionalFilters - Additional filter list
   * @param {{columns: Array, rows: Array}} templateTable - Template structure for table
   * @returns New template table structure with any rows/columns that were deselected in the additional filters
   */
  const updateAdditionalFilteringInTemplate = (additionalFilters, templateTable) => {
    let allAdditionalFilters = [];
    additionalFilters.forEach((additionalFilter) => {
      allAdditionalFilters = allAdditionalFilters.concat(
        additionalFilter.filterOptions?.map((filterOption) => {
          return { ...filterOption };
        }),
      );
    });
    const additionalFiltersToExclude = allAdditionalFilters.filter((option) => option.selected === false)?.map((option) => option.returnValue) || [];
    const filteredColumns = templateTable?.columns?.filter((col) => {
      return !additionalFiltersToExclude.includes(col.codename);
    });

    const filteredTemplateTable = {
      ...templateTable,
      columns: filteredColumns.map((col) => {
        return {
          ...col,
          subColumns: col.subColumns?.filter((subCol) => !additionalFiltersToExclude.includes(subCol.codename)) || [],
        };
      }),
      rows: templateTable?.rows?.filter((row) => !additionalFiltersToExclude.includes(row.codename)) || [],
    };
    return filteredTemplateTable;
  };

  const attainEnityGraphData = (dataSetTemplateResponse, entityGroupedData) => {
    // filter data set template response with specific additional filters
    const subColumnsHaveKeys = dataSetTemplateResponse?.columns?.some((col) => col.subColumns?.some((subCol) => subCol.key)); // Only construct csv for sub cols is any of them have key value
    const entityGraphData = Object.keys(entityGroupedData).map((key) => {
      const columnValues = [];
      entityGroupedData[key]?.forEach((dataSet) => {
        if (subColumnsHaveKeys) {
          dataSetTemplateResponse.columns.forEach((col) => {
            columnValues.push({
              filterKey: col.codename,
              heading: `${col.display} - ${dataSet.reportingPeriod}`,
              data:
                dataSetTemplateResponse?.rows
                  ?.map((row) => {
                    // If is row heading, don't render any values here for the graph
                    if (row.isRowHeading) {
                      return null;
                    }
                    const specificGraphSection = {
                      name: row.display,
                    };
                    col.subColumns?.forEach((subCol) => {
                      specificGraphSection[subCol.display] =
                        parseInt(
                          dataSet?.datafields?.[
                            subCol.key ? `${row.key?.toLowerCase()}|${col.key?.toLowerCase()}-${subCol.key?.toLowerCase()}` : `${row.key?.toLowerCase()}|${col.key?.toLowerCase()}`
                          ],
                        ) || 0;
                    });
                    return specificGraphSection;
                  })
                  ?.filter(Boolean) || [],
            });
          });
        } else {
          columnValues.push({
            width: dataSetTemplateResponse.graphWidth || null,
            heading: `${dataSet.reportingPeriod}`,
            data:
              dataSetTemplateResponse?.rows
                ?.map((row) => {
                  // If is row heading, don't render any values here for the graph
                  if (row.isRowHeading) {
                    return null;
                  }
                  const specificGraphSection = {
                    name: row.display,
                  };

                  dataSetTemplateResponse.columns.forEach((col) => {
                    specificGraphSection[col.display] = parseInt(dataSet?.datafields?.[`${row.key?.toLowerCase()}|${col.key?.toLowerCase()}`]) || 0;
                  });
                  return specificGraphSection;
                })
                .filter(Boolean) || [],
          });
        }
      });
      return {
        chartGroupHeading: key,
        chartData: columnValues,
      };
    });
    return entityGraphData;
  };

  const getEntitiesNotShowingData = (entities, dataSetResponse) => {
    let entitiesNotDisplaying = [];
    entities.map((entity) => {
      let notDisplayingEntity = false;
      let entityIncluded = false;
      dataSetResponse.map((returnedEntity, index) => {
        if (returnedEntity.entityCodeName === entity.returnValue) entityIncluded = true;
        if (
          (returnedEntity.tableType === DATA_TABLE_TYPES.APPEND && notDisplayingEntity === false && entityIncluded === false) ||
          (notDisplayingEntity === false && entityIncluded === false && index === dataSetResponse.length - 1)
        ) {
          entitiesNotDisplaying.push(entity.display);
          notDisplayingEntity = true;
        }
      });
    });
    return entitiesNotDisplaying;
  };

  const groupedEntityData = (dataSetResponse) => {
    const entityGroupedData = {};
    dataSetResponse.forEach((specificEntityYear) => {
      if (specificEntityYear.entity) {
        if (!entityGroupedData[specificEntityYear.entity]) {
          entityGroupedData[specificEntityYear.entity] = [specificEntityYear];
        } else {
          entityGroupedData[specificEntityYear.entity].push(specificEntityYear);
        }
      }
    });
    return entityGroupedData;
  };

  const fetchDataSetsGraph = async (dataSetCodename, reportingYears, entities) => {
    let tableResponse = null;
    try {
      setFetchDataSetsLoading(true);
      const reportingYearCodenames = reportingYears.map((filterValue) => filterValue.display);
      const entityCodenames = entities.map((filterValue) => filterValue.returnValue);
      const dataSetResponse = await getDataSets(dataSetCodename, reportingYearCodenames, entityCodenames);
      const dataSetTemplateResponse = await getDataTableTemplate(dataSetCodename);

      if (dataSetResponse && !dataSetResponse.isError && dataSetTemplateResponse && !dataSetTemplateResponse.isError) {
        setDefaultValue(dataSetTemplateResponse?.defaultValue || '');
        setdataSetTemplate(dataSetTemplateResponse?.structure || {});
        setInitialDataSetResponse(Array.isArray(dataSetResponse) ? sortByObjectProperty(dataSetResponse, 'entity', 'reportingPeriod', true) : []);
        const entityGroupedData = groupedEntityData(dataSetResponse);

        // check if data set is invalid
        if (dataSetTemplateResponse?.structure?.type === DATA_TABLE_TYPES.APPEND) setQualitativeData(true);
        else setQualitativeData(false);

        // set entities that can't show data
        let entitiesNotDisplaying = getEntitiesNotShowingData(entities, dataSetResponse);

        setEntitiesNotRequiredToReport(entitiesNotDisplaying);
        // check if using dataSetTemplate with specificFilters applied
        return attainEnityGraphData(dataSetTemplateResponse?.structure, entityGroupedData);
      }
      return tableResponse;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setFetchDataSetsLoading(false);
    }
  };

  const orderGraphData = (graphData) => {
    if (graphData && Array.isArray(graphData)) {
      // sort entities
      let sortedData = sortByObjectProperty(graphData, 'chartGroupHeading', null, true);
      // sort reporting years
      sortedData?.map((entity) => {
        const sortedYears = sortByObjectProperty(entity?.chartData, 'heading', null, true).reverse();
        entity.chartData = sortedYears;
      });
      return sortedData;
    }
    return [];
  };

  const applyFuntionality = async () => {
    const updatedFilters = {
      chosenDataSet: dataSet,
      reportingYearFilters: [...reportingYearFilterSelection.reportingYearFilters].filter((option) => option.selected === true),
      entityFilters: [...entityFilterSelection.entityFilters].filter((option) => option.selected === true),
    };
    setAppliedFilters(updatedFilters);
    const filteredAdditionalFilters = dataSetAdditionalFilters(dataSet?.codename, allAdditionalFilters);
    setSpecificAdditionalFilters(filteredAdditionalFilters?.length > 0 ? filteredAdditionalFilters : []);

    // set url params
    setUrlParams((prevParams) => {
      // set reporting years
      let selectedReportingYears = [];
      const RYFilters = reportingYearFilterSelection.reportingYearFilters;
      for (let i = 0; i < RYFilters.length; ++i) {
        if (RYFilters[i].selected) selectedReportingYears.push(RYFilters[i].returnValue);
      }
      selectedReportingYears = selectedReportingYears?.join(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.REPORTING_YEAR}`, selectedReportingYears || '');

      // set entities
      const entityFilters = entityFilterSelection.entityFilters;
      let selectedEntities = [];
      for (let i = 0; i < entityFilters.length; ++i) {
        if (entityFilters[i].selected) selectedEntities.push(entityFilters[i].returnValue);
      }
      selectedEntities = selectedEntities?.join(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
      // check if all entities selected
      if (numberOfDropdownOptionsSelected(entityFilterSelection.entityFilters) === 'All')
        prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.ENTITY}`, 'allEntities');
      else prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.ENTITY}`, selectedEntities || '');

      // set data set
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.DATA_SETS}`, dataSet?.returnValue || '');
      return prevParams;
    });

    if (dataSet?.returnValue && updatedFilters?.reportingYearFilters && updatedFilters?.entityFilters) {
      const graphData = await fetchDataSetsGraph(dataSet.returnValue, updatedFilters.reportingYearFilters, updatedFilters?.entityFilters);
      setDataSetGraphData(orderGraphData(graphData));
    }
  };

  // Apply filters
  const applyFilters = async (e) => {
    e.preventDefault();
    applyFuntionality();
  };

  const selectDataSet = (selectedOption, givenDataSetOptions = null) => {
    // update data set and data set options
    setDataSet(selectedOption);
    setDataSetSelected(true);

    let dataSetOptionsCpy = givenDataSetOptions ? givenDataSetOptions : dataSetOptions;
    for (const heading in dataSetOptionsCpy) {
      dataSetOptionsCpy[heading].forEach((option) => {
        if (selectedOption?.returnValue === option?.returnValue) {
          option.selected = !option.selected;
        }
      });
    }

    setDataSetOptions(dataSetOptionsCpy);
  };

  // Checks which additional filters are allowed for given data set
  const dataSetAdditionalFilters = (dataSetId, additionalFilters) => {
    return additionalFilters.filter((filterGroup) => {
      return filterGroup?.dataSets?.includes(dataSetId);
    });
  };

  const accordianIsRequired = () => {
    // if reporting years and entity are provided, return false
    if (atLeastOneEntitySelected() && numberOfDropdownOptionsSelected(reportingYearFilterSelection.reportingYearFilters) !== '0') return false;
    return true;
  };

  const graphHeading = () => {
    if (appliedFilters?.reportingYearFilters?.length === 0) return '';

    let reportingYearsBuffer = '';
    for (let i = 0; i < appliedFilters?.reportingYearFilters?.length; ++i) {
      if (i === 0) reportingYearsBuffer = appliedFilters?.reportingYearFilters?.[i].display;
      else reportingYearsBuffer += ', ' + appliedFilters?.reportingYearFilters?.[i].display;
    }

    return appliedFilters?.chosenDataSet?.display + ', ' + reportingYearsBuffer;
  };

  const givenElementExists = (element, list) => {
    let result = false;
    list.map((item) => {
      if (item?.returnValue === element) result = true;
    });
    return result;
  };

  const givenDataSetExists = (dataSetReturnValue, list) => {
    let result = false;
    for (const heading in list) {
      list[heading].forEach((set) => {
        if (set.returnValue === dataSetReturnValue) result = true;
      });
    }
    return result;
  };

  const URLReportingYearData = (urlReportingYearOptionsList, reportingYearFilters) => {
    let RYArgsNotAvailable = [];
    let RYArgsAvailable = [];
    urlReportingYearOptionsList?.map((year) => {
      if (givenElementExists(year, reportingYearFilters)) RYArgsAvailable.push(year);
      else RYArgsNotAvailable.push(year);
    });

    // remove unusable reporting years from filter
    if (RYArgsNotAvailable.length > 0) {
      setUrlParams((prevParams) => {
        RYArgsAvailable?.join(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
        prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.REPORTING_YEAR}`, RYArgsAvailable || '');
        return prevParams;
      });
    }

    return RYArgsAvailable;
  };

  const URLEntitiesData = (urlEntityOptionsList, entityFilters) => {
    if (urlEntityOptionsList[0] === 'allEntities') return ['allEntities'];
    let EntityArgsNotAvailable = [];
    let EntityArgsAvailable = [];
    urlEntityOptionsList?.map((entity) => {
      if (givenElementExists(entity, entityFilters)) EntityArgsAvailable.push(entity);
      else EntityArgsNotAvailable.push(entity);
    });

    // remove unusable entities from filter
    if (EntityArgsNotAvailable.length > 0) {
      setUrlParams((prevParams) => {
        EntityArgsAvailable?.join(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
        prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.ENTITY}`, EntityArgsAvailable || '');
        return prevParams;
      });
    }

    return EntityArgsAvailable;
  };

  /**
   * Updates the graph data based on additional filter selection
   * @param {{returnValue: String, display: String, selected: Boolean}} selectedOption - Additional fitler option selected
   */
  const filterSelect = (selectedOption) => {
    // toggle box being ticked
    if (selectedOption?.selected === undefined) selectedOption.selected = false;
    if (selectedOption.selected === true) {
      selectedOption.selected = false;
    } else {
      selectedOption.selected = true;
    }

    // update template
    const updatedTemplate = updateAdditionalFilteringInTemplate(specificAdditionalFilters, dataSetTemplate);
    // attain and update filtered graph data

    // use saved initial data set
    const entityGroupedData = groupedEntityData(initialDataSetResponse);
    const graphData = attainEnityGraphData(updatedTemplate, entityGroupedData);
    setDataSetGraphData(graphData);
  };

  const downloadCSV = () => {
    new Promise((resolve) => {
      if (dataSetTemplate.type === DATA_TABLE_TYPES.FIXED) {
        const updatedTemplate = updateAdditionalFilteringInTemplate(specificAdditionalFilters, dataSetTemplate);
        const csvData = constructCSVFixedTable(initialDataSetResponse, updatedTemplate, defaultValue) || [];
        setExportCSVData(csvData);
        resolve();
      }
    }).then(() => {
      exportCSVButton.current?.link?.click();
    });
  };

  const currentSelectionSameFromAppliedFilters = () => {
    const currentChosenDataSet = dataSet;
    const currentReportingYearFilters = [...reportingYearFilterSelection.reportingYearFilters].filter((option) => option.selected === true);
    const currentEntityFilters = [...entityFilterSelection.entityFilters].filter((option) => option.selected === true);

    const isDataSetFilterSame = currentChosenDataSet?.returnValue === appliedFilters.chosenDataSet?.returnValue;
    const isReportingYearFilterSame =
      currentReportingYearFilters.length === appliedFilters.reportingYearFilters.length &&
      appliedFilters.reportingYearFilters.every((element) => currentReportingYearFilters.some((option) => option.returnValue === element.returnValue));
    const isEntityFilterSame =
      currentEntityFilters.length === appliedFilters.entityFilters.length &&
      appliedFilters.entityFilters.every((element) => currentEntityFilters.some((option) => option.returnValue === element.returnValue));

    return isDataSetFilterSame && isReportingYearFilterSame && isEntityFilterSame;
  };

  const hasAppliedFilters = () => {
    return appliedFilters.chosenDataSet?.returnValue && appliedFilters.entityFilters.length > 0 && appliedFilters.reportingYearFilters.length > 0;
  };

  const getDataSet = (returnValue, givenDataSetOptions) => {
    let dataSetFound = null;
    for (const heading in givenDataSetOptions) {
      givenDataSetOptions[heading].forEach((option) => {
        if (option.returnValue === returnValue) dataSetFound = option;
      });
    }
    return dataSetFound;
  };

  return (
    <>
      <DataSetsHeader visualisedView={true} />
      <Suspense fallback={<DataSetsLoading isVisualisation></DataSetsLoading>}>
        <Await resolve={dataSets}>
          {(resolvedData) => {
            if (resolvedData.isError) {
              return <span className={style.failedMessage}>{SOMETHING_WENT_WRONG}</span>;
            }

            const { dataSetFilters, reportingYearFilters, bodyTypeFilters, portfolioFilters, entityFilters, additionalFilters } = resolvedData;
            const filteredDataSetsToBeVisualised = {};
            Object.keys(dataSetFilters).map((key) => {
              filteredDataSetsToBeVisualised[key] = dataSetFilters[key].filter((filterValue) => filterValue.canBeVisualised === 'True');
            });

            const dataSetFiltersCpy = structuredClone(filteredDataSetsToBeVisualised);
            const reportingYearFiltersCpy = structuredClone(reportingYearFilters);
            const bodyTypeFiltersCpy = structuredClone(bodyTypeFilters);
            const portfolioFiltersCpy = structuredClone(portfolioFilters);
            const entityFiltersCpy = structuredClone(entityFilters);
            const additionalFiltersCpy = structuredClone(additionalFilters);

            useEffect(() => {
              if (additionalFiltersCpy) setAllAdditionalFilters(additionalFiltersCpy);
            }, []);

            useEffect(() => {
              const givenDataSet = urlParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.DATA_SETS}`) || {};
              const urlReportingYearOptionsList =
                urlParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.REPORTING_YEAR}`)?.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY) || [];
              const urlEntityOptionsList =
                urlParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.ENTITY}`)?.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY) || [];

              let RYArgsAvailable = URLReportingYearData(urlReportingYearOptionsList, reportingYearFiltersCpy);
              let EntityArgsAvailable = URLEntitiesData(urlEntityOptionsList, entityFiltersCpy);
              let AlteredEntityArgs = [];
              EntityArgsAvailable.map((codename) => AlteredEntityArgs.push({ returnValue: codename }));

              if (!givenDataSetExists(givenDataSet, dataSetFiltersCpy)) {
                setUrlParams((prevParams) => {
                  prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.DATA_SETS}`, '');
                  return prevParams;
                });
              }
              // update selected filters
              if (RYArgsAvailable.length > 0) selectReportingYearsDropdown(RYArgsAvailable, { ...reportingYearFilterSelection, reportingYearFilters: reportingYearFiltersCpy });
              else if (reportingYearFilters) setReportingFilterSelection({ ...reportingYearFilterSelection, reportingYearFilters: reportingYearFiltersCpy });

              if (EntityArgsAvailable.length > 0 && EntityArgsAvailable[0] !== 'allEntities') {
                selectMultipleEntities(AlteredEntityArgs, null, {
                  ...entityFilterSelection,
                  bodyTypeFilters: bodyTypeFiltersCpy,
                  portfolioFilters: portfolioFiltersCpy,
                  entityFilters: entityFiltersCpy,
                });
              } else if (EntityArgsAvailable[0] === 'allEntities') {
                // select all entities and set all entities to true
                selectAllEntitiesFunc({ ...entityFilterSelection, bodyTypeFilters: bodyTypeFiltersCpy, portfolioFilters: portfolioFiltersCpy, entityFilters: entityFiltersCpy });
              } else if (bodyTypeFilters && portfolioFilters && entityFilters) {
                setEntityFilterSelection({ ...entityFilterSelection, bodyTypeFilters: bodyTypeFiltersCpy, portfolioFilters: portfolioFiltersCpy, entityFilters: entityFiltersCpy });
              }

              const dataSetFound = getDataSet(givenDataSet, dataSetFiltersCpy);
              if (dataSetFound) selectDataSet(dataSetFound, dataSetFiltersCpy);
              else if (dataSetFilters) setDataSetOptions(dataSetFiltersCpy);

              // set apply filters
              setApplyChanges(true);
            }, [pathname]);

            useEffect(() => {
              if (applyChanges) {
                // only apply changes if all items provided
                if (atLeastOneEntitySelected() && atLeastOneReportingYearSelected() && dataSetSelected) {
                  applyFuntionality();
                  setApplyChanges(false);
                }
              }
            }, [applyChanges]);

            const additionalFiltersNode = useMemo(
              () => (
                <>
                  {appliedFilters ? (
                    <div className={style.additionalFilterWrapper}>
                      {specificAdditionalFilters?.map((filterGroup, index) => {
                        return (
                          <FormField
                            key={`${filterGroup}-${index}`}
                            title={filterGroup?.filterTitle}
                            showSubText
                            boldedSubText={numberOfDropdownOptionsSelected(filterGroup.filterOptions)}
                            subText={`${filterGroup.filterSubtext} selected`}>
                            <DropdownSelect
                              isMultiSelect
                              renderAsFixed
                              dropdownOptions={filterGroup.filterOptions}
                              selectOptionFunction={(selectedOption) => filterSelect(selectedOption)}
                            />
                          </FormField>
                        );
                      })}
                    </div>
                  ) : null}
                </>
              ),
              [specificAdditionalFilters, dataSetGraphData],
            );
            useEffect(() => {
              console.log(graphHeading());
              console.log(dataSetGraphData);
              console.log(additionalFiltersNode);
              console.log(urlParams.toString());
            }, [dataSetGraphData, additionalFiltersNode, urlParams]);

            return (
              <>
                <form className={style.filterContainer} onSubmit={(e) => e.preventDefault()}>
                  <DataSetsFilterAccordion title="Reporting year, entities or companies and data sets" isRequired={accordianIsRequired()}>
                    <div className={[style.reportingYearFilterContainer, !atLeastOneReportingYearSelected() && style.bottomMargin].join(' ')}>
                      <div className={style.reportingYearDropdown}>
                        <FormField
                          title="Select reporting years"
                          isRequired={numberOfDropdownOptionsSelected(reportingYearFilterSelection.reportingYearFilters) === '0'}
                          showSubText
                          boldedSubText={numberOfDropdownOptionsSelected(reportingYearFilterSelection.reportingYearFilters)}
                          subText="reporting years selected">
                          <DropdownSelect
                            dropdownOptions={reportingYearFilterSelection.reportingYearFilters}
                            selectOptionFunction={selectReportingYearDropdown}
                            isMultiSelect
                            updateDropdownOptionList={atLeastOneYearSelected}
                            renderAsFixed>
                            <div className={style.listSelectSelectAll}>
                              <label htmlFor={'selectAllReportingYears'} className={style.selectAllCheckbox}>
                                <input
                                  checked={reportingYearFilterSelection.selectAllReportingYears}
                                  id={'selectAllReportingYears'}
                                  type={'checkbox'}
                                  onChange={selectAllReportingYears}
                                />
                                Select all
                              </label>
                            </div>
                          </DropdownSelect>
                        </FormField>
                        <button className={['noFill', style.clearButton].join(' ')} onClick={clearReportingYearSelection}>
                          Clear
                        </button>
                      </div>
                    </div>
                    {atLeastOneReportingYearSelected() && (
                      <>
                        <div className={style.entityFilterContainer}>
                          <div className={style.bodyTypePortfolioContainer}>
                            <div className={style.titleArea}>
                              <span className={style.filterTitle}>Filter by body type or portfolio.</span>
                              <br></br>
                              <span>Selecting a body type and/or a portfolio here will preselect entities or companies in the ‘Select an entity or company’ field.</span>
                            </div>
                            <FormField
                              title="Body type"
                              showSubText
                              boldedSubText={numberOfDropdownOptionsSelected(entityFilterSelection.bodyTypeFilters)}
                              subText="body types selected">
                              <DropdownSelect
                                placeholderText="Edit selection"
                                dropdownOptions={entityFilterSelection.bodyTypeFilters}
                                selectOptionFunction={selectBodyTypeFilter}
                                renderAsFixed
                                isMultiSelect>
                                <div className={style.listSelectSelectAll}>
                                  <label htmlFor={'selectAllBodyType'} className={style.selectAllCheckbox}>
                                    <input
                                      className={!allBodyTypesSelected() && entityFilterSelection.selectAllBodyTypes ? 'dashCheckbox' : ''}
                                      checked={entityFilterSelection.selectAllBodyTypes}
                                      id={'selectAllBodyType'}
                                      type={'checkbox'}
                                      onChange={selectAllBodyTypesHandler}
                                    />
                                    Select all
                                  </label>
                                </div>
                              </DropdownSelect>
                            </FormField>
                            <FormField
                              title="Portfolio"
                              showSubText
                              boldedSubText={numberOfDropdownOptionsSelected(entityFilterSelection.portfolioFilters)}
                              subText="portfolios selected">
                              <DropdownSelect
                                placeholderText="Type to search for a portfolio..."
                                dropdownOptions={entityFilterSelection.portfolioFilters}
                                selectOptionFunction={selectPortfolioFilterOption}
                                renderAsFixed
                                inputEnabled
                                isMultiSelect>
                                <div className={style.listSelectSelectAll}>
                                  <label htmlFor={'selectAllPortfolios'} className={style.selectAllCheckbox}>
                                    <input
                                      className={!allPortfoliosSelected() && entityFilterSelection.selectAllPortfolios ? 'dashCheckbox' : ''}
                                      checked={entityFilterSelection.selectAllPortfolios}
                                      id={'selectAllPortfolios'}
                                      type={'checkbox'}
                                      onChange={selectAllPortfoliosHandler}
                                    />
                                    Select all
                                  </label>
                                </div>
                              </DropdownSelect>
                            </FormField>
                          </div>
                          <div className={style.entityOptionsContainer}>
                            <FormField title="Select an entity or company" isRequired={!atLeastOneEntitySelected()}>
                              <AlphabetGroupFilterList
                                dropdownOptions={showOnlySelectedEntities ? filterOnlySelectedEntities() : filterEntityBySelectedReportingYear()}
                                hideAlphabetButtons
                                selectAllState={entityFilterSelection.selectAllEntities}
                                searchPlaceholder="Type to search for a entity or company..."
                                selectMultipleOptionFunction={selectMultipleEntities}
                                selectOptionFunction={selectEntityOption}
                                extraStyleClass={style.fullWidth}
                                extraListStyleClass={style.groupedListStyle}></AlphabetGroupFilterList>
                            </FormField>
                          </div>
                        </div>
                        <div className={style.entitySelectionControlButtons}>
                          <button className="noFill" onClick={clearEntitySelection}>
                            Clear
                          </button>
                          <label className={['buttonStyle lightFill', !atLeastOneEntitySelected() && style.disabledAbolishButton].join(' ')}>
                            <input
                              id="abolishedButton"
                              disabled={!atLeastOneEntitySelected()}
                              type="checkbox"
                              checked={showOnlySelectedEntities}
                              onChange={() => setShowOnlySelectedEntities(!showOnlySelectedEntities)}
                            />
                            Show only selected entities
                          </label>
                        </div>
                        <div className={style.dataSetContainer}>
                          <div className={style.dataSetContent}>
                            <span className={[style.filterTitle, style.title, !dataSetSelected && style.required].join(' ')}>Select a data set</span>
                            <span className={style.dataSetBody}>
                              Tags have been used to represent which body types are required to report.
                              <br />
                              <br />
                              Source: This data has been provided by Commonwealth entities and companies to represent a subset of their published annual report.
                            </span>
                            <div className={style.outerFinancialContent}>
                              <GroupedFilterList
                                isMultiSelect={false}
                                isRadioSelect={true}
                                groupedDropdownOptions={dataSetOptions}
                                selectOptionFunction={selectDataSet}
                                extraStyleClass={style.nonFinancialContent}
                                selectedOption={dataSet}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </DataSetsFilterAccordion>
                  <div className={style.applyFilters}>
                    <p>
                      Selecting {dataSetSelected ? '1' : '0'} data set(s) for{' '}
                      <strong>{numberOfDropdownOptionsSelected(filterEntityBySelectedReportingYear())} entities and companies</strong>
                      {reportingYearsSelectedText() && ' between '}
                      <strong>{reportingYearsSelectedText()}</strong>
                    </p>
                    <CSVLink
                      ref={exportCSVButton}
                      data={exportCSVData}
                      uFEFF={false}
                      filename={`Data set for ${appliedFilters?.chosenDataSet?.display} during ${appliedFilters.reportingYearFilters
                        ?.map((option) => option.display)
                        ?.join(', ')}.csv`}></CSVLink>
                    <div className={style.groupedButtons}>
                      <div className={style.filterButtons}>
                        <button onClick={clearFilters} className="noFill">
                          Clear
                        </button>
                        <button disabled={!atLeastOneEntitySelected() || !atLeastOneReportingYearSelected() || !dataSetSelected} onClick={applyFilters}>
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    className={['lightFill', style.exportAllCSV].join(' ')}
                    onClick={downloadCSV}
                    disabled={!currentSelectionSameFromAppliedFilters() || !hasAppliedFilters() || fetchDataSetsLoading || !(dataSetGraphData?.length > 0)}>
                    Export as CSV
                  </button>
                </form>
                {qualitativeData && appliedFilters.entityFilters.length > 0 && !fetchDataSetsLoading && (
                  <div className={style.messageBox}>
                    <MessageBox title="Data set cannot be visualised">
                      <p>The data provided in this data set is primarily qualitative and is not required to be displayed</p>
                    </MessageBox>
                  </div>
                )}
                {!qualitativeData && appliedFilters.entityFilters.length > 0 && !(dataSetGraphData?.length > 0) && !fetchDataSetsLoading && (
                  <div className={style.messageBox}>
                    <MessageBox title="Data set not found">
                      <p>The data for this data set is unavailable</p>
                    </MessageBox>
                  </div>
                )}
                {!qualitativeData && appliedFilters.entityFilters.length > 0 && entitiesNotRequiredToReport.length > 0 && !fetchDataSetsLoading && (
                  <div className={style.messageBox}>
                    <MessageBox title="About this data" type={MESSAGE_BOX_TYPE.INFORMATION}>
                      <span>The following entities or companies are not required to report: </span>
                      <span className={style.entitiesNotRequiredBold}>{entitiesNotRequiredToReport.join(', ')}</span>
                    </MessageBox>
                  </div>
                )}
                {fetchDataSetsLoading ? (
                  <>
                    <Loading className={style.loadingDataGraphFilters} />
                    <Loading className={style.loadingDataGraphs} />
                  </>
                ) : (
                  <GraphView
                    heading={graphHeading()}
                    dataSet={dataSetGraphData?.length > 0 ? dataSetGraphData : []}
                    additionalFilters={specificAdditionalFilters?.length > 0 ? additionalFiltersNode : null}
                    urlParams={urlParams.toString()}
                  />
                )}
              </>
            );
          }}
        </Await>
      </Suspense>
    </>
  );
}

export default DataSetsVisualised;
