import React, { useCallback, useEffect, useId, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import { LinkCopy, Loading } from 'Components';
import { getCall } from 'Services';
import { CONSTANTS } from 'Constants';
import { debounce, detectViewMode } from 'Utils';
import style from './PdfViewer.module.scss';
import sprite from 'Assets/svgSprite.svg';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js`;

/**
 * PdfRender
 *
 * @summary This is actual pdf component.
 * @param {Object} props - Component props.
 * @prop {Object} props.pdfSrc - PDF file data fetched from API in Unit8Array format.
 * @prop {String} [props.fileName] - PDF file name. If provided, download button will download pdf directly, else, open in new tab.
 */
function PdfRender({ data, pdfSrc, fileName }) {
  const [numPages, setNumPages] = useState(null);
  const [page, setPage] = useState('1');
  const [pageNumber, setPageNumber] = useState('1');
  const [scale, setScale] = useState('100');
  const [pageScale, setPageScale] = useState('100');
  const [isDownloading, setIsDownloading] = useState(false);
  const [viewMode, setViewMode] = useState(detectViewMode());
  const [pageWidth, setPageWidth] = useState(null);
  const [pageRendered, setPageRendered] = useState(false);
  const pdfViewerId = useId();
  const pdfData = useMemo(() => {
    return { data: data.slice(0) };
  }, [data]);
  const commonDocumentOptions = useMemo(() => ({ withCredentials: true }), []);
  const documentRef = useRef();
  const pageRefs = useRef([]);

  useLayoutEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  useLayoutEffect(() => {
    const documentArea = documentRef.current;
    setPageWidth(documentArea.offsetWidth - 36);
  }, [viewMode]);

  useLayoutEffect(() => {
    setPageRendered(false);
  }, [pageWidth]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const inputChangePage = (event) => {
    const newPageNum = Number(event.target.value);

    if (newPageNum >= 0 && newPageNum <= Number(numPages)) {
      setPage(newPageNum.toString());
    }
  };

  const applyPageNumberChange = useCallback(() => {
    if (Number(page) === 0) {
      // if page number value is 0 on blur, set page number to 1
      setPageNumber('1');
    } else {
      // if input value has leading 0 (eg: 01 or 03), remove leading 0
      setPageNumber(page.toString());
    }

    // auto scroll to target page
    const targetPage = document?.querySelector(`[data-page-number="${page}"]`);

    if (documentRef?.current && targetPage) {
      // add 16 to target page offset as there is a top 16px padding on document wrapper (documentRef)
      documentRef.current.scrollTo({ top: targetPage.offsetTop + 16 });
    }
  }, [page, pageNumber, viewMode]);

  const pageChangeKeyUpHandler = useCallback(
    (event) => {
      if (event.key === CONSTANTS.ENTER_KEY) {
        applyPageNumberChange();
      }
    },
    [page, pageNumber, viewMode],
  );

  const inputChangeZoom = (event) => {
    const newZoomLevelPercentage = Number(event.target.value);

    if (newZoomLevelPercentage >= 0) {
      setScale(newZoomLevelPercentage);
    }
  };

  const applyZoomLevel = useCallback(() => {
    const scaleNumber = Number(scale);
    const pageScaleNumber = Number(pageScale);

    if (scaleNumber === 0) {
      // if zoom level value is 0 on blur, set to 100
      setScale(100);
      setPageScale('100');
    } else {
      // if zoom level value has leading 0 (eg: 0100 or 0150), remove leading 0
      setPageScale(scale.toString());
    }

    if (scaleNumber !== pageScaleNumber && !(scaleNumber === 0 && pageScaleNumber === 100)) {
      setPageRendered(false);
    }
  }, [scale, pageScale]);

  const zoomKeyUpHanlder = useCallback(
    (event) => {
      if (event.key === CONSTANTS.ENTER_KEY) {
        applyZoomLevel();
      }
    },
    [scale, pageScale],
  );

  const handleOnScroll = useCallback(() => {
    const rangeSet = [];
    pageRefs.current.forEach((el, i) => {
      if (i === 0) {
        rangeSet.push({ start: 0, end: 16 + el?.offsetHeight - 1, pageNumber: String(i + 1) });
      } else {
        const thisPageStartPosition = rangeSet?.[i - 1]?.end + 1;
        rangeSet.push({ start: thisPageStartPosition, end: thisPageStartPosition + el?.offsetHeight - 1, pageNumber: String(i + 1) });
      }
    });
    const currentViewPageDetector = Number(event.target.scrollTop + event.target.offsetHeight / 2);
    const currentInRangePage = rangeSet.filter((range) => currentViewPageDetector >= range.start && currentViewPageDetector <= range.end)?.[0]?.pageNumber;
    if (currentInRangePage) {
      setPage(currentInRangePage);
    }
  }, [pageRefs.current, viewMode]);

  const handleDownload = () => {
    setIsDownloading(true);
    getCall(pdfSrc, { responseType: 'blob' })
      .then((res) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(fileURL);
        link.remove();
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <div id={pdfViewerId} className={`${style.pdfViewer} linkCopyWrapper`}>
      <div className={style.menu}>
        <div id={style.pageZoomControl}>
          Zoom
          <div className={style.inputWrapper}>
            <input
              disabled={!pdfData}
              type="number"
              value={scale}
              onChange={inputChangeZoom}
              onBlur={applyZoomLevel}
              onKeyUp={zoomKeyUpHanlder}
              aria-label="current zoom level in percentage"
            />
            %
          </div>
        </div>

        <div id={style.pageNavigationControl}>
          <div className={style.inputWrapper}>
            <input
              disabled={!pdfData}
              id={style.currentPageNumber}
              type="number"
              value={page}
              min={1}
              max={numPages}
              step={1}
              onChange={inputChangePage}
              onBlur={applyPageNumberChange}
              onKeyUp={pageChangeKeyUpHandler}
              aria-label="current page number"
            />
            /<span>{numPages ?? '--'}</span>
          </div>
        </div>

        <button
          disabled={!pdfData}
          id={style.download}
          aria-label="Download PDF"
          className="lightFill"
          onClick={handleDownload}
          title={isDownloading ? 'Downloading PDF' : 'Download PDF'}>
          {isDownloading ? (
            <span className="spinner">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
          ) : (
            <svg>
              <use href={`${sprite}#download`} />
            </svg>
          )}
        </button>
      </div>

      <div className={style.documentWrapper} ref={documentRef} onScroll={handleOnScroll}>
        {pdfData && (
          <Document className={style.document} file={pdfData} onLoadSuccess={onDocumentLoadSuccess} options={commonDocumentOptions}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                canvasRef={(el) => (pageRefs.current[index] = el)}
                pageNumber={index + 1}
                className={style.page}
                scale={viewMode !== CONSTANTS.VIEW_MODE.MOBILE ? Number(pageScale) / 100 || 1 : null}
                width={pageWidth}
                loading={() => setPageRendered(false)}
                onRenderSuccess={() => setPageRendered(true)}
              />
            ))}
          </Document>
        )}
      </div>

      {!pageRendered && (
        <div className={style.loadingIndicator}>
          <span className="spinner">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </span>
          Loading PDF
        </div>
      )}
      <LinkCopy targetId={pdfViewerId} />
    </div>
  );
}

PdfRender.propTypes = {
  data: PropTypes.object.isRequired,
  pdfSrc: PropTypes.string.isRequired,
  fileName: PropTypes.string,
};

/**
 * PdfViewer.jsx
 *
 * @summary Render as PDF viewer.
 *
 * @param {Object} props - Component props.
 * @prop {String} props.pdfSrc - PDF file source.
 * @prop {String} [props.fileName] - PDF file name. If provided, download button will download pdf directly, else, open in new tab.
 * @prop {Boolean} [props.setPdfPrintReady] - Optional Param, allows pdfPrintReady to be set to true for download in AnnualReportArticle.jsx
*/
function PdfViewer({ pdfSrc, fileName, setPdfPrintReady=false }) {
  const [pdfData, setPdfData] = useState(null);
  const [pdfApiFailed, setPdfApiFailed] = useState(false);

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        const res = await getCall(pdfSrc, {
          responseType: 'arraybuffer',
        });
        setPdfData(new Uint8Array(res));
        setPdfPrintReady(true);
      } catch (err) {
        console.error(err);
        setPdfApiFailed(true);
        setPdfPrintReady(true);
      }
    };

    fetchPDF();
  }, [pdfSrc]);

  /**
   * pass unit8array pdf file to component instead handle pdf component logic in `PdfViewer` for performance reason as we want
   * to use `useMemo` to cache downloaded pdf file within component while it gets rerendered and `useMemo` can not be used with
   * api call
   */
  if (pdfData) {
    return <PdfRender pdfSrc={pdfSrc} data={pdfData} fileName={fileName} />;
  }

  if (pdfApiFailed) {
    return <p className={style.notFoundText}>PDF file not found</p>;
  }

  return <Loading className={style.loading} />;
}

PdfViewer.propTypes = {
  pdfSrc: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  setPdfPrintReady: PropTypes.bool
};

export default PdfViewer;
