import React from 'react';
import PropTypes from 'prop-types';
import style from './ListSelect.module.scss';
import { Tag } from 'Components';
import { v4 as uuidv4 } from 'uuid';
import { TAG_POSITION_ENUMS } from 'Constants/Constants';

/**
 * ListSelect.jsx
 *
 * @summary Component for list selection functionality.
 *
 * @param {Object} props - Component props.
 * @prop {{display: String, returnValue: String, selected: Boolean, tags: [{display: String, type: String, tagPosition: Boolean}] }[]} props.dropdownOptions - List of dropdown options. Each option is an object that should have properties 'returnValue' and 'display', 'selected' (if multiselect) and 'tag'
 * @prop {Function} props.selectOptionFunction - Select function used to select an option.
 * @prop {String} [props.id] - String id of the component.
 * @prop {String} [props.selectedOption] - Currently selected option (gives currently selected option for non-multiselect).
 * @prop {Boolean} [props.isMultiSelect] - For switching between multiselect and single select.
 * @prop {Boolean} [props.isRadioSelect] - For styling of single select with radio buttons. By default render as checkbox if this prop is not given or false.
 * @prop {String} [props.extraContainerStyleClass] - Extra styling for container of list select items
 * @prop {String} [props.extraLabelStyleClass] - Extra styling specific for labels
 *
 * @description props.dropdownOptions may also contain a 'tag' object. This object has properties 'display', 'type' (for tag type) and 'isBefore' to specify the tag occuring before (appears on bottom is not specified)
 *
 */
function ListSelect({ id, dropdownOptions, selectOptionFunction, selectedOption, isMultiSelect, hideLastBorder, isRadioSelect, extraContainerStyleClass, extraLabelStyleClass, dataSetSelection = false}) {
  const tagPosition = (dropdownOptionTagPosition) => {
    if (dropdownOptionTagPosition === TAG_POSITION_ENUMS.LEFT) {
      return style.tagBeforeText;
    }
    if (dropdownOptionTagPosition === TAG_POSITION_ENUMS.RIGHT) {
      return style.tagAfterText;
    }
    return '';
  };
  if (dataSetSelection){
    return (
      <React.Fragment key={id} className={[style.listOptionsContainer, extraContainerStyleClass].join(' ')}>
        {dropdownOptions.map((dropdownOption, i) => {
          const dropdownInputId = uuidv4();
  
          const liStyles = [];
          liStyles.push(style.listSelectBase);
          if (selectedOption && dropdownOption.returnValue === selectedOption) {
            liStyles.push(style.singleSelected);
          }
          if (isMultiSelect) {
            liStyles.push(style.multiSelectList);
          }
          if (hideLastBorder) {
            liStyles.push(style.hideLastBorder);
          }
          
          return (
            <li className={liStyles.join(' ')} key={`dropdown-${dropdownOption.returnValue}-${i}`}>
              <label htmlFor={dropdownInputId} className={isMultiSelect ? '' : isRadioSelect ? style.radioSelect : style.noCheckbox}>
                <input
                  checked={isMultiSelect ? dropdownOption.selected : selectedOption?.returnValue === dropdownOption?.returnValue}
                  id={dropdownInputId}
                  type={isRadioSelect ? 'radio' : 'checkbox'}
                  onChange={() => selectOptionFunction(dropdownOption)}
                />
                <div className={[style.dropdownLabelContainer, tagPosition(dropdownOption?.tagInfo?.tagPosition), extraLabelStyleClass].join(' ')}>
                  <span className={[style.tagText, tagPosition(dropdownOption?.tagInfo?.tagPosition)].join(' ')}>{dropdownOption.display}</span>
                  <div className={[style.tags, tagPosition(dropdownOption?.tagInfo?.tagPosition), dropdownOption.tagInfo?.tags?.length > 0 && style.hasTags].join(' ')}>
                    {dropdownOption.tagInfo?.tags?.map((tag, index) => {
                      return <Tag key={`${tag.display}-${index}`} displayText={tag.display} type={tag.type} extraStyleClass={tagPosition(dropdownOption?.tagInfo?.tagPosition)} />;
                    })}
                  </div>
                </div>
              </label>
            </li>
          );
        })}
      </React.Fragment>
    );
  } else {
    return (
      <ul id={id} className={[style.listOptionsContainer, extraContainerStyleClass].join(' ')}>
        {dropdownOptions.map((dropdownOption, i) => {
          const dropdownInputId = uuidv4();
  
          const liStyles = [];
          liStyles.push(style.listSelectBase);
          if (selectedOption && dropdownOption.returnValue === selectedOption) {
            liStyles.push(style.singleSelected);
          }
          if (isMultiSelect) {
            liStyles.push(style.multiSelectList);
          }
          if (hideLastBorder) {
            liStyles.push(style.hideLastBorder);
          }
          
          return (
            <li className={[...liStyles, ].join(' ')} key={`dropdown-${dropdownOption.returnValue}-${i}`}>
              <label htmlFor={dropdownInputId} className={isMultiSelect ? '' : isRadioSelect ? style.radioSelect : style.noCheckbox}>
                <input
                  checked={isMultiSelect ? dropdownOption.selected : selectedOption?.returnValue === dropdownOption?.returnValue}
                  id={dropdownInputId}
                  type={isRadioSelect ? 'radio' : 'checkbox'}
                  onChange={() => selectOptionFunction(dropdownOption)}
                />
                <div className={[style.dropdownLabelContainer, tagPosition(dropdownOption?.tagInfo?.tagPosition), extraLabelStyleClass].join(' ')}>
                  <span className={[style.tagText, tagPosition(dropdownOption?.tagInfo?.tagPosition)].join(' ')}>{dropdownOption.display}</span>
                  <div className={[style.tags, tagPosition(dropdownOption?.tagInfo?.tagPosition), dropdownOption.tagInfo?.tags?.length > 0 && style.hasTags].join(' ')}>
                    {dropdownOption.tagInfo?.tags?.map((tag, index) => {
                      return <Tag key={`${tag.display}-${index}`} displayText={tag.display} type={tag.type} extraStyleClass={tagPosition(dropdownOption?.tagInfo?.tagPosition)} />;
                    })}
                  </div>
                </div>
              </label>
            </li>
          );
        })}
      </ul>
    );
  }

}

ListSelect.propTypes = {
  id: PropTypes.string,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string.isRequired,
      returnValue: PropTypes.string.isRequired,
      selected: PropTypes.bool,
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          display: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          tagPosition: PropTypes.oneOf(Object.values(TAG_POSITION_ENUMS)),
        }),
      ),
    }),
  ).isRequired,
  selectedOption: PropTypes.object,
  selectOptionFunction: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool,
  hideLastBorder: PropTypes.bool,
  isRadioSelect: PropTypes.bool,
  extraContainerStyleClass: PropTypes.string,
  extraLabelStyleClass: PropTypes.string,
  dataSetSelection: PropTypes.bool,
};

export default ListSelect;
