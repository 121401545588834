import React from 'react';
import { Loading } from 'Components';
import style from './AnnualReportContentLoading.module.scss';

/**
 * AnnualReportContentLoading.jsx
 *
 * @summary This component shows the loading state page view for both annual report cover pages.
 */
function AnnualReportContentLoading() {
  return (
    <>
      <h1>
        <Loading>
          <p className={style.invisibleTitle}>Loading</p>
        </Loading>
      </h1>
      <Loading className={style.articleContent} />
    </>
  );
}

export default AnnualReportContentLoading;
