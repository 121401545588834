import { escapeStringsFromSearch } from 'Utils/Search/SearchPage';
import { CONSTANTS } from './Constants';

export const FILTERABLE_GROUPS = {
  PUBLICATION_TYPE: { DISPLAY: 'Publication type', VALUE: 'ContentType' },
  PORTFOLIO: { DISPLAY: 'Portfolio', VALUE: 'Portfolio' },
  BODY_TYPE: { DISPLAY: 'Body type', VALUE: 'BodyType' },
  ENTITY: { DISPLAY: 'Entities and companies', VALUE: 'Entity' },
  REPORTING_YEAR: { DISPLAY: 'Reporting year', VALUE: 'ReportingYear' },
  TITLE: { VALUE: 'Title' },
  CONTENT: { VALUE: 'Content' },
};

export const BODY_TYPES = {
  COMMONWEALTH_COMPANY: 'Commonwealth Company',
  CORPORATE_COMMONWEALTH_ENTITY: 'Corporate Commonwealth Entity',
  NON_CORPORATE_COMMONWEALTH_ENTITY: 'Non-Corporate Commonwealth Entity',
};

export const SEARCH_API_URL = {
  SERVICE_NAME_DEV: `acs-dof-shared-aue-01`,
  INDEX_NAME: () => {
    // checks that the current environment variable is one of 'dev', 'uat' and 'prod'
    if (Object.values(CONSTANTS.ENV).includes(process.env.REACT_APP_ENVIRONMENT?.toLowerCase())) {
      return `${process.env.REACT_APP_ENVIRONMENT}-content-index`;
    }
    return 'incorrect-env-variable-found';
  },
  API_VERSION_DEFAULT: process.env.REACT_APP_SEARCH_API_VERSION,
  GET_URL: (serviceName, indexName, apiVersion) => {
    return `https://${serviceName}.search.windows.net/indexes/${indexName}/docs?api-version=${apiVersion}`;
  },
  POST_URL: (serviceName, indexName, apiVersion) => {
    return `https://${serviceName}.search.windows.net/indexes/${indexName}/docs/search?api-version=${apiVersion}`;
  },
  EXPORT_URL: () => {
    if (process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === CONSTANTS.ENV.PROD) {
      return `https://preview.transparency.gov.au/search/exportresults`;
    }
    return `https://${process.env.REACT_APP_ENVIRONMENT}-dart-be-previewapi.dxau.digital/search/exportresults`;
  },
  FILTER_FACET_URL: () => {
    let env;
    // If prod environment return prod url
    if (process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === CONSTANTS.ENV.PROD) {
      return 'https://apps.transparency.gov.au/search/searchfacets';
    }

    // checks that the current environment variable is one of 'dev', 'uat'
    if (Object.values(CONSTANTS.ENV).includes(process.env.REACT_APP_ENVIRONMENT?.toLowerCase())) {
      env = process.env.REACT_APP_ENVIRONMENT.toLowerCase();
    } else {
      env = 'incorrect-env-variable-found';
    }

    return `https://${env}-dart-be.dxau.digital/search/searchfacets`;
  },
};


export const SEARCH_API_CONSTANTS = {
  AND: 'and',
  OR: 'or',
  NOT: 'not',
  EQUALS: 'eq',
  NOT_EQUAL: 'ne',
  ASC: 'asc',
  DESC: 'desc',
};

export const SEARCH_API_FUNCTION = {
  /**
   * Allows for adding filter search all items which have properties '[filterableValue]', where the its value is one of the values in the [filterValueList]
   * Follows this syntax https://learn.microsoft.com/en-us/azure/search/search-query-odata-filter
   * @param {String} filterableValue - Should be one of the constants 'value' of FILTERABLE_GROUPS
   * @param {String[]} filterValueList - List of string values that could be the value
   * @returns {String} value to filter in Azure Cognitive search api
   * @example if given filterableValue = 'ContentType' and filterValueList = [annual_report, pbs, corp_plan], will return 'search.in(ContentType, 'annual_report|pbs|corp_plan', '|')'
   */
  FILTER_IN: (filterableValue, filterValueList) => {
    let convertedString = filterValueList.join('|');
    if (convertedString.includes("'")) {
      // if contains apostrophe, need to escape by adding another apostrophe
      convertedString = convertedString.replaceAll("'", "''");
    }
    if (!convertedString) {
      convertedString = '*';
    }
    return `search.in(${filterableValue}, '${convertedString}', '|')`;
  },

  /**
   * Converts a list of orderings to string in order to be used for `orderby` field of search api
   * Follows the following syntax https://learn.microsoft.com/en-us/azure/search/search-query-odata-orderby
   * @param {{orderGroup: String, desc: Boolean}[]} orderList - List of ordering group values (should be in order) to sort by
   * @returns {String} String value to order search results by
   * @example if given [{orderGroup: 'Entity'}, {orderGroup: 'ReportingYear', desc: true}, {orderGroup: ContentType}], will return 'Entity,ReportingYear desc,ContentType'
   */
  ORDER_BY: (orderList) => {
    const orderSyntax = orderList.map((order) => {
      let orderString = `${order.orderGroup}`;
      if (order.desc) {
        orderString = orderString.concat(` ${SEARCH_API_CONSTANTS.DESC}`);
      }
      return orderString;
    });

    return orderSyntax.join(',');
  },

  /**
   * Allows for search term to search for prefix or suffix
   * Follow this syntax https://learn.microsoft.com/en-us/azure/search/query-simple-syntax
   * Search term will need queryType as "full" in order to use this syntax
   *
   * @param {String} searchTerm - Search term
   * @returns {String} search term with * at the end and before syntax in order to search for any matches containing this search term
   * @example 'depart' search term which will search for anything containing depart (e.g. departing, department, post-depart)
   * @example if search term is 'depart', returns 'depart* or /.*depart/' which allows for search words containing depart after or before
   */
  SEARCH_TERM: (searchTerm, exactMatch = false) => {
    if (searchTerm) {
      if (!exactMatch){
        const updatedTerm = escapeStringsFromSearch(searchTerm);
        return `${updatedTerm} | ${updatedTerm}* | /.*${updatedTerm}/`;
      } else {
        const updatedTerm = escapeStringsFromSearch(searchTerm);
        return  `"${updatedTerm}"`;
      }
    }
    return '';
  },

  /**
   * Combines expressions together using a specified combinator to be used in search API
   * @param {String[]} listOfExpressions - List of string expressions to combine
   * @param {String} combinator - Combinator should be one of the first five SEARCH_API_CONSTANTS. Only 'and', 'or', 'not', 'eq' and 'ne' is accepted.
   * @returns {String} Combined string with combinator
   * @example if list of expressions is ['search.in(ContentType, 'annual_report|pbs|corp_plan', '|')', 'search.in(ReportingYear, '2020-21|2021-22|2022-23|2023-24', '|')']
   * and combinator is 'and', then it returns 'search.in(ContentType, 'annual_report|pbs|corp_plan', '|') and search.in(ReportingYear, '2020-21|2021-22|2022-23|2023-24', '|')'
   */
  COMBINE_EXPRESSION: (listOfExpressions, combinator) => {
    return listOfExpressions.join(` ${combinator} `);
  },
};