import React from 'react';
import PropTypes from 'prop-types';
import style from './FormField.module.scss';

/**
 * FormField.jsx
 *
 * @summary Component for allowing for Form Field to overflow parent
 *
 * @param {Object} props - Component props.
 * @prop {Node} props.children - Children nodes add that will allow for scrolling within.
 * @prop {String} [props.title] - Title label of form field
 * @prop {String} [props.boldedSubText] - Subtext label (bolded section)
 * @prop {String} [props.subText] - Subtext label (non-bolded section)
 * @prop {Boolean} [props.showSubText] - Show subtext
 * @prop {Boolean} [props.isRequired] - Shows the required mark if true
 *
 */
function FormField({ children, title, boldedSubText, subText, showSubText, isRequired }) {
  return (
    <div className={style.formField}>
      {title && <legend className={[style.title, isRequired && style.required].join(' ')}>{title}</legend>}
      {children}
      {showSubText && (
        <span className={style.subText}>
          {boldedSubText !== null && boldedSubText !== undefined && <strong>{boldedSubText}&nbsp;</strong>}
          {subText}
        </span>
      )}
    </div>
  );
}

FormField.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  boldedSubText: PropTypes.string,
  subText: PropTypes.string,
  showSubText: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default FormField;
