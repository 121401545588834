import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AusCrest from 'Assets/AusGovCrest-DefaultARCoverLogo.svg';
import style from './DefaultCoverImage.module.scss';

/**
 * DefaultCoverImage.jsx
 *
 * This component is only ever being used in AnnualReportCover.jsx
 *
 * This is just to seperate out long svg structure from annual report
 * cover page component.
 *
 * @param {Object} props - Component props.
 * @prop {String} props.reportTitle - Report title.
 * @prop {String} [props.entityName] - Entity name of the report. if not given, entity name won't be on the default cover image.
 * @prop {String} [props.logo] - Entity logo image source. if not given, Australian crest image will be used instead.
 */
function DefaultCoverImage({ reportTitle, entityName, logo }) {
  const [printReady, setPrintReady] = useState(false);

  return (
    <svg x="0px" y="0px" viewBox="0 0 595 842" id={style.defaultCoverImage}>
      <switch>
        <foreignObject x="56" y="0" width="483" height="842">
          <div style={{ borderBottom: '2px solid #000000', width: '100%', margin: '56px 0 114px' }} />

          <img src={logo || AusCrest} alt="" style={{ marginBottom: '56px' }} onLoad={() => setPrintReady(true)} />
          {printReady && <span id="readyForPrint" hidden key={new Date().getTime()}></span>}

          <p xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: '36px', fontWeight: '700', lineHeight: '115%', margin: '0 0 24px' }}>
            {reportTitle}
          </p>

          {entityName ? (
            <p xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: '24px', fontWeight: '500', lineHeight: '115%', margin: '0' }}>
              {entityName}
            </p>
          ) : null}
        </foreignObject>
      </switch>
    </svg>
  );
}

DefaultCoverImage.propTypes = {
  entityName: PropTypes.string,
  logo: PropTypes.string,
  reportTitle: PropTypes.string.isRequired,
};

export default DefaultCoverImage;
