import React, { useState, useEffect, useRef, Suspense, useCallback } from 'react';
import { Await, Link, NavLink, useLocation, useMatches } from 'react-router-dom';
import { CONSTANTS, PATHS, PAGE_NOT_FOUND_TITLE } from 'Constants';
import { debounce, detectViewMode } from 'Utils';
import { Loading } from 'Components';
import style from './Breadcrumb.module.scss';

/**
 * Breadcrumb.jsx
 *
 * @summary Render as breadcrumb.
 *
 */
export default function Breadcrumb() {
  const { pathname } = useLocation();
  const matchList = useMatches();
  const [viewMode, setViewMode] = useState(detectViewMode());
  const initialRender = useRef(true);
  const isMobile = viewMode === CONSTANTS.VIEW_MODE.MOBILE;

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }

    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  const renderBase = () => {
    if (pathname !== PATHS.HOME.BASE) {
      if (isMobile) {
        const routes = matchList.filter((match) => match?.handle?.crumb);
        const parentRoute = routes[routes.length - 2]?.pathname || PATHS.HOME.BASE;
        return (
          <li>
            <Link to={parentRoute} title="Navigate to parent page" aria-label="Navigate to parent page">
              ..
            </Link>
          </li>
        );
      }

      return (
        <li>
          <NavLink to={PATHS.HOME.BASE} aria-label="Navigate to home">
            Home
          </NavLink>
        </li>
      );
    }

    return null;
  };

  const renderCrumbs = () => {
    // only consider match obj with handle.crumb
    const crumbs = matchList
      .filter((match) => match?.handle?.crumb)
      .map((match) => {
        const handleCrumb = match?.handle?.crumb;
        const isCurrentPage = match.pathname === pathname;
        let crumbText = handleCrumb?.crumbName;
        const linkAriaLabel = `${isCurrentPage ? 'Current page' : 'Navigate to'}`;

        if (handleCrumb?.isCrumbNameDynamic === true && handleCrumb?.crumbNameSourceId) {
          const dataSource = matchList.find((obj) => obj.id === handleCrumb?.crumbNameSourceId)?.data?.[handleCrumb?.crumbNameSourceId];

          return (
            <Suspense
              key={match.pathname}
              fallback={
                <Loading>
                  <span className={style.loadingCrumb}></span>
                </Loading>
              }>
              <Await resolve={Promise.all([dataSource])}>
                {(data) => {
                  if (data && data.length > 0 && data[0]) {
                    const { routeBaseUrl, routeTitle, routes } = data[0];

                    if (routeBaseUrl === match.pathname) {
                      crumbText = routeTitle;
                    } else {
                      // find matching route and get crumb text
                      const thisRoute = (routeList, thisPathname) => {
                        let targetRoute = routeList?.find((route) => decodeURI(route?.path) === thisPathname);

                        // if not found, check children routes
                        if (!targetRoute) {
                          targetRoute;
                          const childrenRoutes = routeList?.filter((route) => {
                            let previousCrumbPath = thisPathname.split('/');
                            previousCrumbPath.pop();
                            previousCrumbPath = previousCrumbPath.join('/');

                            return decodeURI(route.path) === previousCrumbPath;
                          })?.[0];

                          if (Array.isArray(childrenRoutes?.children) && childrenRoutes?.children?.length > 0) {
                            targetRoute = thisRoute(childrenRoutes?.children, thisPathname);
                          }
                        }

                        return targetRoute;
                      };

                      // use `routeTitle` if route object is found and if is undefined, always use document title as it most likely the last crumb given routes and parent routes loader are set correctly.
                      crumbText = thisRoute(routes, match.pathname)?.routeTitle || PAGE_NOT_FOUND_TITLE;
                    }

                    let displayCrumbText = crumbText;
                    if (displayCrumbText?.length > 40) {
                      displayCrumbText = displayCrumbText.slice(0, 37).trim() + '...';
                    }

                    return (
                      <Link
                        to={match.pathname}
                        aria-label={`${linkAriaLabel} ${crumbText}`}
                        aria-current={isCurrentPage ? 'page' : null}
                        title={crumbText}
                        onClick={(e) => {
                          if (isCurrentPage) e.preventDefault(); // if is current page, prevent page "reload"
                        }}>
                        {displayCrumbText}
                      </Link>
                    );
                  }
                }}
              </Await>
            </Suspense>
          );
        }

        let displayCrumbText = crumbText;
        if (displayCrumbText?.length > 40) {
          displayCrumbText = displayCrumbText.slice(0, 37).trim() + '...';
        }

        return (
          <Link
            key={match.pathname}
            to={match.pathname}
            aria-label={`${linkAriaLabel} ${crumbText}`}
            aria-current={isCurrentPage ? 'page' : null}
            onClick={(e) => {
              if (isCurrentPage) e.preventDefault(); // if is current page, prevent page "reload"
            }}>
            {displayCrumbText}
          </Link>
        );
      });

    // if mobile view, only return current crumb
    if (isMobile && pathname !== PATHS.HOME.BASE) {
      return <li>{crumbs[crumbs?.length - 1]}</li>;
    }

    return crumbs?.map((crumb, i) => <li key={`crumb ${i}`}>{crumb}</li>);
  };

  const base = useCallback(renderBase(), [matchList, viewMode]);
  const crumbs = useCallback(renderCrumbs(), [matchList, viewMode]);

  if (!base && crumbs.length < 1) {
    return null;
  }

  return (
    <nav key={pathname} id={style.breadcrumbs} aria-label="breadcrumb">
      <ol>
        {base}
        {crumbs}
      </ol>
    </nav>
  );
}
