import { TAG_STYLE_ENUMS, TAG_POSITION_ENUMS } from 'Constants';
import { FILTERABLE_GROUPS } from 'Constants/Search';
import { sortByObjectProperty } from 'Utils';

export const DROPDOWN_OPTIONS_MULTI = {
  [FILTERABLE_GROUPS.BODY_TYPE.VALUE]: [
    { display: 'Non-corporate Commonwealth entity', returnValue: '1', selected: false, filterGroupTitle: FILTERABLE_GROUPS.BODY_TYPE.VALUE },
    { display: 'Corporate Commonwealth entity', returnValue: '2', selected: false, filterGroupTitle: FILTERABLE_GROUPS.BODY_TYPE.VALUE },
    { display: 'Commonwealth company', returnValue: '3', selected: false, filterGroupTitle: FILTERABLE_GROUPS.BODY_TYPE.VALUE },
  ],
  [FILTERABLE_GROUPS.PORTFOLIO.VALUE]: [
    {
      display: 'Agriculture',
      returnValue: '4',
      selected: false,
      filterGroupTitle: FILTERABLE_GROUPS.PORTFOLIO.VALUE,
      tag: { display: 'Abolished in 2019-20', type: TAG_STYLE_ENUMS.GREY },
    },
    {
      display: 'Agriculture, Fisheries and Forestry',
      returnValue: '5',
      selected: false,
      filterGroupTitle: FILTERABLE_GROUPS.PORTFOLIO.VALUE,
    },
    {
      display: 'Agriculture, Water and the Environment',
      returnValue: '6',
      selected: false,
      filterGroupTitle: FILTERABLE_GROUPS.PORTFOLIO.VALUE,
      tag: { display: 'Abolished in 2021-22', type: TAG_STYLE_ENUMS.GREY },
    },
    { display: 'Attorney-General’s', returnValue: '7', selected: false, filterGroupTitle: FILTERABLE_GROUPS.PORTFOLIO.VALUE },
    { display: 'Climate Change, Energy, the Environment and Water', returnValue: '8', selected: false, filterGroupTitle: FILTERABLE_GROUPS.PORTFOLIO.VALUE },
    {
      display: 'Communications and the Arts',
      returnValue: '9',
      selected: false,
      filterGroupTitle: FILTERABLE_GROUPS.PORTFOLIO.VALUE,
      tag: { display: 'Abolished in 2019-20', type: TAG_STYLE_ENUMS.GREY },
    },
    { display: 'Defence', returnValue: '10', selected: false, filterGroupTitle: FILTERABLE_GROUPS.PORTFOLIO.VALUE },
    { display: 'Education', returnValue: '11', selected: false, filterGroupTitle: FILTERABLE_GROUPS.PORTFOLIO.VALUE },
    {
      display: 'Education, Skills and Employment',
      returnValue: '12',
      selected: false,
      filterGroupTitle: FILTERABLE_GROUPS.PORTFOLIO.VALUE,
      tag: { display: 'Abolished in 2021-22', type: TAG_STYLE_ENUMS.GREY },
    },
    { display: 'Employment and Workplace Relations', returnValue: '13', selected: false, filterGroupTitle: FILTERABLE_GROUPS.PORTFOLIO.VALUE },
    { display: 'Employment, Skills, Small and Family Business', returnValue: '14', selected: false, filterGroupTitle: FILTERABLE_GROUPS.PORTFOLIO.VALUE },
  ],
  // TODO: when creating entities and companies data, ensure that the values are sorted using this function first
  [FILTERABLE_GROUPS.ENTITY.VALUE]: sortByObjectProperty(
    [
      { display: 'AAF Company', returnValue: '15', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'ASC Pty Ltd', returnValue: '16', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'Aged Care Quality and Safety', returnValue: '17', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'Department of Finance', returnValue: '18', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY },
      { display: 'Commonwealth Superannuation Corporation', returnValue: '19', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'Independant Parliamentary Expenses Authority', returnValue: '20', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'Digital Transformation Agency', returnValue: '21', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'Future Fund Management Agency', returnValue: '22', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'Australian Naval Infrastructure Pty Ltd', returnValue: '23', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'Air Services Australia', returnValue: '24', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'National Capital Authority', returnValue: '25', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'Special Broadcasting Service', returnValue: '26', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'Old Parliament House', returnValue: '27', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'National Transport Commission', returnValue: '28', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'National Museum of Australia', returnValue: '29', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
      { display: 'Screen Australia', returnValue: '30', selected: false, filterGroupTitle: FILTERABLE_GROUPS.ENTITY.VALUE },
    ],
    'display',
  ),
  [FILTERABLE_GROUPS.PUBLICATION_TYPE.VALUE]: sortByObjectProperty(
    [
      {
        display: 'Portfolio Budget Statement',
        returnValue: 'PBS',
        selected: false,
        filterGroupTitle: FILTERABLE_GROUPS.PUBLICATION_TYPE.VALUE,
        tag: { display: 'PBS', type: TAG_STYLE_ENUMS.HIGHLIGHT, tagPosition: TAG_POSITION_ENUMS.LEFT },
      },
      {
        display: 'Corporate Plan',
        returnValue: 'CP',
        selected: false,
        filterGroupTitle: FILTERABLE_GROUPS.PUBLICATION_TYPE.VALUE,
        tag: { display: 'CP', type: TAG_STYLE_ENUMS.HIGHLIGHT, tagPosition: TAG_POSITION_ENUMS.LEFT },
      },
      {
        display: 'Annual Report',
        returnValue: 'AR',
        selected: false,
        filterGroupTitle: FILTERABLE_GROUPS.PUBLICATION_TYPE.VALUE,
        tag: { display: 'AR', type: TAG_STYLE_ENUMS.HIGHLIGHT, tagPosition: TAG_POSITION_ENUMS.LEFT },
      },
    ],
    'display',
  ),
  [FILTERABLE_GROUPS.REPORTING_YEAR.VALUE]: [
    { display: '2023-24', returnValue: '2023-24', selected: false, filterGroupTitle: FILTERABLE_GROUPS.REPORTING_YEAR.VALUE },
    { display: '2022-23', returnValue: '2022-23', selected: false, filterGroupTitle: FILTERABLE_GROUPS.REPORTING_YEAR.VALUE },
    { display: '2021-22', returnValue: '2021-22', selected: false, filterGroupTitle: FILTERABLE_GROUPS.REPORTING_YEAR.VALUE },
    { display: '2020-21', returnValue: '2020-21', selected: false, filterGroupTitle: FILTERABLE_GROUPS.REPORTING_YEAR.VALUE },
    { display: '2019-20', returnValue: '2019-20', selected: false, filterGroupTitle: FILTERABLE_GROUPS.REPORTING_YEAR.VALUE },
    { display: '2018-19', returnValue: '2018-19', selected: false, filterGroupTitle: FILTERABLE_GROUPS.REPORTING_YEAR.VALUE },
  ],
};
