import React, { Suspense, useEffect, useState, useCallback, useRef } from 'react';
import { Await, Link, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { CSVLink } from 'react-csv';
import { DropdownSelect, PageHead, Tag, MessageBox, LoadingMenu } from 'Components';
import { annualReportUrl, debounce, detectViewMode, entityProfileUrl, formatNumber, innerPageScroll } from 'Utils';
import { FinancialRatiosLoading } from 'Pages';
import DefaultCoverImage from 'Pages/AnnualReportCover/DefaultCoverImage';
import { CONSTANTS, CONTENT_ITEM_TYPES, PAGE_NOT_FOUND_PAGE, PATHS, PUBLICATION_TYPE, TAG_STYLE_ENUMS, MESSAGE_BOX_TYPE } from 'Constants';
import { ERROR_CONTENT } from 'Constants/StaticContents';
import {
  EXPORT_DROPDOWN_OPTIONS,
  FILTER_PARAMS,
  FINANCIAL_RATIO_TABLE_ROW_HEADING,
  FINANCIAL_RATIOS_CONTENT_CODENAMES,
  FINANCIAL_RATIOS_DATA_KEY as DATA_KEY,
  FINANCIAL_RATIOS_TABLE_DEFAULT,
  TARGET_CODENAMES,
} from 'Constants/FinancialRatios';
import { getDataSets, getAContentItem } from 'Services/Delivery';
import style from './FinancialRatios.module.scss';
import sprite from 'Assets/svgSprite.svg';
import { BODY_TYPES } from 'Constants/Search';

const MESSAGE_CONTENTS = {
  FETCH_ERROR: <p>Sorry, we could not get the requested data. Please try again later.</p>,
  EXPORT_ERROR: <p>Something went wrong. Sorry we cannot perfrom export CSV. Please try again later.</p>,
  NO_DATA_FOUND: <p>No data found for selected reporting year. Please try other reporting year.</p>,
};

const INITIAL_SELECTED_RATIO = {
  arWebUrl: '',
  entityWebUrl: '',
  entityName: '',
  portfolioWebUrl: '',
  reportingYear: '',
  reportTitle: '',
  coverImage: '',
  ratios: FINANCIAL_RATIOS_TABLE_DEFAULT,
};

/**
 * FinancialRatios.jsx
 *
 * @summary This component is page view for Financial Ratios page.
 *
 * @param {Object} props - Component props.
 */
function FinancialRatios() {
  const [viewMode, setViewMode] = useState(detectViewMode());
  const { homepage } = useRouteLoaderData(PATHS.HOME.ID);
  const { financialRatios } = useRouteLoaderData(PATHS.FINANCIAL_RATIOS.ID);
  const loadedData = useCallback(Promise.all([financialRatios, homepage]), [financialRatios, homepage]);
  const [selectedRatio, setSelectedRatio] = useState(structuredClone(INITIAL_SELECTED_RATIO));
  const [appliedRatio, setAppliedRatio] = useState(false);
  const [selectedExportReportingYears, setSelectedExportReportingYears] = useState([]);
  const [filterParams, setFilterParams] = useSearchParams();
  const [selectedEntity, setSelectedEntity] = useState(filterParams.get(FILTER_PARAMS.ENTITY) || '');
  const [selectedReportingYear, setSelectedReportingYear] = useState(filterParams.get(FILTER_PARAMS.REPORTING_YEAR) || '');
  const [selectedDropdownOptions, setSelectedDropdownOptions] = useState({ entity: null, reportingYear: null });
  const [financialDataFetching, setFinancialDataFetching] = useState(false);
  const [financialDataFetched, setFinancialDataFetched] = useState(false);
  const [errorFinancialData, setErrorFinancialData] = useState(false);
  const [noFinancialData, setNoFinancialData] = useState(false);
  const [ARFetched, setARFetched] = useState(false);
  const [ARErrored, setARErrored] = useState(false);
  const [ARCoverLoaded, setARCoverLoaded] = useState(false);
  const [ARCoverError, setARCoverError] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [readyForDownload, setReadyForDownload] = useState(false);
  const [fetchingCSVData, setFetchingCSVData] = useState(false);
  const [fetchingCSVDataError, setFetchingCSVDataError] = useState(false);
  const [fetchingCSVDataNoData, setFetchingCSVDataNoData] = useState(false);
  const csvDownloader = useRef(null);

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  return (
    <Suspense fallback={<FinancialRatiosLoading />}>
      <Await resolve={loadedData}>
        {(resolvedData) => {
          const loadedFinancialRatioData = resolvedData[0];
          const loadedHomepageData = resolvedData[1];

          if (loadedFinancialRatioData.isError) {
            return PAGE_NOT_FOUND_PAGE(ERROR_CONTENT.NOT_FOUND);
          }

          const { bannerContent, content } = loadedFinancialRatioData;
          const {
            filterOptions: { Entity, ReportingYear },
          } = loadedHomepageData;
          const exportReportingYearOptions = [EXPORT_DROPDOWN_OPTIONS.ALL_YEARS].concat(ReportingYear);

          const selectReportingYear = (option) => {
            setSelectedDropdownOptions((prevState) => {
              return { ...prevState, reportingYear: option };
            });

            setSelectedReportingYear(option.returnValue);
          };

          const selectEntity = (option) => {
            setSelectedDropdownOptions((prevState) => {
              return { ...prevState, entity: option };
            });

            setSelectedEntity(option.returnValue);
          };

          const selectExportReportingYear = (option) => {
            const optionAllReturnValue = EXPORT_DROPDOWN_OPTIONS.ALL_YEARS.returnValue;

            if (option.returnValue === optionAllReturnValue) {
              // if selected option is `All`

              if (option.selected) {
                // if already selected, unselect every options
                exportReportingYearOptions.forEach((obj) => (obj.selected = false));
                setSelectedExportReportingYears([]);
              } else {
                // if not selected, select every options
                exportReportingYearOptions.forEach((obj) => (obj.selected = true));
                setSelectedExportReportingYears(exportReportingYearOptions.filter((obj) => obj.returnValue !== optionAllReturnValue));
              }
            } else {
              // for any options that is not `All`

              const optionAll = exportReportingYearOptions.find((obj) => obj.returnValue === optionAllReturnValue);
              optionAll.selected = false;

              if (option.selected) {
                // if already selected, unselect this option
                option.selected = false;
                setSelectedExportReportingYears((prevState) => {
                  return prevState.filter((obj) => obj.returnValue !== option.returnValue);
                });
              } else {
                // if option is not selected, add to selected options list
                option.selected = true;

                setSelectedExportReportingYears((prevState) => {
                  const newState = [...prevState, option];

                  if (newState.length === ReportingYear.length) {
                    optionAll.selected = true;
                  }

                  return newState;
                });
              }
            }
          };

          const processRatioData = (data) => {
            const notAvailableData = 'N/A';
            const datafields = {};
            const bodyType = data?.[0]?.bodyType;
            data.forEach((obj) => {
              datafields[obj.contentType] = obj.datafields;
            });

            /**
             * Take array of keys and return list of respected data from API response datafields.
             */
            const mapData = (arrayOfKeys, dataSourceCodename) => {
              return arrayOfKeys.map((key) => datafields?.[dataSourceCodename]?.[key]);
            };

            /**
             * Take array of two arraies. Each nested array should contain data. First array represents values to sum and second array represents values to sub.
             */
            const calculateData = ([valuesToSum, valuesToSub]) => {
              if (valuesToSum.some((value) => isNaN(value))) {
                return 'N/A';
              }
              return [valuesToSum, valuesToSub]
                .map((arr) => {
                  if (arr.length > 0) {
                    return arr.reduce((dataA, dataB) => (Number(dataA) || 0) + (Number(dataB) || 0));
                  }

                  return 0;
                })
                .reduce((totalToSum, totalToSub) => totalToSum - totalToSub);
            };

            /**
             * Take array of two arraies. Each nested array should contain data. First array represents values to sum and second array represents values to sub.
             * Take calculated data off from arraies of data as second argument.
             */
            const displayData = ([valuesToSum, valuesToSub], calculatedData) => {
              // if any values are not a number, display N/A
              if (valuesToSum.some((value) => isNaN(value))) {
                return 'N/A';
              }
              return valuesToSum.some(Boolean) || valuesToSub.some(Boolean) ? `${calculatedData < 0 ? '-' : ''}$${formatNumber(Math.abs(calculatedData))}` : notAvailableData;
            };

            /**
             * Take calculated ratio data.
             */
            const displayRatioData = (calculatedRatioData) => {
              return Number.isNaN(calculatedRatioData) || !Number.isFinite(calculatedRatioData) ? notAvailableData : `${calculatedRatioData.toFixed(0)}%`;
            };

            /**
             * Determine whether we need to use the updated datasets (only true when reporting period is 2023-24 or greater)
             */
            const useUpdatedDataSets = (reportingPeriod) => {
              //RegEx to match the format YYYY-YY (e.g. 2023-24)
              const validFormat = /^\d{4}-\d{2}$/;

              // Check if reportingPeriod is provided and it matches the format
              if (!reportingPeriod || !validFormat.test(reportingPeriod)) {
                return false;
              }
              const yearSuffix = Number(reportingPeriod.slice(-2));
              return yearSuffix >= 24;
            };

            const getBodyTypeFinancialData = (bodyType, selectedReportingYear) => {
              const useNewDataSets = useUpdatedDataSets(selectedReportingYear);
              if (bodyType === BODY_TYPES.NON_CORPORATE_COMMONWEALTH_ENTITY) {
                return useNewDataSets ? TARGET_CODENAMES.UPDATED_NCE : TARGET_CODENAMES.NCE;
              } else if (bodyType === BODY_TYPES.CORPORATE_COMMONWEALTH_ENTITY) {
                return useNewDataSets ? TARGET_CODENAMES.UPDATED_CCE : TARGET_CODENAMES.CCE;
              } else if (bodyType === BODY_TYPES.COMMONWEALTH_COMPANY) {
                return useNewDataSets ? TARGET_CODENAMES.UPDATED_CC : TARGET_CODENAMES.CC;
              }
              return [];
            };

            const ratioData = structuredClone(FINANCIAL_RATIOS_TABLE_DEFAULT);
            ratioData.forEach((ratioData) => {
              const tableTitle = ratioData.title;
              const bodyTypeFinancialData = getBodyTypeFinancialData(bodyType, selectedReportingYear);

              // TOTAL LIABILITIES TO TOTAL ASSETS RATIO
              if (tableTitle === CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.TOTAL_LIABILTIIES.HEADING) {
                const liabilitiesData = [
                  mapData(DATA_KEY.TOTAL_LIAB_TO_TOTAL_ASSETS.TOTAL_LIABILTIIES.SUM, bodyTypeFinancialData[0]),
                  mapData(DATA_KEY.TOTAL_LIAB_TO_TOTAL_ASSETS.TOTAL_LIABILTIIES.SUB, bodyTypeFinancialData[0]),
                ];
                const assetsData = [
                  mapData(DATA_KEY.TOTAL_LIAB_TO_TOTAL_ASSETS.TOTAL_ASSETS.SUM, bodyTypeFinancialData[0]),
                  mapData(DATA_KEY.TOTAL_LIAB_TO_TOTAL_ASSETS.TOTAL_ASSETS.SUB, bodyTypeFinancialData[0]),
                ];

                const liabilities = calculateData(liabilitiesData);
                const assets = calculateData(assetsData);
                const ratio = Number((liabilities / assets) * 100);

                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.TOTAL_LIABILTIIES] = displayData(liabilitiesData, liabilities);
                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.TOTAL_ASSETS] = displayData(assetsData, assets);
                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.TOTAL_LIAB_TO_TOTAL_ASSETS] = displayRatioData(ratio);
              }

              // FINANCIAL ASSETS TO TOTAL LIABILITIES RATIO
              else if (tableTitle === CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.FINANCIAL_ASSETS.HEADING) {
                const assetsData = [
                  mapData(DATA_KEY.FIN_ASSET_TO_LIAB.TOTAL_ASSETS.SUM, bodyTypeFinancialData[0]),
                  mapData(DATA_KEY.FIN_ASSET_TO_LIAB.TOTAL_ASSETS.SUB, bodyTypeFinancialData[0]),
                ];
                const liabilitiesData = [
                  mapData(DATA_KEY.FIN_ASSET_TO_LIAB.TOTAL_LIABILTIIES.SUM, bodyTypeFinancialData[0]),
                  mapData(DATA_KEY.FIN_ASSET_TO_LIAB.TOTAL_LIABILTIIES.SUB, bodyTypeFinancialData[0]),
                ];

                const assets = calculateData(assetsData);
                const liabilities = calculateData(liabilitiesData);
                const ratio = Number((assets / liabilities) * 100);

                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.FIN_ASSETS] = displayData(assetsData, assets);
                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.TOTAL_LIABILTIIES] = displayData(liabilitiesData, liabilities);
                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.FIN_ASSETS_TO_TOTAL_LIAB] = displayRatioData(ratio);
              }

              // CAPITAL TURNOVER RATIO
              else if (tableTitle === CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CAPITAL_TURNOVER.HEADING) {
                const depAndAmorExpData = [
                  mapData(DATA_KEY.CAPITAL_TURNOVER.DEP_AND_AMORT_EXP.SUM, bodyTypeFinancialData[1]),
                  mapData(DATA_KEY.CAPITAL_TURNOVER.DEP_AND_AMORT_EXP.SUB, bodyTypeFinancialData[1]),
                ];
                const purchPropPlantEquipData = [
                  mapData(DATA_KEY.CAPITAL_TURNOVER.PURCHASE_PROPERTY.SUM, bodyTypeFinancialData[2]),
                  mapData(DATA_KEY.CAPITAL_TURNOVER.PURCHASE_PROPERTY.SUB, bodyTypeFinancialData[2]),
                ];
                const purchIntangData = [
                  mapData(DATA_KEY.CAPITAL_TURNOVER.PURCHASE_INTANGIBLES.SUM, bodyTypeFinancialData[2]),
                  mapData(DATA_KEY.CAPITAL_TURNOVER.PURCHASE_INTANGIBLES.SUB, bodyTypeFinancialData[2]),
                ];
                const depOnROUAssetsData = [
                  mapData(DATA_KEY.CAPITAL_TURNOVER.DEP_ON_ROU_ASSETS.SUM, bodyTypeFinancialData[3]),
                  mapData(DATA_KEY.CAPITAL_TURNOVER.DEP_ON_ROU_ASSETS.SUB, bodyTypeFinancialData[3]),
                ];

                const depAndAmorExp = calculateData(depAndAmorExpData);
                const purchPropPlantEquip = calculateData(purchPropPlantEquipData);
                const purchIntang = calculateData(purchIntangData);
                const depOnROUAssets = calculateData(depOnROUAssetsData);
                const ratio = Number((depAndAmorExp / (purchPropPlantEquip + purchIntang)) * 100);

                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.DEP_AND_AMORT_EXP] = displayData(depAndAmorExpData, depAndAmorExp);
                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.PURCHASE_PROPERTY] = displayData(purchPropPlantEquipData, purchPropPlantEquip);
                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.PURCHASE_INTANGIBLES] = displayData(purchIntangData, purchIntang);
                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.DEP_ON_ROU_ASSETS] = displayData(depOnROUAssetsData, depOnROUAssets);
                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.CAPITAL_TURNOVER] = displayRatioData(ratio);
              }

              // CURRENT RATIO
              else if (tableTitle === CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CURRENT_RATIO.HEADING) {
                const noMore12MAssetsData = [
                  mapData(DATA_KEY.CURRENT_RATIO.NO_MORE_12M_ASSETS.SUM, bodyTypeFinancialData[4]),
                  mapData(DATA_KEY.CURRENT_RATIO.NO_MORE_12M_ASSETS.SUB, bodyTypeFinancialData[4]),
                ];
                const noMore12MLiabData = [
                  mapData(DATA_KEY.CURRENT_RATIO.NO_MORE_12M_LIAB.SUM, bodyTypeFinancialData[4]),
                  mapData(DATA_KEY.CURRENT_RATIO.NO_MORE_12M_LIAB.SUB, bodyTypeFinancialData[4]),
                ];

                const noMore12MAssets = calculateData(noMore12MAssetsData);
                const noMore12MLiab = calculateData(noMore12MLiabData);
                const ratio = Number((noMore12MAssets / noMore12MLiab) * 100);

                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.NO_MORE_12M_ASSETS] = displayData(noMore12MAssetsData, noMore12MAssets);
                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.NO_MORE_12M_LIAB] = displayData(noMore12MLiabData, noMore12MLiab);
                ratioData.values[FINANCIAL_RATIO_TABLE_ROW_HEADING.CURRENT_RATIO] = displayRatioData(ratio);
              }
            });

            return ratioData;
          };

          const applySelection = async () => {
            const entitySelectionChanged = selectedEntity !== filterParams.get(FILTER_PARAMS.ENTITY);
            const reportingYearSelectionChanged = selectedReportingYear !== filterParams.get(FILTER_PARAMS.REPORTING_YEAR);

            if (entitySelectionChanged || reportingYearSelectionChanged) {
              setFilterParams((prevParams) => {
                prevParams.set(FILTER_PARAMS.ENTITY, selectedEntity);
                prevParams.set(FILTER_PARAMS.REPORTING_YEAR, selectedReportingYear);
                return prevParams;
              });

              setFinancialDataFetched(false);
            }

            // Trigger API to fetch financial ratio data
            if (entitySelectionChanged || reportingYearSelectionChanged || !financialDataFetched) {
              setFinancialDataFetching(true);
              setErrorFinancialData(false);
              setNoFinancialData(false);

              try {
                const dataSetRes = await getDataSets(FINANCIAL_RATIOS_CONTENT_CODENAMES, [selectedReportingYear], [selectedDropdownOptions?.entity?.codename]);

                if (dataSetRes?.isError) {
                  setErrorFinancialData(true);
                } else if (dataSetRes.length > 0) {
                  // const data = dataSetRes[0]; // always assume there will be only one item for financial ratios data
                  const data = dataSetRes;
                  const annualReportCodename = data?.[0]?.reportCodeName;

                  let newSelectedRatioStateData = structuredClone(INITIAL_SELECTED_RATIO);
                  newSelectedRatioStateData.ratios = processRatioData(dataSetRes);

                  // fetch lite version AR
                  setARFetched(false);
                  setARErrored(false);
                  try {
                    const arRes = await getAContentItem(annualReportCodename, CONTENT_ITEM_TYPES.ANNUAL_REPORT.ID, true, { lite: true });

                    if (arRes?.isError) {
                      setARErrored(true);
                    } else {
                      newSelectedRatioStateData.arWebUrl = arRes.WebUrl || '';
                      newSelectedRatioStateData.portfolioWebUrl = arRes.PortfolioWebUrl || '';
                      newSelectedRatioStateData.entityWebUrl = arRes.GovernmentBodyWebUrl || '';
                      newSelectedRatioStateData.entityName = selectedEntity || '';
                      newSelectedRatioStateData.reportTitle = arRes.Title || '';
                      newSelectedRatioStateData.reportingYear = arRes.ReportingPeriod?.[0]?.name || '';
                      const coverImageItem = arRes.CoverImage?.[0]?.Image?.[0];
                      newSelectedRatioStateData.coverImage = coverImageItem ? `${coverImageItem?.url}?${coverImageItem?.renditions?.default?.query}` : null;
                    }

                    setARFetched(true);
                  } catch {
                    setARErrored(true);
                  } finally {
                    setSelectedRatio((prevState) => {
                      const newState = {
                        ...prevState,
                        ...newSelectedRatioStateData,
                      };

                      return newState;
                    });
                    setFinancialDataFetched(true);
                  }

                  setAppliedRatio(true);
                } else {
                  // if no data set return
                  setSelectedRatio(INITIAL_SELECTED_RATIO);
                  setAppliedRatio(false);
                  setNoFinancialData(true);
                }
              } catch (err) {
                console.error(err);
                setErrorFinancialData(true);
              } finally {
                setFinancialDataFetching(false);
              }
            }
          };

          const downloadRatios = () => {
            const combinedCsvData = [];
            const reportingYearsToFetch = selectedExportReportingYears
              .map((year) => year.returnValue)
              .sort()
              .reverse();

            const fetchData = async (reportingYearList) => {
              // set bases
              // TOTAL LIABILITIES TO TOTAL ASSETS RATIO
              const csvTotalLiabToTotalAssets = [
                [CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.TOTAL_LIABILTIIES.HEADING],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.TOTAL_LIABILTIIES],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.TOTAL_ASSETS],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.TOTAL_LIAB_TO_TOTAL_ASSETS],
                [],
              ];

              // FINANCIAL ASSETS TO TOTAL LIABILITIES RATIO
              const csvFinAssetToLiabRatio = [
                [CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.FINANCIAL_ASSETS.HEADING],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.FIN_ASSETS],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.TOTAL_LIABILTIIES],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.FIN_ASSETS_TO_TOTAL_LIAB],
                [],
              ];

              // CAPITAL TURNOVER RATIO
              const csvCapitalTurnover = [
                [CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CAPITAL_TURNOVER.HEADING],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.DEP_AND_AMORT_EXP],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.PURCHASE_PROPERTY],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.PURCHASE_INTANGIBLES],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.DEP_ON_ROU_ASSETS],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.CAPITAL_TURNOVER],
                [],
              ];

              // CURRENT RATIO
              const csvCurrentRatio = [
                [CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CURRENT_RATIO.HEADING],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.NO_MORE_12M_ASSETS],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.NO_MORE_12M_LIAB],
                [FINANCIAL_RATIO_TABLE_ROW_HEADING.CURRENT_RATIO],
              ];

              const updateTargetCsvBase = (numberOfRows, targetCsvBase, sourceData) => {
                [...Array(numberOfRows).keys()].forEach((index) => {
                  targetCsvBase[index + 1].push(...[sourceData[targetCsvBase[index + 1][0]]]);
                });
              };

              setReadyForDownload(false);
              setFetchingCSVDataError(false);
              setFetchingCSVDataNoData(false);
              setFetchingCSVData(true);
              try {
                const dataRes = await getDataSets(FINANCIAL_RATIOS_CONTENT_CODENAMES, reportingYearList, [selectedDropdownOptions?.entity?.codename]);

                if (dataRes.length === 0) {
                  setFetchingCSVDataNoData(true);
                  setReadyForDownload(false);
                } else {
                  reportingYearList.forEach((reportingYear) => {
                    const relevantDataRes = dataRes.filter((dataSet) => {
                      return dataSet.reportingPeriod === reportingYear;
                    });

                    const thisRatioData = processRatioData(relevantDataRes);

                    // add reporting year columns
                    [csvTotalLiabToTotalAssets, csvFinAssetToLiabRatio, csvCapitalTurnover, csvCurrentRatio].forEach((arr) => arr[0].push(reportingYear));

                    thisRatioData.forEach((ratioData) => {
                      const values = ratioData.values;

                      // add data to each row
                      switch (ratioData.title) {
                        // TOTAL LIABILITIES TO TOTAL ASSETS RATIO
                        case CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.TOTAL_LIABILTIIES.HEADING:
                          updateTargetCsvBase(3, csvTotalLiabToTotalAssets, values);
                          break;

                        // FINANCIAL ASSETS TO TOTAL LIABILITIES RATIO
                        case CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.FINANCIAL_ASSETS.HEADING:
                          updateTargetCsvBase(3, csvFinAssetToLiabRatio, values);
                          break;

                        // CAPITAL TURNOVER RATIO
                        case CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CAPITAL_TURNOVER.HEADING:
                          updateTargetCsvBase(5, csvCapitalTurnover, values);
                          break;

                        // CURRENT RATIO
                        case CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CURRENT_RATIO.HEADING:
                          updateTargetCsvBase(3, csvCurrentRatio, values);
                          break;

                        default:
                          break;
                      }
                    });
                  });

                  setReadyForDownload(true);
                }

                combinedCsvData.push(...[...csvTotalLiabToTotalAssets, ...csvFinAssetToLiabRatio, ...csvCapitalTurnover, ...csvCurrentRatio]);
                setCsvData(combinedCsvData);
              } catch (err) {
                console.error(err);
                setReadyForDownload(false);
                setFetchingCSVDataError(true);
              } finally {
                setFetchingCSVData(false);
              }
            };

            fetchData(reportingYearsToFetch);
          };

          const getTableId = (ratioId) => {
            switch (ratioId) {
              case CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.FINANCIAL_ASSETS.CODENAME:
                return CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.FINANCIAL_ASSETS.TABLE;
              case CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.TOTAL_LIABILTIIES.CODENAME:
                return CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.TOTAL_LIABILTIIES.TABLE;
              case CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CAPITAL_TURNOVER.CODENAME:
                return CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CAPITAL_TURNOVER.TABLE;
              case CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CURRENT_RATIO.CODENAME:
                return CONTENT_ITEM_TYPES.FINANCIAL_RATIO_PAGE.CURRENT_RATIO.TABLE;
              default:
                return '';
            }
          };

          useEffect(() => {
            // if url contains entity param and that given entity exists in entity list
            const targetEntityOption = Entity.find((obj) => obj.display === selectedEntity);
            if (selectedEntity && targetEntityOption) {
              selectEntity(targetEntityOption);

              // if entity param value does match to one of entity list, do same process with reporting year
              const targetReportingYearOption = ReportingYear.find((obj) => obj.display === selectedReportingYear);
              if (selectedReportingYear && targetReportingYearOption) {
                selectReportingYear(targetReportingYearOption);
              } else {
                // if reporting year is not found from reporting year option list, delete reporting year param from url
                setFilterParams((prevParams) => {
                  prevParams.delete(FILTER_PARAMS.REPORTING_YEAR, selectedReportingYear);
                  return prevParams;
                });
              }
            } else {
              // if entity is not found from entity option list, delete both entity and reporting year params from url
              setFilterParams((prevParams) => {
                prevParams.delete(FILTER_PARAMS.ENTITY, selectedEntity);
                prevParams.delete(FILTER_PARAMS.REPORTING_YEAR, selectedReportingYear);
                return prevParams;
              });
            }

            setPageLoaded(true);
          }, []);

          useEffect(() => {
            if (pageLoaded && selectedEntity && selectedReportingYear) {
              // if both entity and reporting year are correctly pre selected, apply selection and trigger api to display financial ratios data
              applySelection(Entity);
            }
          }, [pageLoaded]);

          useEffect(() => {
            if (readyForDownload) {
              csvDownloader.current.link.click();
            }
          }, [csvData, readyForDownload]);

          return (
            <>
              <PageHead pageTitle={PATHS.FINANCIAL_RATIOS.TITLE} keepBackgroundImage>
                {bannerContent || <p>Financial metrics provide an indication of an entity&apos;s financial sustainability.</p>}
              </PageHead>

              <div className={style.financialRatioTables}>
                <div className={style.selectFields}>
                  <div className={style.selectText}>
                    <span>Select an entity or company, then reporting year, to view it’s financial ratios:</span>

                    {(errorFinancialData || noFinancialData) && (
                      <div className={style.messageHolder}>
                        <MessageBox title={errorFinancialData ? 'Something went wrong' : 'Data not found'} type={noFinancialData ? MESSAGE_BOX_TYPE.WARNING : null}>
                          {errorFinancialData ? MESSAGE_CONTENTS.FETCH_ERROR : MESSAGE_CONTENTS.NO_DATA_FOUND}
                        </MessageBox>
                      </div>
                    )}
                  </div>
                  <div className={style.dropdownOptions}>
                    <div className={style.formField}>
                      <span className={style.fieldTitle}>Entity or company</span>
                      <DropdownSelect
                        placeholderText="Select an entity"
                        inputEnabled
                        dropdownOptions={Entity}
                        selectedOption={selectedDropdownOptions.entity || null}
                        selectOptionFunction={selectEntity}></DropdownSelect>
                    </div>
                    <div className={style.formField}>
                      <span className={style.fieldTitle}>Reporting year</span>
                      <DropdownSelect
                        disabled={selectedEntity === ''}
                        placeholderText="Select a reporting year"
                        dropdownOptions={ReportingYear}
                        selectedOption={selectedDropdownOptions.reportingYear || null}
                        selectOptionFunction={selectReportingYear}></DropdownSelect>
                    </div>
                    <button disabled={!selectedEntity || !selectedReportingYear} className={style.applyButton} onClick={() => applySelection(Entity)}>
                      Apply
                    </button>
                  </div>
                </div>

                <div key={`financial-ratios-tables`} className={style.tables}>
                  {selectedRatio?.ratios.map((ratio) => {
                    return (
                      <table id={getTableId(ratio.id)} key={`${ratio.title}`} cellSpacing={0}>
                        <caption>
                          <div>
                            {ratio.title}
                            <HashLink className="btn noFill" smooth to={`#${ratio.id}`} scroll={() => innerPageScroll(ratio.id)} aria-label={`${ratio.title} description`}>
                              <svg>
                                <use href={`${sprite}#question`} />
                              </svg>
                            </HashLink>
                          </div>
                        </caption>
                        <tbody>
                          {Object.keys(ratio?.values || {})?.map((ratioKey) => {
                            return (
                              <tr key={`${ratioKey}`}>
                                <th scope="row">{ratioKey}</th>
                                <td className={appliedRatio ? null : style.blankedOut}>{ratio?.values?.[ratioKey]}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    );
                  })}
                </div>

                {(appliedRatio || financialDataFetched) && ARFetched && !ARErrored && (
                  <div className={style.selectedARView}>
                    <span>Read the full Annual Report:</span>
                    <div className={[style.publicationCard, 'card', 'listItem'].join(' ')}>
                      <span className="portfolioInfo">
                        <Link className="headerLink" to={`/${entityProfileUrl(selectedRatio.portfolioWebUrl, selectedRatio.entityWebUrl)}`}>
                          {selectedRatio.entityName}
                        </Link>
                      </span>
                      <Link to={`/${annualReportUrl(selectedRatio.portfolioWebUrl, selectedRatio.entityWebUrl, selectedRatio.arWebUrl)}`}>
                        <span className="limitTextLines">
                          <span className="tags">
                            <Tag displayText={PUBLICATION_TYPE.ANNUAL_REPORT.TAG} type={TAG_STYLE_ENUMS.HIGHLIGHT} />
                            <Tag displayText={selectedRatio.reportingYear} type={TAG_STYLE_ENUMS.GREY} />
                          </span>
                          <span className="cardTitle limitTextLines" style={{ '--MAX-LINE': 3 }}>
                            {selectedRatio.reportTitle}
                          </span>
                        </span>

                        {!selectedRatio.coverImage || ARCoverError ? (
                          <div className="imgLoading">
                            <DefaultCoverImage reportTitle={selectedRatio.reportTitle} entityName={selectedRatio.entityName} />
                          </div>
                        ) : (
                          <>
                            <img
                              className={[ARCoverLoaded ? style.displayCoverImage : style.hidden].join(' ')}
                              src={selectedRatio.coverImage || null}
                              alt=""
                              role="presentation"
                              onLoad={() => setARCoverLoaded(true)}
                              onError={() => setARCoverError(true)}
                            />
                            {ARCoverLoaded && !ARCoverError && (
                              <LoadingMenu
                                numberOfItems={viewMode !== CONSTANTS.VIEW_MODE.MOBILE ? 11 : 5}
                                className={[ARCoverLoaded && selectedRatio.coverImage ? style.hidden : style.displayCoverImage, 'imgLoading'].join(' ')}
                              />
                            )}
                          </>
                        )}
                      </Link>
                    </div>
                  </div>
                )}

                {financialDataFetching && !financialDataFetched && (
                  <div className={style.fetchingOverlay}>
                    <div className={style.modal}>
                      <span className="spinner">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </span>

                      <p>Fetching data</p>
                    </div>
                  </div>
                )}
              </div>

              <div className={style.financialRatioContent}>
                <div className={style.exportSelection}>
                  <div className={style.formField}>
                    <span className={style.fieldTitle}>Reporting year</span>
                    <DropdownSelect
                      isMultiSelect
                      disabled={!selectedDropdownOptions.entity}
                      dropdownOptions={exportReportingYearOptions}
                      selectOptionFunction={selectExportReportingYear}></DropdownSelect>
                  </div>
                  <button className={style.downloadButton} disabled={!selectedDropdownOptions.entity || selectedExportReportingYears.length === 0} onClick={downloadRatios}>
                    Download CSV
                    {fetchingCSVData ? (
                      <span className="spinner">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </span>
                    ) : (
                      <svg>
                        <use href={`${sprite}#download`} />
                      </svg>
                    )}
                  </button>
                  <CSVLink
                    ref={csvDownloader}
                    className={style.hidden}
                    data={csvData}
                    uFEFF={false}
                    filename={`Financial ratios for ${selectedEntity} during ${selectedExportReportingYears.map((year) => year.returnValue).join(' ')}.csv`}>
                    Download CSV (for real)
                  </CSVLink>

                  {(fetchingCSVDataError || fetchingCSVDataNoData) && (
                    <MessageBox type={fetchingCSVDataNoData ? MESSAGE_BOX_TYPE.WARNING : null}>
                      {fetchingCSVDataNoData ? MESSAGE_CONTENTS.NO_DATA_FOUND : MESSAGE_CONTENTS.EXPORT_ERROR}
                    </MessageBox>
                  )}
                </div>

                <div className={style.content}>{content}</div>
              </div>
            </>
          );
        }}
      </Await>
    </Suspense>
  );
}
export default FinancialRatios;
