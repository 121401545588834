const CONTENT_ITEM_TYPES = {
  INDENT: {
    ID: 'indented_text',
    CLASS_NAME: 'indentElement',
  },
  IMAGE: {
    ID: 'image',
    CLASS_NAME: 'imageElement',
    KEY: 'data-imagekey',
    SRC: 'data-imageurl',
    DESCRIPTION: 'data-description',
    ALT_TEXT: 'data-imagealt',
    PADDING: 'data-padding',
    ALIGN: 'data-alignment',
    WRAP_TEXT: 'data-wraptext',
    HEIGHT: 'data-imageheight',
    WIDTH: 'data-imagewidth',
  },
  TABLE: {
    ID: 'table_element',
    CLASS_NAME: 'tableElement',
    INDENT: 'data-indentation',
  },
  PDF: {
    ID: 'pdf_item',
  },
  LINKABLE_SECTION: {
    ID: 'linkable_section',
    CLASS_NAME: 'linkableSection',
  },
  MANDATORY_REQUIREMENT_REPORT_SECTION: {
    ID: 'report_chapter___list_of_requirements',
  },
  LIST: {
    ID: 'list',
  },
  FEEDBACK_FORM: {
    ID: 'feedback_form',
  },
  ANNUAL_REPORT: {
    ID: 'annual_report',
  },
  GOVERNMENT_BODY: {
    ID: 'government_body',
    COLLECTION: 'admin',
  },
  PORTFOLIO: {
    ID: 'portfolio_metadata',
    COLLECTION: 'admin',
  },
  STANDARD_PAGE: {
    ID: 'page',
    COLLECTION: 'admin',
  },
  PORTAL_MAIN_FOOTER: {
    ID: 'portal_header_footer_metadata',
    CODENAME: 'tp_main_portal_footer',
    COLLECTION: 'admin',
  },
  LINK: {
    ID: 'link',
  },
  PORTAL_HOMEPAGE: {
    ID: 'portal_homepage_metadata',
    CODENAME: 'tp_homepage_metadata',
    COLLECTION: 'admin',
  },
  ANAO_REPORT_SECTION: {
    ID: 'anao_report_section',
  },
  DATA_TABLE: {
    ID: 'data_table',
  },
  REPORT_SECTION: {
    ID: 'report_sections',
  },
  REPORT_CHAPTER: {
    ID: 'report_chapter',
  },
  FILE: {
    ID: 'file_download',
    FILE_NAME: 'data-file-name',
    FILE_SIZE: 'data-file-size',
    URL: 'data-url',
    FULL_WIDTH: 'data-take-full-width',
  },
  EMBEDDED_PDF: {
    ID: 'financialstatementembed',
    CLASS_NAME: 'financialstatementembed',
  },
  REPORT_CHAPTER_MIGRATED: {
    ID: 'report_chapter_data_migration',
  },
  FINANCIAL_RATIO_PAGE: {
    CODENAME: 'financial_ratios_page',
    ABOUT: { CODENAME: 'about_content', DISPLAY: 'About financial ratios' },
    BANNER_CONTENT: { CODENAME: 'banner_content' },
    TOTAL_LIABILTIIES: {
      CODENAME: 'total_liabilities_to_total_assets_ratio_content',
      DISPLAY: 'Total liabilities to total assets ratio',
      HEADING: 'Total liabilities to total assets',
      TABLE: 'total_liabilities_to_total_assets_ratio_table',
    },
    FINANCIAL_ASSETS: {
      CODENAME: 'financial_assets_to_total_liabilities_ratio_content',
      DISPLAY: 'Financial assets to total liabilities ratio',
      HEADING: 'Financial asset to liabilities ratio',
      TABLE: 'financial_assets_to_total_liabilities_ratio_table',
    },
    CAPITAL_TURNOVER: { CODENAME: 'capital_turnover_ratio_content', DISPLAY: 'Capital turnover ratio', HEADING: 'Capital turnover', TABLE: 'capital_turnover_ratio_table' },
    CURRENT_RATIO: { CODENAME: 'current_ratio_content', DISPLAY: 'Current ratio', HEADING: 'Current ratio', TABLE: 'current_ratio_table' },
  },
  DATA_SETS_PAGE: {
    CODENAME: 'data_sets_page',
    DATA_SET_ANNOTATION: 'data_set_annotation',
    DEFINITIONS_SCHEMA: 'definitions_and_schema',
  },
  SEARCH_PAGE_METADATA: {
    CODENAME: 'search_page_metadata',
  },
};

export default CONTENT_ITEM_TYPES;
