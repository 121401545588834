export const DOMAIN_URL = {
  PREVIEW: {
    DEV: 'https://dev-dart.dxau.digital',
    DEV_PUBLISHED: 'https://dev-dart-published.dxau.digital',
    UAT: 'https://uat-dart.dxau.digital',
    UAT_PUBLISHED: 'https://uat-dart-published.dxau.digital',
    PROD: 'https://preview.transparency.gov.au',
    PROD_PUBLISHED: 'https://www.transparency.gov.au',
    LOCAL_HOST: 'http://localhost:3000',
  },
};

export const DOMAIN_LIST = Object.values({ ...DOMAIN_URL.PREVIEW });
