import { PATHS, URL_PARAMS } from 'Constants';

/**
 * Function to format number with comma. eg: 123456 => 123,456
 * 
 * NOTE: if you pass number with multiple `.`, such as 123456.1.2, anything after first `.` will be ignored (123456.1.2 => 123,456.1)
 * 
 * @param {String|Number} num Number that will be formatted with comma.
 * @returns {String} Formatted number.
 */
export const formatNumber = (num) => {
  const numberInString = `${num}`.split('.');
  return `${numberInString[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${numberInString[1] ? `.${numberInString[1]}` : ''}`;
};

/**
 * Function to check if given string is valid URL or not.
 * @param {String} url String to check if is valid url. To be valid url, must include correct protocol as well or starts with `/` or `#`.
 * @returns {Boolean} Return true if string is valid url.
 */
export const validUrl = (url) => {
  if (url?.startsWith('/') || url?.startsWith('#')) {
    return true;
  }

  try {
    return Boolean(new URL(url));
  } catch {
    return false;
  }
};

/**
 * This function take standard page's url slug and form standard page url without `/` at the start
 * @param {String} standardPageUrlSlug Url slug from Page content item
 * @return {String} Return standard page url string without `/` at the start
 */
export const standardPageUrl = (standardPageUrlSlug) => {
  if (!standardPageUrlSlug || typeof standardPageUrlSlug !== 'string') console.warn(`[WARNING] standardPageUrl :: \`standardPageUrlSlug\` must be string type.`);

  return `${standardPageUrlSlug || `:${URL_PARAMS.STANDARD_PAGE}`}`;
};

/**
 * This function take portfolio metadata's url slug and form portfolio profile page url without `/` at the start
 * @param {String} portfolioUrlSlug Portfolio metadata url slug
 * @return {String} Return portfolio profile page url string without `/` at the start
 */
export const portfolioProfileUrl = (portfolioUrlSlug) => {
  if (!portfolioUrlSlug || typeof portfolioUrlSlug !== 'string') console.warn(`[WARNING] portfolioProfileUrl :: \`portfolioUrlSlug\` must be string type.`);

  return `${PATHS.PORTFOLIOS_ENTITIES_COMPANIES.BASE}/${portfolioUrlSlug || `:${URL_PARAMS.PORTFOLIO}`}`;
};

/**
 * This function take url slug of portfolio metadata and government body and form entity profile page url without `/` at the start
 * @param {String} portfolioUrlSlug Portfolio metadata url slug
 * @param {String} governmentBodyUrlSlug Government body url slug
 * @returns {String} Return entity profile page url without `/` at the start
 */
export const entityProfileUrl = (portfolioUrlSlug, governmentBodyUrlSlug) => {
  if (!portfolioUrlSlug || typeof portfolioUrlSlug !== 'string') console.warn(`[WARNING] entityProfileUrl() :: \`portfolioUrlSlug\` must be string type.`);
  if (!governmentBodyUrlSlug || typeof governmentBodyUrlSlug !== 'string') console.warn(`[WARNING] entityProfileUrl() :: \`governmentBodyUrlSlug\` must be string type.`);

  return `${portfolioProfileUrl(portfolioUrlSlug)}/${governmentBodyUrlSlug || `:${URL_PARAMS.ENTITY}`}`;
};

/**
 * This function takes three url slug strings and form annual report base url without `/` at the start
 * @param {String} portfolioUrlSlug Portfolio metadata url slug
 * @param {String} governmentBodyUrlSlug Government body url slug
 * @param {String} annualReportUrlSlug Annual report url slug
 * @returns {String} Return annual report base url without `/` at the start
 */
export const annualReportUrl = (portfolioUrlSlug, governmentBodyUrlSlug, annualReportUrlSlug) => {
  if (!portfolioUrlSlug || typeof portfolioUrlSlug !== 'string')
    console.warn(`[WARNING] annualReportUrl() with \`annualReportUrlSlug\`: "${annualReportUrlSlug}" :: \`portfolioUrlSlug\` must be string type.`);
  if (!governmentBodyUrlSlug || typeof governmentBodyUrlSlug !== 'string')
    console.warn(`[WARNING] annualReportUrl() with \`annualReportUrlSlug\`: "${annualReportUrlSlug}" :: \`governmentBodyUrlSlug\` must be string type.`);
  if (!annualReportUrlSlug || typeof annualReportUrlSlug !== 'string')
    console.warn(`[WARNING] annualReportUrl() with \`annualReportUrlSlug\`: "${annualReportUrlSlug}" :: \`annualReportUrlSlug\` must be string type.`);

  return `${PATHS.PUBLICATIONS_LIST.BASE}/${portfolioUrlSlug || `:${URL_PARAMS.PORTFOLIO}`}/${governmentBodyUrlSlug || `:${URL_PARAMS.ENTITY}`}/${annualReportUrlSlug || `:${URL_PARAMS.ANNUAL_REPORT}`
    }`;
};

/**
 * This function take a string and replace all space with `_` and make lower cases.
 * @param {String} string Initial string
 * @returns {String} Return converted string
 */
export const titleToUrl = (string) => {
  return encodeURIComponent(`${string?.replace(/\s+/g, '-')?.replace(/(%26)/g, '&')?.toLowerCase()?.trim()}`);
};

/**
 * This function takes a string and replaces all occurrences of 'stringToReplace' with 'replaceWith'
 * @param {String} string The initial string
 * @param {String} stringToReplace Substring inside the initial string to replace
 * @param {String} replaceWith New string to replace with
 * @returns Converted string with all occurrences of 'stringToReplace' replaced with 'replaceWith'
 */
export const replaceInString = (string, stringToReplace, replaceWith) => {
  if (typeof string !== 'string') return '';
  if (typeof stringToReplace !== 'string' || typeof replaceWith !== 'string') return string;

  return `${string.replaceAll(stringToReplace, replaceWith)}`;
};

/**
 * Remove all html tags from string
 * @param {String} string - String to remove html tags
 * @returns {String} - String with replaced html tags
 */
export const removeHtmlTagsInString = (string) => {
  if (typeof string !== 'string') return '';
  return new DOMParser().parseFromString(string, 'text/html').querySelector('body')?.innerText || '';
};

/**
 * Converts the given string to Sentence case.
 * @param {string} string - The string to be transformed.
 * @returns - The string converted to Sentence case.
 */
export const toSentenceCase = (string) => {
  if (typeof string !== 'string') return '';
  if (string === '') return '';

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 * This function takes a string and returns a url object from that string (if is valid url)
 * @param {String} urlString Url string
 * @returns URL object or 'null' if the string is not a valid url
 */
export const getUrlObject = (urlString) => {
  try {
    const url = new URL(urlString);
    return url;
  } catch {
    return null;
  }
};

/**
 * Converts a stringed number into one with commas (when the number is large enough - 1000+)
 * @param {String} string - Number in form of a string
 * @returns Number string with commas e.g. 1,345,000, if it is not a number, returns original string
 */
export const formatStringNumberWithCommas = (string) => {
  const number = parseFloat(string);
  if (!isNaN(number) && !isNaN(string)) {
    return number.toLocaleString('en-US', { maximumFractionDigits: 2 });
  }
  return string;
};
