import { URL_QUERY_KEYS } from 'Constants';
import { SEARCH_API_CONSTANTS, SEARCH_API_FUNCTION } from 'Constants/Search';

/**
 * Updates multiselect object and selected filters with new selection from given option
 * @param {{display: String, returnValue: String, selected: String}} updatedOption - Option to change in array (Will directly alter the 'selected' value in the object to be true/false)
 * @param {{`filterOptionName`: {display: String, returnValue: String, selected: Boolean, filterGroupTitle: String}[]}} currentMultiSelectOptions - Object of all multiselect filters, Each multiselect filter has a property, with an Array of filter options
 * @param {{display: String, returnValue: String, selected: Boolean, filterGroupTitle: String}[]} currentSelectedFilters - List of all currently selected filters (from any filter category)
 * @param {String} filterGroup - Filter group title/name, used to specify which 'currentMultiSelectOptions' FilterOptionName to change the multi select options
 * @returns {Object} - Returns object containing properties 'newMultiSelectOptions' and 'newCurrentSelectFilters' which are updated selectedOptions and currentSelectedFilters
 */
const updateMultiSelect = (updatedOption, currentMultiSelectOptions, currentSelectedFilters, filterGroup) => {
  let newMultiSelectOptions = { ...currentMultiSelectOptions };
  let newCurrentSelectFilters = [...currentSelectedFilters];
  if (updatedOption && 'selected' in updatedOption) {
    const previousSelection = updatedOption.selected;
    const foundOptionIndex = currentMultiSelectOptions[filterGroup].findIndex((option) => {
      return option.returnValue === updatedOption.returnValue;
    });

    // update multiselect to be when toggled
    if (foundOptionIndex !== -1) {
      const updatedMultiSelect = [...currentMultiSelectOptions[filterGroup]];
      updatedMultiSelect[foundOptionIndex].selected = !updatedMultiSelect[foundOptionIndex].selected;
      newMultiSelectOptions = { ...currentMultiSelectOptions, [filterGroup]: updatedMultiSelect };
    }

    // Add/remove from currently selected filters when the multi selection has been added
    if (previousSelection === false) {
      newCurrentSelectFilters = [...newCurrentSelectFilters, updatedOption];
    } else {
      newCurrentSelectFilters = newCurrentSelectFilters.filter((item) => item.returnValue !== updatedOption.returnValue);
    }
  }
  return { newMultiSelectOptions, newCurrentSelectFilters };
};

/**
 * Update a single multi-select of dropdown option on selection. Also updates the selected filters
 * @param {{display: String, returnValue: String, selected: Boolean, filterGroupTitle: String}} selectedOption - New selected option
 * @param {String} filterGroup - Filter group title/name
 * @param {{`filterOptionName`: {display: String, returnValue: String, selected: Boolean, filterGroupTitle: String}[]}} currentMultiSelectOptions - Object of all multiselect filters, Each multiselect filter has a property, with an Array of filter options
 * @param {Function} setMultiSelectOptionsFunction - Function to update multiselect options
 * @param {{display: String, returnValue: String, selected: Boolean, filterGroupTitle: String}[]} currentSelectedFilters - List of all currently selected filters (from any filter category)
 * @param {Function} setSelectedFiltersFunction - Function to update selected filters
 */
export const updateMultiSelection = (selectedOption, filterGroup, currentMultiSelectOptions, setMultiSelectOptionsFunction, currentSelectedFilters, setSelectedFiltersFunction) => {
  // Note: Relies on there being unique return values
  const { newMultiSelectOptions, newCurrentSelectFilters } = updateMultiSelect(selectedOption, currentMultiSelectOptions, currentSelectedFilters, filterGroup);

  setMultiSelectOptionsFunction(newMultiSelectOptions);
  setSelectedFiltersFunction(newCurrentSelectFilters);
};

/**
 * Updates multiple/a list of dropdown options on selection. Also updates the selected filters
 * @param {{display: String, returnValue: String, selected: Boolean, filterGroupTitle: String}[]} updatedMultiSelectList - List of all options to update
 * @param {String} filterGroup - Filter group title/name
 * @param {{`filterOptionName`: {display: String, returnValue: String, selected: Boolean, filterGroupTitle: String}[]}} currentMultiSelectOptions - Object of all multiselect filters, Each multiselect filter has a property, with an Array of filter options
 * @param {Function} setMultiSelectOptionsFunction - Function to update multiselect options
 * @param {{display: String, returnValue: String, selected: Boolean, filterGroupTitle: String}[]} currentSelectedFilters - List of all currently selected filters (from any filter category)
 * @param {Function} setSelectedFiltersFunction - Function to update selected filters
 */
export const updateEntireList = (
  updatedMultiSelectList,
  filterGroup,
  currentMultiSelectOptions,
  setMultiSelectOptionsFunction,
  currentSelectedFilters,
  setSelectedFiltersFunction,
) => {
  let updatedCurrentSelectFilters = [...currentSelectedFilters];

  updatedMultiSelectList.forEach((updatedOption) => {
    const { newMultiSelectOptions, newCurrentSelectFilters } = updateMultiSelect(updatedOption, currentMultiSelectOptions, updatedCurrentSelectFilters, filterGroup);
    setMultiSelectOptionsFunction(newMultiSelectOptions);
    updatedCurrentSelectFilters = newCurrentSelectFilters;
  });

  setSelectedFiltersFunction(updatedCurrentSelectFilters);
};

/**
 * Assigns a filter group to all dropdown values given in the filterList
 * @param {{returnValue: String, display: String, selected: Boolean}[]} filterList - List of dropdown options for filters
 * @param {String} filterGroup - Filter group to assign title to
 * @returns {{returnValue: String, display: String, selected: Boolean, filterGroupTitle: String}[]} - Filter dropdown options have filter group added to their options
 */
export const assignFilterGroup = (filterList, filterGroup) => {
  if (Array.isArray(filterList) && filterGroup) {
    return filterList.map((filterObject) => {
      const filterOption = { ...filterObject };
      if (filterObject) {
        filterOption.filterGroupTitle = filterGroup;
      }
      return filterOption;
    });
  }
};

/**
 * Find display name from return value (specifically for filters)
 * @param {String} filterGroupValue - Filter value to search by
 * @param {{display: String, returnValue: String}[]} filterableGroupsList - Filter list to search filterGroupValue in
 * @returns {String} Returns the display value for filter value (if found), otherwise returns the return value itself
 *                   - If the filterGroupValue is not found at all in fitlerableGroupList', returning empty string
 */
export const getFilterGroupDisplayName = (filterGroupValue, filterableGroupsList) => {
  const foundFilter = filterableGroupsList.find((filterGroup) => {
    return filterGroup.returnValue === filterGroupValue;
  });
  return foundFilter ? foundFilter.display || foundFilter.returnValue : '';
};

/**
 * Group filters by filter group
 * @param {{returnValue: String, display: String, filterGroupTitle: String}[]} filterList - List of filters, should have the 'filterGroupTitle' value to sort by group
 * @returns {Object} - Each key is the filter group and contains an array of all filters
 */
export const groupFiltersByFilterGroup = (filterList) => {
  const groupedFilters = {};
  filterList.forEach((option) => {
    if (option.filterGroupTitle) {
      if (groupedFilters[option.filterGroupTitle]) {
        groupedFilters[option.filterGroupTitle].push(option.returnValue);
      } else {
        groupedFilters[option.filterGroupTitle] = [option.returnValue];
      }
    }
  });

  return groupedFilters;
};

/**
 * Groups filters and converts each group filter list to a string, separated by ';'
 * @param {{returnValue: String, display: String, filterGroupTitle: String}[]} selectedFilters - List of filters, should have the 'filterGroupTitle' value to sort by group
 * @returns {Object} - Each key is the filter group and contains a string value
 */
export const groupFiltersForUrl = (selectedFilters) => {
  const groupedFilters = groupFiltersByFilterGroup(selectedFilters);
  Object.keys(groupedFilters).forEach((key) => {
    if (groupedFilters[key] && Array.isArray(groupedFilters[key])) {
      groupedFilters[key] = groupedFilters[key].join(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
    }
  });

  return groupedFilters;
};

/**
 * Convert filters params in url to OData expression to be used in search API
 * @param {Object} urlSearchParams - Search object params that contains keys of all url params
 * @returns
 */
export const convertFilterUrlToApi = (urlSearchParams) => {
  let filters = [];

  // Filters will have `$` as its prefix
  for (const key of urlSearchParams.keys()) {
    if (key && key.length > 1 && key[0] === URL_QUERY_KEYS.FILTER_DENOTE) {
      const selectedFilterValues = urlSearchParams.get(key);
      if (selectedFilterValues) {
        // Add converted filter function which will add the key and filter values (split by ';')
        filters.push(SEARCH_API_FUNCTION.FILTER_IN(key.replace(URL_QUERY_KEYS.FILTER_DENOTE, ''), selectedFilterValues.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY)));
      }
    }
  }

  const filterString = SEARCH_API_FUNCTION.COMBINE_EXPRESSION(filters, SEARCH_API_CONSTANTS.AND); // combines all filter expressions
  return filterString;
};

/**
 * Checks if given search value is matching to any dropdown options by acronym
 * This will check for both acronyms formed from first letter of every word, or first letter of every capitalised word
 * @param {{display: String, returnValue: String}[]} dropdownOptions - Array of dropdown options, which should contain the property 'display'
 * @param {String} searchValue - Search term to look for
 * @returns
 */
export const filterSearchAcronym = (dropdownOptions, searchValue) => {
  const dropdownOptionsAcronym = [];
  dropdownOptions.forEach((option) => {
    // converted acronym by spaces and if it matches search value, then add to list
    const convertedAcronym = option?.display?.match(/\b\w/g)?.join('');
    if (convertedAcronym) {
      if (convertedAcronym.toLowerCase() === searchValue.toLowerCase()) {
        dropdownOptionsAcronym.push(option.display);
      }
    }
    // converted acronym by spaces for ONLY capital words and if it matches search value, then add to list
    const convertedCapitalAcronym = option?.display?.match(/[A-Z]/g)?.join('');
    if (convertedCapitalAcronym) {
      if (convertedCapitalAcronym.toLowerCase() === searchValue.toLowerCase()) {
        dropdownOptionsAcronym.push(option.display);
      }
    }
  });
  return dropdownOptionsAcronym;
};
