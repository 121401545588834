import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import style from './AlphabetGroupFilterList.module.scss';
import { GroupedFilterList, SearchBar } from 'Components';
import { debounce, detectViewMode, filterSearchAcronym } from 'Utils';
import { CONSTANTS } from 'Constants';
import { TAG_POSITION_ENUMS } from 'Constants/Constants';

/**
 * AlphabetGroupFilterList.jsx
 *
 * @summary Component for showing dropdown list select with a search functionality and 'jump to alphabetical section' functionality
 *
 * @param {Object} props - Component props.
 * @prop {{display: String, returnValue: String, tag: {display: String, type: String, tagPosition: String}}[]} props.dropdownOptions - Sorted list of dropdown options. Each option is an object that should have properties:
 * - 'returnValue', 'display'
 * - 'tag' which contains 'display' string for tag, 'type' of tag @enums Defined by TAG_STYLE_ENUMS under `/Constants/Constants.js` and 'tagPosition' to render where to place tag. Must be one of the options in TAG_POSITION_ENUMS
 * @prop {Function} props.selectOptionFunction - Select function used to select an option
 * @prop {Function} props.selectMultipleOptionFunction - Select multiple options function
 * @prop {String} [props.searchPlaceholder] - Placeholder text for search box. Defaults to 'Enter your text here' if not given
 * @prop {Boolean} [props.hideAlphabetButtons] - Override hiding alphabet buttons
 * @prop {String} [props.extraStyleClass] - Extra styling class
 * @prop {String} [props.extraListStyleClass] - Extra styling for grouped filter list component
 *
 * @description Note: props.dropdownOptions is assumed to already be sorted in alphabetical order. This component only sorts into alphabetical groups, but does not sort the groups in order.
 *
 */
function AlphabetGroupFilterList({
  dropdownOptions,
  selectOptionFunction,
  selectMultipleOptionFunction,
  searchPlaceholder,
  selectAllState,
  setSelectAllState,
  hideAlphabetButtons,
  extraStyleClass,
  extraListStyleClass,
}) {
  const [sortedAlphabetGroups, setSortedAlphabetGroups] = useState(sortOptionsIntoAlphabetGroups(dropdownOptions));
  const [selectAll, setSelectAll] = useState(selectAllState || false);
  const alphabetList = 'abcdefghijklmnopqrstuvwxyz'.split('');
  const [viewMode, setViewMode] = useState(detectViewMode());
  const optionListRef = useRef();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  useEffect(() => {
    setSelectAll(selectAllState);
  }, [selectAllState]);

  useEffect(() => {
    updateFilteredValue(inputValue || '');
  }, [dropdownOptions]);

  /**
   * Filter down value based on search
   * @param {String} newValue - value to filter down options with
   */
  const updateFilteredValue = (newValue) => {
    const matchedSearchAcronyms = filterSearchAcronym(dropdownOptions, newValue);
    const fitleredList = dropdownOptions.filter((option) => {
      if (option.display) {
        return option.display.toLowerCase().includes(newValue.toLowerCase()) || matchedSearchAcronyms.includes(option.display);
      }
      return false;
    });

    setInputValue(newValue);
    setSortedAlphabetGroups(sortOptionsIntoAlphabetGroups(fitleredList));
  };

  /**
   * Selects all options
   */
  const selectAllOptionsShown = () => {
    const selectAllOptionsShown = [];
    Object.keys(sortedAlphabetGroups).forEach((alphabetGroup) => {
      sortedAlphabetGroups[alphabetGroup]?.forEach((dropdownOption) => {
        // sets all dropdown options to true/false, depending on ticked 'Select all shown' box
        if (dropdownOption.selected === selectAll) {
          selectAllOptionsShown.push(dropdownOption);
        }
      });
    });

    // If was previously unticked, tick the box, and vice versa
    if (!selectAll) {
      setSelectAll(true);
      if (setSelectAllState) {
        setSelectAllState(true);
      }
    } else {
      setSelectAll(false);
      if (setSelectAllState) {
        setSelectAllState(false);
      }
    }
    selectMultipleOptionFunction(selectAllOptionsShown);
  };

  /**
   * Sorts list of options into an object with each key containing a list (seprated by alphabet letter)
   * @param {{display: String, returnValue: string}[]} options - Initial list of options
   * @returns {Object} Sorted options by first letter into their respective alphabet group, with each key being the alphabet letter (lowercase) and each key having a list of dropdown options as its value.
   * @example E.g. {a: [{display: String, returnValue: String...}, b: [{display: String, returnValue: String...}], ...]}
   */
  function sortOptionsIntoAlphabetGroups(options) {
    const sortedData = options.reduce((updatedAlphabetObject, currentValue) => {
      const alphabetGroup = currentValue.display?.[0]?.toLowerCase();
      if (!updatedAlphabetObject[alphabetGroup]) {
        updatedAlphabetObject[alphabetGroup] = [currentValue];
      } else {
        updatedAlphabetObject[alphabetGroup].push(currentValue);
      }

      return updatedAlphabetObject;
    }, {});
    return sortedData;
  }

  /**
   * Jump to alphabet group section in the list of options
   * @param {String} letter - Letter to jump to section
   */
  const jumpToAlphabetGroup = (letter) => {
    const alphabetSection = document.getElementById(`alphabet-group-${letter}`);
    optionListRef.current.scrollTo({ top: alphabetSection.offsetTop - 150, behavior: 'smooth' }); // offset by 150
  };

  /**
   * Returns whether all shown options are currently selected. Used to detect checkbox changes between 'tick' and 'dash'
   * @returns {Boolean} - True if all options are currently selected, else false
   */
  function hasAllCurrentlySelected() {
    const allSelected = Object.keys(sortedAlphabetGroups).every((alphabetGroup) => {
      return sortedAlphabetGroups[alphabetGroup]?.every((dropdownOption) => dropdownOption.selected === true);
    });
    return allSelected;
  }

  return (
    <div className={[style.alphabetFilterList, hideAlphabetButtons && style.hideAlphabetButtons, extraStyleClass].join(' ')}>
      {viewMode !== CONSTANTS.VIEW_MODE.MOBILE && !hideAlphabetButtons && (
        <div className={style.alphabetButtons}>
          {alphabetList.map((letter) => {
            return (
              <button key={`alphabet-button-${letter}`} className="lightFill" disabled={!sortedAlphabetGroups[letter]} onClick={() => jumpToAlphabetGroup(letter)}>
                {letter.toUpperCase()}
              </button>
            );
          })}
        </div>
      )}
      <div className={style.searchList}>
        <div className={style.searchBarContainer}>
          <SearchBar
            placeholder={searchPlaceholder || 'Enter your text here'}
            updateQuery={false}
            onSubmit={updateFilteredValue}
            onClear={updateFilteredValue}
            ariaDescription="On this options list"
          />
          <label className={style.checkboxLabel}>
            <input className={!hasAllCurrentlySelected() && selectAll ? 'dashCheckbox' : ''} type="checkbox" checked={selectAll} onChange={selectAllOptionsShown} />
            Select all shown
          </label>
        </div>
        <GroupedFilterList
          optionListRef={optionListRef}
          groupedDropdownOptions={Object.keys(sortedAlphabetGroups)
            .sort()
            .reduce((obj, key) => {
              obj[key] = sortedAlphabetGroups[key];
              return obj;
            }, {})}
          selectOptionFunction={selectOptionFunction}
          extraStyleClass={extraListStyleClass}></GroupedFilterList>
      </div>
    </div>
  );
}

AlphabetGroupFilterList.propTypes = {
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string.isRequired,
      returnValue: PropTypes.string.isRequired,
      tag: PropTypes.shape({
        display: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        tagPosition: PropTypes.oneOf(Object.values(TAG_POSITION_ENUMS)),
      }),
    }),
  ).isRequired,
  selectOptionFunction: PropTypes.func.isRequired,
  selectMultipleOptionFunction: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  selectAllState: PropTypes.bool,
  setSelectAllState: PropTypes.func,
  hideAlphabetButtons: PropTypes.bool,
  extraStyleClass: PropTypes.string,
  extraListStyleClass: PropTypes.string,
};

export default AlphabetGroupFilterList;
