import React from 'react';
import { Loading } from 'Components';
import style from './PublicationsLoading.module.scss';

/**
 * PublicationsLoading.jsx
 *
 * @summary This component shows the loading state page view for the PublicationsList page.
 *
 */
function PublicationsLoading() {
  return (
    <>
      <div id={style.publicationsLoading}>
        <div className={style.publicationsList}>
          {Array(16)
            .fill('publication-tile')
            .map((value, index) => {
              return <Loading key={`${value}-${index}`} className={style.cardTile} />;
            })}
        </div>
        <div className={style.paginationLoading}>
          <Loading />
        </div>
      </div>
    </>
  );
}

export default PublicationsLoading;
