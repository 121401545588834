import React from 'react';
import { Loading, LoadingMenu } from 'Components';
import style from './ProfilePageLoading.module.scss';

function ProfilePageLoading() {
  return (
    <>
      <Loading className={style.loadingBanner} />
      <div id={style.bottomHalf}>
        <div>
          <h1 id={style.headingStyle}>
            <Loading>
              <span className={style.invisibleTitle}>Loading...</span>
            </Loading>
          </h1>
          <div id={style.profilesLoading}>
            <div className={style.profilesList}>
              {Array(12)
                .fill('profile-tile')
                .map((value, index) => {
                  return <Loading key={`${value}-${index}`} className={style.cardTile} />;
                })}
            </div>
          </div>
        </div>
        <LoadingMenu className={style.loadingHistory} />
      </div>
    </>
  );
}

export default ProfilePageLoading;
