import React, { useState, useId, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CONSTANTS } from 'Constants';
import { OutsideClickDetector } from 'Components';
import { debounce } from 'Utils';
import icon from 'Assets/svgSprite.svg';
import style from './FilterGroupDropdown.module.scss';

const DEFAULT_DIMENSIONS = {
  height: 0,
  width: 0,
};

/**
 * FilterGroupDropdown.jsx
 *
 * @summary Component for filter group dropdown functionality.
 *
 * @param {Object} props - Component props.
 * @prop {Node} props.children - Any children to be added that will display when dropdown is opened. If no children given, dropdown will default to ListSelect.
 * @prop {String} props.filterGroupTitle - Filter title/value of group. Used for indexing and saving to url (the main title)
 * @prop {String} [props.filterGroupDisplayName] - Filter display name of group. Used to display for dropdown. If not given, will use `filterGroupTitle`.
 * @prop {Any} [props.closeDropdownTrigger] - Value to trigger dropdown closing
 *
 * @description Note that this is the styling for the button only and the functionality of dropdown can be handled by passing in children (which is required).
 */
function FilterGroupDropdown({ children, filterGroupTitle, filterGroupDisplayName, closeDropdownTrigger = false }) {
  const [displayDropdownContent, setDisplayDropdownContent] = useState(false);
  const contentId = useId();
  const dropdownButtonId = useId();
  const dropdownRef = useRef();
  const dropdownButtonRef = useRef();
  const dropdownOptionsRef = useRef();
  // uses following dimensions to calculate where the dropdown will appear on screen
  const [dropdownOptionsDimensions, setDropdownOptionsDimensions] = useState({ ...DEFAULT_DIMENSIONS });
  const [dropdownButtonPosition, setDropdownButtonPosition] = useState({ top: 0, right: 0, bottom: 0, left: 0 });
  const [windowDimensions, setWindowDimensions] = useState(DEFAULT_DIMENSIONS);

  useEffect(() => {
    closeDropdown();
  }, [closeDropdownTrigger]);

  useEffect(() => {
    // calculates current dimensions of dropdown options
    if (dropdownOptionsRef.current) {
      setDropdownOptionsDimensions({ width: dropdownOptionsRef.current.clientWidth, height: dropdownOptionsRef.current.clientHeight });
    }

    // calculates the position of the current button (that opens dropdown)
    // dropdown options should appear directly under this button
    if (dropdownButtonRef.current) {
      const { top, right, bottom, left } = dropdownButtonRef.current.getBoundingClientRect();
      setDropdownButtonPosition({ top, right, bottom, left });
    }

    const windowDetails = { width: document.documentElement.clientWidth, height: window.innerHeight };
    setWindowDimensions(windowDetails);
  }, [displayDropdownContent]);

  useEffect(() => {
    // recalculate window, dropdown button and dropdown option dimensions/position when the screen size is changed
    const detectViewPortChange = debounce(() => {
      const windowDetails = { width: document.documentElement.clientWidth, height: window.innerHeight };
      setWindowDimensions(windowDetails);

      if (dropdownOptionsRef.current) {
        setDropdownOptionsDimensions({ width: dropdownOptionsRef.current.clientWidth, height: dropdownOptionsRef.current.clientHeight });
      }

      if (dropdownButtonRef.current) {
        const { top, right, bottom, left } = dropdownRef.current.getBoundingClientRect();
        setDropdownButtonPosition({ top, right, bottom, left });
      }
    });

    // rerun dimensions each time a resize in window
    window.addEventListener('resize', detectViewPortChange);

    return () => {
      window.removeEventListener('resize', detectViewPortChange);
    };
  });

  const toggleDropdown = (event) => {
    event.preventDefault(); // prevent reload when used in forms
    setDisplayDropdownContent((prevValue) => {
      return !prevValue;
    });
  };

  const closeDropdown = () => {
    // allows for onBlur to only close dropdown if target clicked is outside the dropdown
    // clicking button or input will not close the dropdown
    if (displayDropdownContent) {
      setDisplayDropdownContent(false);
    }
  };

  useEffect(() => {
    const close = (e) => {
      // listen for escape key pressed
      if (e.key === CONSTANTS.ESCAPE_KEY) {
        setDisplayDropdownContent(false); // escape should close the dropdown list
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  });

  const adjustDropdownPosition = () => {
    // if the current dropdown options is too large for window, adjust dropdown to appear on screen (hugs to right of screen)
    if (dropdownButtonPosition.left + dropdownOptionsDimensions.width > windowDimensions.width) {
      return style.floatRight;
    }
    return '';
  };

  return (
    <OutsideClickDetector callBack={closeDropdown}>
      <div ref={dropdownRef} className={style.filterDropdownWrapper}>
        <div className={[style.dropdownHeader, displayDropdownContent ? style.expandedDropdown : '', style.buttonDropdown].join(' ')}>
          <button
            id={dropdownButtonId}
            ref={dropdownButtonRef}
            aria-controls={contentId}
            aria-expanded={displayDropdownContent}
            onClick={toggleDropdown}
            className={['lightFill', style.triangle, style.buttonDropdown, displayDropdownContent ? 'activeState' : ''].join(' ')}
            disabled={children ? false : true}>
            <span>{filterGroupDisplayName ?? filterGroupTitle}</span>
            <svg>
              <use href={`${icon}#triangle`} />
            </svg>
          </button>
        </div>
        {displayDropdownContent && (
          <div className={[style.dropdownOptions, adjustDropdownPosition()].join(' ')} ref={dropdownOptionsRef}>
            {children}
          </div>
        )}
      </div>
    </OutsideClickDetector>
  );
}

FilterGroupDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  filterGroupTitle: PropTypes.string.isRequired,
  filterGroupDisplayName: PropTypes.string,
  closeDropdownTrigger: PropTypes.any,
};

export default FilterGroupDropdown;
