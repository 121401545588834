import React, { Suspense, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Await, createSearchParams, Link, useNavigate, useRouteLoaderData, useSearchParams, useLocation } from 'react-router-dom';
import { LogosHeaderFooter, Modal, SearchBar } from 'Components';
import { CONSTANTS, PUBLIC_TP_URL, ROUTES, PATHS } from 'Constants';
import { debounce, detectViewMode, toSentenceCase } from 'Utils';
import style from './MainHeader.module.scss';
import icons from 'Assets/svgSprite.svg';

/**
 * MainHeader.jsx
 *
 * @summary This component is to be used as main header/navigation bar.
 *
 * @param {Object} props - Component props.
 * @param {Boolean} [props.isDART] - If given or true, render without search bar and indicates it is used for DART landing page.
 */
function MainHeader({ isDART }) {
  const [menuOpen, updatedMenuOpen] = useState(false);
  const [viewMode, setViewMode] = useState(detectViewMode());
  const [urlParams] = useSearchParams();
  const navigate = useNavigate();
  const { headerFooterData } = useRouteLoaderData(isDART ? PATHS.CONTRIBUTER_LANDING_PAGE : PATHS.HOME.ID);
  const { pathname } = useLocation();

  // override backdrop style
  const backdropStyle = {
    top: viewMode !== CONSTANTS.VIEW_MODE.DESKTOP ? '68px' : '80px', // 80px (header height) and 68px for mobile
    '--ANIMATION-TIME': '0.4s', // override animation time
  };

  // override modal style
  const modalStyle = {
    position: 'sticky',
    marginLeft: 'auto',
    marginTop: '30px',
    transform: viewMode !== CONSTANTS.VIEW_MODE.MOBILE ? 'translateX(-10px)' : '',
    maxWidth: viewMode !== CONSTANTS.VIEW_MODE.MOBILE ? '392px' : '100%',
    width: '100%',
    '--ANIMATION-TIME': '0.4s', // override animation time
  };

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  // always assume that all menu items are added as children of the 'home' path
  const filteredRoutes = ROUTES[0].children.filter((route) => {
    return route.title; // assumes that only routes with 'title' attribute are added in the route object (paths with '' or 'page-not-found' do not have title attribute)
  });

  const menuList = filteredRoutes.map((route) => {
    return { title: toSentenceCase(route.title), path: route.path };
  });

  const handleOpenMenu = () => {
    updatedMenuOpen((prevState) => {
      return !prevState;
    });
  };

  const handleSearch = (searchTerm) => {
    // if there are other keys in url (filters) then keep them in the url
    const urlParamsObject = {};
    if (pathname === `/${PATHS.SEARCH.BASE}`) {
      for (const key of urlParams.keys()) {
        urlParamsObject[key] = urlParams.get(key) || '';
      }
    }

    navigate({
      pathname: PATHS.SEARCH.BASE,
      search: createSearchParams({
        ...urlParamsObject,
        q: searchTerm,
      }).toString(),
    });
  };

  const searchBar = (
    <form role="search" aria-label="Sitewide">
      <SearchBar onSubmit={handleSearch} />
    </form>
  );

  return (
    <>
      <header id={style.header}>
        <div className={style.limiter}>
          <div className={style.navigationBar}>
            <LogosHeaderFooter isWhite={false} isDART={isDART} />

            <div className={style.headerItems}>
              {isDART ? (
                <a id={style.toTPLink} className="btn lightFill" href={PUBLIC_TP_URL}>
                  Visit Transparency Portal
                </a>
              ) : (
                <>
                  {viewMode !== CONSTANTS.VIEW_MODE.MOBILE && searchBar}
                  <button
                    className={style.toggleMenu}
                    onClick={handleOpenMenu}
                    alt="toggle navigation item menu"
                    aria-expanded={menuOpen}
                    aria-label={`${menuOpen ? 'Close' : 'Open'} menu`}
                    title={`${menuOpen ? 'Close' : 'Open'} menu`}>
                    {menuOpen ? 'Close' : 'Open'} menu
                    <svg>
                      <use href={`${icons}${!menuOpen ? '#hamburger' : '#close'}`} />
                    </svg>
                  </button>
                </>
              )}
            </div>
          </div>

          {menuOpen && (
            <Modal closeModal={handleOpenMenu} backdropStyleOverride={backdropStyle} modalStyleOverride={modalStyle}>
              {/* search bar is shown in the modal menu only for mobile view */}
              {viewMode === CONSTANTS.VIEW_MODE.MOBILE && <div className={style.mobileSearchBar}>{searchBar}</div>}
              <ol className={style.navigationList}>
                {headerFooterData && (
                  <Suspense>
                    <Await resolve={headerFooterData}>
                      {(resolvedData) => {
                        // NOTE: dynamic link menu itmes always appear above static link menu items
                        return resolvedData?.[isDART ? 'dartPageLinks' : 'tpPageLinks']
                          ?.filter((obj) => obj.isMenuItem)
                          ?.map((item, i) => {
                            return (
                              <li className={style.menuItem} key={`dynamic-menu-item-${item.title}-${i}`}>
                                <Link to={item.url} className={[style.menuLink, 'h6'].join(' ')} onClick={handleOpenMenu}>
                                  {item?.title}
                                </Link>
                              </li>
                            );
                          });
                      }}
                    </Await>
                  </Suspense>
                )}
                {menuList.map((item, i) => {
                  return (
                    <li className={style.menuItem} key={`menu-item-${item.title}-${i}`}>
                      <Link to={item.path} className={[style.menuLink, 'h6'].join(' ')} onClick={handleOpenMenu}>
                        {item.title}
                      </Link>
                    </li>
                  );
                })}
              </ol>
            </Modal>
          )}
        </div>
      </header>
    </>
  );
}

MainHeader.propTypes = {
  isDART: PropTypes.bool,
};

export default MainHeader;
