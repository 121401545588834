import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import style from './CompareFunction.module.scss';
import icons from 'Assets/svgSprite.svg';
import { Carousel, DropdownSelect, Loading, PdfViewer, Tag } from 'Components';
import { annualReportUrl, debounce, detectViewMode, entityProfileUrl, sortByObjectProperty } from 'Utils';
import { CONSTANTS, CONTENT_ITEM_TYPES, NO_CONTENT_FOUND_FOR_RULE, PUBLICATION_TYPE, TAG_STYLE_ENUMS, PATHS } from 'Constants';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { searchItems, getAContentItem } from 'Services/Delivery';
import { FILTERABLE_GROUPS, SEARCH_API_CONSTANTS, SEARCH_API_FUNCTION } from 'Constants/Search';
import { processCompareFunctionMandatoryRequirements } from 'Utils/AnnualReport/AnnualReport';
import { parseRichTextHtml, sortWebDataTable } from 'Utils/ProcessContents';

/**
 * CompareFunction.jsx
 *
 * @summary Component to display Compare functionality
 *
 * @param {Object} props - Component props.
 * @prop {Boolean} props.modalIsOpen - Controls compare function modal open
 * @prop {{ruleList: {ruleTitle: String, ruleDescription: String}[], performanceData: Object}} props.mandatoryRules - Mandatory rule list (includes list of rules and performance data - for web tables)
 * @prop {{entity: String, entityUrl: String, bodyType: String, reportingYear: String, statusText: String}} props.annualReportDetails - Current annual report details to display
 * @prop {Function} props.closeModalFunction - Function to close modal
 *
 * @description
 *
 */
function CompareFunction({ mandatoryRules, annualReportDetails, modalIsOpen, closeModalFunction }) {
  const [viewMode, setViewMode] = useState(detectViewMode());
  const [selectedRule, setSelectedRule] = useState({ index: null, title: '', description: '', content: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchedAnnualReports, setFetchedAnnualReports] = useState([]);
  const comparedARDefaultState = {
    reportingYear: null,
    entity: null,
    isEditState: false,
    isLoading: false,
    content: null,
    links: null,
    statusText: null,
    bodyType: null,
    saved: false,
  };
  const [comparedArState, setComparedArState] = useState([{ ...comparedARDefaultState }, { ...comparedARDefaultState }]);
  const [showExpandedItems, setShowExpandedItems] = useState([false, false]);
  const [loadingDropdownOptions, setLoadingDropdownOptions] = useState(false);
  const [loadingCompareReports, setLoadingCompareReports] = useState([false, false]);
  const closeButtonRef = useRef();
  const modalContentRef = useRef();
  const NO_CONTENT_TEXT = (rule) => NO_CONTENT_FOUND_FOR_RULE(rule, style.centerAligned);

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  useEffect(() => {
    // hide scrollbar of entire page when compare function modal is open
    document.body.style.overflow = 'hidden';
  }, [selectedRule]);

  /**
   * Function to select rule
   * @param {Number} index - Index of rule
   * @param {String} ruleTitle - Title of rule
   * @param {String} ruleDescription - Description of rule
   * @param {Array|null} contentReference - The reference to content of the rule
   * @param {Object} extra - Any extra information required (currently adds info about pdf to notify that a pdf file should be displayed)
   */
  const selectRule = (index, ruleTitle, ruleDescription, contentReference, extra = {}) => {
    if (index !== selectedRule.index) {
      let content = NO_CONTENT_TEXT(ruleTitle);

      if (contentReference) {
        // Replace all data tables if found
        const renderDataTables = sortWebDataTable(contentReference, mandatoryRules.performanceData);

        // parse rich text from content item html (disables all images from large image view and renders all links as text)
        content = parseRichTextHtml(renderDataTables, { disableOpenLargeImage: true, renderAsText: true }, undefined, false, true);

        // If there is pdf item, place pdf file that at the end of content
        if (extra?.pdfItem) {
          content = (
            <>
              {content}
              {extra.pdfItem.url && extra.pdfItem.type?.toLowerCase() === 'application/pdf' && <PdfViewer pdfSrc={extra.pdfItem.url} fileName={extra.pdfItem.name} />}
            </>
          );
        }
      }

      setSelectedRule({ index, title: ruleTitle, description: ruleDescription, content });
      setComparedArState([{ ...comparedARDefaultState }, { ...comparedARDefaultState }]); // reset AR state
      setShowExpandedItems([false, false]); // reset expanded state

      // automatically scroll to second page when rule is selected on mobile
      if (viewMode === CONSTANTS.VIEW_MODE.MOBILE) {
        setCurrentPage(2);
      }
    }
  };

  /**
   * Update the edit state of compared annual report
   * @param {Number} index - Index of compare report (currently can only be index 0 or 1)
   */
  const updateEditState = (index) => {
    const newState = [...comparedArState];
    // If currently in edit state, return to non-edit
    if (newState[index].isEditState) {
      if (newState[index].saved) {
        newState[index].isEditState = false;
      } else {
        newState[index] = { ...comparedARDefaultState }; // reset ar state if 'X' is clicked
      }
      showExpandedItems[index] = false; // reset mobile expanded state

      const otherArIndex = index === 0 ? 1 : 0;
      if (!comparedArState?.[otherArIndex]?.saved && !comparedArState?.[otherArIndex]?.isEditState) {
        // if other AR is empty too, set back to only two pages
        setCurrentPage(2);
      }
    } else {
      // If not currently in edit state, set to edit state and fetch dropdown options (if not already found)
      newState[index].isEditState = true;
      if (fetchedAnnualReports.length === 0) {
        fetchReportsOfSameBodyType();
      }
    }
    setComparedArState(newState);
  };

  /**
   * Fetches all annual reports of the SAME BODY TYPE (as current AR) using search API
   */
  const fetchReportsOfSameBodyType = async () => {
    const maxItemsPerCall = 50;
    let totalItems = 50;
    let totalItemsCalled = 0;
    let page = 1;

    // filter to only annual reports with same body type as current AR
    const filterForAnnualReports = SEARCH_API_FUNCTION.FILTER_IN(FILTERABLE_GROUPS.PUBLICATION_TYPE.VALUE, [PUBLICATION_TYPE.ANNUAL_REPORT.VALUE]);
    const filterForBodyType = SEARCH_API_FUNCTION.FILTER_IN(FILTERABLE_GROUPS.BODY_TYPE.VALUE, [annualReportDetails.bodyType]);

    const annualReportData = [];
    try {
      setLoadingDropdownOptions(true);

      while (totalItemsCalled <= totalItems) {
        // Recall api if total items if there is still more items in API
        const fetchedItems = await searchItems(
          maxItemsPerCall,
          page,
          '',
          SEARCH_API_FUNCTION.COMBINE_EXPRESSION([filterForAnnualReports, filterForBodyType], SEARCH_API_CONSTANTS.AND),
          '',
        );

        const fetchedReports = fetchedItems?.[0] || [];
        fetchedReports.forEach((report) => {
          annualReportData.push({
            reportTitle: report.Title,
            reportingYear: report.ReportingYear,
            entityName: report.Entity,
            entityUrl: report.EntityUrlSlug,
            entityCodename: report.EntityCodename,
            urlSlug: report.UrlSlug,
            bodyType: report.BodyType,
            portfolioUrl: report.PortfolioUrlSlug,
            statusText: report.StatusText || '',
          });
        });

        if (fetchedItems?.[1] > totalItems) {
          totalItems = fetchedItems[1];
        }
        page += 1;
        totalItemsCalled += maxItemsPerCall;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFetchedAnnualReports(annualReportData);
      setLoadingDropdownOptions(false);
    }
  };

  /**
   * Get the content item of the compared annual reports for selected rule content reference
   * @param {String} arWebUrl - Web url of compared report
   * @param {Number} index - Index of compared report (can only be 0 or 1)
   * @param {Object} currentAr - Current AR compared state values
   *
   */
  const fetchAnnualReportContents = async (arWebUrl, index, currentAr) => {
    let contentFound = false;
    try {
      // set loading of this particular compared AR
      setLoadingCompareReports((prevState) => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });

      const fetchedReport = await getAContentItem(arWebUrl, CONTENT_ITEM_TYPES.ANNUAL_REPORT.ID);

      if (fetchedReport && !fetchedReport.isError) {
        const ruleList = processCompareFunctionMandatoryRequirements(fetchedReport?.MandatoryRequirements, { mandatoryTableHtml: fetchedReport.ListOfRequirementsMarkup || '' }); // processes the AR's requirements
        const foundRule = ruleList.find((rule) => {
          return rule.ruleTitle === selectedRule.title && rule.ruleDescription === selectedRule.description;
        });

        // The currently selected compared rule should be wihin the list of mandatory requirements of compared reports to compare
        if (foundRule && foundRule.contentReference) {
          // Replace all data tables if found
          const renderDataTables = sortWebDataTable(foundRule.contentReference, fetchedReport?.PerformanceData || []);
          const content = parseRichTextHtml(renderDataTables, { disableOpenLargeImage: true, renderAsText: true });

          // If there is pdf item, place pdf file that at the end of content
          if (foundRule.extraInfo?.pdfItem) {
            currentAr.content = (
              <>
                {content}
                {foundRule.extraInfo.pdfItem.url && foundRule.extraInfo.pdfItem.type?.toLowerCase() === 'application/pdf' && (
                  <PdfViewer pdfSrc={foundRule.extraInfo.pdfItem.url} fileName={foundRule.extraInfo.pdfItem.name} />
                )}
              </>
            );
          } else {
            currentAr.content = content;
          }
          contentFound = true;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (!contentFound) {
        currentAr.content = NO_CONTENT_TEXT(selectedRule.title);
      }

      // reset loading to false
      setLoadingCompareReports((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
  };

  const selectReportingYear = (index, selectedOption) => {
    setComparedArState((prevState) => {
      const newState = [...prevState];
      if (selectedOption && selectedOption.display && selectedOption.returnValue) {
        newState[index].reportingYear = selectedOption;
        newState[index].entity = null; // reset entity dropdown when reporting year changes
      }
      return newState;
    });
  };

  const selectEntity = (index, selectedOption) => {
    setComparedArState((prevState) => {
      const newState = [...prevState];
      if (selectedOption && selectedOption.display && selectedOption.returnValue) {
        newState[index].entity = selectedOption;
      }
      return newState;
    });
  };

  /**
   * Save selected dropdown options and fetch report contents for given rule
   * @param {Number} index - Index of compare annual report
   */
  const saveSelectedOptions = (index) => {
    // Ensure dropdown options for reporting year and entity are selected
    if (comparedArState[index].reportingYear && comparedArState[index].entity) {
      const currentAr = { ...comparedArState[index] };
      // find annual report that has reporting year and entity value (assumes that there will only be one)
      const selectedReport = fetchedAnnualReports.find((report) => {
        return report.reportingYear === comparedArState[index]?.reportingYear?.returnValue && report.entityCodename === comparedArState[index]?.entity?.returnValue;
      });

      if (selectedReport) {
        currentAr.links = {
          entity: entityProfileUrl(selectedReport.portfolioUrl, selectedReport.entityUrl),
          annualReport: annualReportUrl(selectedReport.portfolioUrl, selectedReport.entityUrl, selectedReport.urlSlug),
        };
        fetchAnnualReportContents(selectedReport.urlSlug, index, currentAr);
        currentAr.bodyType = selectedReport.bodyType;
        currentAr.statusText = selectedReport.statusText;
      } else {
        currentAr.links = null;
        currentAr.bodyType = '';
        currentAr.statusText = '';
        currentAr.content = NO_CONTENT_TEXT(selectedRule.title);
      }

      // Always set edit state to false after save, as well as `saved` to true
      currentAr.isEditState = false;
      currentAr.saved = true;
      showExpandedItems[index] = false; // reset mobile expanded state
      setComparedArState((prevState) => {
        const newState = [...prevState];
        newState[index] = currentAr;
        return newState;
      });
    }
  };

  const carouselPages = () => {
    let numberOfPages = 1;
    if (selectedRule.index !== null) {
      numberOfPages = 2;

      // Keep number of pages as 3 if either AR state has been saved
      if (comparedArState?.[0]?.saved || comparedArState?.[1]?.saved) {
        numberOfPages = 3;
      }
    }
    return numberOfPages;
  };

  const updatePageNumber = (page) => {
    setCurrentPage(page);
    // refocus to 'close' button in modal when page changes
    closeButtonRef?.current?.focus();
  };

  /**
   * Updates the expanded state of compared AR header details (only seen on mobile)
   * @param {Number} index - Index of compared AR report (currently there are only two reports - can be index 0 or 1)
   */
  const updateExpandedContent = (index) => {
    setShowExpandedItems((prevState) => {
      const newState = [...prevState];
      newState[index] = !prevState[index];
      return newState;
    });
  };

  /**
   * Dropdown year options
   * @returns List of options for reporting year
   */
  const dropdownYear = () => {
    const reportingYearOptions = {};
    fetchedAnnualReports.forEach((report) => {
      if (!reportingYearOptions[report.reportingYear]) {
        reportingYearOptions[report.reportingYear] = true;
      }
    });

    const dropdownOptions = Object.keys(reportingYearOptions).map((year) => {
      return {
        display: year,
        returnValue: year,
      };
    });

    return sortByObjectProperty(dropdownOptions, 'display')?.reverse() || [];
  };

  /**
   * Dropdown options of entities
   * @returns List of options for entities
   */
  const dropdownEntity = (yearDropdownOption) => {
    const arInReportingYear = fetchedAnnualReports.filter((report) => {
      return report?.reportingYear === yearDropdownOption?.returnValue;
    });

    const dropdownOptions = arInReportingYear.map((report) => {
      return {
        display: report.entityName,
        returnValue: report.entityCodename,
      };
    });

    return sortByObjectProperty(dropdownOptions, 'display') || [];
  };

  return (
    <CSSTransition
      in={modalIsOpen}
      nodeRef={modalContentRef}
      classNames={{
        appear: viewMode !== CONSTANTS.VIEW_MODE.MOBILE ? 'slideUpToMiddle' : 'slideUp',
        exit: viewMode !== CONSTANTS.VIEW_MODE.MOBILE ? 'slideDownToMiddle' : 'slideDown',
      }}
      appear={true}
      timeout={{ appear: 300, exit: 300 }}
      unmountOnExit>
      <div id={style.compareFunction} ref={modalContentRef}>
        <div className={style.header}>
          <h4>Compare Annual Reports</h4>
          <div className={style.headerButtons}>
            {viewMode !== CONSTANTS.VIEW_MODE.MOBILE && (
              <Link to={`/${PATHS.DATA_SETS.BASE}`} className="btn lightFill">
                Data sets
              </Link>
            )}
            {/* {viewMode !== CONSTANTS.VIEW_MODE.MOBILE && <button className="lightFill">Data sets</button>} */}
            <button className={['lightFill', style.noText].join(' ')} onClick={closeModalFunction} ref={closeButtonRef}>
              Close
              <svg>
                <use href={icons + '#close'} />
              </svg>
            </button>
          </div>
        </div>
        <Carousel
          className={style.carousel}
          currentPage={currentPage}
          maxPages={carouselPages()}
          fullWidth={viewMode === CONSTANTS.VIEW_MODE.MOBILE ? true : false}
          onPageChange={updatePageNumber}>
          {/* FIRST PANEL - RULES LIST */}
          <div className={style.mandatoryRules}>
            <div className={style.ruleHeader}>
              <a
                className="btn noFill"
                href="https://www.finance.gov.au/government/managing-commonwealth-resources/pgpa-legislation-associated-instruments-and-policies"
                rel="noreferrer"
                target="_blank">
                <span>Public Governance, Performance and Accountability Rule 2014</span>
                <svg>
                  <use href={icons + '#open-in-new-tab'} />
                </svg>
              </a>
              <span className={style.information}>
                An entity or company must report against a set of financial and non-financial requirements. Below are the requirements found on this page of the Annual Report.
              </span>
            </div>

            {mandatoryRules.ruleList && mandatoryRules.ruleList.length > 0 ? (
              <ul className={style.ruleList}>
                {mandatoryRules.ruleList.map((rule, index) => {
                  return (
                    <li key={`${rule.ruleTitle}-${index}`}>
                      <button
                        className={['noFill', selectedRule.index === index ? style.selectedRule : null].join(' ')}
                        onClick={() => selectRule(index, rule.ruleTitle, rule.ruleDescription, rule.contentReference, rule.extraInfo || {})}>
                        <strong>{rule.ruleTitle}</strong> - {rule.ruleDescription}
                      </button>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <span className={style.noRules}>No mandatory requirements found for this report</span>
            )}
          </div>
          {viewMode !== CONSTANTS.VIEW_MODE.MOBILE && (
            <div className={style.currentAnnualReport}>
              {/* SECOND PANEL (ON DESKTOP) - CURRENT AR REPORT */}
              <div className={style.arHeader}>
                <div className={style.tags}>
                  {annualReportDetails.bodyType && <Tag displayText={annualReportDetails.bodyType}></Tag>}
                  {annualReportDetails.statusText && <Tag displayText={annualReportDetails.statusText} type={TAG_STYLE_ENUMS.DARK}></Tag>}
                  {annualReportDetails.reportingYear && <Tag displayText={annualReportDetails.reportingYear} type={TAG_STYLE_ENUMS.GREY}></Tag>}
                </div>
                <Link className="btn noFill" to={`/${annualReportDetails.entityUrl}` || '#'}>
                  {annualReportDetails.entity}
                </Link>
              </div>
              <div className={style.arContent}>{selectedRule.content}</div>
            </div>
          )}
          {comparedArState.map((arState, index) => {
            // THIRD AND FOURTH PANELS (OR SECOND AND THIRD ON MOBILE) - COMPARED ANNUAL REPORTS
            return (
              <div key={`ar-compare-edit-${index}`} className={style.compareAnnualReport}>
                <div className={style.compareArHeader}>
                  <div className={[style.editReportForm, arState.isEditState || arState.saved ? style.alignTop : null].join(' ')}>
                    <button className={[style.editButton, arState.isEditState ? 'lightFill' : null].join(' ')} onClick={() => updateEditState(index)}>
                      <svg>
                        <use href={icons + `${arState.isEditState ? '#close' : '#edit'}`} />
                      </svg>
                    </button>
                    <div className={style.formDropdowns}>
                      {!arState.isEditState ? (
                        arState.saved ? (
                          <>
                            {viewMode === CONSTANTS.VIEW_MODE.MOBILE && (
                              <button onClick={() => updateExpandedContent(index)} className={['noFill', style.expandButton].join(' ')} aria-expanded={showExpandedItems[index]}>
                                <svg>
                                  <use href={`${icons}#triangle`} />
                                </svg>
                              </button>
                            )}
                            {(viewMode !== CONSTANTS.VIEW_MODE.MOBILE || showExpandedItems[index]) && (
                              <div className={style.tags}>
                                {arState.bodyType && <Tag displayText={arState.bodyType}></Tag>}
                                {arState.statusText && <Tag displayText={arState.statusText} type={TAG_STYLE_ENUMS.DARK}></Tag>}
                                {arState.reportingYear?.display && <Tag displayText={arState.reportingYear.display} type={TAG_STYLE_ENUMS.GREY}></Tag>}
                              </div>
                            )}
                            <div className={style.compareLinks}>
                              {arState.links?.entity && arState.entity?.display && (
                                <Link className="btn noFill" to={`/${arState.links?.entity}`}>
                                  <span className={viewMode === CONSTANTS.VIEW_MODE.MOBILE && !showExpandedItems[index] ? style.limitTextLines : null} style={{ '--MAX-LINE': 1 }}>
                                    {arState.entity.display}
                                  </span>
                                </Link>
                              )}
                              {arState.links?.annualReport && (viewMode !== CONSTANTS.VIEW_MODE.MOBILE || showExpandedItems[index]) && (
                                <Link className="btn noFill" to={`/${arState.links.annualReport}`}>
                                  View source
                                </Link>
                              )}
                            </div>
                          </>
                        ) : (
                          <span className={style.selectEntity}>Select an entity or company to compare</span>
                        )
                      ) : (
                        <div className={style.editFields}>
                          {loadingDropdownOptions ? (
                            <div className={style.formField}>
                              <Loading className={style.invisibleTitle}>
                                <span>Loading</span>
                              </Loading>
                              <Loading className={style.loadingDropdown}></Loading>
                            </div>
                          ) : (
                            <div className={style.formField}>
                              <span className={style.fieldTitle}>Reporting year</span>
                              <DropdownSelect
                                dropdownOptions={dropdownYear()}
                                placeholderText={dropdownYear().length === 0 ? 'No options found' : 'Please select one'}
                                selectedOption={arState.reportingYear || null}
                                selectOptionFunction={(selectedOption) => selectReportingYear(index, selectedOption)}
                              />
                            </div>
                          )}
                          {arState.reportingYear && (
                            <div className={style.formField}>
                              <span className={style.fieldTitle}>Entity or company</span>
                              <div className={style.saveEdits}>
                                <DropdownSelect
                                  dropdownOptions={dropdownEntity(arState.reportingYear)}
                                  placeholderText={dropdownEntity(arState.reportingYear).length === 0 ? 'No options found' : 'Please select one'}
                                  selectedOption={arState.entity || null}
                                  selectOptionFunction={(selectedOption) => selectEntity(index, selectedOption)}
                                />
                                <button className={style.saveButton} disabled={!arState.entity || !arState.reportingYear} onClick={() => saveSelectedOptions(index)}>
                                  Save
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={style.compareArContent}>{loadingCompareReports[index] ? <Loading className={style.reportContentLoading}></Loading> : arState.content}</div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </CSSTransition>
  );
}

CompareFunction.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  mandatoryRules: PropTypes.shape({
    ruleList: PropTypes.arrayOf(
      PropTypes.shape({
        ruleTitle: PropTypes.string.isRequired,
        ruleDescription: PropTypes.string.isRequired,
      }),
    ).isRequired,
    performanceData: PropTypes.array,
  }).isRequired,
  annualReportDetails: PropTypes.shape({
    entity: PropTypes.string,
    entityUrl: PropTypes.string,
    bodyType: PropTypes.string,
    reportingYear: PropTypes.string,
    statusText: PropTypes.string,
  }).isRequired,
  closeModalFunction: PropTypes.func.isRequired,
};

export default CompareFunction;
