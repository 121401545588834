import React from 'react';
import PropTypes from 'prop-types';
import style from './PageNavigation.module.scss';
import icons from 'Assets/svgSprite.svg';
import { Link } from 'react-router-dom';

/**
 * PageNavigation.jsx
 *
 * @summary This component is to be used for page navigation between different publications/articles and within article pages.
 *
 * @param {Object} props - Component props.
 * @prop {{prevTitle: String, prevPath: String, nextTitle: String, nextLink: String}} props.navigationData - Object that contains the prev/next titles and route links.
 * @prop {Boolean} [props.reloadDestinationPage] - Boolean value to reload the destination page when navigating to it. Defaulted to false.
 */
function PageNavigation({ navigationData, reloadDestinationPage = false }) {
  const prevNavAvailable = navigationData.prevTitle !== null && navigationData.prevPath !== null;
  const nextNavAvailable = navigationData.nextTitle !== null && navigationData.nextPath !== null;

  const disabledPrevNav = navigationData.prevTitle === '' ? style.disabled : '';
  const disabledNextNav = navigationData.nextTitle === '' ? style.disabled : '';

  return (
    <nav id={style.pageNavigation}>
      {prevNavAvailable && (
        <Link reloadDocument={reloadDestinationPage} to={navigationData.prevPath} className={['card', style.navCard, style.prev, disabledPrevNav].join(' ')}>
          <svg>
            <use href={icons + '#chevron'} />
          </svg>
          <span className={style.navlink}>Previous</span>
          {navigationData.prevTitle ? <span className={style.title}>{navigationData.prevTitle}</span> : <span className={style.invisibleTitle}>No report name</span>}
        </Link>
      )}
      {nextNavAvailable && (
        <Link reloadDocument={reloadDestinationPage} to={navigationData.nextPath} className={['card', style.navCard, style.next, disabledNextNav].join(' ')}>
          <span className={style.navlink}>Next</span>
          <svg>
            <use href={icons + '#chevron'} />
          </svg>
          {navigationData.nextTitle ? <span className={style.title}>{navigationData.nextTitle}</span> : <span className={style.invisibleTitle}>No report name</span>}
        </Link>
      )}
    </nav>
  );
}

PageNavigation.propTypes = {
  navigationData: PropTypes.shape({
    nextTitle: PropTypes.string,
    nextPath: PropTypes.string,
    prevTitle: PropTypes.string,
    prevPath: PropTypes.string,
  }).isRequired,
  reloadDestinationPage: PropTypes.bool,
};

export default PageNavigation;
