import React, { useState, useEffect, useId } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IS_PREVIEW_MODE } from 'Constants';
import icon from 'Assets/svgSprite.svg';
import style from './Accordion.module.scss';

/**
 * Accordion.jsx
 *
 * @summary This component is accordion.
 *
 * @param {Object} props - Component props.
 * @prop {String} props.title - Text within accordion button.
 * @prop {Node} props.children - Children nodes of accordion content.
 * @prop {Bool} [props.isOpenedByParent] - If true, accordion is open.
 * @prop {Bool} [props.isActive] - If true, accordion button is in active state style.
 * @prop {String} [props.buttonTextLink] - If true, accordion button text itself is also a link.
 * @prop {Bool} [props.contentAreaNoStyle] - If true, accordion content wrapper will not have any style.
 * @prop {Bool} [props.childIsActive] - If true, indicates one or more child item underneath is active and while it is true, closing accordion will result closed accordion button to have active style.
 */
function Accordion({ title, isOpenedByParent, children, isActive, buttonTextLink, contentAreaNoStyle, childIsActive }) {
  const [isOpen, setIsOpen] = useState(isActive ?? false);
  const [displayContents, setDisplayContents] = useState(false);
  const buttonId = useId();
  const contentId = useId();

  const toggleAccordion = (openState) => {
    if (openState === true) {
      setDisplayContents(openState);
      setTimeout(() => {
        setIsOpen(openState);
      }, 1);
    } else {
      setIsOpen(openState);
      setTimeout(() => {
        setDisplayContents(openState);
      }, 1);
    }
  };

  useEffect(() => {
    toggleAccordion(isOpenedByParent);
  }, [isOpenedByParent]);

  // add styling based on the accordion being opened/selected
  const contentAreaClass = [];
  if (isOpen) contentAreaClass.push(style.isOpen);
  if (displayContents) contentAreaClass.push(style.contentShow);
  if (contentAreaNoStyle) contentAreaClass.push(style.noWrapperStyle);

  // if title isn't link, make all a button
  const className = [style.accordion];

  const renderAccordionButton = () => {
    const buttonClass = [style.accordionButton];
    if (isActive || (!isOpen && childIsActive)) buttonClass.push(style.active);
    if (isOpen) buttonClass.push(style.isOpen);
    if (!buttonTextLink && !IS_PREVIEW_MODE) buttonClass.push(style.buttonTitleNoLink);
    if (!title && IS_PREVIEW_MODE) buttonClass.push('noContentOutlineInside'); // add content outline to side navigation when no title is given

    return (
      <div className={buttonClass.join(' ')}>
        {buttonTextLink ? (
          <Link to={buttonTextLink} aria-current={isActive} onClick={(e) => e.stopPropagation()} className="alwaysFresh">
            {title}
          </Link>
        ) : null}
        <button
          id={buttonId}
          aria-controls={contentId}
          aria-expanded={isOpen}
          onClick={() => toggleAccordion(!isOpen)}
          className={[style.triangle, !buttonTextLink && !IS_PREVIEW_MODE ? style.titleNoLink : ''].join(' ')}
          title={`${isOpen ? 'close' : 'open'} accordion`}>
          {!buttonTextLink ? title : ''}
          <svg>
            <use href={`${icon}#triangle`} />
          </svg>
        </button>
      </div>
    );
  };

  return (
    <div className={className.join(' ')}>
      {renderAccordionButton()}
      <div id={contentId} aria-labelledby={buttonId} className={contentAreaClass.join(' ')}>
        {children}
      </div>
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  isOpenedByParent: PropTypes.bool,
  children: PropTypes.node,
  isActive: PropTypes.bool,
  buttonTextLink: PropTypes.string,
  contentAreaNoStyle: PropTypes.bool,
  childIsActive: PropTypes.bool,
};

export default Accordion;
