import { CONSTANTS } from "./Constants";

export const DATA_EXPLORER_FILTER_OPTIONS_API = () => {
    if (process.env.REACT_APP_API_END_POINT_BASE?.toLowerCase() === CONSTANTS.ENV.PROD) {
        return `https://previewapi.transparency.gov.au/search/dataExplorer`;
    }
    return `${process.env.REACT_APP_API_END_POINT_BASE}/search/dataExplorer`;
};

export const DATA_EXPLORER_RESULTS_API = () => {
  if (process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === CONSTANTS.ENV.PROD) {
    return 'https://data.transparency.gov.au/search/dataExplorerResults';
  }
  return `https://app-dof-dataapi-${process.env.REACT_APP_ENVIRONMENT}-aue-01.azurewebsites.net/search/dataExplorerResults`;
};

export const DATA_EXPLORER_URL_FILTER_GROUPS = {
    LOCATION: {
      VALUE: 'Location',
      DISPLAY_NAME: 'Location'
    },
    GENDER: {
      VALUE: 'Gender',
      DISPLAY_NAME: 'Gender'
    },
    EMPLOYMENT_TYPE: {
      VALUE: 'EmploymentType',
      DISPLAY_NAME: 'Employment Type'
    },
    ENGAGEMENT: {
      VALUE: 'Engagement',
      DISPLAY_NAME: 'Engagement'
    },
    APS_BAND: {
      VALUE: 'APSBand',
      DISPLAY_NAME: 'APS Band'
    },
    NAME_OF_CONTRACTED_ORGANISATION: {
      VALUE: 'NameofContractedOrganisation',
      DISPLAY_NAME: 'Name of Contracted Organisation'
    },
    PORTFOLIO: {
      VALUE: 'Portfolio',
      DISPLAY_NAME: 'Portfolio'
    },
    BODY_TYPE: {
      VALUE: 'BodyType',
      DISPLAY_NAME: 'Body Type'
    },
    ENTITY: {
      VALUE: 'Entity',
      DISPLAY_NAME: 'Body'
    },
    REPORTING_YEAR: {
      VALUE: 'ReportingYear',
      DISPLAY_NAME: 'Reporting Period'
    }
  };



  
export const SUBTEXT_MAX_CHARACTER_LIMIT = 50; //Max character limit for subtext to not warp the design of the page


export const AUS_STATE_TERRITORIES_FILTERS = ["NSW", "Qld", "SA", "Tas", "Vic", "WA", "ACT", "NT", "External Territories", "External"];
export const GENDER_FILTERS = ["Woman/Female", "Man/Male", "Non-binary", "Prefers not to answer", "Uses a different term"];

export const BASE_WIDTH = 300;
export const WIDTH_MODIFIER_BAR = 20; 
export const WIDTH_MODIFIER_LINE = 100; 

export const BASE_HEIGHT = 800;
export const HEIGHT_MODIFIER_BAR = 5; 
export const HEIGHT_MODIFIER_LINE = 15; 

export const DATA_EXPLORER_CHART_WIDTH = 1175;


  