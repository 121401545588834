import React, { useState, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Accordion, Tag } from 'Components';
import { CONSTANTS, IS_PREVIEW_MODE, TAG_STYLE_ENUMS } from 'Constants';
import { debounce, detectViewMode } from 'Utils';
import style from './SideNavigation.module.scss';
import icons from 'Assets/svgSprite.svg';
import Tooltip from 'Components/Tooltip/Tooltip';

/**
 * SideNavigation.jsx
 *
 * @summary This component is to be used as side navigation within article page (such as annual report page).
 *
 * @param {Object} props - Component props.
 * @prop {{path: String, routeTitle: String, children: []}[]} props.menu - Array of objects, which should have `path` and `routeTitle`, which are string of path to nested page and title of section.
 * @prop {{title: String, url: String}} [props.cover] - Object that contains title of article and url to article cover page. Page will be given and toggle all nav accordions will shown differently as per design.
 * @prop {Boolean} [props.desktopLookInMobile] - If given or true, in mobile view it will keep desktop style.
 * @prop {Boolean} [prop.noToggleAll=false] - If given, or true, open/close all button will not be displayed. By default, false.
 */
const SideNavigation = memo(function SideNavigation({ menus, desktopLookInMobile, cover, noToggleAll = false, searchData}) {
  
  const { pathname } = useLocation();
  const [openAll, toggleAll] = useState(false);
  const [viewMode, setViewMode] = useState(detectViewMode());
  const [mobileShowNav, setMobileShowNav] = useState(false);
  const initialRender = useRef(true);
  const coverTitleExists = cover?.title || false;
  const isMobileLook = !desktopLookInMobile && coverTitleExists && viewMode !== CONSTANTS.VIEW_MODE.DESKTOP;
  const mobileModalBackground = useRef();
  const mobileSideNav = useRef();

  const toggleAllAccordions = () => {
    if (isMobileLook) {
      setMobileShowNav(!mobileShowNav);
    } else {
      toggleAll(!openAll);
    }
  };

  const detectEscKey = (e) => {
    if (e.key === CONSTANTS.ESCAPE_KEY && mobileShowNav) {
      setMobileShowNav(false);
    }
  };

  const closeMobileModal = (event) => {
    // only when the outer background is clicked will trigger the modal to close
    if (event.target.id === style.mobileSideNavModal) {
      setMobileShowNav(false);
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }

    if (!desktopLookInMobile) {
      const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
      window.addEventListener('resize', detectViewPortWidthChange);

      return () => {
        window.removeEventListener('resize', detectViewPortWidthChange);
      };
    }
  });

  useEffect(() => {
    if (isMobileLook && mobileShowNav) {
      document.addEventListener('keydown', detectEscKey);

      return () => {
        document.removeEventListener('keydown', detectEscKey);
      };
    } else {
      document.removeEventListener('keydown', detectEscKey);
    }
  }, [viewMode, mobileShowNav]);

  useEffect(() => {
    initialRender.current = true;

    if (isMobileLook && mobileShowNav) {
      setMobileShowNav(false);
    }
  }, [pathname]);

  if (isMobileLook) {
    if (mobileShowNav) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = '';
    }
  }

  // render a group of side navigation element (for a certain section)
  const renderChildNav = (data, chapterKey, chapterItem) => {
    return (
      <ol className={[style.menuContainer, style.childNav].join(' ')}>
        {data.children.map((sub, i) => {
          const isActive = pathname.toLowerCase() === sub.path?.toLowerCase();
          const listClass = [];

          if (isActive) {
            listClass.push(style.isActive);
          }
          if (!sub.routeTitle && IS_PREVIEW_MODE) {
            listClass.push('noContentOutlineInside'); // add content outline to side navigation when no title is given
          }

          const matchingChapter = chapterItem?.Sections?.find((chapterItem) => {return chapterItem.System.codename === sub.contentCodename;});

          return (
            <li key={`${chapterKey}-section-${i}-${sub.routeTitle}`} className={listClass.join(' ')}>
              <Link to={sub.path} className={[style.item, 'alwaysFresh'].join(' ')} aria-current={isActive}>
                {sub.routeTitle}
              </Link>
                {matchingChapter && matchingChapter?.SearchResultHits > 0 && (
                    <Tag displayText={`${matchingChapter.SearchResultHits} Result`} type={TAG_STYLE_ENUMS.DARK} />
                )}
            </li>
          );
        })}
      </ol>
    );
  };

  // render entire side navigation menu
  const renderSideMenu = (menus, isInitialRender = initialRender.current) => {
    return (
      <nav id={style.sideNavigation} aria-labelledby={style.navigationToggleBtn} className={isMobileLook && openAll ? style.mobileView : null}>
        <ol className={style.menuContainer}>
          {menus.map((item, i) => {
            const path = item.path;

            const isActive = pathname.toLowerCase() === path?.toLowerCase();
            const key = `chapter-${i}-${item.contentCodename}`;

            const matchingChapter = searchData?.Chapters?.find((chapterItem) => {return chapterItem.System.codename === item.contentCodename;});
            const isChapter = !!matchingChapter;
            // Rendering the chapter item without any section children
            if (item.children.length === 0 && path !== null) {
              // if section has no sub section
              const className = [style.noChild];

              if (isActive) {
                className.push(style.isActive);
              }

              if (!item.routeTitle && IS_PREVIEW_MODE) {
                className.push('noContentOutlineInside'); // add content outline to side navigation when no title is given
              }

              return (
                <li key={key} className={className.join(' ')}>
                  <Link to={path} aria-current={isActive} className={[style.item, 'alwaysFresh'].join(' ')}>
                    {item.routeTitle}
                    {matchingChapter && matchingChapter?.SearchResultHits > 0 && (
                      <>
                        <Tag displayText={`${matchingChapter.SearchResultHits} Result`} type={TAG_STYLE_ENUMS.DARK} />
                      </>
                    )}
                  </Link>

                  
                </li>
              );
            }

            // if section has sub section
            const pathnameArr = pathname.toLowerCase().split('/');
            const thisChapterSectionIsActive = path?.toLowerCase() === pathnameArr.slice(0, pathnameArr.length - 1).join('/');
            const accordionOpened = openAll || (isInitialRender && isActive) || thisChapterSectionIsActive || isActive;

            // NOTE: accordion button text is link only when app is in preview mode or item.chapterHasContent is true
            return (
              <li key={key}>
                <Accordion
                  title={item.routeTitle}
                  isActive={isActive}
                  isOpenedByParent={accordionOpened|| isChapter}
                  childIsActive={thisChapterSectionIsActive}
                  buttonTextLink={item.chapterHasContent || IS_PREVIEW_MODE ? path : null}
                  contentAreaNoStyle>
                    {matchingChapter && matchingChapter?.SearchResultHits > 0 && (
                      <div className={style.tagWrapper}>
                        <Tag displayText={`${matchingChapter.SearchResultHits} Result`} type={TAG_STYLE_ENUMS.DARK} />
                      </div>
                    )}
                    {renderChildNav(item, key, matchingChapter)}
                </Accordion>
                
              </li>
            );
          })}
        </ol>
      </nav>
    );
  };

  // top section of side nav
  const renderSideNavTop = () => {
    const wrapperClass = [];
    if (!coverTitleExists) wrapperClass.push(style.inCoverPage);
    if (isMobileLook) wrapperClass.push(style.mobileView);

    return (
      <div id={style.sideNavTop} className={wrapperClass.join(' ')}>
        {cover && coverTitleExists && (
          <Link to={cover?.url} id={style.toCoverPage}>
            {cover?.title}
          </Link>
        )}
        {!noToggleAll && (
          <Tooltip tooltipText="Use this to open up all sub-sections. Click to expand all.">
            <button
              id={style.navigationToggleBtn}
              aria-controls={style.sideNavigation}
              onClick={toggleAllAccordions}
              aria-expanded={openAll}
              aria-label={`${openAll ? 'Close' : 'Open'} all`}>
              {coverTitleExists ? null : `${openAll ? 'Close' : 'Open'} all`}
              {isMobileLook ? (
                `${openAll ? 'Close' : 'Open'} all`
              ) : (
                <svg>
                  <use href={icons + '#double-arrow'} />
                </svg>
              )}
            </button>
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <>
      {!noToggleAll && renderSideNavTop()}
      {desktopLookInMobile || viewMode === CONSTANTS.VIEW_MODE.DESKTOP ? renderSideMenu(menus) : null}
      <CSSTransition
        nodeRef={mobileModalBackground}
        in={isMobileLook && mobileShowNav}
        classNames={{
          enter: style.fadeIn,
          exit: style.fadeOut,
        }}
        timeout={{ enter: 200, exit: 400 }}
        unmountOnExit>
        <div id={style.mobileSideNavModal} onClick={closeMobileModal} ref={mobileModalBackground}>
          <CSSTransition
            in={isMobileLook && mobileShowNav}
            nodeRef={mobileSideNav}
            classNames={{ appear: style.sideNavAppear, exit: style.sideNavExit }}
            timeout={{ enter: 400, exit: 200 }}
            appear={true}
            unmountOnExit>
            <div ref={mobileSideNav}>
              {renderSideNavTop()}
              {renderSideMenu(menus, true)}
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>
    </>
  );
});

SideNavigation.propTypes = {
  cover: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      routeTitle: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
  desktopLookInMobile: PropTypes.bool,
  noToggleAll: PropTypes.bool,
  searchData:PropTypes.object
};




export default SideNavigation;
