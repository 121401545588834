import React from 'react';
import PropTypes from 'prop-types';
import style from './StandardFilterList.module.scss';
import { ListSelect } from 'Components';
import { TAG_POSITION_ENUMS } from 'Constants/Constants';

/**
 * StandardFilterList.jsx
 *
 * @summary Component for showing standard dropdown list of filters. Used to provide proper spacing
 *
 * @param {Object} props - Component props.
 * @prop {{display: String, returnValue: String, tag: {display: String, type: String, tagPosition: String}}[]} props.dropdownOptions - List of dropdown options. Each option is an object that should have properties:
 *      - 'returnValue' and 'display' string values
 *      - 'tag' which contains 'display' string for tag, 'type' of tag @enums Defined by TAG_STYLE_ENUMS under `/Constants/Constants.js` and 'tagPosition' to render where to place tag. Must be one of the options in TAG_POSITION_ENUMS
 * @prop {Function} props.selectOptionFunction - Select function used to select an option
 * @prop {String} [props.id] - String id of the component
 *
 */
function StandardFilterList({ id, dropdownOptions, selectOptionFunction }) {
  return (
    <div className={style.standardFilterList}>
      <ListSelect id={id} dropdownOptions={dropdownOptions} selectOptionFunction={selectOptionFunction} isMultiSelect />
    </div>
  );
}

StandardFilterList.propTypes = {
  id: PropTypes.string,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string.isRequired,
      returnValue: PropTypes.string.isRequired,
      tag: PropTypes.shape({
        display: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        tagPosition: PropTypes.oneOf(Object.values(TAG_POSITION_ENUMS)),
      }),
    }),
  ).isRequired,
  selectOptionFunction: PropTypes.func.isRequired,
};
export default StandardFilterList;
