import React from 'react';
import PropTypes from 'prop-types';
import { LogosHeaderFooter } from 'Components';
import style from './MainFooter.module.scss';

/**
 * MainFooter.jsx
 *
 * @summary This component is to be used as main footer.
 *
 * @param {Object} props - Component props.
 * @prop {Node} props.children - Content of footer.
 * @prop {Boolean} [props.isDART] - If given or value is true, DART Logo will be displayed instead TP logo. it also make logos non link.
 */
function MainFooter({ children, isDART }) {
  return (
    <footer id={style.footer}>
      <div className={style.limiter}>
        <LogosHeaderFooter isWhite={true} isDART={isDART} />
        <section className={style.contents}>{children}</section>
      </div>
    </footer>
  );
}

MainFooter.propTypes = {
  children: PropTypes.node.isRequired,
  isDART: PropTypes.bool,
};

export default MainFooter;
