import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * OutsideClickDetector.jsx
 *
 * @summary This component is used to check if a click or focus has occurred outside from the current element.
 *
 * @param {Object} props - Component props.
 * @prop {Node} props.children - Children nodes to be wrapped around (to detect clicked outside).
 * @prop {Function} props.callBack - Callback function when detect clicked outside.
 */
function OutsideClickDetector({ children, callBack }) {
  const wrapperRef = useRef();

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      callBack();
    }
  };

  const handleFocusOutside = () => {
    if (wrapperRef.current && !wrapperRef.current.contains(document.activeElement)) {
      callBack();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleFocusOutside, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keyup', handleFocusOutside, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return <div ref={wrapperRef}>{children}</div>;
}

OutsideClickDetector.propTypes = {
  children: PropTypes.node.isRequired,
  callBack: PropTypes.func.isRequired,
};

export default OutsideClickDetector;
