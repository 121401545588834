import React, { useState, useId, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { CONSTANTS, URL_QUERY_KEYS } from 'Constants';
import icons from 'Assets/svgSprite.svg';
import style from './SearchBar.module.scss';

/**
 * SearchBar.jsx
 *
 * @summary Component for rendering search bar. This component must be used within <form/> tag.
 *
 * @param {Object} props - Component props.
 * @prop {String} [placeholder] - Placeholder text for input search box. Will default to 'Search the Portal' if no placeholder is given
 * @prop {Boolean} [props.updateQuery = true] - If given or true, will update/create url with query value of specified query by `targetQuery`. Default to true.
 * @prop {Boolean} [props.takeQuery = false] - If given or true, search bar input will be updated to have specified query's value by `targetQuery`. Default to false.
 * @prop {String} [props.targetQuery = 'q'] - If given, search bar will create/update or take value of specified query. Default to 'q', which is defind in `URL_QUERY_KEYS.SEARCH_TERM` from constants.
 * @prop {Function} [props.onChange] - If given, passed function will be called upon user input on search bar. Always pass `searchTerm` state data to passed function.
 * @prop {Function} [props.onSubmit] - If given, passed function will be called upon triggers search. Always pass `searchTerm` state data to passed function.
 * @prop {Function} [props.onClear] - If given, passed function will be called upon
 * @prop {Boolean} [props.retainSearchTerm = false] - If given or true, will be used to retain the typed text inside the search bar
 * @prop {String} [props.ariaDescription = null] - If given, consider search bar is used within one of filter form's fieldset and set the role as `searchbox` and pass value as `aria-description`.
 */
function SearchBar({
  placeholder,
  updateQuery = true,
  takeQuery = false,
  targetQuery = URL_QUERY_KEYS.SEARCH_TERM,
  onChange,
  onSubmit,
  onClear,
  retainSearchTerm = false,
  ariaDescription = null,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchInputId = useId();
  const initialSearchTerm = takeQuery ? searchParams.get(targetQuery) || '' : '';
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  useEffect(() => {
    if (takeQuery) {
      setSearchTerm(searchParams.get(targetQuery) || '');
    }
  }, [searchParams.get(targetQuery)]);

  useEffect(() => {
    if (onChange) {
      onChange(searchTerm);
    }
  }, [searchTerm]);

  const searchPortal = (event) => {
    event.preventDefault();

    if (updateQuery) {
      setSearchParams((prevParams) => {
        prevParams.set([targetQuery], searchTerm);
        return prevParams;
      });

      if (!takeQuery && !retainSearchTerm) {
        setSearchTerm('');
      }
    }

    if (onSubmit) {
      onSubmit(searchTerm);
    }
  };

  const clearSearchTerm = () => {
    if (onClear) {
      onClear('');
    }
    setSearchTerm('');
    setSearchParams((prevParams) => {
      prevParams.set([targetQuery], '');
      return prevParams;
    });
  };

  const onEnter = (e) => {
    if (e.key === CONSTANTS.ENTER_KEY) {
      searchPortal(e);
    }
  };

  return (
    <fieldset className={style.searchBar}>
      <label htmlFor={searchInputId}>search input</label>
      <input
        id={searchInputId}
        type="search"
        placeholder={placeholder ?? 'Search the Portal'}
        onKeyDown={onEnter}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        role={ariaDescription ? 'searchbox' : null}
        aria-description={ariaDescription}
      />
      {searchTerm !== '' && (
        <button type="reset" id={style.clear} className="noFill" onClick={clearSearchTerm}>
          Clear
          <svg>
            <use href={icons + '#close'} />
          </svg>
        </button>
      )}
      <button type="submit" onClick={searchPortal}>
        Search
        <svg>
          <use href={icons + '#search'} />
        </svg>
      </button>
    </fieldset>
  );
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  updateQuery: PropTypes.bool,
  takeQuery: PropTypes.bool,
  targetQuery: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  retainSearchTerm: PropTypes.bool,
  ariaDescription: PropTypes.string,
};

export default SearchBar;
