const CONTENT_PROP = {
  ALIGNMENT: {
    LEFT_ALIGN: 'left_aligned',
    RIGHT_ALIGN: 'right_aligned',
    CENTER_ALIGN: 'center_aligned',
  },
  TEXT_WRAP: {
    WRAP_AROUND: 'wrap_text_around_image',
  },
  MANDATORY_REQUIREMENTS: {
    MANDATORY: 'mandatory',
    MANDTORY_PORTFOLIO: 'portfolio departments ‑ mandatory',
    IF_APPLICABLE: 'if applicable, mandatory',
  },
};

export default CONTENT_PROP;
