const desktopMin = 1024;
const tabletMin = 717;

const BREAKPOINTS = {
  MOBILE: {
    MAX: tabletMin - 1
  },
  TABLET: {
    MIN: tabletMin,
    MAX: desktopMin - 1
  },
  DESKTOP: {
    MIN: desktopMin,
  }
};

export default BREAKPOINTS;