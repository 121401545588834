import { TAG_POSITION_ENUMS, TAG_STYLE_ENUMS } from 'Constants/Constants';
import React from 'react';
import {
  DATA_TEMPLATE_TABLE_RESPONSE_ACCOUNTABLE_AUTH,
  DATA_TEMPLATE_TABLE_RESPONSE_ONGOING_EMPLOYEES,
  WEB_TABLES_RESPONSE_ACCOUNTABLE_AUTH,
  WEB_TABLES_RESPONSE_ONGOING_EMPLOYEES,
} from './DataSetsTableMock';
import { BODY_TYPES } from 'Constants/Search';

export const GROUPED_DATA_SETS_FILTERS = [
  {
    display: 'APS employment data (5)',
    returnValue: 'APS employment data',
    selected: false,
    tagInfo: { tagPosition: TAG_POSITION_ENUMS.RIGHT, tags: [{ display: 'NCE', type: TAG_STYLE_ENUMS.DARK }] },
  },
  {
    display: 'Ongoing and non-ongoing employees',
    returnValue: 'Ongoing and non-ongoing employees',
    selected: false,
    tagInfo: {
      tagPosition: TAG_POSITION_ENUMS.RIGHT,
      tags: [
        { display: 'NCE', type: TAG_STYLE_ENUMS.DARK },
        { display: 'CCE', type: TAG_STYLE_ENUMS.DARK },
        { display: 'CC', type: TAG_STYLE_ENUMS.DARK },
      ],
    },
  },
  {
    display: 'Financial data (7)',
    returnValue: 'Financial data',
    selected: false,
    tagInfo: {
      tagPosition: TAG_POSITION_ENUMS.RIGHT,
      tags: [
        { display: 'NCE', type: TAG_STYLE_ENUMS.DARK },
        { display: 'CCE', type: TAG_STYLE_ENUMS.DARK },
        { display: 'CC', type: TAG_STYLE_ENUMS.DARK },
      ],
    },
  },
  {
    display: 'Reportable consultancies and non-consultancies (4)',
    returnValue: 'Reportable consultancies and non-consultancies',
    selected: false,
    tagInfo: {
      tagPosition: TAG_POSITION_ENUMS.RIGHT,
      tags: [
        { display: 'CCE', type: TAG_STYLE_ENUMS.DARK },
        { display: 'CC', type: TAG_STYLE_ENUMS.DARK },
      ],
    },
  },
  {
    display: 'Executive remuneration (3)',
    returnValue: 'Executive remuneration',
    selected: false,
    tagInfo: {
      tagPosition: TAG_POSITION_ENUMS.RIGHT,
      tags: [{ display: 'CCE', type: TAG_STYLE_ENUMS.DARK }],
    },
  },
];

export const DATA_SETS_FILTER_LIST = {
  'Non-financial': [
    {
      display: 'All non-ongoing employees',
      returnValue: 'all_non_ongoing_employees___nce',
      dataSetGroup: 'Ongoing and non-ongoing employees',
      selected: false,
      tagInfo: {
        tagPosition: TAG_POSITION_ENUMS.RIGHT,
        tags: [{ display: 'NCE' }, { display: 'CCE' }, { display: 'CC' }],
      },
    },
    {
      display: 'Accountable Authority - CCE',
      returnValue: 'accountable_authority___cce',
      selected: false,
      tagInfo: {
        tagPosition: TAG_POSITION_ENUMS.RIGHT,
        tags: [{ display: 'CCE' }],
      },
    },
    {
      display: 'APS Act Employment performance pay by classification level',
      returnValue: 'aps_employment_performance_pay_by_class_level',
      selected: false,
      tagInfo: {
        tagPosition: TAG_POSITION_ENUMS.RIGHT,
        tags: [{ display: 'NCE' }],
      },
    },
    {
      display: 'Audit committee',
      returnValue: 'n17ag__2a__b___e__audit_committee',
      selected: false,
      tagInfo: {
        tagPosition: TAG_POSITION_ENUMS.RIGHT,
        tags: [{ display: 'NCE' }],
      },
    },
  ],
  Financial: [],
};

export const REPORTING_YEAR_OPTIONS = [
  {
    display: '2023-24',
    returnValue: 'n2023_24',
    selected: false,
  },
  {
    display: '2023',
    returnValue: 'n2023',
    selected: false,
  },
  {
    display: '2022-23',
    returnValue: 'fy_22_23',
    selected: false,
  },
  {
    display: '2021-22',
    returnValue: 'fy_21_22',
    selected: false,
  },
  {
    display: '2020-21',
    returnValue: 'n2020_21',
    selected: false,
  },
  {
    display: '2019-20',
    returnValue: 'n2019_20',
    selected: false,
  },
  {
    display: '2018-19',
    returnValue: 'n2018_19',
    selected: false,
  },
];

export const BODY_TYPE_OPTIONS = [
  {
    display: 'Non-corporate commonwealth entity',
    returnValue: BODY_TYPES.NON_CORPORATE_COMMONWEALTH_ENTITY,
    selected: false,
  },
  {
    display: 'Corporate commonwealth entity',
    returnValue: BODY_TYPES.CORPORATE_COMMONWEALTH_ENTITY,
    selected: false,
  },
  {
    display: 'Commonwealth company',
    returnValue: BODY_TYPES.COMMONWEALTH_COMPANY,
    selected: false,
  },
];

export const PORTFOLIO_FILTER_OPTIONS = [
  {
    display: 'Agriculture',
    returnValue: 'Agriculture',
    selected: false,
    tagInfo: { tags: [{ display: 'Abolished in 2019-20', type: TAG_STYLE_ENUMS.GREY }] },
  },
  { display: 'Attorney-General’s', returnValue: 'Attorney-General’s', selected: false },
  { display: 'Climate Change, Energy, the Environment and Water', returnValue: 'Climate Change, Energy, the Environment and Water', selected: false },
  {
    display: 'Infrastructure, Transport, Regional Development, Communications and the Arts',
    returnValue: 'Infrastructure, Transport, Regional Development, Communications and the Arts',
    selected: false,
    tagInfo: { tags: [{ display: 'Abolished in 2019-20', type: TAG_STYLE_ENUMS.GREY }] },
  },
  { display: 'Defence', returnValue: 'Defence', selected: false },
  { display: 'Education', returnValue: 'Education', selected: false },

  { display: 'Health and Aged Care', returnValue: 'Health and Aged Care', selected: false },
  { display: 'Treasury', returnValue: 'Treasury', selected: false },
];

export const ENTITY_DROPDOWN_OPTIONS = [
  {
    display: 'AAF Company',
    returnValue: 'aaf_company',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'defence_1e6d17a',
  },
  {
    display: 'ASC Pty Ltd',
    returnValue: 'asc_pty_ltd',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'finance_44bb0b6',
  },
  {
    display: 'Aboriginal Hostels Limited',
    returnValue: 'aboriginal_hostels_limited',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Administrative Appeals Tribunal',
    returnValue: 'administrative_appeals_tribunal',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Aged Care Quality and Safety Commission',
    returnValue: 'aged_care_quality_and_safety_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'Airservices Australia',
    returnValue: 'airservices_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__cities_and_regional_dev',
  },
  {
    display: 'Anindilyakwa Land Council',
    returnValue: 'anindilyakwa_land_council',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Army and Air Force Canteen Service (Frontline Defence Services)',
    returnValue: 'army_and_air_force_canteen_service__frontline_defe',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'defence_1e6d17a',
  },
  {
    display: 'Asbestos Safety and Eradication Agency',
    returnValue: 'asbestos_safety_and_eradication_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: "Attorney-General's Department",
    returnValue: 'attorney_general_s_department',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Australia Business Arts Foundation Ltd',
    returnValue: 'australia_business_arts_foundation_ltd',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'Australia Council',
    returnValue: 'australia_council',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'Australian Broadcasting Corporation',
    returnValue: 'australian_broadcasting_corporation',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'Australian Building and Construction Commission',
    returnValue: 'australian_building_and_construction_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Australian Bureau of Statistics',
    returnValue: 'australian_bureau_of_statistics',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Australian Centre for International Agricultural Research (ACIAR)',
    returnValue: 'australian_centre_for_international_agricultural_r_6b3010d',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'foreign_affairs_and_trade_17c541f',
  },
  {
    display: 'Australian Commission for Law Enforcement Integrity (ACLEI)',
    returnValue: 'australian_commission_for_law_enforcement_integrit_1732ab5',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Australian Commission on Safety and Quality in Health Care',
    returnValue: 'australian_commission_on_safety_and_quality_in_hea_bd5529d',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'Australian Communications and Media Authority (ACMA)',
    returnValue: 'australian_communications_and_media_authority__acm',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'Australian Competition and Consumer Commission (ACCC)',
    returnValue: 'australian_competition_and_consumer_commission__ac',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Australian Criminal Intelligence Commission',
    returnValue: 'australian_crime_commission__australian_criminal_i',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Australian Curriculum, Assessment and Reporting Authority',
    returnValue: 'australian_curriculum__assessment_and_reporting_au_d49bc5e',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'education_4cf15db',
  },
  {
    display: 'Australian Digital Health Agency',
    returnValue: 'australian_digital_health_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'Australian Electoral Commission',
    returnValue: 'australian_electoral_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'finance_44bb0b6',
  },
  {
    display: 'Australian Federal Police',
    returnValue: 'australian_federal_police',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Australian Film, Television and Radio School',
    returnValue: 'australian_film__television_and_radio_school',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'Australian Financial Security Authority',
    returnValue: 'australian_financial_security_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Australian Fisheries Management Authority',
    returnValue: 'australian_fisheries_management_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'agriculture',
  },
  {
    display: 'Australian Hearing Services (Hearing Australia)',
    returnValue: 'australian_hearing_services__hearing_australia_',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'services_australia__part_of_the_social_services_po',
  },
  {
    display: 'Australian Human Rights Commission',
    returnValue: 'australian_human_rights_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Australian Institute for Teaching and School Leadership Limited',
    returnValue: 'australian_institute_for_teaching_and_school_leade_d033dd8',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'education_4cf15db',
  },
  {
    display: 'Australian Institute of Aboriginal and Torres Strait Islander Studies',
    returnValue: 'australian_institute_of_aboriginal_and_torres_stra_0535204',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Australian Institute of Criminology',
    returnValue: 'australian_institute_of_criminology',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Australian Institute of Family Studies (AIFS)',
    returnValue: 'australian_institute_of_family_studies__aifs_',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'social_services_1bc99ca',
  },
  {
    display: 'Australian Institute of Health and Welfare',
    returnValue: 'australian_institute_of_health_and_welfare',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'Australian Institute of Marine Science',
    returnValue: 'australian_institute_of_marine_science',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'climate_change__energy__the_environment_and_water_09df647',
  },
  {
    display: 'Australian Law Reform Commission',
    returnValue: 'australian_law_reform_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Australian Maritime Safety Authority',
    returnValue: 'australian_maritime_safety_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__cities_and_regional_dev',
  },
  {
    display: 'Australian Military Forces Relief Trust Fund',
    returnValue: 'australian_military_forces_relief_trust_fund',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'defence_1e6d17a',
  },
  {
    display: 'Australian National Audit Office',
    returnValue: 'australian_national_audit_office',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Australian National Maritime Museum',
    returnValue: 'australian_national_maritime_museum',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'Australian National Preventive Health Agency (ANPHA)',
    returnValue: 'australian_national_preventive_health_agency__anph',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health_and_aged_care_01d8856',
  },
  {
    display: 'Australian National University',
    returnValue: 'australian_national_university',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'education_4cf15db',
  },
  {
    display: 'Australian Naval Infrastructure Pty Ltd',
    returnValue: 'australian_naval_infrastructure_pty_ltd',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'finance_44bb0b6',
  },
  {
    display: 'Australian Nuclear Science and Technology Organisation',
    returnValue: 'australian_nuclear_science_and_technology_organisa_2be7154',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'industry__innovation_and_science',
  },
  {
    display: 'Australian Office of Financial Management (AOFM)',
    returnValue: 'australian_office_of_financial_management__aofm_',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Australian Pesticides and Veterinary Medicines Authority (APVMA)',
    returnValue: 'australian_pesticides_and_veterinary_medicines_aut_b954a67',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'agriculture',
  },
  {
    display: 'Australian Postal Corporation',
    returnValue: 'australian_postal_corporation',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'Australian Prudential Regulation Authority (APRA)',
    returnValue: 'australian_prudential_regulation_authority__apra_',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Australian Public Service Commission',
    returnValue: 'australian_public_service_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Australian Radiation Protection and Nuclear Safety Agency (ARPANSA)',
    returnValue: 'australian_radiation_protection_and_nuclear_safety_26b10ff',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'Australian Rail Track Corporation Limited',
    returnValue: 'australian_rail_track_corporation_limited',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'infrastructure__transport__cities_and_regional_dev',
  },
  {
    display: 'Australian Reinsurance Pool Corporation',
    returnValue: 'australian_reinsurance_pool_corporation',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Australian Renewable Energy Agency',
    returnValue: 'australian_renewable_energy_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'climate_change__energy__the_environment_and_water_09df647',
  },
  {
    display: 'Australian Research Council',
    returnValue: 'australian_research_council',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'education_4cf15db',
  },
  {
    display: 'Australian Securities and Investments Commission (ASIC)',
    returnValue: 'australian_securities_and_investments_commission__',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Australian Security Intelligence Organisation',
    returnValue: 'australian_security_intelligence_organisation',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'home_affairs_0004ba4',
  },
  {
    display: 'Australian Signals Directorate',
    returnValue: 'australian_signals_directorate',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'defence_1e6d17a',
  },
  {
    display: 'Australian Skills Quality Authority (National Vocational Education and Training Regulator)',
    returnValue: 'australian_skills_quality_authority__national_voca_cea5e1c',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'education__skills_and_employment',
  },
  {
    display: 'Australian Sports Anti-Doping Authority',
    returnValue: 'australian_sports_anti_doping_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'Australian Sports Commission (Australian Institute of Sport)',
    returnValue: 'australian_sports_commission__australian_institute',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'Australian Sports Foundation Limited',
    returnValue: 'australian_sports_foundation_limited',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'health',
  },
  {
    display: 'Australian Strategic Policy Institute Ltd',
    returnValue: 'australian_strategic_policy_institute_ltd',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'defence_1e6d17a',
  },
  {
    display: 'Australian Taxation Office',
    returnValue: 'australian_taxation_office',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Australian Trade and Investment Commission (Austrade)',
    returnValue: 'australian_trade_and_investment_commission__austra_db9d33d',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'foreign_affairs_and_trade_17c541f',
  },
  {
    display: 'Australian Transaction Reports and Analysis Centre (AUSTRAC)',
    returnValue: 'australian_transaction_reports_and_analysis_centre_d166741',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Australian Transport Safety Bureau (ATSB)',
    returnValue: 'australian_transport_safety_bureau__atsb_',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__cities_and_regional_dev',
  },
  {
    display: 'Australian War Memorial',
    returnValue: 'australian_war_memorial',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'veterans__affairs_93c0f4b',
  },
  {
    display: 'Bundanon Trust',
    returnValue: 'bundanon_trust',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'Bureau of Meteorology',
    returnValue: 'bureau_of_meteorology',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'agriculture__water_and_the_environment',
  },
  {
    display: 'Cancer Australia',
    returnValue: 'cancer_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'Central Land Council',
    returnValue: 'central_land_council',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Civil Aviation Safety Authority',
    returnValue: 'civil_aviation_safety_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__cities_and_regional_dev',
  },
  {
    display: 'Clean Energy Finance Corporation',
    returnValue: 'clean_energy_finance_corporation',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'climate_change__energy__the_environment_and_water_09df647',
  },
  {
    display: 'Clean Energy Regulator',
    returnValue: 'clean_energy_regulator',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'climate_change__energy__the_environment_and_water_09df647',
  },
  {
    display: 'Climate Change Authority',
    returnValue: 'climate_change_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'climate_change__energy__the_environment_and_water_09df647',
  },
  {
    display: 'Coal Mining Industry (Long Service Leave Funding) Corporation',
    returnValue: 'coal_mining_industry__long_service_leave_funding___1c7b525',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Comcare',
    returnValue: 'comcare',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Commonwealth Grants Commission',
    returnValue: 'commonwealth_grants_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Commonwealth Scientific and Industrial Research Organisation',
    returnValue: 'commonwealth_scientific_and_industrial_research_or_c0c3461',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'industry__innovation_and_science',
  },
  {
    display: 'Commonwealth Superannuation Corporation (CSC)',
    returnValue: 'commonwealth_superannuation_corporation__csc_',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'finance_44bb0b6',
  },
  {
    display: 'Cotton Research and Development Corporation',
    returnValue: 'cotton_research_and_development_corporation',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'agriculture',
  },
  {
    display: 'Creative Partnerships Australia Ltd',
    returnValue: 'creative_partnerships_australia_ltd',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'infrastructure__transport__regional_development__c_06044e8',
  },
  {
    display: 'Defence Housing Australia',
    returnValue: 'defence_housing_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'defence_1e6d17a',
  },
  {
    display: 'Department of Agriculture',
    returnValue: 'department_of_agriculture',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'agriculture',
  },
  {
    display: 'Department of Agriculture, Fisheries and Forestry',
    returnValue: 'department_of_agriculture__fisheries_and_forestry',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'agriculture__fisheries_and_forestry_090434d',
  },
  {
    display: 'Department of Agriculture, Water and the Environment',
    returnValue: 'department_of_agriculture__water_and_the_environme',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'agriculture__water_and_the_environment',
  },
  {
    display: 'Department of Climate Change, Energy, the Environment and Water',
    returnValue: 'department_of_climate_change__energy__the_environm_e4d2b70',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'climate_change__energy__the_environment_and_water_09df647',
  },
  {
    display: 'Department of Communications and the Arts',
    returnValue: 'department_of_communications_and_the_arts',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'Department of Defence',
    returnValue: 'department_of_defence',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'defence_1e6d17a',
  },
  {
    display: 'Department of Education',
    returnValue: 'department_of_education__i_',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'education__i_',
  },
  {
    display: 'Department of Education',
    returnValue: 'department_of_education',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'education_4cf15db',
  },
  {
    display: 'Department of Education, Skills and Employment',
    returnValue: 'department_of_education__skills_and_employment',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'education__skills_and_employment',
  },
  {
    display: 'Department of Employment and Workplace Relations',
    returnValue: 'department_of_employment_and_workplace_relations',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'employment_and_workplace_relations_0db4d5e',
  },
  {
    display: 'Department of Employment, Skills, Small and Family Business',
    returnValue: 'department_of_employment__skills__small_and_family',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'employment__skills__small_and_family_business',
  },
  {
    display: 'Department of Finance',
    returnValue: 'department_of_finance',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'finance_44bb0b6',
  },
  {
    display: 'Department of Foreign Affairs and Trade',
    returnValue: 'department_of_foreign_affairs_and_trade',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'foreign_affairs_and_trade_17c541f',
  },
  {
    display: 'Department of Health',
    returnValue: 'department_of_health',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'health',
  },
  {
    display: 'Department of Health and Aged Care',
    returnValue: 'department_of_health_and_aged_care',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health_and_aged_care_01d8856',
  },
  {
    display: 'Department of Home Affairs',
    returnValue: 'department_of_home_affairs',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'home_affairs_0004ba4',
  },
  {
    display: 'Department of Industry, Innovation and Science',
    returnValue: 'department_of_industry__innovation_and_science',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'industry__innovation_and_science',
  },
  {
    display: 'Department of Industry, Science and Resources',
    returnValue: 'department_of_industry__science_and_resources',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'industry__science_and_resources_36b351c',
  },
  {
    display: 'Department of Industry, Science, Energy and Resources',
    returnValue: 'department_of_industry__science__energy_and_resour',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'industry__science__energy_and_resources',
  },
  {
    display: 'Department of Infrastructure, Transport, Cities and Regional Development',
    returnValue: 'department_of_infrastructure__transport__cities_an',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__cities_and_regional_dev',
  },
  {
    display: 'Department of Infrastructure, Transport, Regional Development and Communications',
    returnValue: 'department_of_infrastructure__transport__regional__b49c764',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__regional_development_an',
  },
  {
    display: 'Department of Infrastructure, Transport, Regional Development, Communications and the Arts',
    returnValue: 'department_of_infrastructure__transport__regional__ddaff22',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__regional_development__c_06044e8',
  },
  {
    display: 'Department of Parliamentary Services',
    returnValue: 'department_of_parliamentary_services',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'parliamentary_departments__not_a_portfolio_',
  },
  {
    display: 'Department of Social Services',
    returnValue: 'department_of_social_services',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'social_services_1bc99ca',
  },
  {
    display: "Department of Veterans' Affairs",
    returnValue: 'department_of_veterans__affairs',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'veterans__affairs_93c0f4b',
  },
  {
    display: 'Department of the Environment and Energy',
    returnValue: 'department_of_the_environment_and_energy',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'environment_and_energy',
  },
  {
    display: 'Department of the House of Representatives',
    returnValue: 'department_of_the_house_of_representatives',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'parliamentary_departments__not_a_portfolio_',
  },
  {
    display: 'Department of the Prime Minister and Cabinet',
    returnValue: 'department_of_the_prime_minister_and_cabinet',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Department of the Senate',
    returnValue: 'department_of_the_senate',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'parliamentary_departments__not_a_portfolio_',
  },
  {
    display: 'Department of the Treasury',
    returnValue: 'department_of_the_treasury',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Digital Transformation Agency',
    returnValue: 'digital_transformation_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'finance_44bb0b6',
  },
  {
    display: 'Director of National Parks',
    returnValue: 'director_of_national_parks',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'agriculture__water_and_the_environment',
  },
  {
    display: 'Domestic, Family and Sexual Violence Commission',
    returnValue: 'domestic__family_and_sexual_violence_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'social_services_1bc99ca',
  },
  {
    display: 'Export Finance and Insurance Corporation (EFIC, Export Finance Australia)',
    returnValue: 'export_finance_and_insurance_corporation__efic__ex',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'foreign_affairs_and_trade_17c541f',
  },
  {
    display: 'Fair Work Commission',
    returnValue: 'fair_work_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Federal Court of Australia',
    returnValue: 'federal_court_of_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Financial Adviser Standards and Ethics Authority Ltd',
    returnValue: 'financial_adviser_standards_and_ethics_authority_l',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Fisheries Research and Development Corporation',
    returnValue: 'fisheries_research_and_development_corporation',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'agriculture',
  },
  {
    display: 'Food Standards Australia New Zealand',
    returnValue: 'food_standards_australia_new_zealand',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'Future Fund Management Agency',
    returnValue: 'future_fund_management_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'finance_44bb0b6',
  },
  {
    display: 'Geoscience Australia',
    returnValue: 'geoscience_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'industry__innovation_and_science',
  },
  {
    display: 'Grains Research and Development Corporation',
    returnValue: 'grains_research_and_development_corporation',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'agriculture',
  },
  {
    display: 'Great Barrier Reef Marine Park Authority',
    returnValue: 'great_barrier_reef_marine_park_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'agriculture__water_and_the_environment',
  },
  {
    display: 'High Speed Rail Authority',
    returnValue: 'high_speed_rail_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__regional_development__c_06044e8',
  },
  {
    display: 'IP Australia',
    returnValue: 'ip_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'industry__innovation_and_science',
  },
  {
    display: 'Independent Health and Aged Care Pricing Authority',
    returnValue: 'independent_health_and_aged_care_pricing_authority_ed2b9c4',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'health_and_aged_care_01d8856',
  },
  {
    display: 'Independent Hospital Pricing Authority',
    returnValue: 'independent_hospital_pricing_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'Independent Parliamentary Expenses Authority',
    returnValue: 'independent_parliamentary_expenses_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'finance_44bb0b6',
  },
  {
    display: 'Indigenous Business Australia',
    returnValue: 'indigenous_business_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Indigenous Land and Sea Corporation',
    returnValue: 'indigenous_land_and_sea_corporation',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Infrastructure Australia',
    returnValue: 'infrastructure_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__cities_and_regional_dev',
  },
  {
    display: 'Infrastructure and Project Financing Agency',
    returnValue: 'infrastructure_and_project_financing_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__cities_and_regional_dev',
  },
  {
    display: 'Inspector-General of Taxation',
    returnValue: 'inspector_general_of_taxation',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Moorebank Intermodal Company Limited',
    returnValue: 'moorebank_intermodal_company_limited',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'infrastructure__transport__cities_and_regional_dev',
  },
  {
    display: 'Murray-Darling Basin Authority',
    returnValue: 'murray_darling_basin_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'agriculture',
  },
  {
    display: 'NBN Co Limited',
    returnValue: 'nbn_co_limited',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'NDIS Quality and Safeguards Commission',
    returnValue: 'ndis_quality_and_safeguards_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'social_services_1bc99ca',
  },
  {
    display: 'National Anti-Corruption Commission',
    returnValue: 'national_anti_corruption_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'National Archives of Australia',
    returnValue: 'national_archives_of_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'National Australia Day Council Limited',
    returnValue: 'national_australia_day_council_limited',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'National Blood Authority',
    returnValue: 'national_blood_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'National Capital Authority',
    returnValue: 'national_capital_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__cities_and_regional_dev',
  },
  {
    display: 'National Competition Council',
    returnValue: 'national_competition_council',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'National Disability Insurance Agency',
    returnValue: 'national_disability_insurance_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'social_services_1bc99ca',
  },
  {
    display: 'National Disability Insurance Scheme Launch Transition Agency',
    returnValue: 'national_disability_insurance_scheme_launch_transi',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'social_services_1bc99ca',
  },
  {
    display: 'National Drought and North Queensland Flood Response and Recovery Agency',
    returnValue: 'national_drought_and_north_queensland_flood_respon',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'National Emergency Management Agency',
    returnValue: 'national_emergency_management_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'home_affairs_0004ba4',
  },
  {
    display: 'National Faster Rail Agency',
    returnValue: 'national_faster_rail_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__regional_development_an',
  },
  {
    display: 'National Film and Sound Archive of Australia',
    returnValue: 'national_film_and_sound_archive_of_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'National Gallery of Australia',
    returnValue: 'national_gallery_of_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'National Health Funding Body (NHFB)',
    returnValue: 'national_health_funding_body__nhfb_',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'National Health and Medical Research Council (NHMRC)',
    returnValue: 'national_health_and_medical_research_council__nhmr',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'National Housing Finance and Investment Corporation (NHFIC)',
    returnValue: 'national_housing_finance_and_investment_corporatio_dcfab4a',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'National Indigenous Australians Agency',
    returnValue: 'national_indigenous_australians_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'National Intermodal Corporation Limited',
    returnValue: 'national_intermodal_corporation_limited',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'infrastructure__transport__regional_development__c_06044e8',
  },
  {
    display: 'National Library of Australia',
    returnValue: 'national_library_of_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'National Mental Health Commission',
    returnValue: 'national_mental_health_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'National Museum of Australia',
    returnValue: 'national_museum_of_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'National Offshore Petroleum Safety and Environmental Management Authority (NOPSEMA)',
    returnValue: 'national_offshore_petroleum_safety_and_environment_2626d4b',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'industry__innovation_and_science',
  },
  {
    display: 'National Portrait Gallery of Australia',
    returnValue: 'national_portrait_gallery_of_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'National Recovery and Resilience Agency',
    returnValue: 'national_recovery_and_resilience_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'National Transport Commission',
    returnValue: 'national_transport_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'infrastructure__transport__cities_and_regional_dev',
  },
  {
    display: 'North Queensland Livestock Industry Recovery Agency',
    returnValue: 'north_queensland_livestock_industry_recovery_agenc',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'North Queensland Water Infrastructure Authority',
    returnValue: 'north_queensland_water_infrastructure_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'climate_change__energy__the_environment_and_water_09df647',
  },
  {
    display: 'Northern Australia Infrastructure Facility',
    returnValue: 'northern_australia_infrastructure_facility',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'industry__innovation_and_science',
  },
  {
    display: 'Northern Land Council',
    returnValue: 'northern_land_council',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Northern Territory Aboriginal Investment Corporation',
    returnValue: 'northern_territory_aboriginal_investment_corporati_e7bb662',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Office of Parliamentary Counsel',
    returnValue: 'office_of_parliamentary_counsel',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Office of the Auditing and Assurance Standards Board (AUASB)',
    returnValue: 'office_of_the_auditing_and_assurance_standards_boa_17974c4',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Office of the Australian Accounting Standards Board (AASB)',
    returnValue: 'office_of_the_australian_accounting_standards_boar_00fa6c8',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Office of the Australian Information Commissioner',
    returnValue: 'office_of_the_australian_information_commissioner',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Office of the Commonwealth Ombudsman',
    returnValue: 'office_of_the_commonwealth_ombudsman',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Office of the Director of Public Prosecutions (CDPP)',
    returnValue: 'office_of_the_director_of_public_prosecutions__cdp',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Office of the Fair Work Ombudsman',
    returnValue: 'fair_work_ombudsman_and_registered_organisations_c',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Office of the Inspector-General of Intelligence and Security',
    returnValue: 'office_of_the_inspector_general_of_intelligence_an_570b9a3',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Office of the Official Secretary to the Governor-General',
    returnValue: 'office_of_the_official_secretary_to_the_governor_g_d1af738',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Office of the Special Investigator',
    returnValue: 'office_of_the_special_investigator',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Old Parliament House',
    returnValue: 'old_parliament_house',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'Organ and Tissue Authority (Australian Organ and Tissue Donation and Transplantation Authority)',
    returnValue: 'organ_and_tissue_authority__australian_organ_and_t',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'Outback Stores Pty Ltd',
    returnValue: 'outback_stores_pty_ltd',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Parliamentary Budget Office',
    returnValue: 'parliamentary_budget_office',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'parliamentary_departments__not_a_portfolio_',
  },
  {
    display: 'Productivity Commission',
    returnValue: 'productivity_commission',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Professional Services Review',
    returnValue: 'professional_services_review',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'RAAF Welfare Recreational Company',
    returnValue: 'raaf_welfare_recreational_company',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'defence_1e6d17a',
  },
  {
    display: 'Regional Investment Corporation',
    returnValue: 'regional_investment_corporation',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'agriculture',
  },
  {
    display: 'Reserve Bank of Australia',
    returnValue: 'reserve_bank_of_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: "Royal Australian Air Force Veterans' Residences Trust Fund",
    returnValue: 'royal_australian_air_force_veterans__residences_tr_201cd83',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'defence_1e6d17a',
  },
  {
    display: 'Royal Australian Air Force Welfare Trust Fund',
    returnValue: 'royal_australian_air_force_welfare_trust_fund',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'defence_1e6d17a',
  },
  {
    display: 'Royal Australian Mint',
    returnValue: 'royal_australian_mint',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'treasury_125e0b7',
  },
  {
    display: 'Royal Australian Navy Central Canteens Board',
    returnValue: 'royal_australian_navy_central_canteens_board',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'defence_1e6d17a',
  },
  {
    display: 'Royal Australian Navy Relief Trust Fund',
    returnValue: 'royal_australian_navy_relief_trust_fund',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'defence_1e6d17a',
  },
  {
    display: 'Rural Industries Research and Development Corporation',
    returnValue: 'rural_industries_research_and_development_corporat_c01bd82',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'agriculture',
  },
  {
    display: 'Safe Work Australia',
    returnValue: 'safe_work_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Screen Australia',
    returnValue: 'screen_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'Seafarers Safety, Rehabilitation and Compensation Authority (Seacare Authority)',
    returnValue: 'seafarers_safety__rehabilitation_and_compensation__f4fe134',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'attorney_general_s_0645452',
  },
  {
    display: 'Services Australia',
    returnValue: 'services_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'services_australia__part_of_the_social_services_po',
  },
  {
    display: 'Snowy Hydro Limited',
    returnValue: 'snowy_hydro_limited',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'climate_change__energy__the_environment_and_water_09df647',
  },
  {
    display: 'Special Broadcasting Service Corporation',
    returnValue: 'special_broadcasting_service_corporation',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'communications_and_the_arts',
  },
  {
    display: 'Sport Integrity Australia',
    returnValue: 'sport_integrity_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'health',
  },
  {
    display: 'Sydney Harbour Federation Trust',
    returnValue: 'sydney_harbour_federation_trust',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'agriculture__water_and_the_environment',
  },
  {
    display: 'Tertiary Education Quality and Standards Agency',
    returnValue: 'tertiary_education_quality_and_standards_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'education_4cf15db',
  },
  {
    display: 'Tiwi Land Council',
    returnValue: 'tiwi_land_council',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Torres Strait Regional Authority',
    returnValue: 'torres_strait_regional_authority',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Tourism Australia',
    returnValue: 'tourism_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'foreign_affairs_and_trade_17c541f',
  },
  {
    display: 'WSA Co Ltd',
    returnValue: 'wsa_co_ltd',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CC' }] },
    bodyType: 'Commonwealth Company',
    portfolio: 'infrastructure__transport__cities_and_regional_dev',
  },
  {
    display: 'Wine Australia',
    returnValue: 'wine_australia',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'agriculture',
  },
  {
    display: 'Workplace Gender Equality Agency',
    returnValue: 'workplace_gender_equality_agency',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'NCE' }] },
    bodyType: 'Non-Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
  {
    display: 'Wreck Bay Aboriginal Community Council',
    returnValue: 'wreck_bay_aboriginal_community_council',
    selected: false,
    tagInfo: { tagPosition: 'right', tags: [{ display: 'CCE' }] },
    bodyType: 'Corporate Commonwealth Entity',
    portfolio: 'prime_minister_and_cabinet_2920fb8',
  },
];

export const ADDITIONAL_FILTER_OPTIONS = [
  {
    filterTitle: 'Employment contract types',
    filterSubText: 'contract types',
    filterOptions: [
      { returnValue: 'ses_3', display: 'SES 3', selected: true },
      { returnValue: 'ses_2', display: 'SES 2', selected: true },
      { returnValue: 'ses_1', display: 'SES 1', selected: true },
      { returnValue: 'el_2', display: 'EL 2', selected: true },
      { returnValue: 'el_1', display: 'EL 1', selected: true },
      { returnValue: 'aps_6', display: 'APS 6', selected: true },
      { returnValue: 'aps_5', display: 'APS 5', selected: true },
      { returnValue: 'aps_4', display: 'APS 4', selected: true },
      { returnValue: 'aps_3', display: 'APS 3', selected: true },
      { returnValue: 'aps_2', display: 'APS 2', selected: true },
      { returnValue: 'aps_1', display: 'APS 1', selected: true },
      { returnValue: 'employment_other', display: 'Other', selected: true },
      { returnValue: 'employment_total', display: 'Total', selected: true },
    ],
    dataSets: ['aps_employment_performance_pay_by_class_level'],
  },
  {
    filterTitle: 'Classification',
    filterSubText: 'classifications',
    filterOptions: [
      { returnValue: 'SES', display: 'SES', selected: true },
      { returnValue: 'Non-SES', display: 'Non-SES', selected: true },
      { returnValue: 'ClassificationTotal', display: 'Total', selected: true },
    ],
    dataSets: ['APS employment arrangements', 'APS employment performance pay by classification level', 'APS employment salary ranges by classification level (minimum/maximum)'],
  },
  {
    filterTitle: 'Contracts',
    filterSubText: 'contracts',
    filterOptions: [
      { returnValue: 'Number', display: 'Number', selected: true },
      { returnValue: `Expenditure $'000 (GST inc.)`, display: `Expenditure $'000 (GST inc.)`, selected: true },
    ],
    dataSets: ['Organisations receiving a share of reportable non-consultancy contract expenditure'],
  },
  {
    filterTitle: 'Contract status',
    filterSubText: 'contract statuses',
    filterOptions: [
      { returnValue: 'NewContracts', display: 'New contracts entered into during the reporting period', selected: true },
      { returnValue: 'OngoingContracts', display: 'Ongoing contracts entered into during the previous reporting period', selected: true },
    ],
    dataSets: [],
  },
  {
    filterTitle: 'Engagement',
    filterSubText: 'engagement types',
    filterOptions: [
      { returnValue: 'Ongoing', display: 'Ongoing', selected: true },
      { returnValue: 'Non-ongoing', display: 'Non-Ongoing', selected: true },
      { returnValue: 'EngagementTotal', display: 'Total', selected: true },
    ],
    dataSets: ['APS employees by full-time and part-time'],
  },
  {
    filterTitle: 'Employment type',
    filterSubText: 'employment types',
    filterOptions: [
      { returnValue: 'full_time', display: 'Full time', selected: true },
      { returnValue: 'part_time', display: 'Part time', selected: true },
      { returnValue: 'total_time', display: 'Total', selected: true },
    ],
    dataSets: ['all_non_ongoing_employees___nce'],
  },
  {
    filterTitle: 'Extract of regulatory charging summary',
    filterSubText: 'summaries',
    filterOptions: [
      { returnValue: '30th June this reporting year', display: '30th June this reporting year', selected: true },
      { returnValue: '30th June previous reporting year', display: '30th June previous reporting year', selected: true },
    ],
    dataSets: [],
  },
  {
    filterTitle: 'Gender',
    filterSubText: 'genders',
    filterOptions: [
      { returnValue: 'male', display: 'Man/Male', selected: true },
      { returnValue: 'female', display: 'Woman/Female', selected: true },
      { returnValue: 'non_binary', display: 'Non-binary', selected: true },
      { returnValue: 'prefers_not_to_answer', display: 'Prefer not to answer', selected: true },
      { returnValue: 'uses_a_different_term', display: 'Uses a different term', selected: true },
      { returnValue: 'total_gender', display: 'Total', selected: true },
    ],
    dataSets: ['all_non_ongoing_employees___nce'],
  },
  {
    filterTitle: 'Location',
    filterSubText: 'locations',
    filterOptions: [
      { returnValue: 'nsw', display: 'NSW', selected: true },
      { returnValue: 'qld', display: 'QLD', selected: true },
      { returnValue: 'sa', display: 'SA', selected: true },
      { returnValue: 'tas', display: 'TAS', selected: true },
      { returnValue: 'vic', display: 'VIC', selected: true },
      { returnValue: 'act', display: 'ACT', selected: true },
      { returnValue: 'nt', display: 'NT', selected: true },
      { returnValue: 'wa', display: 'WA', selected: true },
      { returnValue: 'external_territories', display: 'External Territories', selected: true },
      { returnValue: 'overseas', display: 'Overseas', selected: true },
      { returnValue: 'location_total', display: 'Total', selected: true },
    ],
    dataSets: ['all_non_ongoing_employees___nce'],
  },
  {
    filterTitle: 'Variance',
    filterSubText: 'variances',
    filterOptions: [
      { returnValue: 'Variance - 30th June this reporting year', display: '30th June this reporting year', selected: true },
      { returnValue: 'Variance - 30th June previous reporting year', display: '30th June previous reporting year', selected: true },
      { returnValue: 'Variance - Original budget', display: 'Original budget', selected: true },
    ],
    dataSets: ['Statement of changes in equity', 'Administered cash flow statement', 'Notes to the financial statement (Departmental)'],
  },
  {
    filterTitle: 'Report on financial performance summary',
    filterSubText: 'variances',
    filterOptions: [
      { returnValue: 'Actual available appropriations - Current year', display: 'Actual available appropriations - Current year', selected: true },
      { returnValue: 'Payments made', display: 'Payments made', selected: true },
      { returnValue: 'Balance remaining', display: 'Balance remaining', selected: true },
    ],
    dataSets: [],
  },
  {
    filterTitle: 'Year',
    filterSubText: 'years',
    filterOptions: [
      { returnValue: '2023', display: '2023', selected: true },
      { returnValue: '2022', display: '2022', selected: true },
      { returnValue: '2021', display: '2021', selected: true },
      { returnValue: '2020', display: '2020', selected: true },
      { returnValue: '2019', display: '2019', selected: true },
      { returnValue: '2018', display: '2018', selected: true },
    ],
    dataSets: [],
  },
];

export const DATA_SET_TABLES = [
  {
    dataSetTitle: 'APS employees by full-time and part-time',
    type: 'row',
    dataTemplate: WEB_TABLES_RESPONSE_ACCOUNTABLE_AUTH,
    dataTable: DATA_TEMPLATE_TABLE_RESPONSE_ACCOUNTABLE_AUTH,
  },
  {
    dataSetTitle: 'All non-ongoing employees',
    type: 'fixed',
    dataTemplate: WEB_TABLES_RESPONSE_ONGOING_EMPLOYEES,
    dataTable: DATA_TEMPLATE_TABLE_RESPONSE_ONGOING_EMPLOYEES,
  },
  {
    dataSetTitle: 'Details of Accountable Authority',
    type: 'row',
    dataTemplate: WEB_TABLES_RESPONSE_ACCOUNTABLE_AUTH,
    dataTable: DATA_TEMPLATE_TABLE_RESPONSE_ACCOUNTABLE_AUTH,
  },
];

export const FOOTNOTE_CONTENT = (
  <>
    <p>Source: This data has been provided by Commonwealth entities and companies to represent a subset of their published annual report. </p>
    <p>The information and data displayed in these data sets does not supersede the published annual reports produced by Commonwealth entities and companies. </p>
    <p>
      The annual reports tabled in the Parliament are the authoritative source of information for all purposes including under the Public Governance, Performance and Accountability
      Act 2013. The Department of Finance makes no warranty, guarantee or representation about the accuracy, reliability timeliness or otherwise of the information contained on
      this website or on linked sites on this website. Please refer to the <a>Copyright statement</a> for a full understanding of attribution and derivative material. This website
      is owned and operated by the Commonwealth of Australia as represented by the Department of Finance.
    </p>
  </>
);
