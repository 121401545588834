import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './Filter.module.scss';
import { MobileFilter, Tag } from 'Components';
import { CONSTANTS, TAG_STYLE_ENUMS } from 'Constants';
import { debounce, detectViewMode } from 'Utils';

/**
 * Filter.jsx
 *
 * @summary Component for filter items functionality
 *
 * @param {Object} props - Component props.
 * @prop {String} props.filterTitle - Title for filter.
 * @prop {{filterTitle: String, returnValue: String, display: String}[]} [props.selectedFilters] - List of currently selected filters.
 * @prop {Node} [props.children] - Children nodes add which will appear in filter dropdowns section.
 * @prop {String} [props.filterLabel] - Label for filter (shows in bottom left of filter).
 * @prop {Function} [props.removeFilterFunction] - Function for removing certain filter value
 * @prop {Function} [props.exportSearchPageFunction] - Function for removing certain filter value
 *
 * TODO:
 * - Add 'abolish entity' switch filter
 */
function Filter({
  children,
  filterTitle,
  filterLabel,
  removeFilterFunction,
  clearFilterFunction,
  applyFilterFunction,
  selectedFilters = [],
  isDisabled,
  disableApplyButton,
  notShowFilterCount,
  exportSearchPageFunction
}) {

  const [viewMode, setViewMode] = useState(detectViewMode());

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  const preventEnterKey = (e) => {
    // prevent enter key on 'input' elements from causing form submission or trigger button in form
    if (e.key === CONSTANTS.ENTER_KEY && e.target?.tagName?.toUpperCase() === 'INPUT') {
      e.preventDefault();
    }
  };

  return (
    <>
      {viewMode !== CONSTANTS.VIEW_MODE.MOBILE ? (
        <form className={style.filterContainer} onSubmit={(e) => e.preventDefault()} onKeyDown={preventEnterKey}>
          <span className={style.smallerText}>{filterTitle}</span>
          <div className={[exportSearchPageFunction ? style.rowStylingFilterSection : style.filterSection].join(' ')}>
            {/* Filter children are add here */}
            <div className={style.filterTypes}>{children}</div>
            <div className={[style.filterButtons, exportSearchPageFunction ? style.seperatedGroup: ''].join(' ')}>
              {
                !exportSearchPageFunction ? (
                  <>
                    <button disabled={isDisabled} className="noFill" onClick={() => clearFilterFunction()}>
                      Clear
                    </button>
                    <button disabled={disableApplyButton || isDisabled} onClick={() => applyFilterFunction()}>
                      Apply
                    </button>
                  </>
                ) : (
                  <>
                    <button disabled={disableApplyButton || isDisabled} onClick={() => exportSearchPageFunction()}>
                      Export as CSV
                    </button>
                    <div className={style.groupedButtons}>
                      <button disabled={isDisabled} className="noFill" onClick={() => clearFilterFunction()}>
                        Clear
                      </button>
                      <button disabled={disableApplyButton || isDisabled} onClick={() => applyFilterFunction()}>
                        Apply
                      </button>
                    </div>

                  </>

                )
              }


            </div>
            <div className={style.filterSelections}>
              {selectedFilters.map((selectedFilter, i) => {
                return (
                  // Selected filters
                  <Tag
                    key={`selected-filter-${selectedFilter.returnValue}-${i}`}
                    displayText={selectedFilter.display}
                    filterFunction={() => removeFilterFunction(selectedFilter)}
                    type={TAG_STYLE_ENUMS.DARK}></Tag>
                );
              })}
            </div>
            <span className={[style.filterLabel, style.smallerText].join(' ')}>{filterLabel}</span>
          </div>
        </form>
      ) : (
        <MobileFilter
          isDisabled={isDisabled}
          disableApplyButton={disableApplyButton}
          filterTitle={filterTitle}
          filterLabel={filterLabel}
          selectedFilters={selectedFilters}
          clearFilter={clearFilterFunction}
          applyFilter={applyFilterFunction}
          notShowFilterCount={notShowFilterCount}>
          {children}
        </MobileFilter>
      )}
    </>
  );
}

Filter.propTypes = {
  children: PropTypes.node,
  filterTitle: PropTypes.string.isRequired,
  filterLabel: PropTypes.string,
  selectedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string.isRequired,
      returnValue: PropTypes.string.isRequired,
    }),
  ),
  removeFilterFunction: PropTypes.func.isRequired,
  clearFilterFunction: PropTypes.func.isRequired,
  applyFilterFunction: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  disableApplyButton: PropTypes.bool,
  notShowFilterCount: PropTypes.array,
  exportSearchPageFunction: PropTypes.func,
};

export default Filter;
