import React from 'react';
import PropTypes from 'prop-types';
import style from './AnnualReportBanner.module.scss';
import DefaultBanner from 'Assets/Tree-Half-Light.svg';
import Logo from 'Assets/AusGovCrest-DefaultEntityLogo.svg';
import { Link } from 'react-router-dom';

/**
 * AnnualReportBanner.jsx
 *
 * @summary AnnualReportBanner render as simple banner which does not contains any text and banner image would be purely decorative.
 *
 * @param {Object} props - Component props.
 * @prop {{ [name]: String, [url]: String }} [props.department] - Object that contains name of department and url to its website.
 * @prop {{ [name]: String, [url]: String }} [props.portfolio] - Object that contains name of portfolio and url to its website.
 * @prop {String} [props.logoImgSrc] - Url link for logo image. If not given, will use default.
 * @prop {String} [props.logoImgAltText] - Alt text for logo image. If not given, will use default.
 * @prop {String} [props.bannerImgSrc] - Url link for banner image. If not given, will use default.
 */
function AnnualReportBanner({ department, portfolio, logoImgSrc, logoImgAltText, bannerImgSrc }) {
  const backgroundBannerStyle = {
    // use default banner if no banner is given
    '--background-banner': `url(${bannerImgSrc || DefaultBanner})`,
  };

  const logoStyle = {};
  // remove logo border if no department name or portfolio is added
  if (!department?.name && !portfolio?.name) {
    logoStyle.border = 'none';
  }
  // add no background when showing default logo (since background is white normally)
  if (!logoImgSrc) {
    logoStyle.background = 'none';
  }

  return (
    <div id={style.AnnualReportBanner} style={backgroundBannerStyle} className="fullColorBackground">
      <div className={style.brandMark}>
        <div className={style.logoWrapper} style={logoStyle}>
          {/** use default logo if no logoImgSrc is given */}
          <img className={style.logo} src={logoImgSrc ?? Logo} alt={logoImgAltText || ''} role={logoImgAltText ? null : 'presentation'} />
        </div>

        {(department?.name || portfolio?.name) && (
          <div className={style.brandInfo}>
            {department?.name ? <Link to={`/${department?.webUrl}` || '#'}>{department.name}</Link> : null}
            {portfolio?.name ? (
              <p className={style.portfolio}>
                <span>Portfolio:&nbsp;</span>
                <Link to={`/${portfolio?.webUrl}` || '#'}>{portfolio.name}</Link>
              </p>
            ) : null}
          </div>
        )}
      </div>
      {/** banner image */}
      <div className={style.backgroundContainer}>
        {/** only add the background blur banner style when showing the default banner */}
        {!bannerImgSrc && <div className={style.bannerBlurBackground}></div>}
        <div className={`${style.bannerBackground} ${!bannerImgSrc ? style.default : ''}`} style={backgroundBannerStyle}></div>
      </div>
    </div>
  );
}

const propObjectShape = {
  name: PropTypes.string,
  url: PropTypes.string,
};

AnnualReportBanner.propTypes = {
  department: PropTypes.shape(propObjectShape).isRequired,
  portfolio: PropTypes.shape(propObjectShape).isRequired,
  logoImgSrc: PropTypes.string,
  logoImgAltText: PropTypes.string,
  bannerImgSrc: PropTypes.string,
};

export default AnnualReportBanner;
