import React, { Suspense, useEffect, useState, } from 'react';
import {
  Chart,
  DataExplorerTable,
  DataSetsFilterAccordion,
  DropdownSelect,
  FormField,
  PageHead,
  Tag,
} from 'Components';

import style from './DataExplorer.module.scss';
import { BODY_TYPE, CHART_TYPE, CONSTANTS, PATHS, URL_QUERY_KEYS } from 'Constants';
import { Await, useRouteLoaderData, useSearchParams, } from 'react-router-dom';
import { debounce, detectViewMode } from 'Utils';
import { DataExplorerLoading } from 'Pages';
import sprite from 'Assets/svgSprite.svg';
import { DATA_EXPLORER_URL_FILTER_GROUPS } from 'Constants/DataExplorer';
import icons from 'Assets/svgSprite.svg';
import GreyTree from 'Assets/Tree-Half-Grey.svg';
import { getDataExplorerData } from 'Services/Delivery';

/**
 * DataExplorer.jsx
 *
 * @summary This component is page view for Data Explorer page.
 *
 */
function DataExplorer() {

  const [isAppliedButtonActive, setIsAppliedButtonActive] = useState(false);
  const [filterData, setFilterData] = useState({
    Location:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    Gender:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    EmploymentType:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    Engagement:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    APSBand:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    NameofContractedOrganisation:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    Portfolio:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    BodyType:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    Entity:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    ReportingYear:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
    ContentType:{
      displayName:'',
      filterData:[],
      selectAll:false,
    },
  });
  const [emptyFilters, setEmptyFilters] = useState(structuredClone(filterData));
  const [appliedFilterData, setAppliedFilterData] = useState(structuredClone(filterData));
  const dataExplorerLoaderData = useRouteLoaderData(PATHS.DATA_EXPLORER.ID);
  const [viewMode, setViewMode] = useState(detectViewMode());
  const [urlParams, setUrlParams] = useSearchParams();
  const [datasetTableData, setDatasetTableData] = useState();
  const [datasetBarData, setDatasetBarData] = useState();
  const [datasetLineData, setDatasetLineData] = useState();
  const [disableCharts, setDisableCharts] = useState(true);


  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  },[]);

  // Apply filters
  const applyFilters = (e) => {
    async function fetchData() {
      let responceData = null;
      try {
        responceData = await getDataExplorerData(result);
        console.log(responceData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        processData(responceData);
      }
    }
    
    function processData(responceData) {
      if (responceData && !responceData.isError) {
        // Data Explorer Table Processing
        const filteredTableData = responceData.flatMap(item => {
          const { entity, reportingPeriod, datafields } = item;
    
          return datafields.map(dataField => ({
            Entity: entity,
            ReportingPeriod: reportingPeriod,
            DataFieldName: dataField.dataFieldName,
            Value: dataField.value
          }));
        });

        setDatasetTableData(filteredTableData);
    
        // Data Explorer Bar Graph Processing
        setDatasetBarData(transformBarGraphData(responceData));
        
        // Data Explorer Linegraph Processing
        setDatasetLineData(transformToLineChartData(responceData));
    
        setDisableCharts(false);
      } else {
        console.log(responceData);
      }
    }

    e.preventDefault();
    setAppliedFilterData(structuredClone(filterData));

    //sends out query for api responce
    const result = {
      tags: {},
      reportSelector: {}
    };

    for (const key in filterData) {
      if (filterData?.[key]) {
        const filterDataObject = filterData[key].filterData;
        const selectedValues = filterDataObject?.filter(item => item.selected)?.map(item => item.returnValue);

        if (selectedValues?.length > 0) {
          if (key == "Location" || key == "Gender" || key == "EmploymentType" || key ==  "Engagement" || key == "APSBand" || key == "NameofContractedOrganisation") {
            result["tags"][key] = selectedValues;
          } else  if (key == "Portfolio" || key ==  "BodyType" || key == "Entity" || key == "ReportingYear") {
            result["reportSelector"][key] = selectedValues;
          }
        }
      }
    }

    setUrlParams((prevParams) => {
      Object.keys(DATA_EXPLORER_URL_FILTER_GROUPS).forEach((key) => {
        const filterGroup = DATA_EXPLORER_URL_FILTER_GROUPS[key].VALUE;
        const filterDataObj = filterData[filterGroup]?.filterData || [];
        const selectAll = filterData[filterGroup]?.selectAll || false;
        const selectedValues = selectAll
          ? "ALL"
          : filterDataObj
              .filter((item) => item.selected)
              .map((item) => item.returnValue)
              .join(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
        if (selectedValues){
          prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${filterGroup}`, selectedValues || '');
        }

      });
      return prevParams;
    });

    fetchData();

    setIsAppliedButtonActive(false);
  };

  // Clears filters, all applied filter and data set tables
  const clearFilters = () => {
    setFilterData(structuredClone(emptyFilters));
    setAppliedFilterData(structuredClone(emptyFilters));
    checkApplied();

    setUrlParams('');
  };

  // Used for displaying of dropdown options selection subtext info
  const numberOfDropdownOptionsSelected = (filterList) => {
    if (filterList?.filterData?.every((option) => option.selected === true)) {
      return 'All';
    }
    return `${filterList?.filterData?.filter((option) => option.selected === true).length}` || '0';
  };

  const isFilterDataSelected = (propName) => {
    if (filterData.propName && Array.isArray(filterData[propName].filterData)) {
      return filterData[propName].filterData.every(filterItem => filterItem.selected === true);
    } else {
      return false;
    }
  };

  const setAllFilterDataSelection = (propName, selectedValue) => {
    if (filterData[propName]) {

      const newFilterData = { ...filterData };
      newFilterData[propName].filterData = newFilterData[propName].filterData.map(filterItem => ({
        ...filterItem,
        selected: selectedValue
      }));
  
      newFilterData[propName].selectAll = selectedValue;
      setFilterData(newFilterData);
      checkApplied();
    }
  };

  //checked if the applied content is the same as the current filter content, if they are the same it disables the button otherwise it is enabled
  function checkApplied() {
    function deepEqual(obj1, obj2) {
      if (obj1 === obj2) {
        return true;
      }
    
      if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
      }
    
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
    
      if (keys1.length !== keys2.length) {
        return false;
      }
    
      for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }
    
      return true;
    }
    if (deepEqual(filterData, appliedFilterData)) {
      setIsAppliedButtonActive(false);
    } else {
      setIsAppliedButtonActive(true);
    }
  }

  const atLeastOneReportingYearSelected = () => {
    return filterData.ReportingYear.filterData.some((filterOption) => filterOption.selected === true);
  };

  const atLeastOneEntitySelected = () => {
    return filterData.Entity.filterData.some((filterOption) => filterOption.selected === true);
  };

  const selectLocationFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.Location, selectedOption);
    setFilterData({ 
      ...filterData, 
      Location: {
        ...filterData.Location,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectGenderFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.Gender, selectedOption);
    setFilterData({ 
      ...filterData, 
      Gender: {
        ...filterData.Gender,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectEmploymentTypeFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.EmploymentType, selectedOption);
    setFilterData({ 
      ...filterData, 
      EmploymentType: {
        ...filterData.EmploymentType,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectEngagementFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.Engagement, selectedOption);
    setFilterData({ 
      ...filterData, 
      Engagement: {
        ...filterData.Engagement,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectAPSBandFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.APSBand, selectedOption);
    setFilterData({ 
      ...filterData, 
      APSBand: {
        ...filterData.APSBand,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectNameofContractedOrganisationFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.NameofContractedOrganisation, selectedOption);
    setFilterData({ 
      ...filterData, 
      NameofContractedOrganisation: {
        ...filterData.NameofContractedOrganisation,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectPortfolioFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.Portfolio, selectedOption);
    setFilterData({ 
      ...filterData, 
      Portfolio: {
        ...filterData.Portfolio,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectBodyTypeFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.BodyType, selectedOption);
    setFilterData({ 
      ...filterData, 
      BodyType: {
        ...filterData.BodyType,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  const selectEntityFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.Entity, selectedOption);
    setFilterData({ 
      ...filterData, 
      Entity: {
        ...filterData.Entity,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };

  const selectReportingYearFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection(filterData.ReportingYear, selectedOption);
    setFilterData({ 
      ...filterData, 
      ReportingYear: {
        ...filterData.ReportingYear,
        selectAll: updatedSelection.selectAll,
        filterData: updatedSelection.filterData,
      }
    });
    checkApplied();
  };
  
  // Updates a dropdown selection option list with updated value from selected option. (Converts 'selected' property to true/false or with a specific override value)
  const updateDropdownSelection = (filterCategory, selectedOption, overrideValue) => {
    const updatedSelection = [...filterCategory.filterData];
    const foundSelectedOption = updatedSelection.find((option) => option.returnValue === selectedOption.returnValue);

    if (foundSelectedOption) {
      foundSelectedOption.selected = overrideValue !== undefined && overrideValue !== null ? overrideValue : !foundSelectedOption.selected;
    }

    const selectAll = updatedSelection.every(option => option.selected);

    return {
      selectAll:selectAll,
      filterData:updatedSelection
    };
  };

  function transformToLineChartData(dataArray) {
    // Initialize the result object
    const result = [];
  
    // Group data by Entity
    const groupedData = dataArray.reduce((acc, item) => {
      const entity = item.entity;
      if (!acc[entity]) {
        acc[entity] = [];
      }
      acc[entity].push(item);
      return acc;
    }, {});
  
    // Process each group
    for (const [entity, reports] of Object.entries(groupedData)) {
      const chartGroup = {
        chartGroupHeading: entity,
        chartData: [
          {
            width: null,
            heading: null,
            data: reports.map(report => {
              const dataObject = { name: report.reportingPeriod };
              report.datafields.forEach(field => {
                dataObject[field.dataFieldName] = field.value;
              });
              return dataObject;
            })
          }
        ]
      };
      result.push(chartGroup);
    }
  
    return result;
  }

  // Data Explorer Bar Graph Processing
  const transformBarGraphData = (dataArray) => {
    // Group data by Entity
    const groupedData = dataArray.reduce((acc, entry) => {
      const entity = entry.entity;
      if (!acc[entity]) {
        acc[entity] = [];
      }
      acc[entity].push(entry);
      return acc;
    }, {});

    // Process each group
    const result = Object.entries(groupedData).map(([entity, reports]) => {
      const chartGroupHeading = entity;
      const chartData = [
        {
          width: null,
          heading: null,
          data: reports.map(report => {
            const dataObject = { name: report.reportingPeriod };
            report.datafields.forEach(field => {
              dataObject[field.dataFieldName] = field.value;
            });
            return dataObject;
          })
        }
      ];

      return {
        chartGroupHeading,
        chartData
      };
    });

    return result;
  };

  const disabled = setDatasetLineData?.length === 0 || !setDatasetLineData;

  const [chartType, setChartType] = useState(disabled ? null : CHART_TYPE.BAR);
  const [isChartVertical, setIsChartVertical] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  /*
    useEffect(() => {
      setChartType(disabled ? null : CHART_TYPE.BAR);
    }, [setDatasetLineData]);
  */
  return (
    <>
      <>
        <PageHead
          pageTitle={PATHS.DATA_EXPLORER.TITLE}
          keepBackgroundImage={viewMode !== CONSTANTS.VIEW_MODE.MOBILE}>
          <span>
            Combine data points across all entities and reporting periods into a singular view.<br/><br/>

            The information and data displayed in these data sets does not supersede the published annual reports produced by Commonwealth entities and companies.
          </span>
        </PageHead>
        <div className={style.dataSetsContainer}>
          <h2>Select a data set to view</h2>
          <p className={style.bold}>Learn more about entity and company body types</p>
          <div className={style.entityTypes}>
            <span>
              <Tag displayText={BODY_TYPE.NCE.TAG} fullText={BODY_TYPE.NCE.TEXT} />
              <a className="alwaysFresh" href="https://www.finance.gov.au/about-us/glossary/pgpa/term-non-corporate-commonwealth-entity-nce" rel="noreferrer" target="_blank">
                {BODY_TYPE.NCE.TEXT}
                <svg>
                  <use href={`${sprite}#open-in-new-tab`} />
                </svg>
              </a>
            </span>
            <span>
              <Tag displayText={BODY_TYPE.CCE.TAG} fullText={BODY_TYPE.CCE.TEXT} />
              <a className="alwaysFresh" href="https://www.finance.gov.au/about-us/glossary/pgpa/term-corporate-commonwealth-entity-cce" rel="noreferrer" target="_blank">
                {BODY_TYPE.CCE.TEXT}
                <svg>
                  <use href={`${sprite}#open-in-new-tab`} />
                </svg>
              </a>
            </span>
            <span>
              <Tag displayText={BODY_TYPE.CC.TAG} fullText={BODY_TYPE.CC.TEXT} />
              <a className="alwaysFresh" href="https://www.finance.gov.au/about-us/glossary/pgpa/term-commonwealth-company" rel="noreferrer" target="_blank">
                {BODY_TYPE.CC.TEXT}
                <svg>
                  <use href={`${sprite}#open-in-new-tab`} />
                </svg>
              </a>
            </span>
          </div>
        </div>
      </>
      <Suspense fallback={<DataExplorerLoading/>}>
        <Await resolve={dataExplorerLoaderData}>
          {(resolvedData) => {
            //
            useEffect(() => {
              const updatedFilterData = { ...resolvedData.filters };

              Object.keys(DATA_EXPLORER_URL_FILTER_GROUPS).forEach((key) => {
                const filterGroup = DATA_EXPLORER_URL_FILTER_GROUPS[key].VALUE;
                const urlParam = urlParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${filterGroup}`);
                const filterOptions = resolvedData.filters[filterGroup]?.filterData || [];

                if (urlParam) {
                  if (urlParam == "ALL") {
                    updatedFilterData[filterGroup] = {
                      ...updatedFilterData[filterGroup],
                      displayName: resolvedData.filters[filterGroup].displayName,
                      filterData: filterOptions.map((option) => ({
                        ...option,
                        selected: true,
                      })),
                      selectAll: true,
                    };
                  } else {
                    const selectedValues = urlParam.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
                    updatedFilterData[filterGroup] = {
                      ...updatedFilterData[filterGroup],
                      displayName: resolvedData.filters[filterGroup].displayName,
                      filterData: filterOptions.map((option) => ({
                        ...option,
                        selected: selectedValues.includes(option.returnValue),
                      })),
                      selectAll: filterOptions.every((option) => selectedValues.includes(option.returnValue)),
                    };
                  }
                  
                }
              });
            
              setFilterData(structuredClone(updatedFilterData));
              setEmptyFilters(structuredClone(updatedFilterData));
              setAppliedFilterData(structuredClone(updatedFilterData));
              checkApplied();
            }, []);

            return (
              <>
                {/* Dataset Filters */}
                <form className={style.sectionContainer} onSubmit={(e) => e.preventDefault()}>
                  {
                    filterData && (
                      <>
                        <DataSetsFilterAccordion title="Select Attributes" isRequired={true}>
                        <div className={style.titleArea}>
                          <span className={[style.filterTitle, style.isRequired].join(' ')}>Select attributes to present together</span>
                          <br/>
                          <span>Source: This data has been provided by Commonwealth entities and companies to represent a subset of their published annual report.</span>
                        </div>
    
                        <div className={style.filterList}>
                          <div className={style.upperBlockContainer}>
                            {/* Location Filter */}
                            <div className={style.filterCardContainer}>
                              {filterData.Location.filterData && (
                                <FormField
                                  title={filterData.Location.displayName}
                                  isRequired={false}
                                  showSubText
                                  boldedSubText={numberOfDropdownOptionsSelected(filterData.Location)}
                                  subText="locations selected"                              
                                  >
                                  <div className={style.filterContainer}>
                                    <input
                                      className={!isFilterDataSelected(filterData.Location) && filterData.Location.selectAll ? 'checkbox' : ''}
                                      type="checkbox"
                                      checked={filterData.Location.selectAll}
                                      onChange={(e) => setAllFilterDataSelection("Location", e.target.checked)}
                                    />
      
                                    <DropdownSelect
                                      dropdownOptions={filterData.Location.filterData}
                                      selectOptionFunction={selectLocationFilter}
                                      placeholderText={"    "}
                                      isMultiSelect
                                      renderAsFixed
                                    />
                                  </div>
                                </FormField>
                              )}
                            </div>
    
                            {/* Gender Filter */}
                            <div className={style.filterCardContainer}>
                              {filterData.Gender.filterData && (
                                <FormField
                                  title={filterData.Gender.displayName}
                                  isRequired={false}
                                  showSubText
                                  boldedSubText={numberOfDropdownOptionsSelected(filterData.Gender)}
                                  subText="genders selected"                              
                                  >
      
                                  <div className={style.filterContainer}>
                                    <input
                                      className={!isFilterDataSelected(filterData.Gender) && filterData.Gender.selectAll ? 'checkbox' : ''}
                                      type="checkbox"
                                      checked={filterData.Gender.selectAll}
                                      onChange={(e) => setAllFilterDataSelection("Gender", e.target.checked)}
                                    />
      
                                    <DropdownSelect
                                      dropdownOptions={filterData.Gender.filterData}
                                      selectOptionFunction={selectGenderFilter}
                                      placeholderText={"    "}
                                      isMultiSelect
                                      renderAsFixed
                                    />
                                  </div>
      
                                </FormField>
                              )}
                            </div>
    
                            {/* Employment Type Filter */}
                            <div className={style.filterCardContainer}>
                              {filterData.EmploymentType.filterData && (
                                <FormField
                                  title={filterData.EmploymentType.displayName}
                                  isRequired={false}
                                  showSubText
                                  boldedSubText={numberOfDropdownOptionsSelected(filterData.EmploymentType)}
                                  subText="employment types selected"                              
                                  >
      
                                  <div className={style.filterContainer}>
                                    <input
                                      className={!isFilterDataSelected(filterData.EmploymentType) && filterData.EmploymentType.selectAll ? 'checkbox' : ''}
                                      type="checkbox"
                                      checked={filterData.EmploymentType.selectAll}
                                      onChange={(e) => setAllFilterDataSelection("EmploymentType", e.target.checked)}
                                    />
      
                                    <DropdownSelect
                                      dropdownOptions={filterData.EmploymentType.filterData}
                                      selectOptionFunction={selectEmploymentTypeFilter}
                                      placeholderText={"    "}
                                      isMultiSelect
                                      renderAsFixed
                                    />
                                  </div>
                                </FormField>
                              )}
                            </div>
    
                            {/* Engagement Type Filter */}
                            <div className={style.filterCardContainer}>
                              {filterData.Engagement.filterData && (
                                <FormField
                                  title={filterData.Engagement.displayName}
                                  isRequired={false}
                                  showSubText
                                  boldedSubText={numberOfDropdownOptionsSelected(filterData.Engagement)}
                                  subText="engagement types selected"                              
                                  >
                                  <div className={style.filterContainer}>
                                    <input
                                      className={!isFilterDataSelected(filterData.Engagement) && filterData.Engagement.selectAll ? 'checkbox' : ''}
                                      type="checkbox"
                                      checked={filterData.Engagement.selectAll}
                                      onChange={(e) => setAllFilterDataSelection("Engagement", e.target.checked)}
                                    />
      
                                    <DropdownSelect
                                      dropdownOptions={filterData.Engagement.filterData}
                                      selectOptionFunction={selectEngagementFilter}
                                      placeholderText={"    "}
                                      isMultiSelect
                                      renderAsFixed
                                    />
                                  </div>
                                </FormField>
                              )}
                            </div>
    
                            {/* APS Filter */}
                            <div className={style.filterCardContainer}>
                              {filterData.APSBand.filterData && (
                                <FormField
                                  title={filterData.APSBand.displayName}
                                  isRequired={false}
                                  showSubText
                                  boldedSubText={numberOfDropdownOptionsSelected(filterData.APSBand)}
                                  subText="APS bands selected"                              
                                  >
                                  <div className={style.filterContainer}>
      
                                    <input
                                      className={!isFilterDataSelected(filterData.APSBand) && filterData.APSBand.selectAll ? 'checkbox' : ''}
                                      type="checkbox"
                                      checked={filterData.APSBand.selectAll}
                                      onChange={(e) => setAllFilterDataSelection("APSBand", e.target.checked)}
                                    />
      
                                    <DropdownSelect
                                      dropdownOptions={filterData.APSBand.filterData}
                                      selectOptionFunction={selectAPSBandFilter}
                                      placeholderText={"    "}
                                      isMultiSelect
                                      renderAsFixed
                                    />
                                  </div>
                                </FormField>
                              )}
                            </div>
    
                            {/* Name of Contracted Organisation Filter */}
                            <div className={style.filterCardContainer}>
                              {filterData.NameofContractedOrganisation.filterData && (
                                <FormField
                                  title={filterData.NameofContractedOrganisation.displayName}
                                  isRequired={false}
                                  showSubText
                                  boldedSubText={numberOfDropdownOptionsSelected(filterData.NameofContractedOrganisation)}
                                  subText="contractors selected"                              
                                  >
                                  <div className={style.filterContainer}>
      
                                    <input
                                      className={!isFilterDataSelected(filterData.NameofContractedOrganisation) && filterData.NameofContractedOrganisation.selectAll ? 'checkbox' : ''}
                                      type="checkbox"
                                      checked={filterData.NameofContractedOrganisation.selectAll}
                                      onChange={(e) => setAllFilterDataSelection("NameofContractedOrganisation", e.target.checked)}
                                    />
      
                                    <DropdownSelect
                                      dropdownOptions={filterData.NameofContractedOrganisation.filterData}
                                      selectOptionFunction={selectNameofContractedOrganisationFilter}
                                      placeholderText={"    "}
                                      isMultiSelect
                                      renderAsFixed
                                    />
                                  </div>
                                </FormField>
                              )}
                            </div>
                          </div>
    
                          <div className={style.lowerBlockContainer}>
                            {/* Portfolio Filter */}
                            <div className={style.filterCardContainer}>
                              {filterData.Portfolio.filterData && (
                                <FormField
                                  title={filterData.Portfolio.displayName}
                                  isRequired={false}
                                  showSubText
                                  boldedSubText={numberOfDropdownOptionsSelected(filterData.Portfolio)}
                                  subText="portfolios selected"                              
                                  >
                                  <div className={style.filterContainer}>
                                    <input
                                      className={!isFilterDataSelected(filterData.Portfolio) && filterData.Portfolio.selectAll ? 'checkbox' : ''}
                                      type="checkbox"
                                      checked={filterData.Portfolio.selectAll}
                                      onChange={(e) => setAllFilterDataSelection("Portfolio", e.target.checked)}
                                    />
      
                                    <DropdownSelect
                                      dropdownOptions={filterData.Portfolio.filterData}
                                      selectOptionFunction={selectPortfolioFilter}
                                      placeholderText={"    "}
                                      isMultiSelect
                                      renderAsFixed
                                    />
                                  </div>
                                </FormField>
                              )}
                            </div>
    
                            {/* Body Type Filter */}
                            <div className={style.filterCardContainer}>
                              {filterData.BodyType.filterData && (
                                <FormField
                                  title={filterData.BodyType.displayName}
                                  isRequired={false}
                                  showSubText
                                  boldedSubText={numberOfDropdownOptionsSelected(filterData.BodyType)}
                                  subText="body types selected"                              
                                  >
      
                                  <div className={style.filterContainer}>
      
                                    <input
                                      className={!isFilterDataSelected(filterData.BodyType) && filterData.BodyType.selectAll ? 'checkbox' : ''}
                                      type="checkbox"
                                      checked={filterData.BodyType.selectAll}
                                      onChange={(e) => setAllFilterDataSelection("BodyType", e.target.checked)}
                                    />
      
                                    <DropdownSelect
                                      dropdownOptions={filterData.BodyType.filterData}
                                      selectOptionFunction={selectBodyTypeFilter}
                                      placeholderText={"    "}
                                      isMultiSelect
                                      renderAsFixed
                                    />
                                  </div>
      
                                </FormField>
                              )}
                            </div>
    
                            {/* Entity Filter */}
                            <div className={style.filterCardContainer}>
                            {filterData.Entity.filterData && (
                              <FormField
                                title={filterData.Entity.displayName}
                                isRequired={true}
                                showSubText
                                boldedSubText={numberOfDropdownOptionsSelected(filterData.Entity)}
                                subText="entities and companies selected"                              
                                >
                                <div className={style.filterContainer}>
                                  <input
                                    className={!isFilterDataSelected(filterData.Entity) && filterData.Entity.selectAll ? 'checkbox' : ''}
                                    type="checkbox"
                                    checked={filterData.Entity.selectAll}
                                    onChange={(e) => setAllFilterDataSelection("Entity", e.target.checked)}
                                  />
    
                                  <DropdownSelect
                                    dropdownOptions={filterData.Entity.filterData}
                                    selectOptionFunction={selectEntityFilter}
                                    placeholderText={"    "}
                                    isMultiSelect
                                    renderAsFixed
                                  />
                                </div>
                              </FormField>
                            )}
                            </div>
                          </div>
                        </div>
    
                        </DataSetsFilterAccordion>
      
                        <DataSetsFilterAccordion title="Additional Filters" isRequired={true}>
                          <div className={style.filterList}>
                            <div className={style.singleFilterContainer}>
                              {/* Reporting Year Filter */}
                              <div className={style.filterCardContainer}>
                                {filterData.ReportingYear.filterData && (
                                  <FormField
                                    title={filterData.ReportingYear.displayName}
                                    isRequired={true}
                                    showSubText
                                    boldedSubText={numberOfDropdownOptionsSelected(filterData.ReportingYear)}
                                    subText="reporting years selected"                                               
                                    >
                                    <div className={style.filterContainer}>
                                      <input
                                        className={!isFilterDataSelected(filterData.ReportingYear) && filterData.ReportingYear.selectAll ? 'checkbox' : ''}
                                        type="checkbox"
                                        checked={filterData.ReportingYear.selectAll}
                                        onChange={(e) => setAllFilterDataSelection("ReportingYear", e.target.checked)}
                                      />
        
                                      <DropdownSelect
                                        dropdownOptions={filterData.ReportingYear.filterData}
                                        selectOptionFunction={selectReportingYearFilter}
                                        placeholderText={"    "}
                                        isMultiSelect
                                        renderAsFixed
                                      />
                                    </div>
                                  </FormField>
                                )}
                              </div>
                            </div>
                          </div>
                        </DataSetsFilterAccordion>
      
                        <div className={style.applyFilters}>
                          <div className={style.groupedButtons}>
                            <div className={style.filterButtons}>
                              <button onClick={clearFilters} className="noFill">
                                Clear
                              </button>
                              <button onClick={applyFilters} disabled={!isAppliedButtonActive || !atLeastOneEntitySelected() || !atLeastOneReportingYearSelected()}>
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                </form>

                { !disableCharts && (
                  <>
                    <div className={style.controlWrapper}>
                      <span className={style.label}>View data as:</span>
                      <div className={style.buttonWrapper}>
                        <div>
                          <button disabled={disabled} className={chartType === CHART_TYPE.BAR ? 'activeState' : null} onClick={() => setChartType(CHART_TYPE.BAR)}>
                            Bar chart
                            <svg>
                              <use href={`${icons}#bar-graph`} />
                            </svg>
                          </button>
                          <button disabled={disabled} className={chartType === CHART_TYPE.STACKED_BAR ? 'activeState' : null} onClick={() => setChartType(CHART_TYPE.STACKED_BAR)}>
                            Stacked bar chart
                            <svg>
                              <use href={`${icons}#stacked-bar-graph`} />
                            </svg>
                          </button>
                          <button disabled={disabled} className={chartType === CHART_TYPE.TIME_PLOT ? 'activeState' : null} onClick={() => setChartType(CHART_TYPE.TIME_PLOT)}>
                            Time Series Plot Chart
                            <svg>
                              <use href={`${icons}#stacked-bar-graph`} />
                            </svg>
                          </button>
                          <button disabled={disabled} className={chartType === CHART_TYPE.TABLE ? 'activeState' : null} onClick={() => setChartType(CHART_TYPE.TABLE)}>
                            Table
                            <svg>
                              <use href={`${icons}#stacked-bar-graph`} />
                            </svg>
                          </button>
                        </div>
              
                        {!disabled && (
                          <div>
                            <label className={['buttonStyle lightFill', style.abolishedButton].join(' ')} disabled={chartType === CHART_TYPE.TABLE ? true : false }>
                              <input
                                id="abolishedButton"
                                type="checkbox"
                                checked={showLabels}
                                onChange={() => {
                                  setShowLabels(!showLabels);
                                }}
                                disabled={chartType === CHART_TYPE.TABLE ? true : false }
                              />
                              Show data labels
                            </label>
                            <button className="lightFill" onClick={() => setIsChartVertical(!isChartVertical)} disabled={chartType === CHART_TYPE.TABLE || chartType === CHART_TYPE.TIME_PLOT ? true : false }>
                              Rotate
                              <svg className={isChartVertical ? style.mirrored : null}>
                                <use href={`${icons}#rotate`} />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    {
                      datasetTableData && chartType === CHART_TYPE.TABLE ? (
                        <>
                          <DataExplorerTable
                            datasetHeadings={{
                              Entity: DATA_EXPLORER_URL_FILTER_GROUPS.ENTITY.DISPLAY_NAME,
                              ReportingPeriod: DATA_EXPLORER_URL_FILTER_GROUPS.REPORTING_YEAR.DISPLAY_NAME,
                              DataFieldName: 'Value Name',
                              Value: 'Value'
                            }}
                            dataSet={datasetTableData}
    
                          />
                        </>
                      ) : datasetLineData && chartType === CHART_TYPE.TIME_PLOT ? (
                        <section className={style.graphView}>
                        <div className={style.mobileSeperater}>
                          {disabled ? (
                            <div className={[style.chartWrapper, style.noData].join(' ')} style={{ '--BACKGROUND-IMAGE': `url(${GreyTree})` }}></div>
                          ) : (
                            <div className={style.chartWrapper}>
                              {datasetLineData?.map((item, i) => {
                                const key = `data-item-${i}`;
                  
                                return (
                                  <div key={key} className={style.chartGroup}>
                                    <div className={style.charts}>
                                      {item?.chartData?.map((chart, i) => {
                                        const chartKey = `chartKey-${i}`;
                  
                                        return (
                                          <div key={chartKey} className={style.borders}>
                                            <Chart data={chart?.data} type={chartType} isVertical={false} chartLabel={chart?.heading} showLabels={showLabels} width={chart.width} />
                                          </div>
                                        );
                                      })}
                                    </div>
                                    {item?.chartGroupHeading && <span className={['h6', style.gropuHeading].join(' ')}>{item.chartGroupHeading}</span>}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </section>
    
                      ) : (chartType === CHART_TYPE.BAR || chartType === CHART_TYPE.STACKED_BAR ) ? (
                        <section className={style.graphView}>
                        <div className={style.mobileSeperater}>
                          {disabled ? (
                            <div className={[style.chartWrapper, style.noData].join(' ')} style={{ '--BACKGROUND-IMAGE': `url(${GreyTree})` }}></div>
                          ) : (
                            <div className={style.chartWrapper}>
                              {datasetBarData?.map((item, i) => {
                                const key = `data-item-${i}`;
                  
                                return (
                                  <div key={key} className={style.chartGroup}>

                                    <div className={style.charts}>
                                      {item?.chartData?.map((chart, i) => {
                                        const chartKey = `chartKey-${i}`;
                  
                                        return (
                                          <div key={chartKey} className={style.borders}>
                                            <Chart data={chart?.data} type={chartType} isVertical={isChartVertical} chartLabel={chart?.heading} showLabels={showLabels} width={chart.width} />
                                          </div>
                                        );
                                      })}
                                    </div>
                                    {item?.chartGroupHeading && <span className={['h6', style.gropuHeading].join(' ')}>{item.chartGroupHeading}</span>}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </section>
    
                      ) : (null)
                    }
                  </>
                  )
                }
              </>
            );
          }}
        </Await>
      </Suspense>
    </>
  );
}

export default DataExplorer;