import React, { useState, useId, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import style from './DropdownSelect.module.scss';
import { CONSTANTS } from 'Constants';
import { ListSelect, OutsideClickDetector } from 'Components';
import { debounce } from 'Utils';

/**
 * DropdownSelect.jsx
 *
 * @summary Component for dropdown functionality.
 *
 * @param {Object} props - Component props.
 * @prop {{display: String, returnValue: String}[]} props.dropdownOptions -  List of dropdown options. Each option is an object that should have properties 'returnValue' and 'display'.
 * @prop {Node} [props.children] - Any children to be added that will display when dropdown is opened. If no children given, dropdown will default to ListSelect.
 * @prop {Function} props.selectOptionFunction - Select option function to execute when option is selected
 * @prop {{display: String, returnValue: String}} [props.selectedOption] - Currently selected object (if any). Should have properties 'returnValue' and 'display'. This would only work if `props.isMultiSelect` is false or not given.
 * @prop {String} [props.placeholderText] - Placeholder text
 * @prop {Boolean} [props.inputEnabled] - Enable typing/filtering of input for dropdown options.
 * @prop {Boolean} [props.isMultiSelect] - Allows for multiselect option for dropdown.
 * @prop {Boolean} [props.renderAsFixed] - Allows for rendering dropdown select options as fixed item
 * @prop {Boolean} [props.disabled = false] - If true, dropdown is disabled
 * @prop {Boolean} [props.updateDropdownOptionList] - Updates the dropdown list position from the parent
 *
 * TODO:
 * - Ensure correct styling for dropdown box when input is not enabled
 * - Filter dropdown options to given input value
 * - Add function to update data change in parent component
 * - Add multiselect for mobile dropdown
 */
function DropdownSelect({
  children,
  dropdownOptions,
  selectedOption,
  selectOptionFunction,
  placeholderText,
  inputEnabled,
  isMultiSelect,
  renderAsFixed,
  disabled = false,
  updateDropdownOptionList,
}) {
  const [displayDropdownList, setDisplayDropdownList] = useState(false);
  const [selectedValue, setSelectedValue] = useState(selectedOption || '');
  const [inputValue, setInputValue] = useState('');
  const [filteredList, setFilteredList] = useState(dropdownOptions);
  const contentId = useId();
  const dropdownButtonId = useId();
  const dropdownRef = useRef();
  const dropdownButtonRef = useRef();
  const dropdownOptionsRef = useRef();
  const [dropdownButtonDimensions, setDropdownButtonDimensions] = useState({ height: 0, width: 0, top: 0, bottom: 0, left: 0, right: 0 });
  const [windowDimensions, setWindowDimensions] = useState({ height: 0, width: 0 });

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => {
      const windowDetails = { width: document.documentElement.clientWidth, height: window.innerHeight };
      setWindowDimensions(windowDetails);
    });
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  const getDropdownOptionsPosition = () => {
    if (renderAsFixed && dropdownButtonRef?.current) {
      const { top, right, bottom, left } = dropdownButtonRef.current.getBoundingClientRect();
      setDropdownButtonDimensions({ height: dropdownButtonRef.current.clientHeight, width: dropdownButtonRef.current.clientWidth, top, bottom, right, left });
    }
  };

  useEffect(() => {
    if (dropdownButtonRef?.current) {
      getDropdownOptionsPosition();
    }

    const windowDetails = { width: document.documentElement.clientWidth, height: window.innerHeight };
    setWindowDimensions(windowDetails);
  }, [displayDropdownList]);

  useEffect(() => {
    window.addEventListener('scroll', getDropdownOptionsPosition);

    return () => {
      window.removeEventListener('scroll', getDropdownOptionsPosition);
    };
  });

  useEffect(() => {
    getDropdownOptionsPosition();
  }, [updateDropdownOptionList]);

  useEffect(() => {
    filterDropdown(inputEnabled ? inputValue : '');
  }, [dropdownOptions]);

  useEffect(() => {
    setSelectedValue(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    if (!isMultiSelect) {
      resetFilterOptions();
      setInputValue(selectedValue?.display);
    }
  }, [selectedValue]);

  const toggleDropdown = (event) => {
    event.preventDefault(); // prevent reload when used in forms
    setDisplayDropdownList((prevValue) => {
      return !prevValue;
    });
  };

  const openDropdown = () => {
    setDisplayDropdownList(true);
  };

  const closeDropdown = () => {
    // allows for onBlur to only close dropdown if target clicked is outside the dropdown
    // clicking button or input will not close the dropdown
    if (displayDropdownList) {
      setDisplayDropdownList(false);
      if (selectedValue?.display) {
        setInputValue(!isMultiSelect ? selectedValue?.display : inputValue); // set the input value back to chosen option when closing dropdown (since user can change input through typing)
      }
    }
  };

  const filterDropdown = (value) => {
    const newFilteredList = dropdownOptions.filter((option) => {
      if (option.display) {
        return option.display.toLowerCase().includes(value ? value.toLowerCase() : '');
      }
      return false;
    });
    setFilteredList(newFilteredList);
    setInputValue(value || '');
  };

  const onTextChange = (e) => {
    filterDropdown(e?.target?.value);
  };

  const resetFilterOptions = () => {
    setFilteredList(dropdownOptions);
  };

  const selectOption = (selectedOption) => {
    if (isMultiSelect) {
      if (selectOptionFunction) {
        selectOptionFunction(selectedOption);
      } else {
        if (selectedOption.selected) {
          selectedOption.selected = false;
        } else {
          selectedOption.selected = true;
        }
      }
    } else {
      setDisplayDropdownList(false); // on single select, close the dropdown list
      setInputValue(selectedOption.display); // set the input box to the newly selected option
      if (selectOptionFunction) {
        selectOptionFunction(selectedOption);
      }
    }

    setSelectedValue({ ...selectedOption });
  };

  useEffect(() => {
    const close = (e) => {
      // listen for escape key pressed
      if (e.key === CONSTANTS.ESCAPE_KEY) {
        setDisplayDropdownList(false); // escape should close the dropdown list
        if (selectedValue?.display) {
          setInputValue(!inputEnabled ? selectedValue?.display : inputValue);
        }
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  });

  return (
    <OutsideClickDetector callBack={closeDropdown}>
      <div ref={dropdownRef} className={style.dropdownWrapper}>
        <div ref={dropdownButtonRef} className={[style.dropdownHeader, displayDropdownList ? style.expandedDropdown : ''].join(' ')}>
          {inputEnabled && (
            <input
              placeholder={placeholderText || 'Enter your text here'}
              type="search"
              disabled={!inputEnabled}
              value={inputValue}
              onChange={onTextChange}
              onClick={openDropdown}
            />
          )}

          <button
            disabled={disabled}
            id={dropdownButtonId}
            aria-controls={contentId}
            aria-expanded={displayDropdownList}
            onClick={toggleDropdown}
            className={[style.triangle, !inputEnabled ? style.fullButtonDropdown : null].join(' ')}>
            <span className={[!isMultiSelect && !selectedValue && style.placeholder].join(' ')}>
              {!inputEnabled
                ? isMultiSelect
                  ? placeholderText || 'Edit selection'
                  : `${selectedValue?.display || placeholderText || 'Enter your text here'}`
                : `${displayDropdownList ? 'close' : 'open'} dropdown`}
            </span>
          </button>
        </div>
        {displayDropdownList && (
          <div
            ref={dropdownOptionsRef}
            className={[style.dropdownOptions, renderAsFixed ? style.fixed : ''].join(' ')}
            style={
              renderAsFixed && {
                // Add extra 2 to width for the border px (1px border)
                width: dropdownButtonRef?.current?.clientWidth + 2,
                top: dropdownButtonDimensions.bottom,
                maxHeight: Math.min(windowDimensions.height - dropdownButtonDimensions.bottom - 10, 500),
              }
            }>
            {children ? children : null}
            <ListSelect isMultiSelect={isMultiSelect} id={contentId} dropdownOptions={filteredList} selectOptionFunction={selectOption}></ListSelect>
          </div>
        )}
      </div>
    </OutsideClickDetector>
  );
}

DropdownSelect.propTypes = {
  children: PropTypes.node,
  selectedOption: PropTypes.shape({
    display: PropTypes.string.isRequired,
    returnValue: PropTypes.string.isRequired,
    selected: PropTypes.bool,
  }),
  selectOptionFunction: PropTypes.func,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string.isRequired,
      returnValue: PropTypes.string.isRequired,
    }),
  ).isRequired,
  placeholderText: PropTypes.string,
  inputEnabled: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  allowDefaultSelection: PropTypes.bool,
  renderAsFixed: PropTypes.bool,
  disabled: PropTypes.bool,
  updateDropdownOptionList: PropTypes.bool,
};

export default DropdownSelect;
