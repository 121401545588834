import React, { useEffect, useState } from 'react';
import style from './DataSetsHeader.module.scss';
import PropTypes from 'prop-types';
import sprite from 'Assets/svgSprite.svg';
import { PageHead, Tag } from 'Components';
import { BODY_TYPE, CONSTANTS, PATHS } from 'Constants';
import { Link } from 'react-router-dom';
import { debounce, detectViewMode } from 'Utils';

/**
 * DataSetsHeader.jsx
 *
 * @summary This component is page view for Header of data sets page.
 *
 * @param {Object} props - Component props.
 * @prop {String} [props.visualisedView=false] - If given as true, displays the data sets in visualised view (graphs)
 */
function DataSetsHeader({ visualisedView = false }) {
  const [viewMode, setViewMode] = useState(detectViewMode());

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  return (
    <>
      <PageHead
        pageTitle={visualisedView ? PATHS.DATA_SETS.CHILDREN.DATA_SETS_VISUALISED.TITLE : PATHS.DATA_SETS.TITLE}
        keepBackgroundImage={viewMode !== CONSTANTS.VIEW_MODE.MOBILE}>
        <span>The information and data displayed in these data sets does not supersede the published annual reports produced by Commonwealth entities and companies.</span>
        <div className={style.dataPageLinks}>
          <Link className={`btn ${visualisedView ? 'noFill' : 'lightFill'}`} to={visualisedView ? `/${PATHS.DATA_SETS.BASE}` : PATHS.DATA_SETS.CHILDREN.DATA_SETS_VISUALISED.BASE}>
            {visualisedView ? 'Back to Data sets' : PATHS.DATA_SETS.CHILDREN.DATA_SETS_VISUALISED.TITLE}
            <svg>
              <use href={`${sprite}#${visualisedView ? 'table' : 'bar-graph'}`} />
            </svg>
          </Link>
          {/* <Link className="btn lightFill" to={`/${PATHS.DATA_SETS.BASE}/${PATHS.DATA_SETS.CHILDREN.DATA_DEFINITIONS.BASE}`}>
            {PATHS.DATA_SETS.CHILDREN.DATA_DEFINITIONS.TITLE}
          </Link> */}
        </div>
      </PageHead>
      <div className={style.dataSetsContainer}>
        <h2>Select a data set to view</h2>
        <p className={style.bold}>Learn more about entity and company body types</p>
        <div className={style.entityTypes}>
          <span>
            <Tag displayText={BODY_TYPE.NCE.TAG} fullText={BODY_TYPE.NCE.TEXT} />
            <a className="alwaysFresh" href="https://www.finance.gov.au/about-us/glossary/pgpa/term-non-corporate-commonwealth-entity-nce" rel="noreferrer" target="_blank">
              {BODY_TYPE.NCE.TEXT}
              <svg>
                <use href={`${sprite}#open-in-new-tab`} />
              </svg>
            </a>
          </span>
          <span>
            <Tag displayText={BODY_TYPE.CCE.TAG} fullText={BODY_TYPE.CCE.TEXT} />
            <a className="alwaysFresh" href="https://www.finance.gov.au/about-us/glossary/pgpa/term-corporate-commonwealth-entity-cce" rel="noreferrer" target="_blank">
              {BODY_TYPE.CCE.TEXT}
              <svg>
                <use href={`${sprite}#open-in-new-tab`} />
              </svg>
            </a>
          </span>
          <span>
            <Tag displayText={BODY_TYPE.CC.TAG} fullText={BODY_TYPE.CC.TEXT} />
            <a className="alwaysFresh" href="https://www.finance.gov.au/about-us/glossary/pgpa/term-commonwealth-company" rel="noreferrer" target="_blank">
              {BODY_TYPE.CC.TEXT}
              <svg>
                <use href={`${sprite}#open-in-new-tab`} />
              </svg>
            </a>
          </span>
        </div>
      </div>
    </>
  );
}

DataSetsHeader.propTypes = {
  visualisedView: PropTypes.bool,
};

export default DataSetsHeader;
