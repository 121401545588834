import React, { Suspense, useEffect } from 'react';
import { Await, Link, useRouteLoaderData } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { MainHeader, MainFooter } from 'Components';
import { PATHS, PUBLIC_TP_URL } from 'Constants';
import icons from 'Assets/svgSprite.svg';
import DART_training_screenshot from 'Assets/DART_training_screenshot.png';
import tree from 'Assets/Tree-Half-Light.svg';
import style from './ContributerLandingPage.module.scss';

/**
 * ContributerLandingPage.jsx
 *
 * @summary This component is page view for DART contributer landing page.
 *
 * NOTE: This is static page and assume contents are not content manageable.
 */
export default function ContributerLandingPage() {
  const mainContentId = '#main-content';
  const skipToTopId = '#skip-to-top';
  const { headerFooterData } = useRouteLoaderData(PATHS.CONTRIBUTER_LANDING_PAGE);

  useEffect(() => {
    // FIXME: HOTJAR INTEGRATION - After MVP 2 release, at the start of MVP3, hotjar need to be removed as this was only for research purpose by designer between MVP2 and MVP3
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
  }, []);

  const heroBannerImg = {
    '--BACKGROUND-IMAGE': `url(${tree})`,
  };

  return (
    <div id="TPApp">
      <a id="skip-to-top" href={mainContentId} className="btn skipButton toContent" title="Skip to Content">
        <span>Skip to content</span>
        <svg>
          <use href={icons + '#arrow'} />
        </svg>
      </a>
      <MainHeader isDART />

      <main id="main-content" className={style.dart}>
        <div className={['fullColorBackground', style.hero].join(' ')} style={heroBannerImg}>
          <div>
            <h1>For report coordinators, reviewers and collaborators</h1>

            <p>
              The Digital Annual Reporting Tool (DART) is a web-based authoring tool for Australian Government annual report coordinators, drafters and approvers to draft and
              submit content which can be workflowed through the drafting, review, approval and coordination stages of content creation. Submission of reports will be published to
              the&nbsp;
              <a href={PUBLIC_TP_URL}>Transparency Portal</a> for public access.
            </p>

            <p>
              Sign-in using your email and password that you previously set up during the sign-up stage for Kontent. The following are guidelines for password creation during the
              sign-up stage:
              <ul>
                <li>11 characters minimum</li>
                <li>At least 1 uppercase</li>
                <li>At least 1 lowercase</li>
                <li>At least 1 special character</li>
              </ul>
            </p>

            <p>
              For any issues regarding sign-in, please contact <a href="mailto:dar@finance.gov.au">dar@finance.gov.au</a>.{' '}
            </p>

            <p className={style.message}>
              <strong>Access the Digital Annual Reporting Tool via Kontent.ai</strong>
            </p>
            <div>
              <a href="https://app.kontent.ai/" className="btn">
                Sign-in to DART
                <svg>
                  <use href={icons + '#arrow'} />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <section>
          <h2>Online training for DART</h2>

          <div className={style.nestedSection}>
            <div className={style.limitedWidth}>
              <p>
                A comprehensive training package is available to support report coordinators, approvers and contributors. No need to book in, just click the Start Course button.
              </p>

              <span>Training topics include:</span>
              <ul>
                <li>Accessing DART, and a system overview</li>
                <li>Setting up the report structure</li>
                <li>Assigning content and collaborating</li>
                <li>Adding/editing content</li>
                <li>Workflow</li>
                <li>Data templates</li>
                <li>Publishing</li>
              </ul>

              <div>
                <a href="https://training.transparency.gov.au" className="btn">
                  Start course
                </a>
              </div>
            </div>

            <figure>
              <img src={DART_training_screenshot} alt="" />
              <figcaption>DART training tool</figcaption>
            </figure>
          </div>

          <aside className={style.nestedSection}>
            <h3 className="h2">Finding the correct kind of support</h3>

            <div>
              <h4 className="h6">For challenges with sign-in:</h4>

              <p>If you are unable to access the DART, consider the following:</p>
              <ul>
                <li>Confirm that you are using the right credentials to log into the DART</li>
                <li>Confirm that you have been invited to the tool by your Annual Report Coordinator</li>
                <li>Confirm that you have set up your user profile</li>
              </ul>
              <p>If after confirming the above you are still unable to access the tool, reach out to your Annual Report Coordinator, your line manager or IT team.</p>
            </div>

            <div>
              <h4 className="h6">For challenges with Kontent.ai:</h4>

              <p>If you are experiencing issues with Kontent.ai, consider the following:</p>
              <ul>
                <li>Review the DART training to find a solution</li>
                <li>Use the chat field to speak to a Kontent.ai support team member</li>
              </ul>
              <p>If the issue persists, reach out to your Annual Report Coordinator. A process has been established to review and fix issues.</p>
            </div>
          </aside>
        </section>

        <a href={skipToTopId} className="btn skipButton toTop hidden" title="Back to Top">
          <span>Back to top</span>
          <svg>
            <use href={icons + '#arrow'} />
          </svg>
        </a>
      </main>

      <MainFooter isDART>
        <Suspense>
          <Await resolve={headerFooterData}>
            {(resolvedData) => {
              return (
                <>
                  {resolvedData?.contentNode}

                  <nav>
                    <li>
                      <a href={PUBLIC_TP_URL}>Transparency Portal</a>
                    </li>
                    <li>
                      <a href="https://www.finance.gov.au/">Department of Finance</a>
                    </li>
                    {resolvedData?.dartPageLinks?.map((link, i) => (
                      <li key={`footer page link ${i}`}>
                        <Link to={`../${link?.url}`}>{link?.title}</Link>
                      </li>
                    ))}
                    {Array(4 - (resolvedData?.dartPageLinks?.length ?? 0))
                      .fill('fillter')
                      .map((str, i) => (
                        <li key={`${str} ${i}`}>&nbsp;</li>
                      ))}
                  </nav>
                </>
              );
            }}
          </Await>
        </Suspense>
      </MainFooter>
    </div>
  );
}
