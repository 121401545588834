import { CONSTANTS } from "./Constants";

export const DATA_EXPLORER_FILTER_OPTIONS_API = () => {
    if (process.env.REACT_APP_API_END_POINT_BASE?.toLowerCase() === CONSTANTS.ENV.PROD) {
        return `https://preview.transparency.gov.au/search/dataExplorer`;
    }
    return `${process.env.REACT_APP_API_END_POINT_BASE}/search/dataExplorer`;
};

export const DATA_EXPLORER_RESULTS_API = () => {
  if (process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === CONSTANTS.ENV.PROD) {
    return 'https://data.transparency.gov.au/search/dataExplorerResults';
  }
  return `https://app-dof-dataapi-${process.env.REACT_APP_ENVIRONMENT}-aue-01.azurewebsites.net/search/dataExplorerResults`;
};

export const DATA_EXPLORER_URL_FILTER_GROUPS = {
    LOCATION: {
      VALUE: 'Location',
      DISPLAY_NAME: 'Location'
    },
    GENDER: {
      VALUE: 'Gender',
      DISPLAY_NAME: 'Gender'
    },
    EMPLOYMENT_TYPE: {
      VALUE: 'EmploymentType',
      DISPLAY_NAME: 'Employment Type'
    },
    ENGAGEMENT: {
      VALUE: 'Engagement',
      DISPLAY_NAME: 'Engagement'
    },
    APS_BAND: {
      VALUE: 'APSBand',
      DISPLAY_NAME: 'APS Band'
    },
    NAME_OF_CONTRACTED_ORGANISATION: {
      VALUE: 'NameofContractedOrganisation',
      DISPLAY_NAME: 'Name of Contracted Organisation'
    },
    PORTFOLIO: {
      VALUE: 'Portfolio',
      DISPLAY_NAME: 'Portfolio'
    },
    BODY_TYPE: {
      VALUE: 'BodyType',
      DISPLAY_NAME: 'Body type'
    },
    ENTITY: {
      VALUE: 'Entity',
      DISPLAY_NAME: 'Body'
    },
    REPORTING_YEAR: {
      VALUE: 'ReportingYear',
      DISPLAY_NAME: 'Reporting Year'
    }
  };
  


  