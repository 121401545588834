import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import style from './FeedbackForm.module.scss';

/**
 * FeedbackForm.jsx
 *
 * @summary Render as FeedbackForm.
 *
 * @param {Object} props - Component props.
 */
function FeedbackForm() {
  const RESPONSE_REQUIRED_INPUT = 'responseRequired';
  const RESPONSE_REQUIRED_VALUES = {
    REQUIRED: 'true',
    NOT_REQUIRED: 'false',
  };
  const [submitError, setSubmitError] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, isSubmitSuccessful, isSubmitting },
  } = useForm({
    defaultValues: {
      [RESPONSE_REQUIRED_INPUT]: RESPONSE_REQUIRED_VALUES.NOT_REQUIRED,
    },
  });
  const emailRequired = watch(RESPONSE_REQUIRED_INPUT, RESPONSE_REQUIRED_VALUES.NOT_REQUIRED) === RESPONSE_REQUIRED_VALUES.REQUIRED;
  const disableForm = isSubmitting || isSubmitSuccessful || submitError;

  const onSubmit = async (data) => {
    // on submit, browser will do validation and if validation fails, it won't trigger this
    try {
      setSubmitError(false);

      // TODO: submit the form via api
      // FIXME: remove below lines
      await new Promise((res) => setTimeout(res, 5000));
      console.table(data);
    } catch {
      setSubmitError(true);
    }
  };

  return (
    <div id={style.feedbackForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={disableForm}>
          <legend className="required">Response required</legend>
          <ol className={style.radioList}>
            <li className={style.radioWrapper}>
              <input
                name={RESPONSE_REQUIRED_INPUT}
                id={`${RESPONSE_REQUIRED_INPUT}-yes`}
                type="radio"
                value={RESPONSE_REQUIRED_VALUES.REQUIRED}
                {...register(RESPONSE_REQUIRED_INPUT, { required: true })}
              />
              <label className="radioButton" htmlFor={`${RESPONSE_REQUIRED_INPUT}-yes`}>
                Yes
              </label>
            </li>
            <li className={style.radioWrapper}>
              <input
                name={RESPONSE_REQUIRED_INPUT}
                id={`${RESPONSE_REQUIRED_INPUT}-no`}
                type="radio"
                value={RESPONSE_REQUIRED_VALUES.NOT_REQUIRED}
                {...register(RESPONSE_REQUIRED_INPUT, { required: true })}
              />
              <label className="radioButton" htmlFor={`${RESPONSE_REQUIRED_INPUT}-no`}>
                No
              </label>
            </li>
          </ol>
        </fieldset>

        <fieldset disabled={disableForm}>
          <label htmlFor="name">Name</label>
          <input id="name" type="text" className={errors.name ? 'error' : null} {...register('name')} />
        </fieldset>

        <fieldset disabled={disableForm}>
          <label htmlFor="name">Organisation</label>
          <input id="organisation" type="text" className={errors.organisation ? 'error' : null} {...register('organisation')} />
        </fieldset>

        <fieldset disabled={disableForm}>
          <label htmlFor="email" className={emailRequired ? 'required' : null}>
            Email
          </label>
          <input
            id="email"
            type="email"
            placeholder="name@example.com"
            className={errors.email ? 'error' : null}
            required={emailRequired}
            {...register('email', { required: emailRequired })}
          />
        </fieldset>

        <fieldset disabled={disableForm}>
          <label htmlFor="feedback" className="required">
            Feedback
          </label>
          <textarea
            required
            name="feedback"
            id="feedback"
            aria-describedby="feedbackDesc"
            aria-invalid={errors.feedback ? 'true' : 'false'}
            cols="30"
            rows="3"
            placeholder="Type to enter your feedback"
            className={errors.feedback ? 'error' : null}
            {...register('feedback', { required: true })}></textarea>
          <span id="feedbackDesc" className={['fieldDescription', errors.feedback ? 'error' : ''].join(' ')} role={errors.feedback ? 'alert' : null}>
            This field is required to submit feedback.
          </span>
        </fieldset>

        <input disabled={!isValid || disableForm} type="submit" className={style.submit} onClick={(e) => e.target.blur()} />
      </form>

      {(isSubmitting || isSubmitSuccessful) && (
        <div className={style.submittedMessge}>
          <div className={style.modal}>
            {isSubmitting ? (
              <>
                <span className="spinner">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>

                <p>Submitting feedback</p>
              </>
            ) : (
              <>
                <p>Thank you for providing us feedback!</p>

                <button onClick={() => reset()}>Close</button>
              </>
            )}
          </div>
        </div>
      )}

      {submitError && (
        <div className={style.submittedMessge}>
          <div className={style.modal}>
            <p>Something weng wrong. please try again.</p>

            <button onClick={() => setSubmitError(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default FeedbackForm;
