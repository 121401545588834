import { CONTENT_ITEM_TYPES } from 'Constants';
import { replaceTagsInHtmlString } from 'Utils/ProcessContents';
import { removeHtmlTagsInString } from 'Utils/StringUtils';

/**
 * Process mandatory rule list for compare functionality
 * @param {Object} mandatoryRequirementsList - Fetched Annual report data from Kontent `getAContentItem` call
 * @param {Object} extra - Contains extra information (current only used to pass in mandatory table html)
 * @returns {{ruleTitle: String, ruleDescription: String, contentReference: Array|null, extraInfo: Object}[]} - List of rules
 */
export const processCompareFunctionMandatoryRequirements = (mandatoryRequirementsList, extra = {}) => {
  const mandatoryRequirements = mandatoryRequirementsList || [];
  const ruleList = [];

  mandatoryRequirements.forEach((rule) => {
    let contentReference = null;
    const extraInfo = {};

    const ruleItem = rule?.MandatoryRequirementStaticContent?.[0];

    if (ruleItem?.IsTableSection?.[0]?.codename?.toLowerCase() === 'no') {
      const currentContentReference = rule?.ContentReference?.[0];
      const currentContentReferenceType = currentContentReference?.System?.type;

      // Check through all content types that are allowed for content reference of mandatory requirements
      if (currentContentReferenceType === CONTENT_ITEM_TYPES.TABLE.ID) {
        contentReference = currentContentReference.Table
          ? `<div className="${CONTENT_ITEM_TYPES.TABLE.CLASS_NAME} ${currentContentReference.TableName ? 'haveCaption' : ''}" 
              id="${currentContentReference.LinkId || currentContentReference?.System?.id}" data-system-id="${currentContentReference?.System?.id}">
                  ${currentContentReference.TableName ? `<figcaption className="tableTitle h4">${currentContentReference.TableName}</figcaption>` : ''}
                  <div className="tableOverflowWrapper">
                  ${replaceTagsInHtmlString(currentContentReference.Table, 'a', 'u')}
                  </div>
              </div>`
          : null;
      } else if (currentContentReferenceType === CONTENT_ITEM_TYPES.LINKABLE_SECTION.ID) {
        contentReference = currentContentReference.Text ? replaceTagsInHtmlString(currentContentReference.Text, 'a', 'u') : null;
      } else if (currentContentReferenceType === CONTENT_ITEM_TYPES.MANDATORY_REQUIREMENT_REPORT_SECTION.ID) {
        contentReference = extra.mandatoryTableHtml;
      } else if (currentContentReferenceType === CONTENT_ITEM_TYPES.DATA_TABLE.ID) {
        if (currentContentReference?.DataTable?.[0]?.System?.id) {
          contentReference = `<object data-type="web data table" data-target-system-id="${currentContentReference?.DataTable?.[0]?.System?.id}"></object>`;
        }
      } else if (currentContentReferenceType === CONTENT_ITEM_TYPES.LIST.ID) {
        contentReference = currentContentReference.ListElement || null;
      } else if (currentContentReferenceType === CONTENT_ITEM_TYPES.IMAGE.ID) {
        const currentImageUrl = currentContentReference.Image?.[0]?.url;
        const extraImageRenditions = currentContentReference.Image?.[0]?.renditions?.default?.query;
        contentReference = `<object id="${currentContentReference.LinkId}" class="imageElement" data-system-id="${currentContentReference.System.id}" data-imageurl="${currentImageUrl ? (extraImageRenditions ? `${currentImageUrl}?${extraImageRenditions}` : currentImageUrl) : null
          }" data-description="${currentContentReference.Description}" data-imagealt="${currentContentReference.AltText}" data-alignment="${currentContentReference.Alignment?.[0]?.value || ''
          }" data-padding="${currentContentReference.ImagePadding || ''}" data-wraptext="${currentContentReference.TextWrapping?.[0]?.value || ''}"></object>`;
      } else if ([CONTENT_ITEM_TYPES.REPORT_CHAPTER.ID, CONTENT_ITEM_TYPES.REPORT_SECTION.ID].includes(currentContentReferenceType)) {
        contentReference = currentContentReference.Body ? replaceTagsInHtmlString(currentContentReference.Body, 'a', 'u') : null;
      } else if (currentContentReferenceType === CONTENT_ITEM_TYPES.ANAO_REPORT_SECTION.ID) {
        contentReference = currentContentReference.Body || '';
        extraInfo.pdfItem = currentContentReference?.PdfItem?.[0] || null;
      }

      const formattedRule = {
        ruleTitle: ruleItem?.PgpaRuleReference || '',
        ruleDescription: ruleItem?.RuleDescription ? removeHtmlTagsInString(ruleItem.RuleDescription) : '',
        contentReference,
        extraInfo,
      };

      // Only add requirment if title and description are found for the rule
      if (formattedRule.ruleTitle && formattedRule.ruleDescription) {
        ruleList.push(formattedRule);
      }
    }

  });

  return ruleList;
};
