import React from 'react';
import PropTypes from 'prop-types';
import style from './GroupedFilterList.module.scss';
import { ListSelect } from 'Components';
import { NO_FILTER_RESULTS_FOUND } from 'Constants';

/**
 * GroupedFilterList.jsx
 *
 * @summary Component for showing grouped list of filters
 *
 * @param {Object} props - Component props.
 * @prop {{`groupName`: {display: String, returnValue: String, selected: Boolean, tagInfo: {tagPosition: String, tags: Array}}[]}} props.groupedDropdownOptions - List of grouped dropdown options
 * @prop {Function} props.selectOptionFunction - Select function used to select an option
 * @prop {Ref} [props.optionListRef] - Reference to option list
 * @prop {String} [props.isRadioSelect] - String id of the component
 * @prop {String} [props.extraStyleClass] - Extra styling override class
 * @prop {Boolean} [props.dataSetSelection] - Extra styling override class
 *
 */
function GroupedFilterList({ optionListRef, groupedDropdownOptions, selectOptionFunction, isRadioSelect, extraStyleClass, selectedOption, dataSetSelection = false }) {
  const noValuesShown = () => {
    return Object.keys(groupedDropdownOptions).every((alphabetGroup) => {
      return groupedDropdownOptions[alphabetGroup]?.length === 0;
    });
  };

  return (
    <div className={[style.alphabetListSelect, extraStyleClass || ''].join(' ')} ref={optionListRef}>
      {Object.keys(groupedDropdownOptions).map((key, index) => {
        if (dataSetSelection) {
          return (
            <div key={`alphabet-group-${key}-${index}`}>
              {/* Empty span used to allow for scrolling to id (since header is sticky) */}
              <span id={`alphabet-group-${key}`}></span>
              <header className={style.alphabetHeader}>{key?.toUpperCase()}</header>
              <ListSelect
                dropdownOptions={groupedDropdownOptions[key]}
                selectOptionFunction={selectOptionFunction}
                isMultiSelect={!isRadioSelect}
                isRadioSelect={isRadioSelect}
                selectedOption={selectedOption}
              />
            </div>
          );
        } else {
          return (
            <React.Fragment key={`alphabet-group-${key}-${index}`}>
              {/* Empty span used to allow for scrolling to id (since header is sticky) */}
              <span id={`alphabet-group-${key}`}></span>
              <header className={style.alphabetHeader}>{key?.toUpperCase()}</header>
              <ListSelect
                dropdownOptions={groupedDropdownOptions[key]}
                selectOptionFunction={selectOptionFunction}
                isMultiSelect={!isRadioSelect}
                isRadioSelect={isRadioSelect}
                selectedOption={selectedOption}
              />
            </React.Fragment>
          );
        }

      })}
      {noValuesShown() && <div className={style.noResults}>{NO_FILTER_RESULTS_FOUND}</div>}
    </div>
  );
}

GroupedFilterList.propTypes = {
  optionListRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  groupedDropdownOptions: PropTypes.object.isRequired,
  selectOptionFunction: PropTypes.func.isRequired,
  isRadioSelect: PropTypes.bool,
  extraStyleClass: PropTypes.string,
  selectedOption: PropTypes.object,
  dataSetSelection: PropTypes.bool,
};

export default GroupedFilterList;
