import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './SearchFilterList.module.scss';
import { ListSelect, SearchBar } from 'Components';
import { NO_FILTER_RESULTS_FOUND } from 'Constants';
import { filterSearchAcronym } from 'Utils';
import { TAG_POSITION_ENUMS } from 'Constants/Constants';

/**
 * SearchFilterList.jsx
 *
 * @summary Component for showing dropdown list select with a search functionality to filter down values
 *
 * @param {Object} props - Component props.
 * @prop {{display: String, returnValue: String, tag: {display: String, type: String, tagPosition: String}}[]} props.dropdownOptions - List of dropdown options. Each option is an object that should have properties:
 *      - 'returnValue', 'display' String values
 *      - 'tag' which contains 'display' string for tag, 'type' of tag @enums Defined by TAG_STYLE_ENUMS under `/Constants/Constants.js` and 'tagPosition' to render where to place tag. Must be one of the options in TAG_POSITION_ENUMS
 * @prop {Function} props.selectOptionFunction - Select function used to select an option
 * @prop {String} [props.id] - String id of the component
 * @prop {String} [searchPlaceholder] - Placeholder text for search box. Defaults to 'Enter your text here' if not given
 *
 */
function SearchFilterList({ id, dropdownOptions, selectOptionFunction, searchPlaceholder }) {
  const [filteredOption, setFilteredOption] = useState({ filteredOptionList: dropdownOptions, searchTerm: '' });

  const updateFilteredValue = (newValue) => {
    setFilteredOption({
      filteredOptionList: dropdownOptions.filter((option) => {
        const matchedSearchAcronyms = filterSearchAcronym(dropdownOptions, newValue);

        if (option.display) {
          return option.display.toLowerCase().includes(newValue.toLowerCase()) || matchedSearchAcronyms.includes(option.display);
        }
        return false;
      }),
      searchTerm: newValue,
    });
  };

  return (
    <div className={style.searchFilterList}>
      <div className={style.searchBar} role="search" aria-label="On this options list">
        <SearchBar
          placeholder={searchPlaceholder || 'Enter your text here'}
          updateQuery={false}
          onSubmit={updateFilteredValue}
          onClear={updateFilteredValue}
          ariaDescription="On this options list"
        />
      </div>
      <ListSelect id={id} dropdownOptions={filteredOption.filteredOptionList} selectOptionFunction={selectOptionFunction} isMultiSelect />
      {filteredOption.filteredOptionList.length === 0 && <div className={style.noResults}>{NO_FILTER_RESULTS_FOUND}</div>}
    </div>
  );
}

SearchFilterList.propTypes = {
  id: PropTypes.string,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string.isRequired,
      returnValue: PropTypes.string.isRequired,
      tag: PropTypes.shape({
        display: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        tagPosition: PropTypes.oneOf(Object.values(TAG_POSITION_ENUMS)),
      }),
    }),
  ).isRequired,
  selectOptionFunction: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
};
export default SearchFilterList;
