export class ApiError extends Error {
  constructor(message, err) {
    super(message);
    this.isError = true;
    this.statusCode = err?.response?.status || null;
    this.statusText = err?.response?.statusText || null;
    this.response = err?.response || null;
  }
}

export const STATUS_TYPES = {
  NOT_FOUND: 'Not found',
  MULTIPLE_CONTENT_ITEM: 'Multiple content items found',
  AR_INVALID_CHAPTER: 'Annual report invalid chapter',
  AR_INVALID_SECTION: 'Annual report invalid section',
  OTHER: 'Error',
  RANGE_ERROR: 'Range Error',
};
