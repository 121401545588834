import React from 'react';
import PropTypes from 'prop-types';
import { TAG_STYLE_ENUMS } from 'Constants';
import style from './Tag.module.scss';
import icons from 'Assets/svgSprite.svg';

/**
 * Tag.jsx
 *
 * @summary Render as tag.
 *
 * @param {Object} props - Component props.
 * @prop {String} props.displayText - Display text of tag.
 * @prop {String} [props.fullText] - Used for title.
 * @prop {String} [props.type] - Define tag style. @enums Defined by TAG_STYLE_ENUMS under `/Constants/Constants.js`.
 * @prop {Function} [props.filterFunction] - Function to apply for filter tags.
 * @prop {String} [props.extraStyleClass] - Style class to apply as extra styling
 */
function Tag({ displayText, fullText, type, filterFunction, extraStyleClass }) {
  let className;

  switch (type?.toLowerCase()) {
    case TAG_STYLE_ENUMS.HIGHLIGHT:
      className = TAG_STYLE_ENUMS.HIGHLIGHT;
      break;

    case TAG_STYLE_ENUMS.DARK:
      className = TAG_STYLE_ENUMS.DARK;
      break;

    case TAG_STYLE_ENUMS.GREY:
      className = TAG_STYLE_ENUMS.GREY;
      break;

    default:
      break;
  }

  return (
    <span title={fullText} className={[style.tag, style[className], extraStyleClass ? extraStyleClass : ''].join(' ')} aria-label={fullText ? fullText : null}>
      {displayText}
      {filterFunction && (
        <button onClick={filterFunction} aria-label="Remove this">
          <svg>
            <use href={icons + '#close'} />
          </svg>
        </button>
      )}
    </span>
  );
}

Tag.propTypes = {
  displayText: PropTypes.string.isRequired,
  fullText: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TAG_STYLE_ENUMS)),
  filterFunction: PropTypes.func,
  extraStyleClass: PropTypes.string,
};

export default Tag;
