import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getCall } from 'Services';
import icons from 'Assets/svgSprite.svg';
import style from './DownloadFileButton.module.scss';

/**
 * DownloadFileButton.jsx
 *
 * @summary Render as download file button.
 *
 * @param {Object} props - Component props.
 * @prop {String} props.fileName - Full file name including extension.
 * @prop {Number} props.fileSize - File size.
 * @prop {String} props.fileSource - File source url.
 * @prop {Boolean} [props.fileSource=false] - If true, button takes full width of content area.
 */
function DownloadFileButton({ fileName, fileSize, fileSource, fullWidth = false }) {
  const [downloading, setDownloading] = useState(false);
  const fileType = fileName?.split('.')?.at(-1)?.toUpperCase();
  const size = Number(fileSize);
  const SIZE_BREAK_POINTS = {
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    TB: 1024 * 1024 * 1024 * 1024,
  };

  let unit = 'B';
  let sizeToDisplay = size;

  if (size >= SIZE_BREAK_POINTS.KB) {
    // if size is equal or larger than 1024, unit is KB
    unit = 'KB';
    sizeToDisplay = Math.ceil(size / SIZE_BREAK_POINTS.KB);
  }

  if (size >= SIZE_BREAK_POINTS.MB) {
    // if size is equal or larger than 1,048,576, unit is MB
    unit = 'MB';
    sizeToDisplay = Math.ceil(size / SIZE_BREAK_POINTS.MB);
  }

  if (size >= SIZE_BREAK_POINTS.GB) {
    // if size is equal or larger than 1,073,741,824, unit is GB
    unit = 'GB';
    sizeToDisplay = Math.ceil(size / SIZE_BREAK_POINTS.GB);
  }

  if (size >= SIZE_BREAK_POINTS.TB) {
    // if size is equal or larger than 1,099,511,627,776, unit is TB
    unit = 'TB';
    sizeToDisplay = Math.ceil(size / SIZE_BREAK_POINTS.TB);
  }

  const downloadFile = () => {
    setDownloading(true);
    getCall(fileSource, { responseType: 'blob' }, true)
      .then((res) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = fileURL;
        link.target = '_blank';
        if (fileName) link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(fileURL);
        link.remove();
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <button disabled={downloading} onClick={downloadFile} className={[style.fileDownload, fullWidth ? null : style.listItem].join(' ')}>
      <span className={style.iconState}>
        {!downloading ? (
          <svg>
            <use href={`${icons}#file`} />
          </svg>
        ) : (
          <span className="spinner">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </span>
        )}
      </span>

      <span className={style.fileInfo}>
        <span className={style.fileName}>{fileName}</span>
        <span>
          {fileType} {sizeToDisplay}
          {unit}
        </span>
      </span>
    </button>
  );
}

DownloadFileButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired,
  fileSource: PropTypes.string.isRequired,
  fullWidth: PropTypes?.bool,
};

export default DownloadFileButton;
