import React, { Suspense, useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import PropTypes from 'prop-types';
import { Await, Link, useAsyncError, useRouteError, useRouteLoaderData } from 'react-router-dom';
import { ERROR_CONTENT } from 'Constants/StaticContents';
import { CONSTANTS, PAGE_NOT_FOUND_PAGE, PATHS } from 'Constants';
import { StandardPageLoading } from 'Pages';
import { PageHead, SideNavigation } from 'Components';
import { debounce, detectViewMode } from 'Utils';
import icons from 'Assets/svgSprite.svg';
import style from './StandardPage.module.scss';

/**
 * StandardPage.jsx
 *
 * @summary This component is page view for standard page.
 *
 * @param {Object} props - Component props.
 * @prop {String} [props.dataFromLoader] - Route ID. If not given, expect `title`, `children` props to be required, else, consider data are coming from loader.
 * @prop {String} [props.title] - Error page title if `dataFromLoader` is false, else, ignored.
 * @prop {Node} [props.children] - Content to display if `dataFromLoader` is false, else, ignored.
 * @prop {Boolean} [props.hideRelevantLinks] - If true or given, error page would not display relevant links.
 * @prop {Function} [setErrorFunction] - Allow for a function to be passed in to set error state if error occured
 */
function StandardPage({ dataFromLoader, children, title, hideRelevantLinks, setErrorFunction }) {
  const isErrored = useRouteError();
  const isAsyncError = useAsyncError();
  const data = useRouteLoaderData(dataFromLoader);
  const [viewMode, setViewMode] = useState(detectViewMode());
  const isDesktop = viewMode === CONSTANTS.VIEW_MODE.DESKTOP;

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  useEffect(() => {
    // FIXME: HOTJAR INTEGRATION - After MVP 2 release, at the start of MVP3, hotjar need to be removed as this was only for research purpose by designer between MVP2 and MVP3
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);

    if (isErrored || isAsyncError) {
      if (setErrorFunction) {
        setErrorFunction(true);
      }
    }
  }, []);

  const relevantLinkCard = (url, name, summary, openInNewTab = false) => {
    return (
      <Link to={url} className="card" target={openInNewTab ? '_blank' : null} rel={openInNewTab ? 'nonreferer' : null}>
        <span className="limitTextLines" style={{ '--MAX-LINE': 4 }}>
          <span className="newTabIconWrapper">
            <strong className="cardTitle">{name}</strong>
            {openInNewTab && (
              <svg className="newTabIcon">
                <use href={icons + '#open-in-new-tab'} />
              </svg>
            )}
          </span>
          <span className="description">{summary}</span>
        </span>
      </Link>
    );
  };

  // if `dataFromLoader` is given
  if (dataFromLoader) {
    return (
      <Suspense fallback={<StandardPageLoading />}>
        <Await resolve={data?.standardPage}>
          {(resolvedData) => {
            if (resolvedData.isError) {
              return PAGE_NOT_FOUND_PAGE(ERROR_CONTENT.NOT_FOUND);
            }
            const { contentNode, relevantLinks, routeTitle, routes } = resolvedData;
            /**
             * NOTE: `routes` should always be in below format
             * [
             *   {
             *     chapterHasContent: false,
             *     routeTitle: 'ABC',
             *     path: '/base-path',
             *     children: [
             *       {
             *         routeTitle: 'Def',
             *         path: '/base-path/child-path',
             *       },
             *       ...
             *     ],
             *   },
             * ]
             *
             * Note that due to nature of standard page, array should have a single parent object.
             */

            return (
              <>
                <PageHead pageTitle={routeTitle} darkTree keepBackgroundImage />

                <section id={style.standardPageContent}>
                  <div id={style.firstCol}>
                    {routes ? (
                      isDesktop && <SideNavigation menus={routes} noToggleAll />
                    ) : (
                      <Link to={PATHS.HOME.BASE} className="alwaysFresh" id={style.backToHome}>
                        Back to Home
                      </Link>
                    )}
                  </div>

                  <div id={style.lastCol}>{contentNode}</div>
                </section>

                {relevantLinks?.length > 0 && (
                  <div id={style.relevantLinks} className={['fullColorBackground'].join(' ')}>
                    <h2 className="h6">Relevant links</h2>

                    <ul>
                      {relevantLinks.map((link, i) => (
                        <li key={`relevant link ${i}`}>{relevantLinkCard(link?.url, link?.title, link?.summary, link?.newTab)}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            );
          }}
        </Await>
      </Suspense>
    );
  }

  // if `dataFromLoader` is false
  const relevantLinks = [
    {
      name: 'Publications',
      summary: 'Find Annual Reports, Corporate Plans and Portfolio Budget Statements for all Commonwealth listed entities and companies.',
      url: `/${PATHS.PUBLICATIONS_LIST.BASE}`,
    },
    {
      name: 'Data sets',
      summary: 'Compare data collected from entities and companies across the Commonwealth Government.',
      url: `/`, // FIXME: page not set yet
    },
    {
      name: 'Financial ratios',
      summary: 'Inspect financial sustainability of each Commonwealth entity and company through different ratios.',
      url: `/`, // FIXME: page not set yet
    },
  ];
  return (
    <>
      <PageHead pageTitle={title} darkTree keepBackgroundImage />

      <section id={style.standardPageContent} className={hideRelevantLinks ? style.noBottomPadding : null}>
        <div id={style.firstCol}>
          <Link to={PATHS.HOME.BASE} className="alwaysFresh" id={style.backToHome}>
            Back to Home
          </Link>
        </div>

        <div id={style.lastCol}>{children}</div>
      </section>

      {!hideRelevantLinks && (
        <div id={style.relevantLinks} className={['fullColorBackground'].join(' ')}>
          <h2 className="h6">Relevant links</h2>

          <ul>
            {relevantLinks.map((link) => (
              <li key={link.name}>{relevantLinkCard(link.url, link.name, link.summary)}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

StandardPage.propTypes = {
  dataFromLoader: PropTypes.string,
  children: function (props, propName, componentName) {
    /** `children` is only required if `dataFromLoader` is true */
    if (!props.dataFromLoader && !props[propName]) {
      return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. \`${propName}\` is required when \`dataFromLoader\` prop is not given.`);
    }
  },
  title: function (props, propName, componentName) {
    /** `title` is only required if `dataFromLoader` is true */
    const value = props[propName];
    if (!props.dataFromLoader && (typeof value !== 'string' || value === '')) {
      return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Prop value must be a string when \`dataFromLoader\` prop is not given.`);
    }
  },
  hideRelevantLinks: PropTypes.bool,
  setErrorFunction: PropTypes.func,
};

export default StandardPage;
