import { LOCAL_STORAGE_TIMEOUT, SEARCH_PAGE_LOCAL_STORAGE_KEY } from 'Constants';
import { getFromLocalStorage, saveToLocalStorage } from 'Utils/StorageUtils';

/**
 * Get search result data from local storage
 * @param {Number} pageNum - Page number to search for search result data
 * @returns {Object} If found search results data, will return object containing search results (array), totalItems (number) and timestamp (string)
 */
export const getSearchResultsFromLocal = (pageNum) => {
  const searchResultsInStorage = getFromLocalStorage(SEARCH_PAGE_LOCAL_STORAGE_KEY);
  if (searchResultsInStorage) {
    const currentPageInfo = searchResultsInStorage[pageNum];
    if (currentPageInfo) {
      if (new Date() - new Date(currentPageInfo.timestamp) < LOCAL_STORAGE_TIMEOUT) {
        // If the date timestamp is less than 5 minutes, use this saved local data
        return {
          searchResults: currentPageInfo.searchResults,
          totalItems: currentPageInfo.totalItems,
          timestamp: currentPageInfo.timestamp,
          filters: currentPageInfo.filters,
          searchTerm: currentPageInfo.searchTerm,
        };
      }
    }
  }

  return null;
};

/**
 * Save search results data of a specific page to local storage
 * @param {Number} pageNum - Specific page number of search result data
 * @param {Array} searchResults - List of search results for this specific page
 * @param {Number} totalItems - Total number of items
 * @param {String} filters - Current filter string applied
 * @param {String} searchTerm - Current search term applied
 */
export const saveSearchResultsToLocal = (pageNum, searchResults, totalItems, filters, searchTerm) => {
  const searchResultPageObject = {
    searchResults,
    totalItems,
    timestamp: new Date().toISOString(),
    filters,
    searchTerm: searchTerm || '',
  };
  const searchResultsInStorage = getFromLocalStorage(SEARCH_PAGE_LOCAL_STORAGE_KEY);

  let searchResultsToStore = {};
  if (searchResultsInStorage) {
    searchResultsToStore = { ...searchResultsInStorage }; // Get all other page number data that is already stored
  }
  searchResultsToStore[`${pageNum}`] = searchResultPageObject;

  saveToLocalStorage(SEARCH_PAGE_LOCAL_STORAGE_KEY, searchResultsToStore);
};

/**
 * Escapes all unsafe characters given in the Cognitive search
 * https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax#escaping-special-characters
 *
 * @param {String} searchTerm - Search term
 * @returns same string with special characters escaped with '\'
 */
export const escapeStringsFromSearch = (searchTerm) => {
  return searchTerm.replace(/[*&|!^"`<>#%~:+?^${}()|[\]\\]/g, '\\$&');
};


export const SEARCH_PAGE_ORDER_GROUPS = {
  RELEVANCE: {
    VALUE: 'relevance',
    DISPLAY_NAME: 'Relevance'
  },
  ENTITY_COMPANY: {
    VALUE: 'entity_company',
    DISPLAY_NAME: 'By Entity or company'
  }
};

