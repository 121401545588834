import React, { Suspense, useEffect, useState } from 'react';
import { Await, useRouteLoaderData, useSearchParams, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Filter, FilterGroupDropdown, LoadingMenu, PageHead, StandardFilterList, Tag} from 'Components';
import { PortfolioEntitiesCompaniesLoading, StandardPage } from 'Pages';
import { debounce, detectViewMode, innerPageScroll, updateMultiSelection, groupFiltersForUrl, filterSearchAcronym, arraysAreEqualByProperty } from 'Utils';
import { CONSTANTS, DROPDOWN_OPTIONS_MULTI, ERROR_PAGE, PAGE_NOT_FOUND_TITLE, PATHS, SOMETHING_WENT_WRONG, TAG_STYLE_ENUMS, NO_RESULTS_FOUND, URL_QUERY_KEYS, BODY_TYPE } from 'Constants';
import sprite from 'Assets/svgSprite.svg';
import { FILTERABLE_GROUPS } from 'Constants/Search';
import { FILTER_API_FAILED } from 'Constants/StaticContents';
import DefaultLogo from 'Assets/AusGovCrest-DefaultEntityLogo.svg';
import style from './PortfolioEntitiesCompanies.module.scss';

/**
 * PortfolioEntitiesCompanies.jsx
 *
 * @summary This component is page view for PortfolioEntitiesCompanies page.
 *
 */
export default function PortfolioEntitiesCompanies() {
  const { portfolios } = useRouteLoaderData(PATHS.PORTFOLIOS_ENTITIES_COMPANIES.ID);
  const [imageState, setImageState] = useState({});
  const [apiFailed, setApiFailed] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [multiSelectOptions, setMultiSelectOptions] = useState(DROPDOWN_OPTIONS_MULTI);
  const [abolishedMultiSelectOption, setAbolishedMultiSelectOption] = useState([
    { display: 'Abolished/re-named portfolios and bodies', returnValue: '1', selected: false, filterGroupTitle: 'Abolished' },
  ]);
  const [showAbolished, setShowAbolished] = useState(false);
  const [appliedShowAbolished, setAppliedShowAbolished] = useState(false);

  const [activeMultiSelectOption, setActiveMultiSelectOption] = useState([
    { display: 'Active portfolios and bodies', returnValue: '1', selected: false, filterGroupTitle: 'Active' },
  ]);
  const [showActive, setShowActive] = useState(true);
  const [appliedShowActive, setAppliedShowActive] = useState(true);


  const [filterParams, setFilterParams] = useSearchParams();
  const [filteredPortfolioData, setFilteredPortfolioData] = useState([]);
  const [totalPortfolios, setTotalPortfolios] = useState(0);
  const [viewMode, setViewMode] = useState(detectViewMode());
  const [justUpdated, setJustUpdated] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [allEntities, setAllEntities] = useState([]);
  const { homepage } = useRouteLoaderData(PATHS.HOME.ID);
  const { pathname } = useLocation();
  const [entityNamesWithAcronym, setEntityNamesWithAcronym] = useState([]);
  const [portfolioNamesWithAcronym, setPortfolioNamesWithAcronym] = useState([]);
  const [filterOptionsData, setFilterOptionsData] = useState(null);

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  const updateSingleMultiSelect = (selectedOption) => {
    // Note: Relies on there being unique return values across ALL filter values
    updateMultiSelection(selectedOption, FILTERABLE_GROUPS.BODY_TYPE.VALUE, multiSelectOptions, setMultiSelectOptions, selectedFilters, setSelectedFilters);
  };

  const updateAbolishedMultiSelect = () => {
    // Note: Relies on there being unique return values across ALL filter values
    showAbolished ? setShowAbolished(false) : setShowAbolished(true);
    setAbolishedMultiSelectOption([{ display: 'Abolished/re-named portfolios and bodies', returnValue: '1', selected: !showAbolished, filterGroupTitle: 'Abolished' }]);
  };

  const updateActiveMultiSelect = () => {
    // Note: Relies on there being unique return values across ALL filter values
    showActive ? setShowActive(false) : setShowActive(true);
    setActiveMultiSelectOption([{ display: 'Active portfolios and bodies', returnValue: '1', selected: false, filterGroupTitle: 'Active' }]);
  };

  const removeFilter = (dropdownOption) => {
    // Filter out dropdown option from currently select filter values
    setSelectedFilters((currentSelected) =>
      currentSelected.filter((option) => {
        return option.returnValue !== dropdownOption.returnValue;
      }),
    );

    // Unselect multiSelect option
    multiSelectOptions[FILTERABLE_GROUPS.BODY_TYPE.VALUE].forEach((option) => {
      if (option.returnValue === dropdownOption.returnValue) {
        dropdownOption.selected = false;
        return;
      }
    });
  };

  const filterPortfolioData = (portfolioList) => {
    // only 1 portfolio or entity title needs to contain the keyword per item to show the whole item
    const regex = new RegExp(searchTerm, 'gi');
    let filteredPortfolios = [];
    portfolioList.map((portfolio) => {
      // check if showing abolished, then check if portfolio was abolished

      const showPortfolio = () => {
        if (appliedShowAbolished && !appliedShowActive && portfolio.statusText !== '') {
          return true;
        } else if (appliedShowActive && !appliedShowAbolished && portfolio.statusText === '') {
          return true;
        } else if (appliedShowActive && appliedShowAbolished) {
          return true;
        } else {
          return false;
        }
      };
      console.log(portfolio);
      console.log(showPortfolio());

      if (showPortfolio()) {
        let filteredPortfolio = {
          previouslyKnownAs: portfolio.previouslyKnownAs,
          statusText: portfolio.statusText,
          subtext: portfolio.subtext,
          title: portfolio.title,
          url: portfolio.url,
          PBSNum: portfolio.PBSNum,
          ARNum: portfolio.ARNum,
          CPNum: portfolio.CPNum,
        };
        // check entity has search word
        let entitiesContainSearchTerm = false;
        if (portfolio.entities?.length > 0) {
          // apply filters to portfolios entities/companies
          filteredPortfolio.entities = portfolio.entities.filter((entity) => {
            // removeEntity will also check if acronym is included in entity
            const entityResults = removeEntity(entity, regex);
            if (entityResults.remove === false) {
              // check if entity contained search term
              if (entityResults.searchTermExists) entitiesContainSearchTerm = entityResults.searchTermExists;
              return true;
            }
            return false;
          });
        } 
        const portfolioContainsSearchTerm = searchTerm !== '' && (portfolio.title?.search(regex) !== -1 || portfolioNamesWithAcronym.includes(portfolio?.title));

        if (filteredPortfolio.entities?.length > 0 && (searchTerm === '' || portfolioContainsSearchTerm || entitiesContainSearchTerm)) {
          filteredPortfolios.push(filteredPortfolio);
        }
      }
    });

    setFilteredPortfolioData(filteredPortfolios);
  };

  const applyFilter = () => {
    setAppliedFilters(selectedFilters);
    setAppliedShowAbolished(showAbolished);
    setAppliedShowActive(showActive);
    setJustUpdated(true);
    const groupedFilters = groupFiltersForUrl(selectedFilters);

    setFilterParams((prevParams) => {
      Object.keys(groupedFilters).forEach((key) => {
        prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${key}`, groupedFilters[key]);
      });
      Object.keys(multiSelectOptions).forEach((key) => {
        if (!Object.keys(groupedFilters).includes(key)) {
          prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${key}`, ''); // reset all other keys
        }
      });
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}abolished`, showAbolished);
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}active`, showActive);
      return prevParams;
    });
  };

  const clearFilter = () => {
    // Group filters by category (to be applied for search api)
    const groupedFilters = groupFiltersForUrl(selectedFilters);
    setFilterParams((prevParams) => {
      Object.keys(groupedFilters).forEach((key) => {
        prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${key}`, ''); // reset all filter values in url
      });
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}abolished`, false);
      return prevParams;
    });

    // Unselect multiSelect option
    Object.keys(multiSelectOptions).map((key) => {
      if (Array.isArray(multiSelectOptions[key])) {
        multiSelectOptions[key].forEach((option) => {
          option.selected = false;
        });
      }
    });

    setSelectedFilters([]);
    setShowAbolished(false);
    setShowActive(false);
    setAbolishedMultiSelectOption([{ display: 'Abolished/re-named portfolios and bodies', returnValue: '1', selected: false, filterGroupTitle: 'Abolished' }]);
    setActiveMultiSelectOption([    { display: 'Active portfolios and bodies', returnValue: '1', selected: false, filterGroupTitle: 'Active' }]);
    setAppliedFilters([]);
    setAppliedShowAbolished(false);
    setAppliedShowActive(false);
    setJustUpdated(true);
  };

  /**
   * Set loading state for images
   * @param {String} codename - Codename identifier for image
   */
  const onImageLoad = (codename) => {
    setImageState((prevState) => {
      const newState = { ...prevState };
      if (newState[codename]) {
        newState[codename].loading = false;
      }
      return newState;
    });
  };

  /**
   * Set fail state for images
   * @param {String} codename - Codename identifier for image
   */
  const onImageError = (codename) => {
    setImageState((prevState) => {
      const newState = { ...prevState };
      if (newState[codename]) {
        newState[codename].fail = true;
      }
      return newState;
    });
  };

  /**
   * Check if entity should be filtered (removed)
   * @param {String} entity - Entity object
   * @param {String} regex - Expression to search for in entity name
   */
  const removeEntity = (entity, regex) => {
    // check if showing abolished entities - If appliedshowabolish and status text shows abolish message it is kept
    if (appliedShowAbolished && !appliedShowActive && entity.statusText === ''){
      return { remove: true, searchTermExists: false };
    }
    else if (appliedShowActive && !appliedShowAbolished && entity.statusText !== '') {
      return { remove: true, searchTermExists: false };
    }

    // if at least 1 entity has search term/acronym or searchTerm is '' then show whole portfolio
    if (appliedFilters.length === 0) {
      if (searchTerm !== '' && (entity.name?.search(regex) !== -1 || entityNamesWithAcronym.includes(entity?.name))) return { remove: false, searchTermExists: true };
      return { remove: false, searchTermExists: false };
    }
    // check if entityBodyType is included in filter
    for (let i = 0; i < appliedFilters.length; ++i) {
      if (appliedFilters[i]?.display?.toLowerCase() === entity.bodyType?.toLowerCase()) {
        if (searchTerm !== '' && (entity.name?.search(regex) !== -1 || entityNamesWithAcronym.includes(entity?.name))) return { remove: false, searchTermExists: true };
        return { remove: false, searchTermExists: false };
      }
    }
    return { remove: true, searchTermExists: false };
  };

  /**
   * Apply change without applying new filters
   * @param {String} termEntered - The search term entered by the user
   */
  const updateSearchTerm = (termEntered, entities, porfolios) => {
    setEntityNamesWithAcronym(filterSearchAcronym(entities || allEntities, termEntered));
    setPortfolioNamesWithAcronym(filterSearchAcronym(porfolios || allPortfolios, termEntered));
    setSearchTerm(termEntered);

    if (termEntered !== '') innerPageScroll(filteredPortfolioData[0]?.title);
    setFilterParams((prevParams) => {
      prevParams.set(URL_QUERY_KEYS.SEARCH_TERM, termEntered);
      return prevParams;
    });
  };

  const updateFilters = (entities, portfolios) => {
    const selectedFilterList = [];
    // Only add 'Body type' filtered options from url (if any)
    if (filterOptionsData?.BodyType.length > 0) {
      // Note: Assumes that values are split by ';'
      const groupInUrl = filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${'BodyType'}`)?.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY) || [];
      if (groupInUrl.length > 0) {
        filterOptionsData.BodyType.forEach((dropdownOption) => {
          if (groupInUrl.includes(dropdownOption.returnValue)) {
            dropdownOption.selected = true;
            selectedFilterList.push(dropdownOption);
          } else {
            dropdownOption.selected = false;
          }
        });
      }
      setMultiSelectOptions({ BodyType: filterOptionsData.BodyType });
    }

    if (selectedFilterList.length > 0) {
      setSelectedFilters(selectedFilterList);
      setAppliedFilters(selectedFilterList);
    }

    if (filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}abolished`)) {
      setShowAbolished(filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}abolished`) === 'true');
      setAppliedShowAbolished(filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}abolished`) === 'true');
    }

    if (filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}active`)) {
      setShowActive(filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}active`) === 'true');
      setAppliedShowActive(filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}active`) === 'true');
    }

    // update allEntities and allPortfolios

    if (filterParams.get(`${URL_QUERY_KEYS.SEARCH_TERM}`)) updateSearchTerm(filterParams.get(`${URL_QUERY_KEYS.SEARCH_TERM}`), entities, portfolios);
  };


  //Accordian Functionlity

    
  const [openAccordions, setOpenAccordions] = useState({});
  const [openAllAccordions, setOpenAllAccordions] = useState(false);

  const toggleAccordion = (id) => {
    setOpenAccordions((prevOpenAccordions) => ({
      ...prevOpenAccordions,
      [id]: !prevOpenAccordions[id], 
    }));
  };

  const toggleAllAccordions = () => {
    // Determine the new state for all accordions based on the current openAllAccordions state
    const shouldOpen = !openAllAccordions;
  
    // Create an object with all keys set to the determined state (open or closed)
    const toggledAccordions = {};
    filteredPortfolioData.forEach((portfolio) => {
      toggledAccordions[portfolio.title] = shouldOpen;
    });
  
    // Update the state to toggle all accordions
    setOpenAccordions(toggledAccordions);
    setOpenAllAccordions(shouldOpen);
  };
  

  return (
    <>
      {!apiFailed && (
        <PageHead pageTitle={PATHS.PORTFOLIOS_ENTITIES_COMPANIES.TITLE} keepBackgroundImage>
          <p>Find all available portfolios, entities and companies that are available on the Portal.</p>
          <br />
        </PageHead>
      )}
      {!apiFailed && (
        <Suspense>
          <Await resolve={homepage}>
            {(loadedData) => {
              let { filterOptions, filterGroupValues } = loadedData;
              if (!filterOptions || !filterGroupValues) {
                return (
                  <Filter filterTitle="Filter publications" isDisabled removeFilterFunction={removeFilter} clearFilterFunction={clearFilter} applyFilterFunction={applyFilter}>
                    {FILTER_API_FAILED}
                  </Filter>
                );
              }

              useEffect(() => {
                setFilterOptionsData(filterOptions);
                const selectedFilterList = [];
                // Only add 'Body type' filtered options from url (if any)
                if (filterOptions?.BodyType.length > 0) {
                  // Note: Assumes that values are split by ';'
                  const groupInUrl = filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${'BodyType'}`)?.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY) || [];
                  if (groupInUrl.length > 0) {
                    filterOptions.BodyType.forEach((dropdownOption) => {
                      if (groupInUrl.includes(dropdownOption.returnValue)) {
                        dropdownOption.selected = true;
                        selectedFilterList.push(dropdownOption);
                      } else {
                        dropdownOption.selected = false;
                      }
                    });
                  }
                }
                setMultiSelectOptions({ BodyType: filterOptions.BodyType });

                if (selectedFilterList.length > 0) {
                  setSelectedFilters(selectedFilterList);
                  setAppliedFilters(selectedFilterList);
                }

                if (filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}abolished`)) {
                  setShowAbolished(filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}abolished`) === 'true');
                  setAppliedShowAbolished(filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}abolished`) === 'true');
                }
                if (filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}active`)) {
                  setShowActive(filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}active`) === 'true');
                  setAppliedShowActive(filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}active`) === 'true');
                }

                if (filterParams.get(`${URL_QUERY_KEYS.SEARCH_TERM}`)) updateSearchTerm(filterParams.get(`${URL_QUERY_KEYS.SEARCH_TERM}`));
              }, [pathname]);

              return (
                <Filter
                  filterTitle="Filter entities and companies"
                  filterLabel={`Showing ${filteredPortfolioData.length} of ${totalPortfolios} portfolios`}
                  selectedFilters={selectedFilters}
                  applyFilterFunction={() => applyFilter(allPortfolios)}
                  clearFilterFunction={clearFilter}
                  removeFilterFunction={removeFilter}
                  notShowFilterCount={['Abolished/re-named portfolios and bodies']}
                  disableApplyButton={arraysAreEqualByProperty(selectedFilters, appliedFilters, 'returnValue') && showAbolished === appliedShowAbolished && showActive === appliedShowActive  }>
                  <FilterGroupDropdown filterGroupTitle={FILTERABLE_GROUPS.BODY_TYPE.DISPLAY}>
                    <StandardFilterList
                      dropdownOptions={multiSelectOptions[FILTERABLE_GROUPS.BODY_TYPE.VALUE]}
                      selectOptionFunction={(option) => updateSingleMultiSelect(option, FILTERABLE_GROUPS.BODY_TYPE.VALUE)}></StandardFilterList>
                  </FilterGroupDropdown>
                  {viewMode !== CONSTANTS.VIEW_MODE.MOBILE ? (
                    <>
                      <label className={['buttonStyle lightFill', style.abolishedButton].join(' ')}>
                        <input id="activeButton" type="checkbox" checked={showActive} onChange={() => (showActive ? setShowActive(false) : setShowActive(true))} />
                        Active portfolios and bodies
                      </label>
                      <label className={['buttonStyle lightFill', style.abolishedButton].join(' ')}>
                        <input id="abolishedButton" type="checkbox" checked={showAbolished} onChange={() => (showAbolished ? setShowAbolished(false) : setShowAbolished(true))} />
                        Abolished/re-named portfolios and bodies
                      </label>
                    </>
                  ) : (
                    viewMode === CONSTANTS.VIEW_MODE.MOBILE && (
                      <>
                        <FilterGroupDropdown filterGroupTitle={'Active portfolios and bodies'}>
                          <StandardFilterList dropdownOptions={activeMultiSelectOption} selectOptionFunction={() => updateActiveMultiSelect()}></StandardFilterList>
                        </FilterGroupDropdown>
                        <FilterGroupDropdown filterGroupTitle={'Abolished/re-named portfolios and bodies'}>
                          <StandardFilterList dropdownOptions={abolishedMultiSelectOption} selectOptionFunction={() => updateAbolishedMultiSelect()}></StandardFilterList>
                      </FilterGroupDropdown>
                      </>

                    )
                  )}
                </Filter>
              );
            }}
          </Await>
        </Suspense>
      )}
      <Suspense fallback={<PortfolioEntitiesCompaniesLoading />}>
        <Await
          resolve={portfolios}
          errorElement={
            <StandardPage setErrorFunction={() => setApiFailed(true)} title={PAGE_NOT_FOUND_TITLE}>
              {SOMETHING_WENT_WRONG}
            </StandardPage>
          }>
          {(resolvedPortfolios) => {
            useEffect(() => {
              if (resolvedPortfolios.isError) {
                setApiFailed(true);
              }
            }, [resolvedPortfolios.isError]);

            if (resolvedPortfolios.isError) {
              return ERROR_PAGE();
            }

            const { portfolioList } = resolvedPortfolios;

            useEffect(() => {
              filterPortfolioData(allPortfolios);
            }, [searchTerm]);

            useEffect(() => {
              // Adds all loading states of images (that are found)
              const loadingFailStates = {};
              if (filteredPortfolioData && Array.isArray(filteredPortfolioData)) {
                filteredPortfolioData.forEach((portfolio) => {
                  if (portfolio.entities && Array.isArray(portfolio.entities)) {
                    portfolio.entities.forEach((entity) => {
                      if (!loadingFailStates[entity.codename] && entity.logo) {
                        loadingFailStates[entity.codename] = { loading: true, fail: false };
                      }
                    });
                  }
                });
              }
              setImageState(loadingFailStates);
              // set all portfolios and entities
              let allEntities = [];
              if (portfolioList.length > 0) {
                portfolioList.map((portfolio) => {
                  portfolio.display = portfolio?.title;
                  portfolio?.entities.map((entity) => {
                    entity.display = entity?.name;
                    allEntities.push(entity);
                  });
                });
              }
              setAllEntities(allEntities);
              setAllPortfolios(portfolioList);

              if (portfolioList.length > 0) {
                if (filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${'BodyType'}`) || filterParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}abolished`)) {
                  updateFilters(allEntities, portfolioList);
                } else {
                  setFilteredPortfolioData(portfolioList);
                }
              }

              filterPortfolioData(portfolioList);
              setTotalPortfolios(portfolioList.length);
              setJustUpdated(true);
            }, []);

            // use effect that applies once state data is updated
            useEffect(() => {
              if (setJustUpdated) {
                filterPortfolioData(portfolioList);
                setJustUpdated(false);
              }
            }, [justUpdated]);

            useEffect(() => {
              filterPortfolioData(portfolioList);
            }, [entityNamesWithAcronym]);

            return (
              <>
                {/* Using the Datasets style header */}
                <div className={style.dataSetsContainer}>
                  {/* <h2>Select a data set to view</h2> */}
                  <p style={{marginTop:20}} className={style.bold}><b>Learn more about entity and company body types</b></p>
                  <div className={style.entityTypesContainers}>
                    <div className={style.entityTypes}>
                      <span>
                        <Tag displayText={BODY_TYPE.NCE.TAG} fullText={BODY_TYPE.NCE.TEXT} />
                        <a className="alwaysFresh" href="https://www.finance.gov.au/about-us/glossary/pgpa/term-non-corporate-commonwealth-entity-nce" rel="noreferrer" target="_blank">
                          {BODY_TYPE.NCE.TEXT}
                          <svg>
                            <use href={`${sprite}#open-in-new-tab`} />
                          </svg>
                        </a>
                      </span>
                      <span>
                        <Tag displayText={BODY_TYPE.CCE.TAG} fullText={BODY_TYPE.CCE.TEXT} />
                        <a className="alwaysFresh" href="https://www.finance.gov.au/about-us/glossary/pgpa/term-corporate-commonwealth-entity-cce" rel="noreferrer" target="_blank">
                          {BODY_TYPE.CCE.TEXT}
                          <svg>
                            <use href={`${sprite}#open-in-new-tab`} />
                          </svg>
                        </a>
                      </span>
                      <span>
                        <Tag displayText={BODY_TYPE.CC.TAG} fullText={BODY_TYPE.CC.TEXT} />
                        <a className="alwaysFresh" href="https://www.finance.gov.au/about-us/glossary/pgpa/term-commonwealth-company" rel="noreferrer" target="_blank">
                          {BODY_TYPE.CC.TEXT}
                          <svg>
                            <use href={`${sprite}#open-in-new-tab`} />
                          </svg>
                        </a>
                      </span>
                    </div>
                    <button onClick={toggleAllAccordions} className={['btn lightFill', style.link].join(' ')}>
                      {!openAllAccordions ? 'Minimise all' : 'Maximise all'}
                    </button>
                  </div>

                </div>

                <div className={style.pecBody}>
                  {filteredPortfolioData.length > 0 ? (
                    <div className={style.portfolioList}>
                      {filteredPortfolioData.map((portfolio, i) => {
                        const isOpen = openAccordions[portfolio.title];
                        return (
                          <div id={portfolio.title} key={`${portfolio.title}-${i}`} className={style.portfolio}>
                            <div className={style.portfolioHeader} onClick={() => toggleAccordion(portfolio.title)}>
                              <div className={style.portfolioHeaderTitleWrapper}>
                                <span className="h2">
                                  <p>
                                    {portfolio.title}
                                  </p>

                                  {portfolio.statusText ? (
                                    <Tag displayText="Abolished Portfolio" type={TAG_STYLE_ENUMS.GREY} />) : (
                                    <Tag displayText="Active Portfolio" type={TAG_STYLE_ENUMS.DARK} />
                                  )}
                                </span>
                              </div>

                              <div className={style.portfolioHeaderSvgWrapper}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{transform: !isOpen ? 'rotate(0deg)' : 'rotate(180deg)'}}>
                                  <path d="M11.9984 8.54995L16.9199 13.4714C17.2349 13.7864 17.0118 14.325 16.5663 14.325H7.43055C6.98509 14.325 6.76201 13.7864 7.07699 13.4714L11.9984 8.54995Z" fill="black"/>
                                </svg>
                              </div>
                            </div>

                            {!isOpen && (
                              <div className={style.portfolioBody}>
                                <div className={style.portfolioDetails}>
                                  <div className={style.portfolioDetailsWrapper}>
                                    <p>In this portfolio </p>

                                    <div className={style.portfolioStats}>
                                      <div className={style.numberAndParagraphDiv}>
                                        <p className={style.titleAndNumber}>{portfolio.PBSNum}</p>
                                        <p className={style.titleText}>&nbsp;Portfolio Budget Statements</p>
                                      </div>
                                      <div className={style.numberAndParagraphDiv}>
                                        <p className={style.titleAndNumber}>{portfolio.ARNum}</p>
                                        <p className={style.titleText}>&nbsp;Annual Reports</p>
                                      </div>
                                      <div className={style.numberAndParagraphDiv}>
                                        <p className={style.titleAndNumber}>{portfolio.CPNum}</p>
                                        <p className={style.titleText}>&nbsp;Corporate Plans</p>
                                      </div>
                                    </div>

                                    {portfolio.previouslyKnownAs?.length > 0 && (
                                      <span className={style.previousNames}>
                                        Previous known as:{' '}
                                        {portfolio.previouslyKnownAs
                                          .map((previous) => {
                                            return previous.name;
                                          })
                                          .join(', ')}
                                      </span>
                                    )}
                                  </div>

                                  <div className={style.portfolioDetailsButtonWrapper}>
                                    <Link to={`/${portfolio.url}`}>
                                      <button>
                                        Visit Portfolio
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                                <div className={style.entities}>
                                  <span className={style.title}>Entities and companies</span>
                                  <ol>
                                    {portfolio.entities.map((entity, entityIndex) => {
                                      return (
                                        <li key={`${entity.name}-${entityIndex}`} className={['card', 'listItem', 'entityItem', entity.leadEntity ? 'leadingEntity' : ''].join(' ')}>
                                          <Link className="entityLink" to={`/${entity.url}?pageTag=${portfolio.statusText}`}>
                                            <span className="limitTextLines">
                                              <span className="tags">
                                                {entity.bodyType && <Tag displayText={entity.bodyType} />}
                                                {entity.statusText && <Tag displayText={entity.statusText} type={TAG_STYLE_ENUMS.DARK} extraStyleClass={style.whiteBorderTags} />}
                                              </span>
                                              <span
                                                className={[entity.leadEntity ? 'cardTitleWhite' : 'cardTitle', style.entityTitle, 'limitTextLines'].join(' ')}
                                                style={{ '--MAX-LINE': 3 }}>
                                                {entity.name}
                                              </span>
                                            </span>
                                            <LoadingMenu numberOfItems={4} className={imageState[entity.codename]?.loading ? style.loadingImage : style.hide}></LoadingMenu>
                                            <div className={['imgContainer', imageState[entity.codename]?.loading ? style.hide : ''].join(' ')}>
                                              <img
                                                aria-hidden={!(entity.logo && !imageState[entity.codename]?.fail)}
                                                alt={entity.logo && !imageState[entity.codename]?.fail ? `${entity.name} logo` : ''}
                                                src={entity.logo && !imageState[entity.codename]?.fail ? entity.logo : DefaultLogo}
                                                onLoad={() => onImageLoad(entity.codename)}
                                                onError={() => onImageError(entity.codename)}></img>
                                            </div>
                                          </Link>
                                        </li>
                                      );
                                    })}
                                    {portfolio.entities?.length === 0 && <span className={style.noEntities}>No entities found in this portfolio</span>}
                                  </ol>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <span className={style.notResults}>{NO_RESULTS_FOUND}</span>
                  )}
                </div>
              </>
            );
          }}
        </Await>
      </Suspense>
    </>
  );
}
