import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Chart, DataSetsFilterAccordion } from 'Components';
import { CHART_TYPE } from 'Constants';
import style from './GraphView.module.scss';
import icons from 'Assets/svgSprite.svg';
import GreyTree from 'Assets/Tree-Half-Grey.svg';

/**
 * GraphView.jsx
 *
 * @summary Render as graph view from data visualisation page.
 *
 * @param {Object} props - Component props.
 * @prop {String} props.heading - Heading of component. Always render as h2.
 * @prop {[{ chartData: { data: [{ name: String, ... }], [heading]: String }, [chartGroupHeading]: String }]} [props.dataSet = []] - Data to render charts. If not given or passed empty array or nullish value, component will render as no data state.
 */
const GraphView = memo(function GraphView({ heading, dataSet = [], additionalFilters, urlParams = null }) {
  const disabled = dataSet?.length === 0 || !dataSet;
  const [chartType, setChartType] = useState(disabled ? null : CHART_TYPE.BAR);
  const [isChartVertical, setIsChartVertical] = useState(false);
  const [showLabels, setShowLabels] = useState(false);

  useEffect(() => {
    setChartType(disabled ? null : CHART_TYPE.BAR);
  }, [dataSet]);

  return (
    <section className={style.graphView}>
      <div className={style.controlWrapper}>
        <span className={style.label}>View as:</span>
        <div className={style.buttonWrapper}>
          <div>
            <button disabled={disabled} className={chartType === CHART_TYPE.BAR ? 'activeState' : null} onClick={() => setChartType(CHART_TYPE.BAR)}>
              Bar chart
              <svg>
                <use href={`${icons}#bar-graph`} />
              </svg>
            </button>
            <button disabled={disabled} className={chartType === CHART_TYPE.STACKED_BAR ? 'activeState' : null} onClick={() => setChartType(CHART_TYPE.STACKED_BAR)}>
              Stacked bar chart
              <svg>
                <use href={`${icons}#stacked-bar-graph`} />
              </svg>
            </button>
            {disabled ? (
              <span className="btn buttonStyle disabledState">
                Table
                <svg className={style.newTab}>
                  <use href={`${icons}#open-in-new-tab`} />
                </svg>
              </span>
            ) : (
              <Link to={urlParams ? `../?${urlParams}` : '../'} className="btn" target="_blank">
                Table
                <svg className={style.newTab}>
                  <use href={`${icons}#open-in-new-tab`} />
                </svg>
              </Link>
            )}
          </div>

          {!disabled && (
            <div>
              <label className={['buttonStyle lightFill', style.abolishedButton].join(' ')}>
                <input
                  id="abolishedButton"
                  type="checkbox"
                  checked={showLabels}
                  onChange={() => {
                    setShowLabels(!showLabels);
                  }}
                />
                Show data labels
              </label>
              <button className="lightFill" onClick={() => setIsChartVertical(!isChartVertical)}>
                Rotate
                <svg className={isChartVertical ? style.mirrored : null}>
                  <use href={`${icons}#rotate`} />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>

      {!disabled && (
        <div className={style.headingWrapper}>
          <h2>{heading}</h2>

          {additionalFilters && (
            <div className={style.filter}>
              <DataSetsFilterAccordion title="Filter this data set" styleOverride={style.filterAccordionButton} darkTheme>
                <div className={style.filterWrapper}>
                  {/* FIXME: this should be same filter from data sets table filters. Should reuse codes here */}
                  {additionalFilters}
                </div>
              </DataSetsFilterAccordion>
            </div>
          )}
        </div>
      )}

      <div className={style.mobileSeperater}>
        {disabled ? (
          <div className={[style.chartWrapper, style.noData].join(' ')} style={{ '--BACKGROUND-IMAGE': `url(${GreyTree})` }}></div>
        ) : (
          <div className={style.chartWrapper}>
            {dataSet?.map((item, i) => {
              const key = `data-item-${i}`;

              return (
                <div key={key} className={style.chartGroup}>
                  <div className={style.charts}>
                    {item?.chartData?.map((chart, i) => {
                      const chartKey = `chartKey-${i}`;

                      return (
                        <div key={chartKey} className={style.borders}>
                          <Chart data={chart?.data} type={chartType} isVertical={isChartVertical} chartLabel={chart?.heading} showLabels={showLabels} width={chart.width} />
                        </div>
                      );
                    })}
                  </div>
                  {item?.chartGroupHeading && <span className={['h6', style.gropuHeading].join(' ')}>{item.chartGroupHeading}</span>}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
});

GraphView.propTypes = {
  heading: PropTypes.string.isRequired,
  showLabels: PropTypes.bool,
  dataSet: PropTypes.arrayOf(
    PropTypes.shape({
      chartGroupHeading: PropTypes.string,
      chartData: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          data: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
            }),
          ),
        }),
      ),
    }),
  ),
  additionalFilters: PropTypes.node,
  urlParams: PropTypes.string,
};

export default GraphView;
