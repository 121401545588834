import React, { Suspense, useState } from 'react';
import { Await, Link, useRouteLoaderData } from 'react-router-dom';
import { ERROR_PAGE, PAGE_NOT_FOUND_PAGE, PATHS, PUBLICATION_TYPE, TAG_STYLE_ENUMS, URL_QUERY_KEYS } from 'Constants';
import { Tag } from 'Components';
import icons from 'Assets/svgSprite.svg';
import FullColourTree from 'Assets/Tree.svg';
import { ReactComponent as TPLogo } from 'Assets/TPLogo.svg';
import style from './HomePage.module.scss';
import { FILTERABLE_GROUPS } from 'Constants/Search';
import { ERROR_CONTENT, PAGE_NOT_FOUND } from 'Constants/StaticContents';
import { STATUS_TYPES } from 'Utils/Error';
import { HomePageLoading } from 'Pages';

/**
 * HomePage.jsx
 *
 * @summary This component is page view for home page.
 */
export default function HomePage() {
  const { homepage } = useRouteLoaderData(PATHS.HOME.ID);

  // update title
  document.title = 'Transparency Portal';

  const publicationsPreFilter = [PUBLICATION_TYPE.ANNUAL_REPORT_GROUP.VALUE, PUBLICATION_TYPE.CORPORATE_PLAN.VALUE, PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.VALUE];

  const backgroundImages = {
    hero: {
      '--BACKGROUND-IMAGE': `url(${FullColourTree})`,
    },
  };

  const applyFiltersToUrl = (url, filterToApply) => {
    // apply filters to url link to be used (not the to denote a filter, a '$' needs to be added)
    return `${url}?${encodeURIComponent(URL_QUERY_KEYS.FILTER_DENOTE)}${FILTERABLE_GROUPS.PUBLICATION_TYPE.VALUE}=${filterToApply}`;
  };


  // State to keep track of which accordion item is open
  const [openAccordion, setOpenAccordion] = useState(null);

  // Function to toggle accordion item
  const toggleAccordion = (index) => {
    if (openAccordion === index) {
      // If the clicked item is already open, close it
      setOpenAccordion(null);
    } else {
      // Otherwise, open the clicked item
      setOpenAccordion(index);
    }
  };

  return (
    <Suspense fallback={<HomePageLoading />}>
      <Await resolve={homepage} errorElement={ERROR_PAGE()}>
        {(resolvedData) => {
          if (resolvedData.isError) {
            if (resolvedData.statusCode === STATUS_TYPES.NOT_FOUND) return PAGE_NOT_FOUND_PAGE(ERROR_CONTENT.NOT_FOUND);
            if (resolvedData.statusCode === STATUS_TYPES.RANGE_ERROR) return ERROR_PAGE(PAGE_NOT_FOUND(ERROR_CONTENT.INVALID_CONTENT));
            if (resolvedData.statusCode === STATUS_TYPES.OTHER) return ERROR_PAGE();
            return ERROR_PAGE();
          }

          const {
            homepageMetadata: {
              heroBanner: { body, links },
              publicationsSection,
              relatedResources,
            },
          } = resolvedData;

          return (
            <>
              <div id={style.hero} className="fullColorBackground" style={backgroundImages.hero}>
                <div id={style.contentArea}>
                  <h1>
                    <span id="TP">Transparency Portal</span>
                    <TPLogo aria-describedby="TP" />
                  </h1>

                  {body}

                  <div>
                    <a id={style.jumpToPublicationsList} className="a" href={`#${style.publicationsList}`}>
                      Scroll down to find more
                      <svg>
                        <use href={icons + '#arrow'} />
                      </svg>
                    </a>
                  </div>
                </div>

                <div id={style.quickLinks}>
                  {links.map((obj) => (
                    <Link key={`quick-link-${obj?.title}`} to={obj?.url} className="card">
                      <span className="cardTitle">{obj?.title}</span>
                      <span className="description">{obj?.summary}</span>
                    </Link>
                  ))}
                </div>

                <div className={style.backgroundImage}>
                  <div className={style.imageContainer}>
                    <div className={style.tree}></div>
                  </div>
                  <div className={[style.imageContainer, style.transparentBackground].join(' ')}>
                    <div className={style.tree}></div>
                  </div>
                </div>
              </div>

              <section id={style.publicationsList}>
                <h2>{publicationsSection?.title}</h2>
                {publicationsSection?.columns?.map((obj, i) => (
                  <div key={`publication-type-${obj.title}`} className={style.publicationTypeWrapper}>
                    <button
                      className={style.accordianButton}
                      onClick={() => toggleAccordion(i)}
                    >
                      <div className={`${style.buttonTextWrapper} ${openAccordion === i ? style.openButtonAccordian : ''}`}>
                        <h3 className={['h6', style.cardTitle].join(' ')}>
                          {obj.title}
                        </h3>
                        <Tag
                            displayText={obj.title
                              .split(' ')
                              .map((str) => str[0])
                              .join('')}
                            fullText={obj.title}
                            type={TAG_STYLE_ENUMS.HIGHLIGHT}
                          />
                      </div>

                      <div className={style.buttonArrowWrapper }>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={openAccordion === i ? style.arrowRotated : ''}>
                          <path d="M11.9984 8.5502L16.9199 13.4716C17.2349 13.7866 17.0118 14.3252 16.5663 14.3252H7.43055C6.98509 14.3252 6.76201 13.7866 7.07699 13.4716L11.9984 8.5502Z" fill="black"/>
                        </svg>
                      </div>

                    </button>
                    <div
                      className={style.accordianTextWrapper}
                      style={{ display: openAccordion === i ? 'block' : 'none' }}
                    >
                      <div>{obj?.node}</div>
                    </div>
                    <div
                      className={style.accordianLinkWrapper}
                      style={{ display: openAccordion === i ? 'block' : 'none' }}
                    >
                      <Link className={['btn lightFill', style.link].join(' ')} to={applyFiltersToUrl(PATHS.PUBLICATIONS_LIST.BASE, publicationsPreFilter[i])}>
                        <p>{`View ${obj.title}`}</p>
                      </Link>
                    </div>

                  </div>
                ))}
              </section>

              <section id={style.relatedResources}>
                <h2>Related resources to visit</h2>
                {relatedResources?.map((obj) => (
                  <a
                    key={`related-resource-${obj.title}`}
                    href={obj.url}
                    target={obj?.openInNewTab ? '_blank' : null}
                    rel={obj?.openInNewTab ? 'noreferrer' : null}
                    className="card">
                    <span className={obj?.openInNewTab ? 'newTabIconWrapper' : null}>
                      <span className="cardTitle">{obj.title}</span>
                      {obj?.openInNewTab && (
                        <svg className="newTabIcon">
                          <use href={icons + '#open-in-new-tab'} />
                        </svg>
                      )}
                    </span>
                    <span className="limitTextLines" style={{ '--MAX-LINE': 3 }}>
                      <span className="description">{obj.summary}</span>
                    </span>
                  </a>
                ))}
              </section>
            </>
          );
        }}
      </Await>
    </Suspense>
  );
}
