import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { domToReact } from 'html-react-parser';
import { CONSTANTS, CONTENT_ITEM_TYPES, DOMAIN_LIST, KONTENT_EMPTY_DATA_VALUES, MANDATORY_REQUIREMENTS_CHAPTER_TITLE } from 'Constants';
import { titleToUrl, getUrlObject, validUrl } from 'Utils';
import icons from 'Assets/svgSprite.svg';

/**
 * Function to check if Y scroll exists or not
 * @returns {Boolean} - If Y scroll exists, return true, else false
 */
export const scrollExists = () => {
  return document.body.clientHeight > window.innerHeight;
};

/**
 * Debounce function
 * @param {Function} func - Call back function on debounce
 * @param {Number} [timeout=100] - Debounce delay in ms
 * @returns
 */
export const debounce = (func, timeout = 100) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

/**
 * Function to return view mode string
 * @returns {String['desktop', 'tablet', 'mobile']} - String of current view mode
 * based on viewport width
 */
export const detectViewMode = () => {
  const viewportWidth = window.innerWidth;

  if (viewportWidth <= CONSTANTS.MOBILE.MAX) return CONSTANTS.VIEW_MODE.MOBILE;
  if (viewportWidth <= CONSTANTS.TABLET.MAX && viewportWidth >= CONSTANTS.TABLET.MIN) return CONSTANTS.VIEW_MODE.TABLET;
  if (viewportWidth >= CONSTANTS.DESKTOP.MIN) return CONSTANTS.VIEW_MODE.DESKTOP;
};

/**
 * Function to scroll to target html tag
 * @param {String} targetId Target html tag id
 */
export const innerPageScroll = (targetId) => {
  const target = document?.getElementById(targetId);

  if (target) {
    window.scrollTo({
      top: target.getBoundingClientRect().top + window.pageYOffset - 100,
      behavior: 'smooth',
    });
  }
};

/**
 * Finds if there is a target tag with id or data-system-id for given targetId
 * @param {String} htmlString - Html string to check if contains tag with target id
 * @param {String} targetId - String target id
 * @returns {Boolean} - Boolean based on whether a tag was found with id
 */
export const findTargetIdInHtmlString = (htmlString, targetId) => {
  const chapterHtml = new DOMParser().parseFromString(htmlString, 'text/html');
  if (chapterHtml) {
    const targetTag = chapterHtml.querySelector(`[id="${targetId}"]`);
    const targetTagSystemId = chapterHtml.querySelector(`[data-system-id="${targetId}"]`);
    if (targetTag || targetTagSystemId) {
      return true;
    }
  }
  return false;
};

/**
 * Update url link to add current portfolio and entity url slug
 * @param {Object} urlObject - Url object
 * @returns {String} url with pathname for portfolio and entity updated with current
 */
export const updatePortfolioAndEntityUrl = (urlObject) => {
  let updatedUrlWithPortfolioAndEntity = null;
  if (urlObject) {
    const currentPathname = window.location?.pathname?.split('/').filter(Boolean);
    const urlObjectPathList = urlObject.pathname?.split('/').filter(Boolean);
    if (urlObjectPathList.length >= 3 && currentPathname.length >= 3) {
      // expect there to be at least 3 values e.g. '/publications/portfolioUrl/entityUrl/...'
      const updatedUrlList = urlObjectPathList.map((pathValue, index) => {
        if (index == 1 || index == 2) {
          // update porfolioUrl and entityUrl (at index 1 and 2) with current url one
          return currentPathname[index];
        }
        return pathValue;
      });
      updatedUrlWithPortfolioAndEntity = updatedUrlList.join('/');
    }
  }
  return updatedUrlWithPortfolioAndEntity;
};

/**
 * Function to replace <a> with `data-item-id` with <Link>, <HashLink> or <a> with target to #
 * @param {Object} domNode Dom node passed from html-react-parser
 * @param {String} htmlString Raw html string which contains <a data-item-id="something">
 * @param {{ chapterList: Object[], articleWebUrl: String }} extra that contains list of chapters and target article codename
 * @returns Object
 */
export const correctLinkNode = (domNode, htmlString, extra = {}) => {
  // replace cross refereing hyperlinks
  const attribs = domNode.attribs;
  const dataItemId = attribs?.['data-item-id'];
  const classNames = attribs?.class;
  const openInNewTab = attribs?.target === '_blank';
  const newTabIcon = (
    <svg className="newTabIcon">
      <use href={`${icons}#open-in-new-tab`} />
    </svg>
  );

  const getCurrentPath = () => {
    let pathname = '';
    const currentPathname = window.location.pathname.split('/').filter(Boolean);
    if (currentPathname.length >= 4) {
      const pathUrlList = currentPathname.slice(0, 4);
      pathname = pathUrlList.join('/');
    }

    return pathname;
  };

  // For migrated reports, link is populated with x ref link attribute
  if (attribs?.['data-migrated-x-ref-link']) {
    const urlPart = attribs['data-migrated-x-ref-link'].split('#');
    return (
      <Link
        className={classNames}
        to={`/${getCurrentPath()}${urlPart[0]
          .split('/')
          .map((str) => titleToUrl(str))
          .join('/')}${urlPart?.[1] ? `#${urlPart[1]}` : ''}`}
        target={openInNewTab ? '_blank' : null}
        rel={openInNewTab ? 'nonreferer' : null}>
        {domToReact(domNode.children)}
        {openInNewTab && !classNames?.includes('inBodyContent') && newTabIcon}
      </Link>
    );
  }

  
  //Check for data footnote item, if it contains the custom attribute it creates a hashlink
  if (attribs?.['data-footnote-link']) {
    const linkText = domToReact(domNode.children);

    return (
      <HashLink
        className={classNames}
        id={`${attribs?.['id']}` || ''}
        smooth
        to={`#${attribs?.['data-footnote-link']}`}
        scroll={() => innerPageScroll(`#${attribs?.['data-footnote-link']}`)}
        target={openInNewTab ? '_self' : null}
        rel={openInNewTab ? 'nonreferer' : null}>
        {linkText}
      </HashLink>
    );
  }

  // if href value is invalid URL, return it as span
  if (!validUrl(attribs.href) && !dataItemId) {
    return <span>{domToReact(domNode.children)}</span>;
  }

  // when link was created via selecting target content item in Kontent
  if (dataItemId) {
    // check if there are linkable section with id match to this dom's data-item-id value
    const thisPageHtml = new DOMParser().parseFromString(htmlString, 'text/html');
    const target = thisPageHtml.querySelector(`[data-system-id="${dataItemId}"]`);
    const linkText = domToReact(domNode.children);
    const targetId = target ? target.getAttribute('id') : dataItemId;

    if (extra.renderAsText) {
      return <u>{linkText}</u>;
    }

    // if within same page use <HashLink>
    if (thisPageHtml.getElementById(targetId)) {
      return (
        <HashLink
          className={classNames}
          smooth
          to={`#${targetId?.startsWith('#') ? targetId?.slice(1) : targetId}`}
          scroll={() => innerPageScroll(targetId)}
          target={openInNewTab ? '_blank' : null}
          rel={openInNewTab ? 'nonreferer' : null}>
          {linkText}
          {openInNewTab && !classNames?.includes('inBodyContent') && newTabIcon}
        </HashLink>
      );
    }

    // if in different chapter/section (`chapterList` must be passed via `extra`)
    if (extra?.chapterList) {
      const chapterList = extra.chapterList;
      let path = `${window.location.pathname.split(extra?.articleWebUrl)[0]}${extra?.articleWebUrl}`;

      // check if target is chapter
      const targetChapter = chapterList.find((chapter) => chapter?.system?.id === targetId || chapter?.System?.id === targetId);
      if (targetChapter) {
        if (targetChapter?.System?.type === CONTENT_ITEM_TYPES.MANDATORY_REQUIREMENT_REPORT_SECTION.ID) {
          path += `/${titleToUrl(targetChapter?.elements?.title?.value || targetChapter?.Title || MANDATORY_REQUIREMENTS_CHAPTER_TITLE)}`;
        } else {
          path += `/${targetChapter?.WebUrl || titleToUrl(targetChapter?.elements?.title?.value || targetChapter?.Title)}`;

          // if target chapter body is empty, link should be first child section of the target chapter
          if (KONTENT_EMPTY_DATA_VALUES.includes(targetChapter?.elements?.body?.value || targetChapter?.Body)) {
            if (targetChapter.elements?.sections?.linkedItems?.[0] || targetChapter?.Sections?.[0]) {
              path += `/${
                targetChapter?.Sections?.[0]?.WebUrl ||
                titleToUrl(targetChapter?.elements?.sections?.linkedItems?.[0].elements?.title?.value || targetChapter?.Sections?.[0]?.Title)
              }`;
            } else {
              // If no child section is found (while chapter body is empty), link to current page (so that the link does not go anywhere)
              // This case will only be seen for preview mode, since in non-preview, it is expected that a chapter body should have 1. content OR 2. a section as a child, or it will not render
              path = '';
            }
          }
        }
      } else {
        // check if chapter body contains target
        const targetWithinChapterBody = chapterList.find((chapter) => chapter.elements?.body?.linkedItems?.find((item) => item.system.id === targetId));
        if (targetWithinChapterBody) {
          path += `/${targetWithinChapterBody?.WebUrl || titleToUrl(targetWithinChapterBody.elements.title.value)}#${targetWithinChapterBody.elements?.link_id?.value || targetId}`;
        }
        // check if target is section of a chapter
        const chapterContainsTargetSection = chapterList.find((chapter) => {
          return (
            chapter.elements?.sections?.linkedItems?.some((section) => section?.system?.id === targetId) || chapter?.Sections?.some((section) => section?.System?.id === targetId)
          );
        });
        const targetSection = chapterContainsTargetSection?.elements?.sections?.linkedItems?.find((section) => section.system.id === targetId);
        if (targetSection) {
          path += `/${chapterContainsTargetSection?.WebUrl || titleToUrl(chapterContainsTargetSection.elements.title.value)}/${
            targetSection?.WebUrl || titleToUrl(targetSection.elements.title.value)
          }`;
        }

        const targetSectionNewModel = chapterContainsTargetSection?.Sections?.find((section) => section.System?.id === targetId);
        if (targetSectionNewModel) {
          if (targetSectionNewModel?.System?.type === CONTENT_ITEM_TYPES.MANDATORY_REQUIREMENT_REPORT_SECTION.ID) {
            path += `/${chapterContainsTargetSection?.WebUrl || titleToUrl(chapterContainsTargetSection.Title)}/${
              targetSectionNewModel?.WebUrl || titleToUrl(targetSectionNewModel?.elements?.title?.value || targetSectionNewModel?.Title || MANDATORY_REQUIREMENTS_CHAPTER_TITLE)
            }`;
          } else {
            path += `/${chapterContainsTargetSection?.WebUrl || titleToUrl(chapterContainsTargetSection.Title)}/${
              targetSection?.WebUrl || titleToUrl(targetSectionNewModel.Title)
            }`;
          }
        }

        // check if section body of a chapter contains target
        const chapterContainsTargetSectionBody = chapterList.find((chapter) => {
          return chapter.elements?.sections?.linkedItems?.find((section) => section.elements?.body?.linkedItems?.some((item) => item.system.id === targetId));
        });
        const sectionContainsTarget = chapterContainsTargetSectionBody?.elements?.sections?.linkedItems?.find((section) =>
          section.elements.body.linkedItems.find((item) => item.system.id === targetId),
        );
        if (sectionContainsTarget) {
          const target = sectionContainsTarget.elements?.body?.linkedItems?.find((item) => item.system.id === targetId);
          path += `/${chapterContainsTargetSectionBody?.WebUrl || titleToUrl(chapterContainsTargetSectionBody.elements.title.value)}/${
            sectionContainsTarget?.WebUrl || titleToUrl(sectionContainsTarget.elements.title.value)
          }#${target.elements?.link_id?.value || targetId}`;
        }

        // New model of AR - check if chapter or section body contains item with target id
        const chapterContainsTargetSectionBodyNewModel = chapterList.find((chapter) => {
          return findTargetIdInHtmlString(chapter?.Body || '', targetId);
        });
        const sectionContainsTargetSectionBodyNewModel = chapterList.find((chapter) => {
          return chapter?.Sections?.find((section) => {
            return findTargetIdInHtmlString(section?.Body || '', targetId);
          });
        });

        // Add path for element with certain id
        if (chapterContainsTargetSectionBodyNewModel) {
          const chapterHtml = new DOMParser().parseFromString(chapterContainsTargetSectionBodyNewModel.Body, 'text/html');
          const targetTag = chapterHtml?.querySelector(`[data-system-id="${targetId}"]`);
          const linkId = targetTag?.id || null; // Take link id if found
          path += `/${chapterContainsTargetSectionBodyNewModel?.WebUrl || titleToUrl(chapterContainsTargetSectionBodyNewModel.Title)}#${linkId?.replaceAll('#', '') || targetId}`;
        } else if (sectionContainsTargetSectionBodyNewModel) {
          const findSectionWithinChapter = sectionContainsTargetSectionBodyNewModel?.Sections?.find((section) => {
            return findTargetIdInHtmlString(section?.Body || '', targetId);
          });
          if (findSectionWithinChapter) {
            const chapterHtml = new DOMParser().parseFromString(findSectionWithinChapter.Body, 'text/html');
            const targetTag = chapterHtml?.querySelector(`[data-system-id="${targetId}"]`);
            const linkId = targetTag?.id || null;
            path += `/${sectionContainsTargetSectionBodyNewModel?.WebUrl || titleToUrl(sectionContainsTargetSectionBodyNewModel.Title)}/${titleToUrl(
              findSectionWithinChapter.Title,
            )}#${linkId?.replaceAll('#', '') || targetId}`;
          }
        }
      }

      return (
        <Link className={classNames} to={path} target={openInNewTab ? '_blank' : null} rel={openInNewTab ? 'nonreferer' : null}>
          {linkText}
          {openInNewTab && !classNames?.includes('inBodyContent') && newTabIcon}
        </Link>
      );
    }
  }

  // when link was created via providing url of target in Kontent
  if (!dataItemId && attribs?.href?.length > 0) {
    // if target link has hash (target is a specific part of the destination page)
    const targetId = attribs.href.match(/#([\w-_]+)$/)?.[0]?.slice(1); // ignore index 0 as it would always `#`
    const linkText = domToReact(domNode.children);
    let linkUrl = domNode.attribs.href;

    if (extra.renderAsText) {
      return <u>{linkText}</u>;
    }

    // removes the origin from the path if it is one of the known domain sites (preview or non preview sites)
    // otherwise, will render normal url
    const urlObject = getUrlObject(domNode.attribs.href);
    if (urlObject) {
      if (DOMAIN_LIST.includes(urlObject.origin)) {
        // Updates url with current in window portfolio and entity values
        const updatedUrlWithPortfolioAndEntity = updatePortfolioAndEntityUrl(urlObject);
        if (urlObject.pathname && urlObject.pathname !== '/') {
          linkUrl = `/${updatedUrlWithPortfolioAndEntity || urlObject.pathname}#${urlObject.hash?.replaceAll('#', '')}`;
        } else {
          linkUrl = '#'; // if no pathname is given, then just default to current page
        }
      }
    } else if (linkUrl?.[0] === '#') {
      // if linking directly to linkId (always has first letter as '#')
      const targetId = linkUrl;
      if (extra?.chapterList) {
        let originPath = `${window.location.pathname.split(extra?.articleWebUrl)[0]}${extra?.articleWebUrl}`;
        // New model of AR - check if chapter or section body contains item with target id
        const chapterContainsTargetSectionBodyNewModel = extra.chapterList?.find((chapter) => {
          return findTargetIdInHtmlString(chapter?.Body || '', targetId);
        });
        const sectionContainsTargetSectionBodyNewModel = extra.chapterList?.find((chapter) => {
          return chapter?.Sections?.find((section) => {
            return findTargetIdInHtmlString(section?.Body || '', targetId);
          });
        });

        // Add path for element with certain id
        if (chapterContainsTargetSectionBodyNewModel) {
          const chapterHtml = new DOMParser().parseFromString(chapterContainsTargetSectionBodyNewModel.Body, 'text/html');
          const targetTag = chapterHtml?.querySelector(`[id="${targetId}"]`);
          const linkId = targetTag?.id || null; // Take link id if found
          linkUrl =
            originPath +
            `/${chapterContainsTargetSectionBodyNewModel?.WebUrl || titleToUrl(chapterContainsTargetSectionBodyNewModel.Title)}#${linkId?.replaceAll('#', '') || targetId}`;
        } else if (sectionContainsTargetSectionBodyNewModel) {
          const findSectionWithinChapter = sectionContainsTargetSectionBodyNewModel?.Sections?.find((section) => {
            return findTargetIdInHtmlString(section?.Body || '', targetId);
          });
          if (findSectionWithinChapter) {
            const chapterHtml = new DOMParser().parseFromString(findSectionWithinChapter.Body, 'text/html');
            const targetTag = chapterHtml?.querySelector(`[id="${targetId}"]`);
            const linkId = targetTag?.id || null;
            linkUrl =
              originPath +
              `/${sectionContainsTargetSectionBodyNewModel?.WebUrl || titleToUrl(sectionContainsTargetSectionBodyNewModel.Title)}/${
                findSectionWithinChapter?.WebUrl || titleToUrl(findSectionWithinChapter.Title)
              }#${linkId?.replaceAll('#', '') || targetId}`;
          }
        }
      }
    }

    if (targetId) {
      return (
        <HashLink
          className={classNames}
          smooth
          to={linkUrl}
          scroll={() => innerPageScroll(targetId)}
          target={openInNewTab ? '_blank' : null}
          rel={openInNewTab ? 'nonreferer' : null}>
          {linkText}
          {openInNewTab && !classNames?.includes('inBodyContent') && newTabIcon}
        </HashLink>
      );
    }

    // target link has no hash (target is not a specific part of the destination page)
    return (
      <HashLink className={classNames} smooth to={linkUrl} target={openInNewTab ? '_blank' : null} rel={openInNewTab ? 'nonreferer' : null}>
        {linkText}
        {openInNewTab && !classNames?.includes('inBodyContent') && newTabIcon}
      </HashLink>
    );
  }

  const targetId = attribs?.['data-item-id'] || attribs?.href?.match(/#([\w-_]+)$/)?.[0]?.slice(1);
  let linkUrl = domNode?.attribs?.href;

  if (!validUrl(linkUrl)) {
    linkUrl = `/${getCurrentPath()}/${linkUrl}`;
  }

  return (
    <HashLink className={classNames} smooth to={linkUrl} scroll={() => innerPageScroll(targetId)} target={openInNewTab ? '_blank' : null} rel={openInNewTab ? 'nonreferer' : null}>
      {domToReact(domNode.children)}
    </HashLink>
  );
};

export const moduleMetaDataUrl = () => {
  return new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();
};

export const kontentLinkedItemObjectHtml = (linkedItemCodename, links) => {
  const body = [
    {
      elements: {
        body: { value: `<object type="application/kenticocloud" data-type="item" data-rel="component" data-codename="${linkedItemCodename}"></object>`, links: links || [] },
      },
    },
  ];
  return body;
};
