import React from 'react';
import { Loading } from 'Components';
import style from './FinancialRatiosLoading.module.scss';

function FinancialRatiosLoading() {
  return (
    <>
      <Loading className={style.loadingBanner} />

      <div id={style.financialRatiosLoading}>
        <div className={style.section}>
          <Loading className={style.topLeftCard} />
          <Loading className={style.topRightCard} />
        </div>
        <div className={style.section}>
          <Loading className={style.bottomTitleCard} />
        </div>
        <div className={style.section}>
          <Loading className={style.bottomLeftCard} />
          <Loading className={style.bottomRightCard} />
        </div>
        <div className={style.section}>
          <Loading className={style.bottomTitleCard} />
        </div>
        <div className={style.section}>
          <Loading className={style.bottomLeftCard} />
          <Loading className={style.bottomRightCard} />
        </div>
      </div>
    </>
  );
}

export default FinancialRatiosLoading;
