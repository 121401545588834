import { CONSTANTS } from './Constants';

export const BODY_TYPE = {
  NCE: {
    TAG: 'NCE',
    TEXT: 'Non-corporate Commonwealth entity',
    VALUE: 'Non-Corporate Commonwealth Entity',
  },
  CCE: {
    TAG: 'CCE',
    TEXT: 'Corporate Commonwealth entity',
    VALUE: 'Corporate Commonwealth Entity',
  },
  CC: {
    TAG: 'CC',
    TEXT: 'Commonwealth company',
    VALUE: 'Commonwealth Company',
  },
};

export const getBodyTypeTag = (value) => {
  if (value?.toLowerCase() === BODY_TYPE.NCE.VALUE.toLowerCase()) {
    return BODY_TYPE.NCE.TAG;
  } else if (value?.toLowerCase() === BODY_TYPE.CCE.VALUE.toLowerCase()) {
    return BODY_TYPE.CCE.TAG;
  } else if (value?.toLowerCase() === BODY_TYPE.CC.VALUE.toLowerCase()) {
    return BODY_TYPE.CC.TAG;
  }
  return '';
};

export const getBodyTypeTextFromTag = (value) => {
  if (value?.toLowerCase() === BODY_TYPE.NCE.TAG.toLowerCase()) {
    return BODY_TYPE.NCE.VALUE;
  } else if (value?.toLowerCase() === BODY_TYPE.CCE.TAG.toLowerCase()) {
    return BODY_TYPE.CCE.VALUE;
  } else if (value?.toLowerCase() === BODY_TYPE.CC.TAG.toLowerCase()) {
    return BODY_TYPE.CC.VALUE;
  }
  return '';
};

export const DATA_SETS_URL_FILTER_GROUPS = {
  DATA_SETS: 'DataSets',
  REPORTING_YEAR: 'ReportingYear',
  ENTITY: 'Entity',
  ADDITIONAL: 'Additional',
};

export const DATA_TABLE_TYPES = {
  FIXED: 'fixed',
  APPEND: 'row',
};

export const DATA_SETS_API = () => {
  // return `https://${process.env.REACT_APP_ENVIRONMENT}-dart-dataapi.dxau.digital/api/datasets/simplified`;
  if (process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === CONSTANTS.ENV.PROD) {
    return 'https://data.transparency.gov.au/api/datasets';
  }
  return `https://app-dof-dataapi-${process.env.REACT_APP_ENVIRONMENT}-aue-01.azurewebsites.net/api/datasets/simplified`;
};

export const DATA_SETS_FILTER_OPTIONS_API = () => {
  if (process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === CONSTANTS.ENV.PROD) {
    return `https://apps.transparency.gov.au/datarepo/getfilters`;
  }
  return `https://${process.env.REACT_APP_ENVIRONMENT}-dart-be.dxau.digital/datarepo/getfilters`;
};

export const DATA_SET_URL_CONSTANTS = {
  ALL_ENTITIES: 'allEntities',
  ALL_DATA_SETS: 'allDataSets',
};

export const TOTAL_NUMBER_LIMIT = (datasetMaxLength) => [
  { datasets: 2, reportingYears: 10, entities: 120 },
  { datasets: 10, reportingYears: 10, entities: 15 },
  { datasets: datasetMaxLength, reportingYears: 10, entities: 3 }
];